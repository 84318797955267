/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import superagent from "superagent";
import { LOGOUT } from "./constants/actionTypes";
import { store } from "./store";

// Deployment API URL ENDPOINT from .env
const API_ROOT = process.env.REACT_APP_API_BASE_URL;

let token = null;

// Token Filler
const tokenPlugin = req => {
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
};

// Manage Catched Errors
const apiCatch = err => {
  if (err.status === 401 && store.getState().common.currentUser != null) {
    store.dispatch({ type: LOGOUT });
  }
  if (process.env.REACT_APP_ENV === "development") console.dir(err);
  throw err;
};

//Manage Return
const returnRes = res => {
  return res;
};

// Base API service
const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(returnRes)
      .catch(apiCatch),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(returnRes)
      .catch(apiCatch),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .then(returnRes)
      .catch(apiCatch),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .then(returnRes)
      .catch(apiCatch)
};

// API devided per class
const Auth = {
  session: () => requests.get("/user"),
  login: (username, password) =>
    requests.post("/users/login", { username, password }),
  logout: () => requests.del("/user/session"),
  Language: {
    update: body => requests.put("/user/language", body)
  }
};

const Users = {
  Agronomists: {
    get: () => requests.get("/users/agronomists"),
    search: query => requests.get(`/users/agronomists?query=${query}`)
  }
};

const Farms = {
  get: limit => requests.get(`/farms?limit=${limit}`),
  search: query => requests.get(`/farms?query=${query}`),
  getOne: id => requests.get(`/farm/${id}`),
  update: (id, body) => requests.put(`/farm/${id}`, body),
  create: body => requests.post("/farm", body),
  Lock: {
    create: (farmId, periodId) =>
      requests.post(`/farm/${farmId}/lock`, {
        lock: {
          period: {
            id: periodId
          }
        }
      }),
    lock: (farmId, lockId) =>
      requests.put(`/farm/${farmId}/lock/${lockId}`, { lock: { status: 2 } }),
    unlock: (farmId, lockId, password) =>
      requests.put(`/farm/${farmId}/lock/${lockId}`, {
        lock: { status: 1, password: password }
      })
  },
  Paddocks: {
    get: (farmId, periodId = null) =>
      requests.get(
        `/farm/${farmId}/paddocks${periodId ? `?periodId=${periodId}` : ""}`
      ),
    create: (farmId, body) => requests.post(`/farm/${farmId}/paddock`, body),
    search: (farmId, period, crop) =>
      requests.get(
        `/farm/${farmId}/paddocks?periodId=${period}&cropId=${crop}`
      ),
    update: (farmId, paddockId, body) =>
      requests.put(`/farm/${farmId}/paddock/${paddockId}`, body),
    delete: (farmId, paddockId) =>
      requests.del(`/farm/${farmId}/paddock/${paddockId}`),
    adminDelete: (farmId, paddockId) =>
      requests.del(`/farm/${farmId}/paddock/${paddockId}?admin=1`),
    Operations: {
      get: (farmId, paddockId) =>
        requests.get(`/farm/${farmId}/paddock/${paddockId}/operations`),
      update: (farmId, paddockId, operationId, body) =>
        requests.put(
          `/farm/${farmId}/paddock/${paddockId}/operation/${operationId}`,
          body
        ),
      singleValidate: (farmId, paddockId, operationId, body) =>
        requests.put(
          `/farm/${farmId}/paddock/${paddockId}/operation/${operationId}/validate`,
          body
        ),
      import: (farmId, paddockId, targetPaddockId) =>
        requests.put(`/farm/${farmId}/paddock/${paddockId}/operations/import`, {
          paddock: { id: targetPaddockId }
        }),
      duplicate: (farmId, paddockId, operationId) =>
        requests.get(
          `/farm/${farmId}/paddock/${paddockId}/operation/${operationId}/copy`
        ),
      create: (farmId, paddockId, body) =>
        requests.post(`/farm/${farmId}/paddock/${paddockId}/operation`, body),
      delete: (farmId, paddockId, operationId) =>
        requests.del(
          `/farm/${farmId}/paddock/${paddockId}/operation/${operationId}`
        ),
      validate: (farmId, paddockId) =>
        requests.put(
          `/farm/${farmId}/paddock/${paddockId}/operations/validate`
        ),
      Canvas: {
        delete: (farmId, paddockId) =>
          requests.del(`/farm/${farmId}/paddock/${paddockId}/operations/canvas`)
      }
    }
  },
  FarmMachineries: {
    get: (farmId, periodId, operationId) =>
      requests.get(
        `/farm/${farmId}/farmMachineries?periodId=${periodId}&operationTypeId=${operationId}`
      ),
    getOne: (farmId, FarmMachineryId) =>
      requests.get(`/farm/${farmId}/farmMachinery/${FarmMachineryId}`),
    getAll: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/farmMachineries?periodId=${periodId}`),
    search: (farmId, periodId, operationId, query) =>
      requests.get(
        `/farm/${farmId}/farmMachineries?periodId=${periodId}&operationType=${operationId}&query=${query}`
      ),
    update: (farmId, farmMachineryId, body) =>
      requests.put(`/farm/${farmId}/farmMachinery/${farmMachineryId}`, body),
    create: (farmId, body) =>
      requests.post(`/farm/${farmId}/farmMachinery`, body),
    delete: (farmId, farmMachineryId) =>
      requests.del(`/farm/${farmId}/farmMachinery/${farmMachineryId}`)
  },
  FarmInputsPurchases: {
    get: (farmId, inputType, periodId) =>
      requests.get(
        `/farm/${farmId}/farmInputsPurchases/${inputType}?periodId=${periodId}`
      ),
    getWithCrop: (farmId, inputType, periodId, cropId) =>
      requests.get(
        `/farm/${farmId}/farmInputsPurchases/${inputType}?periodId=${periodId}&cropId=${cropId}`
      ),
    getOne: (farmId, inputType, farmInputPurchaseId) =>
      requests.get(
        `/farm/${farmId}/farmInputPurchase/${inputType}/${farmInputPurchaseId}`
      ),
    getInputEstimated: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/inputEstimation?periodId=${periodId}`),
    postInputEstimated: (farmId, periodId) =>
      requests.post(`/farm/${farmId}/inputEstimation?periodId=${periodId}`),
    deleteInputEstimated: (farmId, periodId) =>
      requests.del(`/farm/${farmId}/inputEstimation?periodId=${periodId}`),
    update: (farmId, inputType, farmInputPurchaseId, body) =>
      requests.put(
        `/farm/${farmId}/farmInputPurchase/${inputType}/${farmInputPurchaseId}`,
        body
      ),
    create: (farmId, inputType, body) =>
      requests.post(`/farm/${farmId}/farmInputPurchase/${inputType}`, body),
    delete: (farmId, inputType, farmInputPurchaseId) =>
      requests.del(
        `/farm/${farmId}/farmInputPurchase/${inputType}/${farmInputPurchaseId}`
      ),
    validate: (farmId, inputType, periodId) =>
      requests.put(
        `/farm/${farmId}/farmInputsPurchases/${inputType}/validate?periodId=${periodId}`
      ),
    NPK: {
      check: (farmId, periodId, inputId, N, P, K) =>
        requests.get(
          `/farm/${farmId}/input/npk?periodId=${periodId}&fertId=${inputId}&N=${N}&P=${P}&K=${K}`
        )
    }
  },
  Blocsperi: {
    get: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/blocperi?periodId=${periodId}`),
    create: (farmId, body) => requests.post(`/farm/${farmId}/blocperi`, body),
    update: (farmId, blocperiId, body) =>
      requests.put(`/farm/${farmId}/blocperi/${blocperiId}`, body),
    delete: (farmId, blocperiId) =>
      requests.del(`/farm/${farmId}/blocperi/${blocperiId}`)
  },
  Blocs: {
    get: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/blocs?periodId=${periodId}`),
    create: (farmId, body) => requests.post(`/farm/${farmId}/bloc`, body),
    update: (farmId, blocId, body) =>
      requests.put(`/farm/${farmId}/bloc/${blocId}`, body),
    delete: (farmId, blocId) => requests.del(`/farm/${farmId}/bloc/${blocId}`)
  },
  CropsTechs: {
    get: (farmId, periodId, cropId) =>
      requests.get(
        `/farm/${farmId}/cropsTechs?cropId=${cropId}&periodId=${periodId}`
      ),
    update: (farmId, cropsTechsId, body) =>
      requests.put(`/farm/${farmId}/cropsTechs/${cropsTechsId}`, body)
  },
  CropsResidues: {
    get: (farmId, periodId, cropId) =>
      requests.get(
        `/farm/${farmId}/cropsResidues?cropId=${cropId}&periodId=${periodId}`
      ),
    update: (farmId, cropsResiduesId, body) =>
      requests.put(`/farm/${farmId}/cropsResidues/${cropsResiduesId}`, body)
  },
  CropsIrrigations: {
    get: (farmId, periodId, cropId) =>
      requests.get(
        `/farm/${farmId}/cropIrrigations?cropId=${cropId}&periodId=${periodId}`
      ),
    update: (farmId, cropsResiduesId, body) =>
      requests.put(`/farm/${farmId}/cropIrrigation/${cropsResiduesId}`, body)
  },
  CropsOrganic: {
    get: (farmId, periodId, cropId) =>
      requests.get(
        `/farm/${farmId}/cropsOrganics?cropId=${cropId}&periodId=${periodId}`
      ),
    create: (farmId, periodId, cropId, body) =>
      requests.post(
        `/farm/${farmId}/cropsOrganics?periodId=${periodId}&cropId=${cropId}`,
        body
      ),
    update: (farmId, cropsOrganicsId, body) =>
      requests.put(`/farm/${farmId}/cropsOrganics/${cropsOrganicsId}`, body),
    delete: (farmId, cropsOrganicsId) =>
      requests.del(`/farm/${farmId}/cropsOrganics/${cropsOrganicsId}`)
  },
  LandOccupation: {
    get: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/landOccupation?periodId=${periodId}`),
    update: (farmId, landOccupationId, body) =>
      requests.put(`/farm/${farmId}/landOccupation/${landOccupationId}`, body)
  },
  GhgPrograms: {
    get: farmId => requests.get(`/farm/${farmId}/ghgPrograms`),
    create: (farmId, body) => requests.post(`/farm/${farmId}/ghgProgram`, body),
    update: (farmId, ghgProgramId, body) =>
      requests.put(`/farm/${farmId}/ghgProgram/${ghgProgramId}`, body),
    delete: (farmId, ghgProgramId) =>
      requests.del(`/farm/${farmId}/ghgProgram/${ghgProgramId}`)
  },
  Hedgerows: {
    get: (farmId, periodId) =>
      requests.get(`/farm/${farmId}/hedgerows?periodId=${periodId}`),
    create: (farmId, body) => requests.post(`/farm/${farmId}/hedgerows`, body),
    update: (farmId, hedgerowId, body) =>
      requests.put(`/farm/${farmId}/hedgerows/${hedgerowId}`, body),
    delete: (farmId, hedgerowId) =>
      requests.del(`/farm/${farmId}/hedgerows/${hedgerowId}`)
  }
};

const Machineries = {
  get: (operationId, periodId, farmId) =>
    requests.get(
      `/machineries?operationTypeId=${operationId}&periodId=${periodId}&farmId=${farmId}`
    ),
  search: (operationId, periodId, farmId, query) =>
    requests.get(
      `/machineries?operationTypeId=${operationId}&periodId=${periodId}&farmId=${farmId}&query=${query}`
    )
};

const Inputs = {
  get: (inputType, periodId, farmId) =>
    requests.get(
      `/inputs/${inputType}?periodId=${periodId}&farmId=${farmId}&limit=50`
    ),
  search: (inputType, periodId, farmId, query, page = 0) =>
    requests.get(
      `/inputs/${inputType}?periodId=${periodId}&farmId=${farmId}&query=${query}&page=${page}&limit=50`
    ),
  Prices: {
    get: (inputType, inputId, periodId, farmId) =>
      requests.get(
        `/input/${inputType}/${inputId}/prices?periodId=${periodId}&farmId=${farmId}`
      )
  },
  Quantities: {
    get: (inputType, inputId, periodId) =>
      requests.get(
        `/input/${inputType}/${inputId}/quantities?periodId=${periodId}`
      )
  }
};

const Countries = {
  get: () => requests.get("/countries"),
  search: query => requests.get(`/countries?query=${query}`)
};

const Regions = {
  get: countryId => requests.get(`/country/${countryId}/regions`),
  search: (countryId, query) =>
    requests.get(`/country/${countryId}/regions?query=${query}`)
};

const OperationTypes = {
  get: () => requests.get("/operation-types")
};

const Periods = {
  get: farmId => requests.get(`/periods?farmId=${farmId}`)
};

const Crops = {
  get: () => requests.get("/crops")
};

const Phs = {
  get: () => requests.get("/ph")
};

const Moistures = {
  get: () => requests.get("/moistures")
};

const Drainages = {
  get: () => requests.get("/drainages")
};

const Frequencies = {
  get: () => requests.get("/frequencies")
};

const SoilTypes = {
  get: () => requests.get("/soiltypes")
};

const Languages = {
  get: () => requests.get("/languages")
};

const Techs = {
  get: () => requests.get("/techs")
};

const Covers = {
  get: () => requests.get("/covers")
};

const Programs = {
  get: () => requests.get("/programs")
};

const Species = {
  get: () => requests.get("/trees"),
  search: query => requests.get(`/trees?query=${query}`)
};

const Occupations = {
  get: () => requests.get("/occupations")
};

const Destructions = {
  get: () => requests.get("/destructions")
};

const Irrigations = {
  get: () => requests.get("/irrigations")
};

const Sources = {
  get: () => requests.get("/sources")
};

const Powers = {
  get: () => requests.get("/powers")
};

const CustomTypes = {
  get: farmId => requests.get(`/customTypes?farmId=${farmId}`)
};

const API = {
  Auth,
  Users,
  Farms,
  Countries,
  Regions,
  OperationTypes,
  Periods,
  Machineries,
  Inputs,
  Crops,
  Phs,
  Moistures,
  Drainages,
  Frequencies,
  SoilTypes,
  Languages,
  Covers,
  Programs,
  Species,
  Techs,
  Occupations,
  Destructions,
  Irrigations,
  Sources,
  Powers,
  CustomTypes,
  setToken: _token => {
    token = _token;
  }
};

export default API;
