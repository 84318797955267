/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
const defaultTheme = createMuiTheme();

const palette = {
  primary: { main: "#F4CE83" },
  secondary: { main: "#B07C49" },
  text: { primary: "#4d4f45", faded: fade("#4d4f45", 0.7) },
  info: {
    light: "#8ca7da",
    main: "#527BC7",
    dark: "#32579a",
  },
  warning: {
    light: "#ffc97e",
    main: "#FFA931",
    dark: "#e48500",
  },
  error: {
    light: "#ee8a8e",
    main: "#d92027",
    dark: "#96161b",
  },
  success: {
    light: "#a7da8c",
    main: "#7bc752",
    dark: "#579a32",
  },
  bio: {
    main: "#37a640",
  },
};

const shape = {
  borderRadius: 8,
};

const overrides = {
  MuiCssBaseline: {
    "@global": {
      "input::-webkit-search-cancel-button": {
        "-webkit-appearance": "none",
      },
      html: {
        "overflow-y": "scroll",
      },
      "input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
      },
      "input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: 0,
      marginBottom: 0,
    },
    root: {
      height: "fit-content",
    },
  },
  MuiContainer: {
    root: {
      width: "1200px",
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: defaultTheme.palette.grey[400],
    },
    colorSecondary: {
      backgroundColor: defaultTheme.palette.grey[400],
    },
    barColorPrimary: {
      background: `linear-gradient(45deg, ${defaultTheme.palette.info.light} 50%, ${defaultTheme.palette.info.dark} 90%)`,
    },
    barColorSecondary: {
      background: `linear-gradient(45deg, ${defaultTheme.palette.success.light} 50%, ${defaultTheme.palette.success.dark} 90%)`,
    },
  },
  MuiDialogTitle: {
    root: {
      backgroundColor: palette.primary.main,
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "1.2em",
    },
  },
};

const props = {
  MuiTextField: {
    variant: "outlined",
    size: "small",
    color: "secondary",
  },
  MuiButton: {
    variant: "contained",
    size: "medium",
    color: "secondary",
  },
  Typography: {
    color: "textPrimary",
  },
  FormControl: {
    color: "secondary",
  },
};

export default createMuiTheme({ palette, shape, overrides, props });
