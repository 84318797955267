/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  UPDATE_FARM_INPUT_PURCHASE,
  ADD_FARM_INPUT_PURCHASE,
  REMOVE_FARM_INPUT_PURCHASE
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import BlankIcon from "../../BlankIcon";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";
import SearchInputs from "./SearchInputs";
import PriceConfirmation from "./PriceConfirmation";
import DeleteConfirmation from "../../DeleteConfirmation";
import CustomInput from "./CustomInput";

//Theme
import customTheme from "../../../theme";

//UI Components
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Portal from "@material-ui/core/Portal";

const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  rowContainer: {
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    paddingRight: "10px"
  },
  confirmInputs: {
    display: "flex",
    justifyContent: "center"
  }
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  selectedInputType: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  index: PropTypes.number,
  data: PropTypes.object,

  //Provided props
  inputEstimated: PropTypes.number,
  updateFarmInputPurchase: PropTypes.func, // Provided by Redux
  addFarmInputPurchase: PropTypes.func, // Provided by Redux
  deleteFarmInputPurchase: PropTypes.func, // Provided by Redux
  classes: PropTypes.object // Provided by Redux
};

const PurchaseRow = ({
  classes,
  inputEstimated,
  selectedInputType,
  farmId,
  selectedPeriod,
  index,
  data = {},
  updateFarmInputPurchase,
  addFarmInputPurchase,
  deleteFarmInputPurchase
}) => {
  const prevSelectedInputTypeRef = useRef();
  useEffect(() => {
    prevSelectedInputTypeRef.current = selectedInputType;
  });
  const prevSelectedInputType = prevSelectedInputTypeRef.current;

  const [id, setId] = useState(null);
  const [price, setPrice] = useState("");
  const [isGrouped, setIsGrouped] = useState(0);
  const [unit, setUnit] = useState("");
  const [grouped, setGrouped] = useState({});
  const [status, setStatus] = useState(0);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(0);
  const [currency, setCurrency] = useState("");
  const [, setLast] = useState(0);
  const [average, setAverage] = useState(0);
  const [isAverage, setIsAverage] = useState(0);
  const [input, setInput] = useState(null);
  const [lock, setLock] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [changed, setChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [notDeletable, setNotDeletable] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [composition, setComposition] = useState({});

  const { t } = useTranslation();
  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR
  ]);

  useEffect(() => {
    if (data.id) {
      setId(data.id);
      setPrice(data.price === null ? "" : data.price);
      setIsGrouped(data.isGrouped);
      setGrouped(
        data.input &&
          data.input.prices &&
          data.input.prices.grouped &&
          data.input.prices.grouped.id
          ? data.input.prices.grouped
          : {}
      );
      setLock(data.lock);
      setStatus(data.status);
      setCurrency(data.currency);
      if (data.input && data.input.prices) {
        setPriceMin(data.input.prices.min || 0);
        setPriceMax(data.input.prices.max || 0);
        setAverage(data.input.prices.average || 0);
        setLast(data.input.prices.last || 0);
        setUnit(data.input.unit || "");
      }
      setNotDeletable(data.flagDelete);
      setIsAverage(data.isAverage);
      const {
        id: inputId,
        name: inputName,
        unit: inputUnit,
        operationUnit: inputOperationUnit,
        price: inputPrice
      } = data.input || {};
      setInput(
        data.input
          ? {
              id: inputId,
              name: inputName,
              unit: inputUnit,
              operationUnit: inputOperationUnit,
              price: inputPrice
            }
          : {}
      );
      setErrors([]);
    }
  }, [data]);

  const clear = useCallback(() => {
    if (id === null && input !== null) {
      setChanged(false);
      setConfirmed(false);
      setPrice("");
      setIsGrouped(0);
      setUnit("");
      setGrouped({});
      setStatus(0);
      setPriceMin(0);
      setPriceMax(0);
      setLast(0);
      setAverage(0);
      setIsAverage(0);
      setInput(null);
      setErrors([]);
    }
  }, [id, input]);

  useEffect(() => {
    if (selectedInputType !== prevSelectedInputType) {
      clear();
    }
  }, [selectedInputType, clear, prevSelectedInputType]);

  const update = useCallback(
    requestBody => {
      const updatePurchase = agent.Farms.FarmInputsPurchases.update(
        farmId,
        selectedInputType,
        id,
        requestBody
      )
        .then(res => {
          updateFarmInputPurchase(res.body.farmInputPurchase, index);
          setChanged(false);
          setConfirmed(false);
        })
        .catch(() => {
          setChanged(false);
          setConfirmed(false);
        });

      toast.promise(updatePurchase, {
        loading: t("global.loading"),
        success: t("purchases.section.notifications.input-saved"),
        error: t("purchases.section.notifications.error")
      });
    },
    [updateFarmInputPurchase, farmId, selectedInputType, id, index, t]
  );

  const validateData = useCallback(() => {
    if (input && !input.id) {
      setErrors(errors => [
        ...errors,
        {
          field: "input",
          message: t("purchases.section.errors.required-input")
        }
      ]);
    } else {
      setErrors(errors => [...errors.filter(item => item.field !== "input")]);
    }
    if (input && input.id) {
      if (!inputEstimated && (price === "" || price < 0)) {
        setErrors(errors => [
          ...errors,
          { field: "price", message: t("purchases.section.errors.null-price") }
        ]);
      } else {
        setErrors(errors => [...errors.filter(item => item.field !== "price")]);
      }
    } else {
      setErrors(errors => [...errors.filter(item => item.field === "input")]);
    }
  }, [price, input, t, inputEstimated]);

  useEffect(() => {
    if (changed) {
      validateData();
    }
  }, [changed, validateData]);

  useEffect(() => {
    if (
      !confirmed &&
      !confirmOpen &&
      !isAverage &&
      !isGrouped &&
      price > 0 &&
      price !== data.price &&
      changed &&
      (priceMin > 0 || priceMax > 0) &&
      (price < priceMin || price > priceMax || priceMin === priceMax)
    ) {
      setLoading(true);
      const timer = setTimeout(() => setConfirmOpen(true), 1100);
      return () => clearTimeout(timer);
    } else if (!confirmOpen) {
      setConfirmed(true);
      setLoading(false);
    }
  }, [
    price,
    data.price,
    priceMin,
    priceMax,
    changed,
    isAverage,
    isGrouped,
    confirmOpen,
    confirmed
  ]);

  useEffect(() => {
    const errCheck = errors.filter(item => item.field !== "price");
    if (
      changed &&
      errCheck.length === 0 &&
      id &&
      id !== null &&
      confirmed &&
      (price !== data.price || (status === 3 && status !== data.status))
    ) {
      const requestBody = {
        farmInputPurchase: {
          isGrouped: isGrouped,
          isAverage: isAverage,
          price: price,
          status: status,
          input: {
            ...input,
            prices: {
              grouped: grouped.id ? grouped : {},
              min: priceMin,
              max: priceMax
            }
          },
          composition
        }
      };
      const timer = setTimeout(() => update(requestBody), 700);
      return () => clearTimeout(timer);
    }
  }, [
    confirmed,
    changed,
    errors,
    isGrouped,
    price,
    status,
    priceMin,
    priceMax,
    input,
    grouped,
    selectedPeriod,
    update,
    isAverage,
    id,
    data.price,
    data.status,
    composition
  ]);

  const handleCreate = () => {
    setLoading(true);
    if (
      errors.length === 0 &&
      (inputEstimated || price >= 0) &&
      input &&
      input.id
    ) {
      const requestBody = {
        farmInputPurchase: {
          isGrouped: isGrouped,
          isAverage: isAverage,
          price: price,
          status: status,
          input: {
            ...input,
            prices: {
              grouped: grouped.id ? grouped : {},
              min: priceMin,
              max: priceMax
            }
          },
          name: input.name,
          composition,
          period: {
            id: selectedPeriod
          }
        }
      };

      const createPurchase = agent.Farms.FarmInputsPurchases.create(
        farmId,
        selectedInputType,
        requestBody
      )
        .then(res => {
          addFarmInputPurchase(res.body.farmInputPurchase);
          setChanged(false);
          setConfirmed(false);
          setId(null);
          setPrice("");
          setIsGrouped(0);
          setUnit("");
          setGrouped({});
          setStatus(0);
          setPriceMin(0);
          setPriceMax(0);
          setLast(0);
          setAverage(0);
          setIsAverage(0);
          setInput(null);
          setComposition({});
          setErrors([]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      toast.promise(createPurchase, {
        loading: t("global.loading"),
        success: t("purchases.section.notifications.input-added"),
        error: t("purchases.section.notifications.error")
      });
    }
  };

  const handleDelete = () => {
    const deletePurchase = agent.Farms.FarmInputsPurchases.delete(
      farmId,
      selectedInputType,
      id
    ).then(() => {
      deleteFarmInputPurchase(index);
    });

    toast.promise(deletePurchase, {
      loading: t("global.loading"),
      success: t("purchases.section.notifications.input-deleted"),
      error: t("purchases.section.notifications.error")
    });
  };

  const handleStatus = () => {
    setStatus(3);
    setChanged(true);
    setConfirmed(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  useEffect(() => {
    if (!inputEstimated) {
      if (
        (data && data.status !== 3 && status !== 3 && changed) ||
        (changed && data && data.price !== price)
      ) {
        if (parseFloat(price) === 0 && !isGrouped) {
          setStatus(0);
        } else if (
          (parseFloat(priceMin) <= parseFloat(price) &&
            parseFloat(price) <= parseFloat(priceMax)) ||
          isGrouped
        ) {
          setStatus(2);
        } else {
          setStatus(1);
        }
      }
    } else {
      handleStatus();
    }
  }, [
    inputEstimated,
    price,
    status,
    changed,
    isGrouped,
    priceMax,
    priceMin,
    data
  ]);

  return (
    <Grid className={classes.rowContainer} container spacing={1}>
      <Grid item>
        <SearchInputs
          farmId={farmId}
          disabled={!permissions.canEdit() || Boolean(id && input && input.id)}
          periodId={selectedPeriod}
          selectedInputType={selectedInputType}
          query={query}
          setQuery={setQuery}
          onChange={async input => {
            setIsAverage(0);
            setIsGrouped(0);
            if (!inputEstimated) {
              setPrice("");
            }
            if (input && input.custom) {
              setCustomOpen(true);
              return;
            }
            if (input && input.id) {
              const { body, error } = await agent.Inputs.Prices.get(
                selectedInputType,
                input.id,
                selectedPeriod,
                farmId
              );
              if (!error) {
                const prices = body.prices;
                setUnit(input.unit);
                if (!inputEstimated) {
                  setPrice(input.price === null ? "" : input.price);
                }
                setInput(input);
                setAverage(prices.average);
                setGrouped(prices.grouped.id ? prices.grouped : {});
                setPriceMin(prices.min);
                setPriceMax(prices.max);
                setLast(prices.last);
                setCurrency(prices.currency);
              }
            } else {
              setAverage(0);
              setGrouped({});
              setPriceMin(0);
              setPriceMax(0);
              setInput({});
              setLast(0);
            }
            setChanged(true);
          }}
          errors={errors}
          value={input}
          label={t("purchases.section.input")}
          NLabel={t("purchases.section.tooltip.N")}
          PLabel={t("purchases.section.tooltip.P")}
          KLabel={t("purchases.section.tooltip.K")}
          name="input"
        />
      </Grid>
      <Grid item data-woi="input-price">
        <FieldWithTextAdornment
          // TODO: Add condition to make the field disbaled in inputEstimation is 1
          disabled={
            inputEstimated ||
            !permissions.canEdit() ||
            isGrouped ||
            !input ||
            !input.id
              ? true
              : false
          }
          name="price"
          type="number"
          label={t("purchases.section.price")}
          format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,3})?$/}
          value={inputEstimated ? "" : price}
          errors={inputEstimated ? null : errors}
          error={Boolean(status === -1)}
          onChange={e => {
            setIsAverage(0);
            setPrice(e.target.value);
            setConfirmed(false);
            setChanged(true);
          }}
          onFocus={event => event.target.select()}
          adornment={!inputEstimated && unit ? `${currency}/${unit}` : ""}
          width="130px"
        />
      </Grid>
      {/* TODO: Add condition to not display grid item if inputEstimation is 1
          Check with Yannick if on the DB we don't care about the validation status of the inputs when inputEstimation is 1
      */}
      {inputEstimated ? null : (
        <Grid
          item
          style={{ paddingTop: "9px" /* Status height adjustement */ }}
        >
          {permissions.canView() && status === 0 && id !== null ? (
            <Tooltip title={t("purchases.section.validation.status-0")} arrow>
              <div>
                <IconButton
                  disabled={!permissions.canEdit()}
                  size="small"
                  onClick={handleStatus}
                >
                  <ErrorIcon
                    style={{ color: customTheme.palette.error.main }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ) : permissions.canView() && status === 1 ? (
            <Tooltip title={t("purchases.section.validation.status-1")} arrow>
              <div>
                <IconButton
                  disabled={!permissions.canEdit()}
                  size="small"
                  onClick={handleStatus}
                >
                  <WarningIcon
                    style={{ color: customTheme.palette.warning.main }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ) : permissions.canView() && status === 2 ? (
            <Tooltip title={t("purchases.section.validation.status-2")} arrow>
              <div>
                <IconButton
                  disabled={!permissions.canEdit()}
                  size="small"
                  onClick={handleStatus}
                >
                  <CheckCircleIcon
                    style={{ color: customTheme.palette.success.main }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ) : status === 3 ? (
            <Tooltip title={t("purchases.section.validation.status-3")} arrow>
              <div>
                <IconButton disabled size="small">
                  <VerifiedUserIcon
                    style={{ color: customTheme.palette.success.dark }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ) : status === -1 ? (
            <Tooltip title={t("purchases.section.validation.status--1")} arrow>
              <div>
                <IconButton disabled size="small">
                  <ErrorOutlineIcon
                    style={{ color: customTheme.palette.error.main }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <div>
              <IconButton disabled size="small">
                <BlankIcon />
              </IconButton>
            </div>
          )}
        </Grid>
      )}
      <Grid item>
        <Paper variant="outlined" className={classes.checkboxes}>
          <Checkbox
            disabled={!permissions.canEdit() || !grouped.id ? true : false}
            size="small"
            name="grouped"
            checked={isGrouped ? true : false}
            onChange={(e, data) => {
              setPrice(data ? grouped.price : "");
              setIsAverage(0);
              setIsGrouped(data ? 1 : 0);
              setChanged(true);
              setConfirmed(data ? true : false);
            }}
          />
          {t("purchases.section.grouped-label")}
        </Paper>
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled
          name="min"
          format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,3})?$/}
          label={t("purchases.section.min")}
          value={priceMin > 0 || priceMax > 0 ? priceMin : ""}
          adornment={unit ? `${currency}/${unit}` : ""}
          width="130px"
        />
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled
          name="max"
          format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,3})?$/}
          label={t("purchases.section.max")}
          value={priceMin > 0 || priceMax > 0 ? priceMax : ""}
          adornment={unit ? `${currency}/${unit}` : ""}
          width="130px"
        />
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={classes.checkboxes}>
          <Checkbox
            disabled={
              !permissions.canEdit() || isGrouped || average === 0
                ? true
                : false
            }
            size="small"
            name="average"
            onChange={(e, data) => {
              setIsAverage(data ? 1 : 0);
              setPrice(data ? average : "");
              setChanged(true);
              setConfirmed(data ? true : false);
            }}
            checked={isAverage ? true : false}
          />
          {t("purchases.section.average-label")}
        </Paper>
      </Grid>
      {id === null && permissions.canEdit() ? (
        <Grid item>
          <Button
            disabled={
              loading ||
              !input ||
              (input && !input.id) ||
              (!inputEstimated && (!(price >= 0) || price === ""))
            }
            style={{ minWidth: "120px" }}
            fullWidth={true}
            onClick={handleCreate}
          >
            {t("purchases.section.add-button-label")}
          </Button>
        </Grid>
      ) : ((!permissions.canView() && status !== 3) || permissions.canView()) &&
        permissions.canEdit() ? (
        <Tooltip
          title={
            (notDeletable ? (
              true
            ) : (
              false
            )) ? (
              <Typography>
                {t("purchases.section.not-deletable-message")}
              </Typography>
            ) : (
              ""
            )
          }
        >
          <div>
            <IconButton
              disabled={notDeletable ? true : false}
              onClick={handleDeleteConfirm}
            >
              <DeleteForever
                color={(notDeletable ? true : false) ? "disabled" : "error"}
              />
            </IconButton>
          </div>
        </Tooltip>
      ) : null}
      <Portal>
        <PriceConfirmation
          open={confirmOpen}
          onCancel={() => {
            setPrice(data.price ? data.price : "");
            setIsAverage(data ? data.isAverage : 0);
            setIsGrouped(data ? data.isGrouped : 0);
            setConfirmed(false);
            setConfirmOpen(false);
          }}
          onConfirm={() => {
            setConfirmOpen(false);
            setConfirmed(true);
          }}
          currency={currency}
          errors={errors}
          unit={unit}
          price={price}
          setPrice={setPrice}
          min={priceMin}
          max={priceMax}
        />
      </Portal>
      <Portal>
        <CustomInput
          open={customOpen}
          onCancel={() => {
            setCustomOpen(false);
          }}
          onConfirm={(inputId, inputName, composition, currency, unit) => {
            setInput({ id: inputId, name: inputName });
            setComposition(composition);
            setCurrency(currency);
            setUnit(unit);
            setCustomOpen(false);
          }}
          farmId={farmId}
          periodId={selectedPeriod}
        />
      </Portal>
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
    </Grid>
  );
};

PurchaseRow.propTypes = propsDefinition;

const mapDispatchToProps = dispatch => ({
  updateFarmInputPurchase: (payload, index) =>
    dispatch({
      type: UPDATE_FARM_INPUT_PURCHASE,
      payload,
      index
    }),
  addFarmInputPurchase: payload =>
    dispatch({ type: ADD_FARM_INPUT_PURCHASE, payload }),
  deleteFarmInputPurchase: index =>
    dispatch({ type: REMOVE_FARM_INPUT_PURCHASE, index })
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PurchaseRow));
