/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  variant: PropTypes.string,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

const TypographyWithIcon = ({ variant, color, label, icon }) => {
  return (
    <React.Fragment>
      <Typography variant={variant || "body1"} color={color || "textSecondary"}>
        {label}
      </Typography>
      <Box display='flex' variant={variant || "body1"}>
        {icon}
      </Box>
    </React.Fragment>
  );
};

TypographyWithIcon.propTypes = propsDefinition;

export default TypographyWithIcon;
