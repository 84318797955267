/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//UI Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  blocperi: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  errors: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,

  //Provided props
  blocsperi: PropTypes.array, // Provided by Redux
};

const SelectBlocperi = (props) => {
  const {
    blocperi,
    onChange,
    blocsperi = [],
    disabled,
    label,
    errors: errList,
    name,
    width,
  } = props;
  const errors = errList.filter((item) => item.field === name);
  const { field, message } = errors.length > 0 ? errors[0] : {};
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (blocperi === null || !blocperi.id) {
      setValue("");
    }
  }, [blocperi]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      closeIcon={<HighlightOffIcon style={{ fontSize: "20px", color: "red" }} />}
      style={{
        width: width || "230px",
      }}
      disabled={disabled}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      freeSolo
      size='small'
      onBlur={() => {
        if (blocperi === null || !blocperi.id) {
          setValue("");
          onChange(null, null);
        }
      }}
      onInputChange={(e, d) => {
        if (d) {
          setValue(d);
        }
      }}
      inputValue={blocperi === null || !blocperi.id ? value : blocperi.bloc.name}
      forcePopupIcon={open || (blocperi && blocperi.id) ? false : true}
      popupIcon={<SearchIcon />}
      value={blocperi}
      getOptionLabel={(option) =>
        option.bloc && option.bloc.name ? option.bloc.name : ""
      }
      options={blocsperi.length > 0 ? blocsperi : [{ ...blocsperi }]}
      ListboxProps={{ "data-woi": "bloc-list" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='normal'
          variant='outlined'
          error={field ? true : false}
          helperText={message ? message : ""}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

SelectBlocperi.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  blocsperi: state.land.blocsperi,
});

export default connect(mapStateToProps)(SelectBlocperi);
