/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import SelectCrop from "./SelectCrop";
import SelectBlocperi from "./SelectBlocperi";
import BlankIcon from "../../BlankIcon";

//UI Components
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  checkboxes: {
    paddingRight: "16px",
    backgroundColor: "inherit",
  },
  radioGroup: {
    backgroundColor: "inherit",
  },
  notchedOutline: {
    borderWidth: "0px",
  },
});

const propsDefinition = {
  //Provided props
  paddocks: PropTypes.array, // Provided by Redux
  classes: PropTypes.object, // Provided by Redux
};

const PaddockRowTotalArea = ({ classes, paddocks }) => {
  const { t } = useTranslation();
  const [totalArea, setTotalArea] = useState(0);

  useEffect(() => {
    let total = 0;
    paddocks.forEach((item) => {
      if (!item.isSecondCrop) {
        total += item.area;
      }
    });
    setTotalArea(total);
  }, [paddocks]);

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item style={{ visibility: "hidden" }}>
        <SelectBlocperi
          name='blocperi'
          width='140px'
          label={t("land.paddocks.bloc")}
          blocperi={null}
          errors={[]}
        />
      </Grid>
      <Grid item style={{ visibility: "hidden" }}>
        <SelectCrop
          name='crop'
          width='180px'
          label={t("land.paddocks.crop")}
          crop={null}
          errors={[]}
        />
      </Grid>
      <Grid item style={{ visibility: "hidden" }}>
        <Paper variant='outlined' className={classes.checkboxes}>
          <Checkbox size='small' name='grouped' />
          {t("land.paddocks.crop-second")}
        </Paper>
      </Grid>
      <Grid item style={{ visibility: "hidden" }}>
        <IconButton disabled size='small'>
          <BlankIcon />
        </IconButton>
      </Grid>
      <Grid item style={{ visibility: "hidden" }}>
        <Paper variant='outlined' className={classes.checkboxes}>
          <Checkbox size='small' name='grouped' />
          {t("land.paddocks.organic")}
        </Paper>
      </Grid>
      <Grid item>
        <Tooltip arrow title={t("land.paddocks.total-area-tooltip")}>
          <TextField
            style={{ width: "120px", borderWidth: "0px" }}
            disabled
            color='secondary'
            label={t("land.paddocks.total-area")}
            value={totalArea}
            InputProps={{
              inputProps: { min: 0 },
              classes: {
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <Box color='secondary.main'>ha</Box>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

PaddockRowTotalArea.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  paddocks: state.land.paddocks,
});

export default connect(mapStateToProps)(withStyles(styles)(PaddockRowTotalArea));
