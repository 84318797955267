/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import agent from "./agent";
import { ASYNC_START, ASYNC_END, LOGIN, LOGOUT, APP_LOAD } from "./constants/actionTypes";
import i18n from "./i18n";
import jwtDecode from "jwt-decode";

//Notification
import toast from "react-hot-toast";

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    if (action.type === "LOGIN") {
      toast.promise(action.payload, {
        loading: "Loading...",
        success: "Succesfully logged in",
        error: "Error while logging in",
      });
    }

    action.payload
      .then((res) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.done = true;
        action.payload = res.body;
        store.dispatch({
          type: ASYNC_END,
          usedtype: action.type,
          promise: action.payload,
        });
        store.dispatch(action);
      })
      .catch((error) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.error = true;
        action.payload = error.response ? error.response.body : error;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      });

    return;
  }

  next(action);
};

const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN) {
    if (!action.error) {
      window.localStorage.setItem("sessionToken", action.payload.sessionToken);
      agent.setToken(action.payload.sessionToken);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem("sessionToken", "");
    localStorage.setItem("reduxState", "");
    agent.setToken(null);
  }

  next(action);
};

const setLanguage = (language) => {
  localStorage.setItem("i18nextLng", language);
  i18n.changeLanguage(language);
};

const languageMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN) {
    if (!action.error) {
      const language = jwtDecode(action.payload.sessionToken).language.toLowerCase();
      localStorage.setItem("i18nextLng", language);
      i18n.changeLanguage(language);
    }
  } else if (action.type === APP_LOAD && action.payload && action.payload.language) {
    const language = action.payload.language.toLowerCase();
    setLanguage(language);
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware, languageMiddleware };
