/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import bioLogo from "../assets/bio/bio.jpg";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  style: PropTypes.object,
  height: PropTypes.string,
};

const BioLogo = ({ style, height }) => {
  return (
    <img
      style={{ borderRadius: "8px", height: height || "24px", ...style }}
      alt='bioLogo'
      src={bioLogo}
    />
  );
};

BioLogo.propTypes = propsDefinition;

export default BioLogo;
