/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,

  //Provided props
  operationTypes: PropTypes.array, // Provided by Redux
};

const SelectOperation = ({ value, onChange, operationTypes, focused }) => {
  if (operationTypes && operationTypes.length > 0 && value >= 0) {
    return (
      <FormControl
        disabled={focused ? false : true}
        variant='standard'
        size='small'
        style={{ width: "140px" }}
        data-woi='select-operation'
      >
        <Select name='operationType' value={value} onChange={onChange} displayEmpty>
          {operationTypes.map((item, i) => {
            return (
              <MenuItem key={i} value={item.id} data-woi='operation-item'>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectOperation.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  operationTypes: state.global.operationTypes,
});

export default connect(mapStateToProps)(SelectOperation);
