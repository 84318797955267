/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  usedFarmInputPurchase: PropTypes.object.isRequired,
};

const ConfirmFarmInputPurchase = ({
  onCancel,
  onConfirm,
  open,
  loading,
  usedFarmInputPurchase,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='md'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle color='primary' id='confirmation-dialog-title'>
        {t("operations.purchases-database-modal.confirm-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        {usedFarmInputPurchase && usedFarmInputPurchase.input ? (
          <div>
            {t("operations.purchases-database-modal.confirm-modal.name", {
              usedFarmInputPurchase,
            })}
          </div>
        ) : (
          ""
        )}
        <Typography>
          {t("operations.purchases-database-modal.confirm-modal.description")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onCancel}
          variant='text'
          style={{ color: "#f44336" }}
        >
          {t("operations.purchases-database-modal.confirm-modal.cancel-button-label")}
        </Button>
        <Button disabled={loading} onClick={onConfirm} color='secondary'>
          {t("operations.purchases-database-modal.confirm-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmFarmInputPurchase.propTypes = propsDefinition;

export default ConfirmFarmInputPurchase;
