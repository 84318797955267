/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { GLOBAL_CUSTOM_TYPES_LOADED } from "../../../../constants/actionTypes";

//API
import agent from "../../../../agent";

//UI Components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  farmId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,

  //Provided props
  customTypes: PropTypes.array, // Provided by Redux
  setCustomTypes: PropTypes.array, // Provided by Redux
};

const SelectCustomType = ({
  customTypes = [],
  selectedType,
  onChange,
  farmId,
  setCustomTypes,
}) => {
  useEffect(() => {
    if (customTypes.length === 0) {
      const getCustomTypes = async () => {
        const { body } = await agent.CustomTypes.get(farmId);
        setCustomTypes(body.customTypes);
        onChange({
          target: { value: body.customTypes[0].id },
          currentTarget: {
            dataset: {
              npk: body.customTypes[0].npk,
              unit: body.customTypes[0].unit,
              currency: body.customTypes[0].currency,
            },
          },
        });
      };

      getCustomTypes();
    } else if (selectedType === "") {
      onChange({
        target: { value: customTypes[0].id },
        currentTarget: {
          dataset: {
            npk: customTypes[0].npk,
            unit: customTypes[0].unit,
            currency: customTypes[0].currency,
          },
        },
      });
    }
  }, [customTypes, onChange, selectedType, farmId, setCustomTypes]);

  if (customTypes.length > 0) {
    return (
      <FormControl variant='outlined' size='small' style={{ width: "180px" }}>
        <Select name='customType' value={selectedType} onChange={onChange}>
          {customTypes.map((item, i) => {
            return (
              <MenuItem
                key={i}
                value={item.id}
                data-npk={item.npk}
                data-unit={item.unit}
                data-currency={item.currency}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectCustomType.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  customTypes: state.global.customTypes,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomTypes: (payload) => dispatch({ type: GLOBAL_CUSTOM_TYPES_LOADED, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCustomType);
