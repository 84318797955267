/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import LoadingSpinner from "../../LoadingSpinner";
import CropsOrganicRow from "./CropsOrganicRow";

//Theme
import customTheme from "../../../theme";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
};

const CropsOrganicsSection = ({ farmId, selectedPeriod, selectedCrop }) => {
  const [cropsOrganics, setCropsOrganics] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const getCropsOrganics = async () => {
      const { body, error } = await agent.Farms.CropsOrganic.get(
        farmId,
        selectedPeriod,
        selectedCrop
      );

      if (!error) {
        setCropsOrganics(body.cropsOrganics);
      }
      setLoading(false);
    };

    getCropsOrganics();
  }, [farmId, selectedCrop, selectedPeriod]);

  const cropsOrganicsList = cropsOrganics.map((item, index) => (
    <CropsOrganicRow
      key={index}
      organic={item}
      setCropsOrganics={setCropsOrganics}
      farmId={farmId}
    />
  ));

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (cropsOrganics.length > 0) {
    return <div>{cropsOrganicsList}</div>;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
          fontSize: "3rem",
        }}
      >
        {t("operations.crops-organics.empty")}
      </div>
    );
  }
};

CropsOrganicsSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  selectedCrop: state.crop.selectedCrop,
});

export default connect(mapStateToProps)(CropsOrganicsSection);
