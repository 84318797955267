/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  SELECT_CROP,
  CROP_REDIRECT,
  PADDOCKS_LOADED,
  SELECT_PADDOCK,
  OPERATIONS_LOADED,
  SELECT_OPERATION,
  SELECT_FARM_PERIOD,
  DASHBOARD_LOADED,
  ADD_OPERATION,
  UPDATE_OPERATION,
  DELETE_OPERATION,
  UPDATE_SELECTED_PADDOCK,
  UPDATE_OPERATIONS,
  UPDATE_SELECTED_PADDOCK_STATUS,
  SELECT_BAR,
  SET_OPERATIONS,
} from "../constants/actionTypes";
import sortData from "../utils/sorter";

const Crop = (state = { selectedBar: null, operationLoaded: false }, action) => {
  switch (action.type) {
    case DASHBOARD_LOADED:
      return {
        ...state,
        selectedOperation: null,
        operations: [],
        paddocks: null,
        selectedPaddock: null,
        selectedBar: null,
      };
    case SELECT_FARM_PERIOD:
      return {
        ...state,
        operationLoaded: false,
      };
    case CROP_REDIRECT:
      return {
        ...state,
        selectedCrop: action.payload.cropId,
        selectedOperation: null,
        operations: [],
        paddocks: null,
        selectedPaddock: null,
        selectedBar: null,
        operationLoaded: false,
      };
    case SELECT_CROP:
      return {
        ...state,
        selectedCrop: action.payload,
        operations: [],
        selectedOperation: null,
        selectedPaddock: null,
        selectedBar: null,
        operationLoaded: false,
      };
    case SELECT_PADDOCK: {
      return {
        ...state,
        selectedBar: "paddock",
        selectedPaddock:
          (state.selectedPaddock && state.selectedPaddock.id === action.payload) ||
          state.paddocks.filter((item) => item.id === action.payload)[0],
        selectedOperation: null,
        operations: [],
        operationLoaded: false,
      };
    }
    case SELECT_BAR: {
      return {
        ...state,
        selectedBar: action.payload,
        selectedPaddock: null,
        selectedOperation: null,
        operations: [],
      };
    }
    case UPDATE_SELECTED_PADDOCK:
      return {
        ...state,
        selectedPaddock: action.payload,
      };
    case UPDATE_SELECTED_PADDOCK_STATUS:
      return {
        ...state,
        selectedPaddock: {
          ...state.selectedPaddock,
          status: action.status,
        },
        paddocks: state.paddocks.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              status: action.status,
            };
          }
          return item;
        }),
      };
    case PADDOCKS_LOADED:
      var isSelected = [];
      if (action.payload.paddocks) {
        isSelected = action.payload.paddocks.filter(
          (item) => state.selectedPaddock && item.id === state.selectedPaddock.id
        );
      }
      return {
        ...state,
        paddocks: action.payload.paddocks,
        selectedPaddock:
          state.selectedBar && state.selectedBar !== "paddock"
            ? null
            : isSelected.length > 0
            ? state.selectedPaddock
            : action.payload.paddocks[0],
        selectedBar: state.selectedBar ? state.selectedBar : "paddock",
      };
    case OPERATIONS_LOADED:
      if (
        state.selectedPaddock &&
        state.selectedPaddock.id === action.paddockId &&
        action.payload &&
        action.payload.operations
      ) {
        return {
          ...state,
          operations: sortData(action.payload.operations, "chrono"),
          operationLoaded: true,
          selectedOperation: null,
        };
      }
      return {
        ...state,
      };
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.payload,
      };
    case UPDATE_OPERATIONS:
      return {
        ...state,
        operations: sortData(action.payload, "chrono"),
      };
    case ADD_OPERATION:
      const newData = [...state.operations];
      newData.splice(
        action.index + 1,
        0,
        action.payload
          ? action.payload
          : {
              id: "new",
              type: action.operationType,
              initDate: action.initDate ? action.initDate : null,
            }
      );
      return {
        ...state,
        operations: sortData(newData, "chrono"),
        selectedOperation: action.payload ? action.payload.id : "new",
      };
    case UPDATE_OPERATION:
      return {
        ...state,
        operations: state.operations.map((operation, index) => {
          if (index === action.index) return action.payload;
          return operation;
        }),
        selectedOperation: !action.withoutInputs ? null : action.payload.id,
      };
    case DELETE_OPERATION:
      return {
        ...state,
        operations: state.operations.filter((operation, index) => index !== action.index),
        selectedOperation: null,
      };
    case SELECT_OPERATION:
      const operations = action.canvas
        ? state.operations.map((operation, index) => {
            if (index === action.index && operation.status === -1) operation.status = 0;
            return operation;
          })
        : [...state.operations];
      return {
        ...state,
        operations,
        selectedOperation: action.payload,
      };
    default:
      return state;
  }
};

export default Crop;
