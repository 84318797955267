/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SET_LAND_PAGE } from "../../constants/actionTypes";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    marginBottom: "8px",
  },
  titleContainer: {
    marginBottom: "24px",
  },
  tab: {
    padding: "8px 16px 6px 16px",
    borderRadius: "16px 16px 0px 0px",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
    minWidth: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[500],
    "&:hover": {
      color: "inherit",
      backgroundColor: theme.palette.grey[500],
      cursor: "default",
    },
  },
  barStart: {
    width: theme.spacing(4),
    backgroundColor: "inherit",
  },
  barEnd: {
    width: "100%",
    backgroundColor: "inherit",
  },
  barStyle: {
    borderRadius: "0px",
    borderColor: theme.palette.grey[500],
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  active: {
    borderBottom: 0,
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    cursor: "default",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent",
    },
  },
  add: {
    color: theme.palette.info.dark,
    backgroundColor: "transparent",
    "&:hover": {
      color: theme.palette.info.dark,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  selectedLandPage: PropTypes.string, // Provided by Redux
  setLandPag: PropTypes.func, // Provided by Redux
};

const LandHeader = ({ classes, selectedLandPage, setLandPage }) => {
  const { t } = useTranslation();

  const tabTypes = [
    {
      type: "blocs",
      name: t("land.header.tabs.blocs"),
    },
    {
      type: "blocsperi",
      name: t("land.header.tabs.blocsperi"),
    },
    {
      type: "paddocks",
      name: t("land.header.tabs.paddocks"),
    },
  ];

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.titleContainer} item container xs={12}>
        <Grid item container xs={6} alignItems='center'>
          <Typography variant='h4'>{t("land.header.title")}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.root} display='flex'>
          <Paper
            variant='outlined'
            className={`${classes.barStyle} ${classes.barStart}`}
          />
          {tabTypes.map((item, index) => (
            <Paper
              key={index}
              data-woi='rotation-navigation-button'
              variant='outlined'
              onClick={() => {
                setLandPage(item.type);
              }}
              className={`${classes.tab} ${
                item.type === selectedLandPage ? classes.active : ""
              }`}
            >
              <Typography className={classes.title} variant='body2'>
                {item.name}
              </Typography>
            </Paper>
          ))}
          <Paper variant='outlined' className={`${classes.barStyle} ${classes.barEnd}`} />
        </Box>
      </Grid>
    </Grid>
  );
};

LandHeader.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  selectedLandPage: state.land.selectedLandPage,
});

const mapDispatchToProps = (dispatch) => ({
  setLandPage: (payload) => dispatch({ type: SET_LAND_PAGE, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LandHeader));
