/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FixedSizeList } from "react-window";

//API
import agent from "../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//Custom Components
import PaddockListElement from "./PaddockListElement";
import ConfirmPaddock from "./ConfirmPaddock";

//UI Components
import Paper from "@material-ui/core/Paper";
import Portal from "@material-ui/core/Portal";

const ItemRenderer = ({ data, index, style }) => {
  // Access the items array using the "data" prop:
  const paddock = data.paddocks[index];

  return (
    <PaddockListElement
      onClick={data.onClick}
      style={style}
      data={paddock}
      itemIndex={index}
    />
  );
};

const propsDefinition = {
  //Required attributes to be defined in the implementation
  paddocks: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  setOperations: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  selectedPaddockId: PropTypes.string.isRequired,
};

const PaddockList = ({ paddocks, onClose, setOperations, farmId, selectedPaddockId }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [target, setTarget] = useState("");
  const { t } = useTranslation();

  const onSelectPaddock = (targetId) => {
    setTarget(targetId);
    setOpenConfirm(true);
  };

  const handleSetOperation = () => {
    setLoading(true);
    const importOperations = agent.Farms.Paddocks.Operations.import(
      farmId,
      selectedPaddockId,
      target
    )
      .then((res) => {
        setOperations(res.body.operations);
        setLoading(false);
        setOpenConfirm(false);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        setOpenConfirm(false);
        onClose();
        throw err;
      });

    toast.promise(importOperations, {
      loading: t("global.loading"),
      success: t("operations.section.notifications.operations-imported"),
      error: (err) => err.response.body.error,
    });
  };

  return (
    <Paper style={{ marginTop: "16px" }} variant='outlined'>
      {paddocks ? (
        <FixedSizeList
          itemData={{ paddocks: paddocks, onClick: onSelectPaddock }}
          height={320}
          itemSize={50}
          itemCount={paddocks.length}
        >
          {ItemRenderer}
        </FixedSizeList>
      ) : (
        ""
      )}
      <Portal>
        <ConfirmPaddock
          onConfirm={handleSetOperation}
          onCancel={() => setOpenConfirm(false)}
          loading={loading}
          open={openConfirm}
        />
      </Portal>
    </Paper>
  );
};

PaddockList.propTypes = propsDefinition;

export default PaddockList;
