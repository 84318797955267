/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AdminDeleteConfirmation = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='delete-confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='delete-confirmation-dialog-title'>
        {t("global.delete-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography color='error'>
          {t("global.delete-modal.admin-description")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("global.delete-modal.cancel-button-label")}</Box>
        </Button>
        <Button onClick={onConfirm} color='secondary' data-woi="confirm-delete-button">
          {t("global.delete-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdminDeleteConfirmation.propTypes = propsDefinition;

export default AdminDeleteConfirmation;
