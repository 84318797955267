import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import { renderTextField } from "../../utils/fieldRenderers";

//UI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const propsDefinition = {
  //Provided props
  handleSubmit: PropTypes.func, // Provided by Redux
  pristine: PropTypes.bool, // Provided by Redux
  submitting: PropTypes.bool, // Provided by Redux
  loginError: PropTypes.string, // Provided by Redux
};

function LoginForm({ handleSubmit, pristine, submitting, loginError }) {
  const { t } = useTranslation();

  return (
    <form id='loginForm' onSubmit={handleSubmit}>
      <Grid container spacing={2} direction='row-reverse'>
        <Grid item xs={12}>
          <Field
            name='username'
            data-woi='username'
            component={renderTextField}
            label={t("login.username")}
            fullWidth={true}
            loginError={
              loginError ? t("login.notifications.invalid-user-or-password") : false
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='password'
            data-woi='password'
            component={renderTextField}
            label={t("login.password")}
            type='password'
            fullWidth={true}
            loginError={
              loginError ? t("login.notifications.invalid-user-or-password") : false
            }
          />
        </Grid>
        <Grid item>
          <Button type='submit' disabled={pristine || submitting}>
            {t("login.login-button-label")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  loginError: state.common.error,
});

export default connect(mapStateToProps)(reduxForm({ form: "LoginForm" })(LoginForm));
