/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  HEDGEROWS_LOADED,
  ADD_HEDGEROWS,
  UPDATE_HEDGEROWS,
  REMOVE_HEDGEROWS,
} from "../constants/actionTypes";

const Hedgerows = (state = {}, action) => {
  switch (action.type) {
    case HEDGEROWS_LOADED:
      return {
        ...state,
        hedgerows: action.payload,
      };
    case ADD_HEDGEROWS:
      return {
        ...state,
        hedgerows: [...state.hedgerows, action.payload],
      };
    case UPDATE_HEDGEROWS:
      return {
        ...state,
        hedgerows: state.hedgerows.map((hedgerow, index) => {
          if (index === action.index) {
            return action.payload;
          }
          return hedgerow;
        }),
      };
    case REMOVE_HEDGEROWS:
      return {
        ...state,
        hedgerows: [...state.hedgerows.filter((i, index) => action.index !== index)],
      };
    default:
      return state;
  }
};

export default Hedgerows;
