/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import TypographyWithIcon from "../../TypographyWithIcon";

//UI Components
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1.4),
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.2,
    padding: theme.spacing(0.75, 2),
  },
  desc: {
    padding: theme.spacing(0, 1),
  },
  progressValid: {
    backgroundColor: "green",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
  },
  progress: {
    height: 5,
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  classes: PropTypes.object,
  verified: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CropCard = ({ classes, verified, title, progress, onClick }) => {
  const { t } = useTranslation();

  let progressLabel = t("dashboard.crops.crop-card.start-encoding-label");
  if (progress > 0 && progress < 100) {
    progressLabel = t("dashboard.crops.crop-card.continue-encoding-label");
  } else if (progress !== 0) {
    progressLabel = t("dashboard.crops.crop-card.modify-encoding-label");
  }

  return (
    <Card className={classes.root} variant='outlined' data-woi='crop-card'>
      <CardActionArea onClick={onClick}>
        <Box color='red'>
          <LinearProgress
            className={classes.progress}
            color={verified ? "secondary" : "primary"}
            variant='determinate'
            value={Number(progress)}
          />
        </Box>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item xs={6} container alignItems='center'>
            <Typography className={classes.title} noWrap={true}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs container alignItems='center' justify='flex-end'>
            <TypographyWithIcon
              className={classes.desc}
              label={progressLabel}
              color='secondary'
              icon={<NavigateNextIcon color='secondary' />}
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

CropCard.propTypes = propsDefinition;

export default withStyles(styles)(CropCard);
