/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import { push } from "connected-react-router";
import { hotjar } from "react-hotjar";

//Timezone and Calendar
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/min/locales.min";

//Redux
import { store } from "../store";
import { connect } from "react-redux";
import { APP_LOAD, REDIRECT, LOGOUT } from "../constants/actionTypes";

//API
import agent from "../agent";

//HOC
import { withTranslation } from "react-i18next";

//Custom Components
import Home from "../components/Home";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard";
import Crop from "../components/Crop";
import Equipments from "./Equipments";
import Land from "../components/Land";
import Purchases from "../components/Purchases";
import Hedgerows from "../components/Hedgerows";
import GhgProgram from "../components/GhgProgram";
import LandOccupation from "../components/LandOccupation";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";

//Theme
import customTheme from "../theme";
import "fontsource-roboto/latin-500-normal.css";

//UI Components
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

class App extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  UNSAFE_componentWillMount() {
    try {
      const token = window.localStorage.getItem("sessionToken");
      if (token) {
        agent.setToken(token);
      }

      this.props.onLoad(token ? agent.Auth.session() : null, token);
    } catch (e) {
      this.props.onLoad(null, null);
    }
    hotjar.initialize(2360184, 6);
  }

  render() {
    const PrivateRoute = ({ isLoggedIn, ...props }) => {
      return isLoggedIn ? (
        props.computedMatch.params.farmId ? (
          <FarmRoute {...props} />
        ) : (
          <Route {...props} />
        )
      ) : (
        <Redirect to='/login' />
      );
    };

    const FarmRoute = ({ ...props }) => {
      return props.computedMatch.params.farmId === this.props.selectedFarm ||
        props.computedMatch.params.farmId === "new" ? (
        <Route {...props} />
      ) : (
        <Redirect to='/' />
      );
    };

    if (this.props.appLoaded) {
      return (
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={this.props.language || "en"}
          >
            <Container>
              {this.props.currentUser ? <Header /> : ""}

              <Switch>
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  exact
                  path='/'
                  render={(routeProps) => <Home {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/dashboard'
                  render={(routeProps) => <Dashboard {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/crop'
                  render={(routeProps) => <Crop {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/equipments'
                  render={(routeProps) => <Equipments {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/purchases'
                  render={(routeProps) => <Purchases {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/land'
                  render={(routeProps) => <Land {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/program'
                  render={(routeProps) => <GhgProgram {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/hedgerows'
                  render={(routeProps) => <Hedgerows {...routeProps} />}
                />
                <PrivateRoute
                  isLoggedIn={this.props.currentUser}
                  path='/farm/:farmId/occupation'
                  render={(routeProps) => <LandOccupation {...routeProps} />}
                />
                <Route path='/login' component={Login} />
              </Switch>
            </Container>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoadingSpinner size='5rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    language: state.common.language,
    redirectTo: state.common.redirectTo,
    selectedFarm: state.common.selectedFarm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  logout: () => dispatch({ type: LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
