/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Theme
import customTheme from "../../../../../theme";

//UI Components
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  operationInputs: PropTypes.array.isRequired,
  focused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const PurchaseTab = ({
  operationInputs,
  focused,
  onClick,
  selected,
  label,
  type,
  disabled,
}) => {
  const { t } = useTranslation();
  let count = 0;
  let errors = 0;

  const titleList =
    operationInputs.length > 0
      ? operationInputs.map((operationInput, index) => {
          if (operationInput.status >= 0) {
            count++;
            if (!(operationInput.quantity > 0) || !operationInput.farmInputPurchase.id) {
              errors++;
            }
            return (
              <div key={index} style={{ display: "flex" }}>
                {operationInput.farmInputPurchase.name ? (
                  operationInput.farmInputPurchase.name
                ) : (
                  <span style={{ color: customTheme.palette.error.light }}>
                    {t("operations.operation-input.tab.missing")}
                  </span>
                )}{" "}
                -
                <Box
                  color={!(operationInput.quantity > 0) ? "error.light" : "primary.main"}
                  style={{ marginLeft: 2 }}
                >
                  {operationInput.quantity + " "}
                  {operationInput.farmInputPurchase.operationUnit}
                </Box>
              </div>
            );
          }
          return null;
        })
      : t("operations.operation-input.tab.empty");

  return (
    <Tooltip
      arrow
      title={
        <React.Fragment>
          <Typography>{label}</Typography>
          {titleList}
        </React.Fragment>
      }
    >
      <div>
        <Button
          disabled={!focused || disabled}
          variant='text'
          onClick={() =>
            selected === type ? onClick(null) : focused ? onClick(type) : {}
          }
          data-woi='input-tab'
        >
          <Typography component={"span"} style={{ display: "flex" }}>
            <Box
              color={
                focused && selected === type
                  ? "secondary.main"
                  : focused
                  ? "text.primary"
                  : "text.faded"
              }
            >
              {label}
            </Box>
            <Box
              marginLeft='4px'
              style={{
                color:
                  errors > 0
                    ? customTheme.palette.error.main
                    : focused
                    ? customTheme.palette.text.primary
                    : customTheme.palette.text.faded,
              }}
            >
              ({count})
            </Box>
          </Typography>
        </Button>
      </div>
    </Tooltip>
  );
};

PurchaseTab.propTypes = propsDefinition;

export default PurchaseTab;
