/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import SearchGlobalMachineryBar from "./SearchGlobalMachineryBar";
import MachineryList from "./MachineryList";
import CustomDialogTitle from "../../../../CustomDialogTitle";

//UI Components
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  operationId: PropTypes.string,
  farmId: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  setFarmMachinery: PropTypes.func.isRequired,
};

const MachineryListModal = ({
  open,
  onClose,
  givenName,
  operationId,
  farmId,
  periodId,
  setFarmMachinery,
}) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [machineries, setMachineries] = useState([]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      const getMachineries = async () => {
        const { body, error } = await agent.Machineries.search(
          operationId,
          periodId,
          farmId,
          searchQuery
        );

        if (!error) {
          setMachineries(body.machineries);
        }
      };

      getMachineries();
    }
  }, [searchQuery, farmId, operationId, open, periodId]);

  useEffect(() => {
    if (open) {
      const getMachineries = async () => {
        const { body, error } = await agent.Machineries.get(
          operationId,
          periodId,
          farmId
        );

        if (!error) {
          setMachineries(body.machineries);
        }
      };

      getMachineries();
    }
  }, [open, operationId, periodId, farmId]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth='sm'
      aria-labelledby='machinery-modal-dialog-title'
      open={open}
    >
      <CustomDialogTitle id='machinery-modal-dialog-title' onClose={handleClose}>
        {t("operations.equipment-database-modal.title")}
      </CustomDialogTitle>
      <DialogContent dividers>
        <SearchGlobalMachineryBar
          query={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          handleClear={() => setSearchQuery("")}
          label={t("operations.equipment-database-modal.search-bar-label")}
        />
        {givenName ? (
          <div style={{ marginTop: "8px", marginLeft: "8px" }}>
            <span style={{ color: "#f44336" }}>
              {t("operations.equipment-database-modal.given-name")}
            </span>{" "}
            {givenName}
          </div>
        ) : null}
        <MachineryList
          setFarmMachinery={setFarmMachinery}
          farmId={farmId}
          givenName={givenName}
          periodId={periodId}
          machineries={machineries}
          onClose={onClose}
        ></MachineryList>
      </DialogContent>
    </Dialog>
  );
};

MachineryListModal.propTypes = propsDefinition;

export default MachineryListModal;
