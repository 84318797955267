/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { fade } from "@material-ui/core/styles/colorManipulator";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: "64px",
    color: theme.palette.secondary.main,
  },
  disabled: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: fade(theme.palette.grey[300], 0.75),
    "z-index": 1000,
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledTitle: PropTypes.string,
  color: PropTypes.string,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const MenuButton = ({
  classes,
  icon,
  title,
  onClick,
  disabled,
  disabledTitle,
  color,
}) => {
  return (
    <Tooltip title={disabled && disabledTitle ? disabledTitle : ""}>
      <Card variant='outlined'>
        <CardActionArea
          disabled={disabled}
          className={classes.container}
          onClick={onClick}
        >
          <Grid container>
            <Grid item container justify='center'>
              <SvgIcon className={classes.icon} component={icon} style={{ color }} />
            </Grid>
            <Grid item container justify='center'>
              <Typography align='center' variant='h5' style={{ color }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
          {disabled && <div className={classes.disabled} />}
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

MenuButton.propTypes = propsDefinition;

export default withStyles(styles)(MenuButton);
