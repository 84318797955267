/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
const profiles = {
  _ADM: "ADMIN",
  _AGRS: "INTERNAL_AGRONOMIST",
  _AGR: "AGRONOMIST",
  _ENC: "ENCODER",
  _AUD: "AUDITOR",
};

export default profiles;
