/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Theme
import customTheme from "../../../theme";

//Utils
import usePrevious from "../../../utils/usePrevious";

//UI Components
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import List from "@material-ui/core/List";

const filter = createFilterOptions();

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, onScroll, ...custom } = props;

  return (
    <div ref={ref} {...custom} onScroll={onScroll}>
      <List aria-label='inputs'>
        {children.map((child) => {
          return child;
        })}
      </List>
    </div>
  );
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectedInputType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

const SearchInputs = ({
  value,
  onChange,
  name,
  selectedInputType,
  label,
  farmId,
  periodId,
  disabled,
  query,
  setQuery,
  errors,
}) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState([]);
  const [page, setPage] = useState(1);
  const [dataEnd, setDataEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const getInputs = useCallback(
    async (query = "") => {
      setLoading(true);
      setInputs([]);
      const { body, error } = await agent.Inputs.search(
        selectedInputType,
        periodId,
        farmId,
        query
      );
      if (!error) {
        setInputs(body.inputs);
      } else {
        setInputs({ id: "error", name: "error" });
      }
      setPage(1);
      setDataEnd(false);
      setLoading(false);
      setInitialLoaded(true);
    },
    [selectedInputType, periodId, farmId]
  );

  const prevQuery = usePrevious(query);

  useEffect(() => {
    if (open) {
      let timer;
      if (query !== "" && query !== prevQuery) {
        timer = setTimeout(() => {
          getInputs(query);
        }, 300);

        return () => clearTimeout(timer);
      } else if ((query === "" && query !== prevQuery) || !initialLoaded) {
        timer = setTimeout(() => {
          getInputs();
        }, 0);

        return () => clearTimeout(timer);
      }
    }
  }, [query, open, getInputs, prevQuery, initialLoaded]);

  const getInputsPage = async () => {
    setLoading(true);
    const { body, error } = await agent.Inputs.search(
      selectedInputType,
      periodId,
      farmId,
      query,
      page + 1
    );
    if (!error) {
      setPage(page + 1);
      if (body.inputs.length === 0) {
        setDataEnd(true);
      } else {
        setInputs((inputs) => [...inputs, ...body.inputs]);
      }
    }
    setLoading(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const title = value && value.name ? <Typography>{value.name}</Typography> : "";

  return (
    <Tooltip title={title}>
      <Autocomplete
        style={{ width: "220px" }}
        closeIcon={
          <HighlightOffIcon
            style={{ fontSize: "18px", marginTop: "1px", color: "red" }}
          />
        }
        fullWidth={true}
        onChange={(e, data) => onChange(data)}
        onInputChange={(e, value) => setQuery(value)}
        value={value}
        onOpen={onOpen}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (loading && inputs.length > 0) {
            filtered.push({
              loading: true,
            });
          }

          if (selectedInputType === "fertiliser") {
            filtered.push({
              custom: true,
            });
          }

          return filtered;
        }}
        onClose={onClose}
        freeSolo
        size='small'
        name={name}
        options={inputs}
        disabled={disabled}
        getOptionDisabled={(option) => {
          return (
            (option.loading || option.id === "error" || option.usedByFarm === 1) &&
            (!value || option.id !== value.id)
          );
        }}
        getOptionLabel={(option) => {
          return option.name ? option.name : "";
        }}
        renderOption={(option) => {
          return option ? (
            option.id ? (
              <div>
                {option.input && option.input.name
                  ? option.input.name
                  : option.name
                  ? option.name
                  : ""}
              </div>
            ) : option.custom ? (
              <div style={{ color: customTheme.palette.info.main }}>
                {t("purchases.section.input-add-custom")}
              </div>
            ) : option.loading ? (
              <div style={{ color: customTheme.palette.grey[900] }}>
                {t("purchases.section.input-search")}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          );
        }}
        ListboxComponent={ListboxComponent}
        ListboxProps={{
          onScroll: (event) => {
            const list = event.currentTarget;
            if (
              list.scrollTop + list.clientHeight >= list.scrollHeight * 0.95 &&
              !loading &&
              !dataEnd
            ) {
              getInputsPage();
            }
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin='normal'
            variant='outlined'
            error={errors.filter((item) => item.field === "input").length > 0}
            helperText={
              errors.filter((item) => item.field === "input").length > 0
                ? errors.filter((item) => item.field === "input")[0].message
                : ""
            }
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            value={value}
          />
        )}
      />
    </Tooltip>
  );
};

SearchInputs.propTypes = propsDefinition;

export default SearchInputs;
