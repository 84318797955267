/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

const ConfirmSoilType = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='soil-type-confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='soil-type-confirmation-dialog-title'>
        {t("land.blocsperi.soil-type-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("land.blocsperi.soil-type-modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("land.blocsperi.soil-type-modal.cancel-button-label")}</Box>
        </Button>
        <Button onClick={handleConfirm} color='secondary' data-woi='confirm-soil-type'>
          {t("land.blocsperi.soil-type-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmSoilType.propTypes = propsDefinition;

export default ConfirmSoilType;
