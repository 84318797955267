/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

//Notifications
import toast from "react-hot-toast";

//Theme
import customTheme from "../../../theme";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import FieldWithTextAdornment from "../../FieldWithTextAdornment";
import DeleteConfirmation from "../../DeleteConfirmation";
import BlankIcon from "../../BlankIcon";

//Material UI Components
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Portal from "@material-ui/core/Portal";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  rowContainer: {
    marginBottom: theme.spacing(1),
    paddingLeft: "300px",
  },
  checkboxes: {
    paddingRight: "10px",
  },
  confirmInputs: {
    display: "flex",
    justifyContent: "center",
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  organic: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by withStyles HOC
};

const CropsOrganicRow = ({ organic, setCropsOrganics, farmId }) => {
  const classes = useStyle();

  const [id, setId] = useState(null);
  const [status, setStatus] = useState(0);
  const [lock, setLock] = useState(0);
  const [fertiliser, setFertiliser] = useState(null);
  const [passedYears, setPassedYears] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [notDeletable, setNotDeletable] = useState(0);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [changed, setChanged] = useState(false);

  const { t } = useTranslation();
  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  useEffect(() => {
    if (organic.id) {
      setId(organic.id);
      setStatus(organic.status);
      setLock(organic.lock);
      setFertiliser(organic.fertiliser);
      setPassedYears(organic.passedYears);
      setPercentage(organic.percentage);
      setNotDeletable(organic.flagDelete);
      setChanged(false);
    }
  }, [organic]);

  const validateData = useCallback(() => {
    if (!(passedYears > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "passedYears",
          message: t("operations.crops-organics.errors.passed-years"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "passedYears")]);
    }
    if (!(percentage > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "percentage",
          message: t("operations.crops-organics.errors.percentage"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "percentage")]);
    }
  }, [passedYears, percentage, t]);

  useEffect(() => {
    if (changed) {
      validateData();
    }
  }, [changed, validateData]);

  const handleUpdate = useCallback(() => {
    const validate = (passedYears, percentage) => {
      let error = false;
      if (!(passedYears > 0)) {
        error = true;
      }
      if (!(percentage > 0)) {
        error = true;
      }
      return error;
    };

    if (!validate(passedYears, percentage)) {
      const reqBody = {
        cropsOrganics: {
          passedYears,
          percentage,
        },
      };

      const updateCropOrganic = agent.Farms.CropsOrganic.update(farmId, id, reqBody).then(
        (res) => {
          setCropsOrganics((crops) => [
            ...crops.map((crop) => {
              if (crop.id === id) {
                return res.body.cropsOrganics;
              }
              return crop;
            }),
          ]);
        }
      );

      toast.promise(updateCropOrganic, {
        loading: t("global.loading"),
        success: t("operations.crops-organics.notifications.saved"),
        error: t("operations.crops-organics.notifications.error"),
      });
    }
  }, [passedYears, percentage, setCropsOrganics, id, farmId, t]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed]);

  const handleStatus = async () => {
    setStatus(3);
    if (!(errors.length > 0)) {
      const reqBody = {
        cropsOrganics: {
          status: 3,
        },
      };

      const updateCropOrganic = agent.Farms.CropsOrganic.update(farmId, id, reqBody).then(
        (res) => {
          setCropsOrganics((crops) => [
            ...crops.map((crop) => {
              if (crop.id === id) {
                return res.body.cropsOrganics;
              }
              return crop;
            }),
          ]);
        }
      );

      toast.promise(updateCropOrganic, {
        loading: t("global.loading"),
        success: t("operations.crops-organics.notifications.validated"),
        error: t("operations.crops-organics.notifications.error"),
      });
    }
  };

  const handleDelete = async () => {
    const deleteCropOrganic = agent.Farms.CropsOrganic.delete(farmId, id).then((res) => {
      setCropsOrganics((crops) => [
        ...crops.filter((crop) => crop.id !== res.body.crop.id),
      ]);
    });

    toast.promise(deleteCropOrganic, {
      loading: t("global.loading"),
      success: t("operations.crops-organics.notifications.deleted"),
      error: t("operations.crops-organics.notifications.error"),
    });
  };

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  return (
    <Grid className={classes.rowContainer} container spacing={1}>
      <Grid item>
        <TextField
          disabled
          label={t("operations.crops-organics.fertiliser")}
          value={fertiliser ? fertiliser.name : ""}
          data-woi='organic-fertiliser-name'
        />
      </Grid>
      <Grid item style={{ paddingTop: "9px" /* Status height adjustement */ }}>
        {permissions.canView() && status === 0 && id !== null ? (
          <Tooltip title={t("operations.crops-organics.status-0")} arrow>
            <div>
              <IconButton
                disabled={!permissions.canEdit()}
                size='small'
                onClick={handleStatus}
                data-woi='organic-fertiliser-unvalidated'
              >
                <ErrorIcon style={{ color: customTheme.palette.error.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : status === 3 ? (
          <Tooltip title={t("operations.crops-organics.status-3")} arrow>
            <div>
              <IconButton disabled size='small' data-woi='organic-fertiliser-validated'>
                <VerifiedUserIcon style={{ color: customTheme.palette.success.dark }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div>
            <IconButton disabled size='small'>
              <BlankIcon />
            </IconButton>
          </div>
        )}
      </Grid>
      <Grid item data-woi='organic-fertiliser-years'>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit()}
          name='passedYears'
          format={/^[0-9][0-9]?[0-9]?$/}
          label={t("operations.crops-organics.passed-years")}
          value={passedYears}
          onChange={(e) => {
            setPassedYears(e.target.value);
            setChanged(true);
          }}
          adornment={t("operations.crops-organics.years-ago")}
          width='130px'
          errors={errors}
        />
      </Grid>
      <Grid item data-woi='organic-fertiliser-percent'>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit()}
          name='percentage'
          format={/^[0-9][0-9]?$|^100$/}
          label={t("operations.crops-organics.percentage")}
          value={percentage}
          onChange={(e) => {
            setPercentage(e.target.value);
            setChanged(true);
          }}
          adornment={t("operations.crops-organics.of-surface")}
          width='200px'
          errors={errors}
        />
      </Grid>
      {((!permissions.canView() && status !== 3) || permissions.canView()) &&
      permissions.canEdit() ? (
        <Grid item style={{ paddingTop: "9px" /* Status height adjustement */ }}>
          <Tooltip
            title={
              (notDeletable ? true : false) ? (
                <Typography>
                  {t("operations.crops-organics.not-deletable-message")}
                </Typography>
              ) : (
                ""
              )
            }
          >
            <div>
              <IconButton
                size='small'
                disabled={notDeletable ? true : false}
                onClick={handleDeleteConfirm}
              >
                <DeleteForever
                  color={(notDeletable ? true : false) ? "disabled" : "error"}
                />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      ) : null}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
    </Grid>
  );
};

CropsOrganicRow.propTypes = propsDefinition;

export default CropsOrganicRow;
