/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  GLOBAL_IRRIGATIONS_LOADED,
  GLOBAL_SOURCES_LOADED,
  GLOBAL_POWERS_LOADED,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Custom Components
import CropsIrrigationRow from "./CropsIrrigationRow";
import LoadingSpinner from "../../LoadingSpinner";

//Theme
import customTheme from "../../../theme";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
  onIrrigationsLoaded: PropTypes.func, // Provided by Redux
  onSourcesLoaded: PropTypes.func, // Provided by Redux
  onPowersLoaded: PropTypes.func, // Provided by Redux
};

const CropsIrrigationSection = ({
  farmId,
  selectedPeriod,
  selectedCrop,
  onIrrigationsLoaded,
  onSourcesLoaded,
  onPowersLoaded,
}) => {
  const [cropsIrrigations, setCropsIrrigations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getCropsIrrigations = async () => {
      const { body, error } = await agent.Farms.CropsIrrigations.get(
        farmId,
        selectedPeriod,
        selectedCrop
      );

      if (!error) {
        setCropsIrrigations(body.cropIrrigations);
      }
      setLoading(false);
    };

    const getIrrigations = async () => {
      const { body, error } = await agent.Irrigations.get();

      if (!error) {
        onIrrigationsLoaded(body.irrigations);
      }
    };

    const getSources = async () => {
      const { body, error } = await agent.Sources.get();

      if (!error) {
        onSourcesLoaded(body.sources);
      }
    };

    const getPowers = async () => {
      const { body, error } = await agent.Powers.get();

      if (!error) {
        onPowersLoaded(body.powers);
      }
    };
    getSources();
    getPowers();
    getIrrigations();
    getCropsIrrigations();
  }, [
    onPowersLoaded,
    onSourcesLoaded,
    onIrrigationsLoaded,
    farmId,
    selectedCrop,
    selectedPeriod,
  ]);

  const cropsIrrigationsList = cropsIrrigations.map((item, index) => (
    <CropsIrrigationRow key={index} cropIrrigation={item} farmId={farmId} />
  ));

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (cropsIrrigations.length > 0) {
    return <div>{cropsIrrigationsList}</div>;
  }
  return null;
};

CropsIrrigationSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  selectedCrop: state.crop.selectedCrop,
});

const mapDispatchToProps = (dispatch) => ({
  onIrrigationsLoaded: (payload) =>
    dispatch({ type: GLOBAL_IRRIGATIONS_LOADED, payload }),
  onSourcesLoaded: (payload) => dispatch({ type: GLOBAL_SOURCES_LOADED, payload }),
  onPowersLoaded: (payload) => dispatch({ type: GLOBAL_POWERS_LOADED, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CropsIrrigationSection);
