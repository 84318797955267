/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React from "react";
import { store, history } from "./store";
import customTheme from "./theme";

import "./i18n";

//Notifications
import { Toaster } from "react-hot-toast";

import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import App from "./components/App";

import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = "h8vjcs8y";

store.subscribe(() => {
  window.localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

ReactDOM.render(
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <Provider store={store}>
      <Toaster
        position='bottom-left'
        reverseOrder={true}
        toastOptions={{
          // Define default options
          style: {
            background: customTheme.palette.warning.dark,
            borderRadius: "8px",
            margin: "30px",
            color: "#fff",
            zIndex: 1,
          },
          duration: 3000,
          // Default options for specific types
          success: {
            duration: 1500,
            style: {
              background: customTheme.palette.success.dark,
              borderRadius: "8px",
              margin: "30px",
              color: "#fff",
              zIndex: 1,
            },
            iconTheme: {
              primary: customTheme.palette.success.main,
              secondary: "#fff",
            },
          },
          error: {
            duration: 3000,
            style: {
              background: customTheme.palette.error.dark,
              borderRadius: "8px",
              margin: "30px",
              color: "#fff",
              zIndex: 1,
            },
            iconTheme: {
              primary: customTheme.palette.error.main,
              secondary: "#fff",
            },
          },
          loading: {
            duration: 60000,
            style: {
              background: customTheme.palette.info.dark,
              borderRadius: "8px",
              margin: "30px",
              color: "#fff",
              zIndex: 1,
            },
            iconTheme: {
              primary: customTheme.palette.info.main,
              secondary: "#fff",
            },
          },
        }}
      />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path='/' component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </IntercomProvider>,

  document.getElementById("root")
);
