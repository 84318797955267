/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//UI Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  bloc: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  errors: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,

  //Provided props
  blocs: PropTypes.array, // Provided by Redux
};

const SelectBloc = ({
  bloc,
  onChange,
  blocs = [],
  disabled,
  label,
  width,
  errors: errList,
  name,
}) => {
  const errors = errList.filter((item) => item.field === name);
  const { field, message } = errors.length > 0 ? errors[0] : {};
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      closeIcon={<HighlightOffIcon style={{ fontSize: "20px", color: "red" }} />}
      style={{
        width: width || "175px",
      }}
      disabled={disabled}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      freeSolo
      size='small'
      forcePopupIcon={open || (bloc && bloc.id) ? false : true}
      popupIcon={<SearchIcon />}
      value={bloc}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      options={blocs.length > 0 ? blocs : [{ ...blocs }]}
      getOptionDisabled={(option) => (option.blocperi ? Boolean(option.blocperi) : false)}
      ListboxProps={{ "data-woi": "bloc-list" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='normal'
          variant='outlined'
          error={field ? true : false}
          helperText={message ? message : ""}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

SelectBloc.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  blocs: state.land.blocs,
});

export default connect(mapStateToProps)(SelectBloc);
