/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  SELECT_OPERATION,
  ADD_OPERATION,
  UPDATE_OPERATION,
  DELETE_OPERATION,
  UPDATE_SELECTED_PADDOCK_STATUS,
} from "../../../../../constants/actionTypes";

//API
import agent from "../../../../../agent";

//Hooks
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//Custom
import Permissions from "../../../../../utils/Permissions";
import profiles from "../../../../../constants/profiles";

//Custom Components
import FieldWithTextAdornment from "../../../../FieldWithTextAdornment";
import OperationStatusIcon from "./OperationStatusIcon";
import SelectOperation from "./SelectOperation";
import SelectDate from "../../../../SelectDate";
import SearchEquipment from "./SearchEquipment";
import PurchaseTab from "./PurchaseTab";
import OperationInputsSection from "./OperationInputsSection";
import DeleteConfirmation from "../../../../DeleteConfirmation";
import MachineryListModal from "./MachineryListModal";
import CancelConfirmation from "../../../../CancelConfirmation";

//Custom Theme
import customTheme from "../../../../../theme";

//UI Components
import CommentIcon from "@material-ui/icons/Comment";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  root: {
    position: "relative",
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 2, 2, 3),
    "@global": {
      ".MuiInputBase-root": {
        fontSize: "0.95rem",
      },
    },
  },
  focusedCard: {
    cursor: "standard",
    background: `linear-gradient(90deg, ${theme.palette.secondary.main} 8px, #FFF 8px)`,
    border: `1px solid ${theme.palette.grey[600]}`,
  },
  notFocusedCard: {
    cursor: "pointer",
    "& *": {
      cursor: "pointer",
    },
  },
  cannotEdit: {
    cursor: "standard",
    "& *": {
      cursor: "standard",
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  inactiveOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: fade(theme.palette.grey[200], 0.5),
    borderWidth: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "transparent",
    cursor: "pointer",
    zIndex: 10,
  },
  activeOverlay: {
    color: theme.palette.info.main,
  },
  operationMenu: {
    position: "absolute",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[600]}`,
    backgroundColor: "#FFF",
    top: 0,
    width: "50px",
    right: -60,
    zIndex: 10,
  },
  operationContainer: {
    paddingRight: theme.spacing(6),
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& > div": {
      marginLeft: theme.spacing(1),
    },
  },
  menuButton: {
    color: theme.palette.secondary.main,
  },
  remove: {
    color: theme.palette.error.main,
  },
  addNewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15px",
    "& > *": {
      visibility: "hidden",
    },
  },
  containerActive: {
    padding: "4px",
    height: "30px",
    cursor: "pointer",
    "& > *": {
      visibility: "visible",
    },
  },
  activeNote: {
    color: theme.palette.warning.main,
  },
  inactiveAddOperation: {
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  operation: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,

  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedPaddock: PropTypes.object, // Provided by Redux
  selectedOperation: PropTypes.string, // Provided by Redux
  setSelectedOperation: PropTypes.func, // Provided by Redux
  addOperation: PropTypes.func, // Provided by Redux
  updateOperation: PropTypes.func, // Provided by Redux
  deleteOperation: PropTypes.func, // Provided by Redux
  updateSelectedPaddockStatus: PropTypes.func, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
};

const OperationCard = ({
  operation,
  setSelectedOperation,
  selectedOperation,
  addOperation,
  updateOperation,
  deleteOperation,
  index,
  farmId,
  selectedPeriod,
  selectedPaddock,
  updateSelectedPaddockStatus,
  selectedCrop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Card States
  const [focused, setFocused] = useState(false);
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedInputsType, setSelectedInputsType] = useState(null);
  const [inputErrors, setInputErrors] = useState([]);
  const [editing, setEditing] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [openMachineryModal, setOpenMachineryModal] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [canValidate, setCanValidate] = useState(true);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);

  // Operation Data
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [date, setDate] = useState(null);
  const [initDate, setInitDate] = useState(null);
  const [type, setType] = useState("");
  const [farmMachinery, setFarmMachinery] = useState({});
  const [noteMachinery, setNoteMachinery] = useState("");
  const [passageNumber, setPassageNumber] = useState(1);
  const [time, setTime] = useState(0);
  const [consumption, setConsumption] = useState(0);
  const [price, setPrice] = useState(0);
  const [defaultPrice, setDefaultPrice] = useState(null);
  const [useDefaultPrice, setUseDefaultPrice] = useState(0);
  const [note, setNote] = useState("");
  const [currency, setCurrency] = useState(null);
  const [seeds, setSeeds] = useState([]);
  const [fertilisers, setFertilisers] = useState([]);
  const [pesticides, setPesticides] = useState([]);
  const [lock, setLock] = useState(0);

  // Permissions
  const permissions = new Permissions(selectedPaddock.status === 3 ? 3 : status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  // Setting Operation Data
  useEffect(() => {
    if (operation && operation.id && operation.id !== "new") {
      setId(operation.id);
      setStatus(operation.status);
      setDate(operation.date);
      setType(operation.type);
      setFarmMachinery(operation.farmMachinery);
      setNoteMachinery(operation.noteMachinery);
      setPassageNumber(operation.passageNumber > 0 ? operation.passageNumber : 1);
      setTime(operation.time);
      setConsumption(operation.consumption);
      setPrice(operation.price);
      setDefaultPrice(operation.defaultPrice);
      setUseDefaultPrice(operation.useDefaultPrice);
      setNote(operation.note);
      setCurrency(operation.currency);
      setLock(operation.lock);

      const seeds = operation.operationInputTypes.filter(
        (item) => item.type === "seed"
      )[0].operationInputs;
      setSeeds(seeds);

      const fertilisers = operation.operationInputTypes.filter(
        (item) => item.type === "fertiliser"
      )[0].operationInputs;
      setFertilisers(fertilisers);

      const pesticides = operation.operationInputTypes.filter(
        (item) => item.type === "pesticide"
      )[0].operationInputs;
      setPesticides(pesticides);
    } else if (operation.id === "new") {
      setId(operation.id);
      setStatus(null);
      setDate(null);
      setInitDate(operation.initDate);
      setType(operation.type);
      setFarmMachinery({});
      setNoteMachinery("");
      setPassageNumber(1);
      setTime(0);
      setConsumption(0);
      setPrice(0);
      setDefaultPrice(0);
      setUseDefaultPrice(0);
      setNote("");
      setCurrency("");
      setSeeds([]);
      setFertilisers([]);
      setPesticides([]);
      setDateIsOpen(true);
      setCheckStatus(false);
    }
  }, [operation]);

  const generateRequestBody = () => {
    return {
      operation: {
        status,
        date,
        type: { id: type.id },
        farmMachinery,
        noteMachinery,
        time,
        consumption,
        passageNumber,
        price,
        useDefaultPrice,
        defaultPrice,
        note,
        operationInputTypes: [
          {
            type: "seed",
            operationInputs: [
              ...seeds
                .map((input) => {
                  const newInput = { ...input };
                  if (!input.id || input.id === "duplicate") {
                    return false;
                  }
                  if (newInput.id === "toBeAdded") {
                    delete newInput.id;
                  }
                  return newInput;
                })
                .filter(Boolean),
            ],
          },
          {
            type: "fertiliser",
            operationInputs: [
              ...fertilisers
                .map((input) => {
                  const newInput = { ...input };
                  if (!input.id || input.id === "duplicate") {
                    return false;
                  }
                  if (newInput.id === "toBeAdded") {
                    delete newInput.id;
                  }
                  return newInput;
                })
                .filter(Boolean),
            ],
          },
          {
            type: "pesticide",
            operationInputs: [
              ...pesticides
                .map((input) => {
                  const newInput = { ...input };
                  if (!input.id || input.id === "duplicate") {
                    return false;
                  }
                  if (newInput.id === "toBeAdded") {
                    delete newInput.id;
                  }
                  return newInput;
                })
                .filter(Boolean),
            ],
          },
        ],
      },
    };
  };

  // Handle Operation Selection
  const handleOperationSelection = () => {
    if (selectedOperation === null && permissions.canEdit()) {
      if (status === -1) {
        setStatus(0);
        setSelectedOperation(id);
      } else {
        setSelectedOperation(id);
      }
    }
  };

  // Test Operation Inputs for Validation
  useEffect(() => {
    let isInvalid = false;

    if (
      seeds.length === 0 &&
      fertilisers.length === 0 &&
      pesticides.length === 0 &&
      status === 0 &&
      farmMachinery &&
      farmMachinery.id &&
      price >= 0
    ) {
      setStatus(2);
    }

    if ((focused && farmMachinery && !farmMachinery.id) || !(price >= 0)) {
      isInvalid = true;
    }

    if (focused && checkStatus) {
      const statusZero =
        seeds.filter((seed) => seed.quantity === 0 || !seed.farmInputPurchase.id).length >
          0 ||
        fertilisers.filter(
          (fertiliser) => fertiliser.quantity === 0 || !fertiliser.farmInputPurchase.id
        ).length > 0 ||
        pesticides.filter(
          (pesticide) => pesticide.quantity === 0 || !pesticide.farmInputPurchase.id
        ).length > 0;

      if (statusZero) {
        setStatus(0);
        isInvalid = true;
      } else {
        if (
          seeds.filter(
            (seed) =>
              seed.farmInputPurchase.quantities &&
              (seed.quantity < seed.farmInputPurchase.quantities.min ||
                seed.quantity > seed.farmInputPurchase.quantities.max)
          ).length > 0 ||
          fertilisers.filter(
            (fertiliser) =>
              fertiliser.farmInputPurchase.quantities &&
              (fertiliser.quantity < fertiliser.farmInputPurchase.quantities.min ||
                fertiliser.quantity > fertiliser.farmInputPurchase.quantities.max)
          ).length > 0 ||
          pesticides.filter(
            (pesticide) =>
              pesticide.farmInputPurchase.quantities &&
              (pesticide.quantity < pesticide.farmInputPurchase.quantities.min ||
                pesticide.quantity > pesticide.farmInputPurchase.quantities.max)
          ).length > 0
        ) {
          setStatus(1);
        } else {
          if (operation.status < 3) {
            setStatus(2);
          }
        }
      }
    }
    setCanValidate(!isInvalid);
  }, [
    seeds,
    fertilisers,
    pesticides,
    focused,
    id,
    farmMachinery,
    selectedInputsType,
    operation,
    price,
    status,
    checkStatus,
  ]);

  // Selecting Operation
  useEffect(() => {
    if (id && selectedOperation === id) {
      setFocused(true);
      let isInvalid = false;
      if (selectedInputsType === null && status < 3) {
        const seedsErrors =
          seeds.filter((seed) => {
            if (seed.quantity === 0 || !seed.farmInputPurchase.id) {
              isInvalid = true;
            }
            return (
              seed.quantity === 0 ||
              !seed.farmInputPurchase.quantities ||
              seed.quantity < seed.farmInputPurchase.quantities.min ||
              seed.quantity > seed.farmInputPurchase.quantities.max
            );
          }).length > 0;

        if (seedsErrors) {
          setSelectedInputsType("seed");
        } else {
          const fertilisersErrors =
            fertilisers.filter((fertiliser) => {
              if (fertiliser.quantity === 0 || !fertiliser.farmInputPurchase.id) {
                isInvalid = true;
              }
              return (
                fertiliser.quantity === 0 ||
                !fertiliser.farmInputPurchase.quantities ||
                fertiliser.quantity < fertiliser.farmInputPurchase.quantities.min ||
                fertiliser.quantity > fertiliser.farmInputPurchase.quantities.max
              );
            }).length > 0;

          if (fertilisersErrors) {
            setSelectedInputsType("fertiliser");
          } else {
            const presticidesErrors =
              pesticides.filter((pesticide) => {
                if (pesticide.quantity === 0 || !pesticide.farmInputPurchase.id) {
                  isInvalid = true;
                }
                return (
                  pesticide.quantity === 0 ||
                  !pesticide.farmInputPurchase.quantities ||
                  pesticide.quantity < pesticide.farmInputPurchase.quantities.min ||
                  pesticide.quantity > pesticide.farmInputPurchase.quantities.max
                );
              }).length > 0;
            if (presticidesErrors) {
              setSelectedInputsType("pesticide");
            }
          }
        }
      }
      if ((farmMachinery && !farmMachinery.id) || !(price >= 0)) {
        isInvalid = true;
      }
      setCanValidate(!isInvalid);
    } else {
      setDateIsOpen(false);
      setErrors([]);
      setSelectedInputsType(null);
      setInputErrors([]);
      setEditing(false);
      setHovered(false);
      setOpenMachineryModal(false);
      setDeleteOpen(false);
      setHasError(false);
      setCanValidate(true);
      setFocused(false);
      setCheckStatus(false);
    }
  }, [
    selectedOperation,
    id,
    selectedInputsType,
    fertilisers,
    pesticides,
    seeds,
    status,
    farmMachinery,
    price,
  ]);

  // Handle Operation Change
  const handleOperationChange = (e) => {
    e.stopPropagation();
    setType({ id: e.target.value });
    setFarmMachinery({});
    setPassageNumber(1);
    setTime(0);
    setPrice(0);
    setConsumption(0);
    setDefaultPrice(0);
  };

  // Handle Date Change
  const handleDateChange = (date) => {
    setDate(date);
  };

  // Handle Machinery Change
  const handleEquipmentChange = (e, i) => {
    if (i && !i.id && i.givenName) {
      setNoteMachinery(i.givenName);
      setFarmMachinery({});
    } else if (i && i.dbData) {
      setFarmMachinery({});
      setOpenMachineryModal(true);
    } else {
      setFarmMachinery(
        i
          ? i.machinery
            ? {
                id: i.id,
                givenName: i.givenName,
                time: i.machinery.time,
                consumption: i.machinery.consumption,
                price: i.machinery.price,
                currency: i.machinery.currency,
              }
            : {
                noteMachinery: i.noteMachinery ? i.noteMachinery : "",
              }
          : {}
      );
      setTime(i && i.machinery ? i.machinery.time : 0);
      setConsumption(i && i.machinery ? i.machinery.consumption : 0);
      setDefaultPrice(i && i.machinery ? i.machinery.price : 0);
      setUseDefaultPrice(1);
      setPrice(i && i.machinery ? i.machinery.price : 0);
    }
    setCheckStatus(true);
  };

  // Handle Use Default Price Change
  const handleUseDefaultPriceChange = () => {
    if (!useDefaultPrice) {
      setPrice(defaultPrice);
    } else {
      setPrice(0);
    }
    setUseDefaultPrice(!useDefaultPrice);
    setCheckStatus(true);
  };

  // Handle Passage Number Change
  const handlePassageNumberChange = (e) => {
    setPassageNumber(e.target.value);
    setCheckStatus(true);
  };

  // Handle Price Change
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
    setCheckStatus(true);
  };

  // Handle Note Change
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  // Validate Data on Change
  useEffect(() => {
    if (!date) {
      setErrors((errors) => [
        ...errors,
        {
          field: "date",
          message: t("operations.operation-card.errors.date-required"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "date")]);
    }
    if (farmMachinery && farmMachinery.id && !(price >= 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "price",
          message: t("operations.operation-card.errors.price-required"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "price")]);
    }
    if (farmMachinery && farmMachinery.id && !(passageNumber > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "passageNumber",
          message: t("operations.operation-card.errors.price-required"),
        },
      ]);
    } else {
      setErrors((errors) => [
        ...errors.filter((error) => error.field !== "passageNumber"),
      ]);
    }
    if (farmMachinery && !farmMachinery.id && noteMachinery === "") {
      setErrors((errors) => [
        ...errors,
        {
          field: "farmMachinery",
          message: t("operations.operation-card.errors.farm-machinery-required"),
        },
      ]);
    } else {
      setErrors((errors) => [
        ...errors.filter((error) => error.field !== "farmMachinery"),
      ]);
    }
  }, [date, price, farmMachinery, noteMachinery, t, passageNumber]);

  // Check All Errors
  useEffect(() => {
    if (errors.length > 0 || inputErrors.length > 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errors, inputErrors]);

  // Handle Create
  const handleCreate = () => {
    const createOperation = agent.Farms.Paddocks.Operations.create(
      farmId,
      selectedPaddock.id,
      generateRequestBody()
    ).then((res) => {
      if (selectedPaddock.status === 3) {
        updateSelectedPaddockStatus(0, selectedPaddock.id);
      }
      updateOperation(res.body.operation, index);
    });

    toast.promise(createOperation, {
      loading: t("global.loading"),
      success: t("operations.operation-card.notifications.operation-added"),
      error: t("operations.operation-card.notifications.error"),
    });
  };

  // Handle Save
  const handleSave = async () => {
    const saveOperation = agent.Farms.Paddocks.Operations.update(
      farmId,
      selectedPaddock.id,
      id,
      generateRequestBody()
    ).then((res) => {
      setCheckStatus(false);
      if (selectedPaddock.status === 3) {
        updateSelectedPaddockStatus(0, selectedPaddock.id);
      }
      updateOperation(res.body.operation, index);
    });

    toast.promise(saveOperation, {
      loading: t("global.loading"),
      success: t("operations.operation-card.notifications.operation-saved"),
      error: t("operations.operation-card.notifications.error"),
    });
  };

  // Handle Validate
  const handleValidate = () => {
    let reqBody = generateRequestBody();
    reqBody.operation.status = 3;
    if (id === "new") {
      const toastId = toast.loading("Loading...");
      agent.Farms.Paddocks.Operations.create(farmId, selectedPaddock.id, reqBody)
        .then((res) => {
          const createBody = res.body;
          agent.Farms.Paddocks.Operations.singleValidate(
            farmId,
            selectedPaddock.id,
            createBody.operation.id,
            createBody
          )
            .then((res) => {
              setSelectedInputsType(null);
              setCheckStatus(false);
              if (selectedPaddock.status === 3) {
                updateSelectedPaddockStatus(0, selectedPaddock.id);
              }
              updateOperation(res.body.operation, index);
              toast.success(
                t("operations.operation-card.notifications.operation-validated"),
                {
                  id: toastId,
                }
              );
            })
            .catch(() => {
              toast.error(t("operations.operation-card.notifications.error"), {
                id: toastId,
              });
            });
        })
        .catch(() => {
          toast.error(t("operations.operation-card.notifications.error"), {
            id: toastId,
          });
        });
    } else {
      const validateOperation = agent.Farms.Paddocks.Operations.singleValidate(
        farmId,
        selectedPaddock.id,
        id,
        reqBody
      ).then((res) => {
        setSelectedInputsType(null);
        setCheckStatus(false);
        if (selectedPaddock.status === 3) {
          updateSelectedPaddockStatus(0, selectedPaddock.id);
        }
        updateOperation(res.body.operation, index);
      });

      toast.promise(validateOperation, {
        loading: t("global.loading"),
        success: t("operations.operation-card.notifications.operation-validated"),
        error: t("operations.operation-card.notifications.error"),
      });
    }
  };

  const handleCancel = () => {
    setCancelOpen(true);
  };

  // Handle Cancelled
  const handleCancelled = async () => {
    if (id === "new") {
      deleteOperation(index);
    } else {
      setSelectedOperation(null);
      setFocused(false);
      setSelectedInputsType(null);
      setId(operation.id);
      setStatus(operation.status);
      setDate(operation.date);
      setType(operation.type);
      setFarmMachinery(operation.farmMachinery);
      setNoteMachinery(operation.noteMachinery);
      setTime(operation.time);
      setConsumption(operation.consumption);
      setPrice(operation.price);
      setDefaultPrice(operation.defaultPrice);
      setUseDefaultPrice(operation.useDefaultPrice);
      setNote(operation.note);
      setCurrency(operation.currency);
      setCheckStatus(false);

      const seeds = operation.operationInputTypes
        .filter((item) => item.type === "seed")[0]
        .operationInputs.filter((input) => input.id && input.id !== "duplicate");
      setSeeds(seeds);

      const fertilisers = operation.operationInputTypes
        .filter((item) => item.type === "fertiliser")[0]
        .operationInputs.filter((input) => input.id && input.id !== "duplicate");
      setFertilisers(fertilisers);

      const pesticides = operation.operationInputTypes
        .filter((item) => item.type === "pesticide")[0]
        .operationInputs.filter((input) => input.id && input.id !== "duplicate");
      setPesticides(pesticides);
    }
  };

  //Handle Delete
  const handleDelete = async () => {
    if (id && id !== "new") {
      const removeOperation = agent.Farms.Paddocks.Operations.delete(
        farmId,
        selectedPaddock.id,
        id
      ).then(() => {
        if (selectedPaddock.status === 3) {
          updateSelectedPaddockStatus(0, selectedPaddock.id);
        }
        deleteOperation(index);
      });

      toast.promise(removeOperation, {
        loading: t("global.loading"),
        success: t("operations.operation-card.notifications.operation-deleted"),
        error: t("operations.operation-card.notifications.error"),
      });
    } else if (id === "new") {
      deleteOperation(index);
      toast.success(t("operations.operation-card.notifications.operation-deleted"));
    }
  };

  // Handle Copy
  const handleCopy = async () => {
    if (id) {
      const toastId = toast.loading("Loading...");

      agent.Farms.Paddocks.Operations.update(
        farmId,
        selectedPaddock.id,
        id,
        generateRequestBody()
      )
        .then((res) => {
          updateOperation(res.body.operation, index);
          agent.Farms.Paddocks.Operations.duplicate(farmId, selectedPaddock.id, id)
            .then((res) => {
              if (selectedPaddock.status === 3) {
                updateSelectedPaddockStatus(0, selectedPaddock.id);
              }
              addOperation(index, type, res.body.operation);
              toast.success(
                "operations.operation-card.notifications.operation-duplicated",
                {
                  id: toastId,
                }
              );
            })
            .catch(() => {
              toast.error(t("operations.operation-card.notifications.error"), {
                id: toastId,
              });
            });
        })
        .catch(() => {
          toast.error(t("operations.operation-card.notifications.error"), {
            id: toastId,
          });
        });
    }
  };

  // Handle Add operation
  const handleAddOperation = async () => {
    if (selectedOperation === null) {
      addOperation(index, type, null, date);
    } else if (selectedOperation === id) {
      const changeOperation = agent.Farms.Paddocks.Operations.update(
        farmId,
        selectedPaddock.id,
        id,
        generateRequestBody()
      ).then((res) => {
        if (selectedPaddock.status === 3) {
          updateSelectedPaddockStatus(0, selectedPaddock.id);
        }
        updateOperation(res.body.operation, index);
        addOperation(index, type, null, date);
      });

      toast.promise(changeOperation, {
        loading: t("global.loading"),
        success: t("operations.operation-card.notifications.operation-added"),
        error: t("operations.operation-card.notifications.error"),
      });
    }
  };

  return (
    <div
      className={classes.container}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      data-woi='operation-card'
    >
      <Paper
        variant='outlined'
        className={`${
          focused
            ? classes.focusedCard
            : permissions.canEdit()
            ? classes.notFocusedCard
            : ""
        } ${classes.root}`}
        onClick={handleOperationSelection}
      >
        <Grid container>
          <Grid className={classes.operationContainer} item>
            <SelectOperation
              focused={focused}
              value={type ? type.id : ""}
              onChange={handleOperationChange}
            />
          </Grid>
          <Grid
            className={classes.fieldContainer}
            item
            container
            xs
            data-woi='operation-info'
          >
            <OperationStatusIcon status={status} />
            <SelectDate
              isOpen={dateIsOpen}
              setIsOpen={setDateIsOpen}
              width='150px'
              focused={focused}
              value={date ? new Date(date) : null}
              initDate={initDate}
              disableFuture={true}
              onChange={handleDateChange}
              name='date'
              label={t("operations.operation-card.date")}
              errors={errors}
            />
            <SearchEquipment
              currentMachinery={farmMachinery}
              onChange={handleEquipmentChange}
              focused={focused}
              disabled={!type}
              operationId={type ? type.id : ""}
              label={t("operations.operation-card.farm-machinery")}
              name='farmMachinery'
              error={Boolean(farmMachinery && !farmMachinery.id)}
              errors={errors}
              noteMachinery={farmMachinery && farmMachinery.id ? "" : noteMachinery}
              width='160px'
              farmId={farmId}
              selectedPeriod={selectedPeriod}
            />
            <FieldWithTextAdornment
              name='passageNumber'
              format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
              label={t("operations.operation-card.passage-number")}
              type='text'
              value={passageNumber}
              onChange={handlePassageNumberChange}
              errors={errors}
              width='105px'
              attribute='input-time'
            />
            <FieldWithTextAdornment
              disabled
              name='time'
              format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
              label={t("operations.operation-card.time")}
              type='text'
              value={time}
              adornment='h/ha'
              width='105px'
              attribute='input-time'
            />
            <FieldWithTextAdornment
              disabled
              name='consumption'
              format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,1})?$/}
              label={t("operations.operation-card.consumption")}
              type='text'
              value={consumption}
              adornment='L/ha'
              width='105px'
              attribute='input-fuel'
            />
            <FieldWithTextAdornment
              disabled={!focused || Boolean(useDefaultPrice)}
              label={t("operations.operation-card.price")}
              name='price'
              format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,2})?$/}
              onChange={handlePriceChange}
              value={useDefaultPrice ? defaultPrice : price}
              adornment={currency}
              width='195px'
              errors={errors}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    {t("operations.operation-card.default-letter")}
                    <Checkbox
                      checked={Boolean(useDefaultPrice)}
                      onChange={handleUseDefaultPriceChange}
                      size='small'
                      disabled={!focused}
                      data-woi='checkbox-cost'
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          className={!focused ? classes.disabled : ""}
          style={{ marginTop: "10px" }}
          item
          container
        >
          <Grid item container xs={11}>
            <Grid item container xs={4}>
              <PurchaseTab
                disabled={editing || hasError}
                label={t("operations.operation-card.input-types.seed")}
                type='seed'
                operationInputs={seeds}
                selected={selectedInputsType}
                onClick={setSelectedInputsType}
                focused={focused}
                errors={errors}
              />
            </Grid>
            <Grid item container xs={4} data-woi='fertiliser-inputs'>
              <PurchaseTab
                disabled={editing || hasError}
                label={t("operations.operation-card.input-types.fertiliser")}
                type='fertiliser'
                operationInputs={fertilisers}
                selected={selectedInputsType}
                onClick={setSelectedInputsType}
                focused={focused}
                errors={errors}
              />
            </Grid>
            <Grid item container xs={4} data-woi='pesticide-inputs'>
              <PurchaseTab
                disabled={editing || hasError}
                label={t("operations.operation-card.input-types.pesticide")}
                type='pesticide'
                operationInputs={pesticides}
                selected={selectedInputsType}
                onClick={setSelectedInputsType}
                focused={focused}
                errors={errors}
              />
            </Grid>
          </Grid>
          {status >= 0 && !focused && note !== "" && (
            <Grid item container xs={1} justify='flex-end' className={classes.activeNote}>
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography>{t("operations.operation-card.note")}</Typography>
                    <div>{note}</div>
                  </React.Fragment>
                }
              >
                <CommentIcon style={{ fontSize: 26 }} />
              </Tooltip>
            </Grid>
          )}
          {status >= 0 && focused && selectedInputsType !== null ? (
            <OperationInputsSection
              handleInputErrors={setInputErrors}
              inputErrors={inputErrors}
              selectedInputsType={selectedInputsType}
              setCheckStatus={setCheckStatus}
              purchases={
                selectedInputsType === "seed"
                  ? seeds
                  : selectedInputsType === "fertiliser"
                  ? fertilisers
                  : selectedInputsType === "pesticide"
                  ? pesticides
                  : []
              }
              setEditing={setEditing}
              onInputsChange={
                selectedInputsType === "seed"
                  ? setSeeds
                  : selectedInputsType === "fertiliser"
                  ? setFertilisers
                  : selectedInputsType === "pesticide"
                  ? setPesticides
                  : () => {}
              }
              selectedPaddock={selectedPaddock}
              farmId={farmId}
              selectedPeriod={selectedPeriod}
              selectedCrop={selectedCrop}
              selectedOperation={selectedOperation}
            />
          ) : focused ? (
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "grey",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            ></div>
          ) : (
            ""
          )}

          {focused ? (
            <Grid style={{ marginTop: "10px" }} item container>
              <Grid item container xs justify='flex-start'>
                <TextField
                  label='Note'
                  value={note}
                  onChange={handleNoteChange}
                  multiline
                  rows={2}
                  rowsMax={4}
                />
              </Grid>
              <Grid item container xs={9} justify='flex-end' alignItems='flex-end'>
                {permissions.canView() && status !== 3 ? (
                  <Button
                    disabled={editing || hasError || !canValidate}
                    style={{ height: "40px", marginRight: "8px" }}
                    className={classes.validateButton}
                    onClick={handleValidate}
                    data-woi='validate-operation-button'
                  >
                    <VerifiedUserIcon style={{ marginRight: "4px" }} />
                    {t("operations.operation-card.validate-button-label")}
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  style={{ height: "40px" }}
                  className={classes.cancelButton}
                  onClick={handleCancel}
                >
                  {t("operations.operation-card.cancel-button-label")}
                </Button>
                {id === "new" ? (
                  <Button
                    disabled={editing || hasError}
                    style={{ height: "40px", marginLeft: "8px" }}
                    onClick={handleCreate}
                  >
                    {t("operations.operation-card.create-button-label")}
                  </Button>
                ) : (
                  <Button
                    disabled={editing || hasError}
                    style={{ height: "40px", marginLeft: "8px" }}
                    onClick={handleSave}
                  >
                    {t("operations.operation-card.save-button-label")}
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {status === -1 ? (
          <Paper
            variant='outlined'
            className={clsx(classes.inactiveOverlay, {
              [classes.activeOverlay]: hovered,
            })}
            onClick={handleOperationSelection}
          >
            <Typography variant='h5'>
              {t("operations.operation-card.canvas-add-button-label")}
            </Typography>
          </Paper>
        ) : (
          ""
        )}
        {status === -1 && !focused ? (
          <AddCircleIcon
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            style={{
              fontSize: "39px",
              position: "absolute",
              top: "50%",
              transform: `translate(0, -50%)`,
              left: -20,
              color: customTheme.palette.success.main,
              zIndex: 11,
            }}
          />
        ) : (
          ""
        )}
      </Paper>
      {focused && (
        <Paper variant='outlined' className={classes.operationMenu}>
          <IconButton disabled={editing || hasError} onClick={handleAddOperation}>
            <AddCircleOutlineIcon
              className={classes.menuButton}
              style={editing || hasError ? { color: customTheme.palette.grey[300] } : {}}
            />
          </IconButton>
          {id ? (
            <IconButton
              disabled={editing || hasError || id === "new"}
              onClick={handleCopy}
            >
              <FileCopyOutlinedIcon
                className={classes.menuButton}
                style={
                  editing || hasError || id === "new"
                    ? { color: customTheme.palette.grey[300] }
                    : {}
                }
              />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            onClick={() => {
              setDeleteOpen(true);
            }}
            data-woi='delete-operation-button'
          >
            <DeleteOutlineIcon className={classes.remove} />
          </IconButton>
        </Paper>
      )}
      {(permissions.canView() ||
        (status !== 3 &&
          selectedPaddock.status !== 3 &&
          status >= 0 &&
          (selectedOperation === id || selectedOperation === null))) &&
      permissions.canEdit() ? (
        <div
          className={clsx(classes.addNewContainer, {
            [classes.containerActive]: hovered,
          })}
          onClick={handleAddOperation}
        >
          <div
            style={{
              width: "30%",
              height: "2px",
              backgroundColor: "lightgrey",
            }}
          ></div>
          <Typography
            className={classes.inactiveAddOperation}
            style={{
              margin: "0px 25px",
              display: "flex",
              alignContent: "center",
            }}
          >
            <AddCircleIcon style={{ marginRight: "5px" }} />
            {t("operations.operation-card.add-button-label")}
          </Typography>
          <div
            style={{
              width: "30%",
              height: "2px",
              backgroundColor: "lightgrey",
            }}
          ></div>
        </div>
      ) : (
        <div style={{ padding: "8px" }} />
      )}
      <Portal>
        <MachineryListModal
          open={openMachineryModal}
          operationId={type.id}
          farmId={farmId}
          periodId={selectedPeriod}
          givenName={noteMachinery !== "" ? noteMachinery : null}
          onClose={() => {
            setOpenMachineryModal(false);
          }}
          setFarmMachinery={handleEquipmentChange}
        />
      </Portal>
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
      <Portal>
        <CancelConfirmation
          open={cancelOpen}
          onCancel={() => {
            setCancelOpen(false);
          }}
          onConfirm={() => {
            setCancelOpen(false);
            handleCancelled();
          }}
        />
      </Portal>
    </div>
  );
};

OperationCard.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  selectedCrop: state.crop.selectedCrop,
  selectedOperation: state.crop.selectedOperation,
  selectedPaddock: state.crop.selectedPaddock,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedOperation: (payload, canvas = false, index = false) =>
    dispatch({
      type: SELECT_OPERATION,
      payload,
      canvas,
      index,
    }),
  updateOperation: (payload, index, withoutInputs = false) =>
    dispatch({ type: UPDATE_OPERATION, payload, index, withoutInputs }),
  addOperation: (index, operationType, payload = false, initDate = null) =>
    dispatch({ type: ADD_OPERATION, index, operationType, payload, initDate }),
  deleteOperation: (index) => dispatch({ type: DELETE_OPERATION, index }),
  updateSelectedPaddockStatus: (status, id) =>
    dispatch({ type: UPDATE_SELECTED_PADDOCK_STATUS, status, id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationCard);
