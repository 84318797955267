/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  ADD_HEDGEROWS,
  REMOVE_HEDGEROWS,
  UPDATE_HEDGEROWS
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";
import BlankIcon from "../../BlankIcon";

//Custom Components
import SearchSpecies from "./SearchSpecies";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";
import DeleteConfirmation from "../../DeleteConfirmation";

//Theme
import customTheme from "../../../theme";

//UI Components
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    width: "140px"
  }
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  hedgerow: PropTypes.object,
  farmId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  index: PropTypes.number,

  //Provided props
  addHedgerow: PropTypes.func, // Provided by Redux
  updateHedgerow: PropTypes.func, // Provided by Redux
  deleteHedgerow: PropTypes.func, // Provided by Redux
  classes: PropTypes.object // Provided by Material ui style
};

const HedgerowRow = ({
  hedgerow = null,
  classes,
  farmId,
  selectedPeriod,
  index,
  addHedgerow,
  updateHedgerow,
  deleteHedgerow
}) => {
  const [id, setId] = useState(null);
  const [species, setSpecies] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [difference, setDifference] = useState(0);
  const [status, setStatus] = useState(0);
  const [lastYearSize, setLastYearSize] = useState(0);
  const [currentYearSize, setCurrentYearSize] = useState(0);
  const [loading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [changed, setChanged] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [lock, setLock] = useState(0);

  const { t } = useTranslation();
  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR
  ]);

  useEffect(() => {
    if (hedgerow) {
      setId(hedgerow.id);
      setSpecies(hedgerow.species);
      setQuantity(hedgerow.quantity);
      setDifference(hedgerow.difference);
      setStatus(hedgerow.status);
      setLastYearSize(hedgerow.size.lastYear);
      setCurrentYearSize(hedgerow.size.current);
      setLock(hedgerow.lock);
    }
  }, [hedgerow]);

  const checkValidate = useCallback(() => {
    if (!species || (species && !species.id)) {
      setErrors(errors => [
        ...errors,
        {
          field: "species",
          message: t("hedgerows.section.errors.required-species")
        }
      ]);
    } else {
      setErrors(errors => [...errors.filter(item => item.field !== "species")]);
    }
    if (!(quantity > 0)) {
      setErrors(errors => [
        ...errors,
        {
          field: "quantity",
          message: t("hedgerows.section.errors.required-quantity")
        }
      ]);
    } else {
      setErrors(errors => [
        ...errors.filter(item => item.field !== "quantity")
      ]);
    }
    if (!(currentYearSize > 0)) {
      setErrors(errors => [
        ...errors,
        {
          field: "currentYearSize",
          message: t("hedgerows.section.errors.required-current-year-size")
        }
      ]);
    } else {
      setErrors(errors => [
        ...errors.filter(item => item.field !== "currentYearSize")
      ]);
    }
  }, [species, quantity, currentYearSize, t]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed]);

  const handleCreate = () => {
    if (errors.length === 0) {
      const reqBody = {
        hedgerow: {
          species,
          period: {
            id: selectedPeriod
          },
          quantity,
          difference,
          status,
          size: {
            lastYear: lastYearSize,
            current: currentYearSize
          }
        }
      };

      const createHedgerow = agent.Farms.Hedgerows.create(farmId, reqBody).then(
        res => {
          addHedgerow(res.body.hedgerow);
          setChanged(false);
          setId(null);
          setSpecies(null);
          setQuantity(0);
          setDifference(0);
          setStatus(0);
          setLastYearSize(0);
          setCurrentYearSize(0);
          setErrors([]);
        }
      );

      toast.promise(createHedgerow, {
        loading: t("global.loading"),
        success: t("hedgerows.section.notifications.hedgerow-added"),
        error: t("hedgerows.section.notifications.error")
      });
    }
  };

  const handleUpdate = useCallback(() => {
    const validate = (species, quantity, currentYearSize) => {
      let error = false;
      if (!species || (species && !species.id)) {
        error = true;
      }
      if (!(quantity > 0)) {
        error = true;
      }
      if (!(currentYearSize > 0)) {
        error = true;
      }
      return error;
    };

    if (!validate(species, quantity, currentYearSize)) {
      const reqBody = {
        hedgerow: {
          species,
          quantity,
          difference,
          size: {
            lastYear: lastYearSize,
            current: currentYearSize
          }
        }
      };

      const changeHedgerow = agent.Farms.Hedgerows.update(
        farmId,
        id,
        reqBody
      ).then(res => {
        updateHedgerow(res.body.hedgerow, index);
        setChanged(false);
      });

      toast.promise(changeHedgerow, {
        loading: t("global.loading"),
        success: t("hedgerows.section.notifications.hedgerow-saved"),
        error: t("hedgerows.section.notifications.error")
      });
    }
  }, [
    t,
    farmId,
    id,
    index,
    updateHedgerow,
    species,
    quantity,
    difference,
    lastYearSize,
    currentYearSize
  ]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed]);

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  const handleDelete = () => {
    const removeHedgerow = agent.Farms.Hedgerows.delete(farmId, id).then(
      res => {
        deleteHedgerow(index);
      }
    );

    toast.promise(removeHedgerow, {
      loading: t("global.loading"),
      success: t("hedgerows.section.notifications.hedgerow-deleted"),
      error: t("hedgerows.section.notifications.error")
    });
  };

  const handleStatus = async () => {
    const reqBody = {
      hedgerow: {
        status: 3
      }
    };

    const changeHedgerow = agent.Farms.Hedgerows.update(
      farmId,
      id,
      reqBody
    ).then(res => {
      updateHedgerow(res.body.hedgerow, index);
      setChanged(false);
    });

    toast.promise(changeHedgerow, {
      loading: t("global.loading"),
      success: t("hedgerows.section.notifications.hedgerow-validated"),
      error: t("hedgerows.section.notifications.error")
    });
  };

  const actionType =
    !id && permissions.canEdit() ? (
      <Grid item>
        <Button
          disabled={loading || !species || (species && !species.id)}
          fullWidth={true}
          onClick={handleCreate}
        >
          {t("hedgerows.section.add-button-label")}
        </Button>
      </Grid>
    ) : permissions.canEdit() && (permissions.canView() || status !== 3) ? (
      <div>
        <IconButton onClick={handleDeleteConfirm}>
          <DeleteForever color="error" />
        </IconButton>
      </div>
    ) : (
      ""
    );

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item>
        <SearchSpecies
          disabled={!permissions.canEdit()}
          onChange={species => {
            setSpecies(species);
            setChanged(true);
          }}
          errors={errors}
          value={species}
          label={t("hedgerows.section.species")}
          name="species"
        />
      </Grid>
      <Grid item style={{ paddingTop: "9px" /* Status height adjustement */ }}>
        {permissions.canView() && status === 0 ? (
          <Tooltip title={t("hedgerows.section.validation.status-0")} arrow>
            <div>
              <IconButton
                disabled={!permissions.canEdit()}
                size="small"
                onClick={handleStatus}
              >
                <ErrorIcon style={{ color: customTheme.palette.error.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : status === 3 ? (
          <Tooltip title={t("hedgerows.section.validation.status-3")} arrow>
            <div>
              <IconButton disabled size="small">
                <VerifiedUserIcon
                  style={{ color: customTheme.palette.success.dark }}
                />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div>
            <IconButton disabled size="small">
              <BlankIcon />
            </IconButton>
          </div>
        )}
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit() || !species}
          name="quantity"
          format={/^[0-9]{1,5}$/}
          label={t("hedgerows.section.quantity")}
          value={quantity}
          errors={errors}
          onChange={e => {
            setQuantity(e.target.value);
            setChanged(true);
          }}
          onFocus={event => event.target.select()}
          adornment={t("hedgerows.section.trees")}
          width="130px"
        />
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit() || !species}
          name="currentYearSize"
          format={/^[0-9][0-9]?[0-9]?$/}
          label={t("hedgerows.section.current-year-size")}
          value={currentYearSize}
          errors={errors}
          onChange={e => {
            setCurrentYearSize(e.target.value);
            setChanged(true);
          }}
          onFocus={event => event.target.select()}
          adornment={t("hedgerows.section.unit")}
          width="150px"
        />
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit() || !species}
          name="lastYearSize"
          format={/^[0-9][0-9]?[0-9]?$/}
          label={t("hedgerows.section.last-year-size")}
          value={lastYearSize}
          errors={errors}
          onChange={e => {
            setLastYearSize(e.target.value);
            setChanged(true);
          }}
          onFocus={event => event.target.select()}
          adornment={t("hedgerows.section.unit")}
          width="180px"
        />
      </Grid>
      <Grid item>
        <FieldWithTextAdornment
          disabled={!permissions.canEdit() || !species}
          name="difference"
          format={/^[0-9][0-9]?[0-9]?$/}
          label={t("hedgerows.section.difference")}
          value={difference}
          errors={errors}
          onChange={e => {
            setDifference(e.target.value);
            setChanged(true);
          }}
          onFocus={event => event.target.select()}
          adornment={t("hedgerows.section.trees")}
          width="130px"
        />
      </Grid>
      {actionType}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
    </Grid>
  );
};

HedgerowRow.propTypes = propsDefinition;

const mapDispachToProps = dispatch => ({
  addHedgerow: payload => dispatch({ type: ADD_HEDGEROWS, payload }),
  updateHedgerow: (payload, index) =>
    dispatch({ type: UPDATE_HEDGEROWS, payload, index }),
  deleteHedgerow: index => dispatch({ type: REMOVE_HEDGEROWS, index })
});

export default connect(
  null,
  mapDispachToProps
)(withStyles(styles)(HedgerowRow));
