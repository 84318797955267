/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SELECT_LANGUAGE, GLOBAL_LANGUAGE_LOADED } from "../constants/actionTypes";

//API
import agent from "../agent";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Flag from "react-world-flags";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  onLanguagesLoaded: PropTypes.func, // Provided by Redux
  languages: PropTypes.array, // Provided by Redux
  language: PropTypes.string, // Provided by Redux
  setSelectedLanguage: PropTypes.func, // Provided by Redux
  className: PropTypes.string, // Provided by Redux
};

const iso3languages = {
  EN: "GBR",
  FR: "FRA",
  DE: "DEU",
  ES: "ARG",
  IT: "ITA",
};

const SelectLanguage = (props) => {
  const {
    onLanguagesLoaded,
    languages,
    language,
    setSelectedLanguage,
    className,
  } = props;
  const { i18n } = useTranslation();

  useEffect(() => {
    const getLoadedLanguages = async () => {
      const { body, error } = await agent.Languages.get();

      if (!error) {
        onLanguagesLoaded(body.languages);
      }
    };
    getLoadedLanguages();
  }, [onLanguagesLoaded]);

  const onSelectChange = async (event) => {
    const prevLanguage = language;
    const { body, error } = await agent.Auth.Language.update({
      language: { id: event.target.value },
    });
    if (!error) {
      setSelectedLanguage(body.language.id);
      i18n.changeLanguage(body.language.id.toLowerCase());
      localStorage.setItem("i18nextLng", body.language.id.toLowerCase());
    } else {
      setSelectedLanguage(prevLanguage);
    }
  };

  if (language !== undefined && languages && languages.length > 0) {
    return (
      <FormControl
        variant='standard'
        size='small'
        className={className}
        style={{ width: "160px" }}
      >
        <Select name='timePeriod' value={language} onChange={onSelectChange}>
          {languages.map((item, i) => {
            return (
              <MenuItem key={i} value={item.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Flag
                    height='16'
                    width='40px'
                    code={iso3languages[item.id]}
                    style={{ marginRight: "8px" }}
                  />
                  {item.name}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectLanguage.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  languages: state.global.languages,
  language: state.common.language,
});

const mapDispatchToProps = (dispatch) => ({
  onLanguagesLoaded: (payload) =>
    dispatch({
      type: GLOBAL_LANGUAGE_LOADED,
      payload,
    }),
  setSelectedLanguage: (payload) =>
    dispatch({
      type: SELECT_LANGUAGE,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
