/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import useIsMountedRef from "../../../utils/isMountedRef";

// Custom theme
import customTheme from "../../../theme";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

const selector = formValueSelector("ProfileForm");

const mapStateToProps = (state) => {
  const country = selector(state, "country");
  return {
    country,
  };
};

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorText: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,

  //Provided props
  country: PropTypes.object, // Provided by Redux
};

const SearchRegion = ({
  value,
  onChange,
  disabled,
  errorText,
  name,
  label,
  country,
  locked,
}) => {
  const { t } = useTranslation();
  const [regions, setRegions] = useState([
    { id: 0, disabled: true, name: t("dashboard.form.fields.region-search") },
  ]);
  const [loaded, setLooaded] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (country && country.id) {
      setRegions([
        { id: 0, disabled: true, name: t("dashboard.form.fields.region-search") },
      ]);
      const getRegions = async (countryId) => {
        const { body, error } = await agent.Regions.get(countryId);
        if (isMountedRef.current && !error) {
          setRegions(body.regions);
          setLooaded(true);
        }
      };
      getRegions(country.id);
    }
  }, [country, isMountedRef, t]);

  const onChangeCustom = (event, data) => {
    onChange(event, { id: event.target.value });
  };

  if (regions) {
    return (
      <Tooltip
        arrow
        title={locked ? t("dashboard.form.fields.region-search-locked") : ""}
      >
        <FormControl
          variant='outlined'
          color='secondary'
          size='small'
          style={{ width: "100%" }}
        >
          <InputLabel
            style={{
              backgroundColor: "#faf5ea",
              paddingRight: "6px",
              paddingLeft: "6px",
              marginLeft: "-4px",
              color: Boolean(errorText)
                ? customTheme.palette.error.main
                : disabled
                ? customTheme.palette.text.disabled
                : customTheme.palette.text.primary,
            }}
            shrink
          >
            {label}
          </InputLabel>
          <Select
            disabled={!(regions.length > 0) || !country || disabled}
            name={name}
            value={loaded ? value.id || "" : ""}
            onChange={onChangeCustom}
            data-woi='region'
          >
            {regions.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id} data-woi='region-item'>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
  return null;
};

SearchRegion.propTypes = propsDefinition;

export default connect(mapStateToProps)(SearchRegion);
