/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  ADD_OPERATION,
  SELECT_OPERATION,
  SET_OPERATIONS,
} from "../../../../constants/actionTypes";

//API
import agent from "../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import Permissions from "../../../../utils/Permissions";

//Custom Components
import ConfirmSingleOperation from "./ConfirmSingleOperation";
import PaddockListModal from "./PaddockListModal";
import MenuButton from "./MenuButton";
import ConfirmValidatePaddock from "./ConfirmValidatePaddock";

//Theme
import customTheme from "../../../../theme";

//UI Components
import Portal from "@material-ui/core/Portal";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Grid from "@material-ui/core/Grid";

const propsDefinition = {
  //Provided props
  operations: PropTypes.array, // Provided by Redux
  addOperation: PropTypes.func, // Provided by Redux
  operationTypes: PropTypes.array, // Provided by Redux
  setSelectedOperation: PropTypes.func, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
  selectedPaddock: PropTypes.object, // Provided by Redux
  farmId: PropTypes.string, // Provided by Redux
  setOperations: PropTypes.func, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
  validatePaddock: PropTypes.func, // Provided by Redux
};

const OperationSectionMenu = ({
  operations,
  addOperation,
  operationTypes,
  setSelectedOperation,
  selectedCrop,
  selectedPaddock,
  farmId,
  setOperations,
  selectedPeriod,
  lock,
  validatePaddock,
}) => {
  const { t } = useTranslation();

  const [canvas, setCanvas] = useState([]);
  const [paddockModal, setPaddockModal] = useState(false);
  const [singleConfirm, setSingleConfirm] = useState(false);
  const [validateOpen, setValidateOpen] = useState(false);

  const permissions = new Permissions(null, lock);

  useEffect(() => {
    setCanvas(operations.filter((item) => item.status === -1));
  }, [operations]);

  const handleSingleOperation = () => {
    if (canvas.length > 0) {
      setSingleConfirm(true);
    } else {
      setSingleOperation();
    }
  };

  const setSingleOperation = async () => {
    const { body, error } = await agent.Farms.Paddocks.Operations.Canvas.delete(
      farmId,
      selectedPaddock.id
    );
    if (!error) {
      setOperations(body.operations);
      addOperation(0, operationTypes[0]);
    }
  };

  return (
    <div>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={3}>
          <MenuButton
            disabled={canvas.length < 1 || !permissions.canEdit()}
            disabledTitle={t("operations.section.menu.tooltip.canvas")}
            icon={FormatListBulletedIcon}
            title={t("operations.section.menu.canvas-button")}
            onClick={() => setSelectedOperation(canvas[0].id, true, 0)}
          />
        </Grid>
        <Grid item xs={3} data-woi='start-operation-button'>
          <MenuButton
            disabled={!permissions.canEdit()}
            icon={AddCircleIcon}
            title={t("operations.section.menu.single-button")}
            onClick={handleSingleOperation}
          />
        </Grid>
        <Grid item xs={3}>
          <MenuButton
            disabled={!permissions.canEdit()}
            icon={ImportExportIcon}
            title={t("operations.section.menu.import-button")}
            onClick={() => setPaddockModal(true)}
          />
        </Grid>
        <Grid item xs={9}>
          <MenuButton
            disabled={!permissions.canEdit()}
            icon={VerifiedUserIcon}
            color={customTheme.palette.success.dark}
            title={t("operations.section.menu.validate-paddock")}
            onClick={() => setValidateOpen(true)}
          />
        </Grid>
      </Grid>
      <Portal>
        <PaddockListModal
          open={paddockModal}
          onClose={() => setPaddockModal(false)}
          farmId={farmId}
          selectedCrop={selectedCrop}
          selectedPaddockId={selectedPaddock.id}
          setOperations={setOperations}
          selectedPeriod={selectedPeriod}
        />
      </Portal>
      <Portal>
        <ConfirmSingleOperation
          open={singleConfirm}
          onCancel={() => setSingleConfirm(false)}
          onConfirm={setSingleOperation}
        />
      </Portal>
      <Portal>
        <ConfirmValidatePaddock
          open={validateOpen}
          onCancel={() => setValidateOpen(false)}
          onConfirm={() => {
            setValidateOpen(false);
            validatePaddock();
          }}
        />
      </Portal>
    </div>
  );
};

OperationSectionMenu.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  operationTypes: state.global.operationTypes,
  selectedPaddock: state.crop.selectedPaddock,
  farmId: state.farm.farm.id,
  selectedCrop: state.crop.selectedCrop,
  selectedPeriod: state.farm.selectedPeriod,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  addOperation: (index, operationType, payload = false) =>
    dispatch({ type: ADD_OPERATION, index, operationType, payload }),
  setSelectedOperation: (payload, canvas = false, index = false) =>
    dispatch({
      type: SELECT_OPERATION,
      payload,
      canvas,
      index,
    }),
  setOperations: (payload) => dispatch({ type: SET_OPERATIONS, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationSectionMenu);
