/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";

const filter = createFilterOptions();

const propsDefinition = {
  //Required attributes to be defined in the implementation
  farmId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  operationId: PropTypes.string.isRequired,
  currentMachinery: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  noteMachinery: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

const SearchEquipment = ({
  farmId,
  selectedPeriod,
  operationId,
  currentMachinery,
  focused,
  noteMachinery,
  label,
  error,
  errors: errList,
  onChange,
  name,
  width,
}) => {
  const [equipments, setEquipments] = useState([{}]);
  const [open, setOpen] = useState(false);

  const errors = errList.filter((item) => item.field === name);
  const { field, message } = errors.length > 0 ? errors[0] : {};
  const { t } = useTranslation();

  const getFarmMachineries = async () => {
    const { body, error } = await agent.Farms.FarmMachineries.get(
      farmId,
      selectedPeriod,
      operationId
    );
    !error &&
      body.operationTypes.length > 0 &&
      setEquipments(body.operationTypes[0].farmMachineries);
  };

  const onOpen = async () => {
    setOpen(true);
    await getFarmMachineries();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      closeIcon={<HighlightOffIcon style={{ fontSize: "20px", color: "red" }} />}
      disabled={focused ? false : true}
      style={{
        width,
      }}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      size='small'
      freeSolo
      forcePopupIcon={open || (currentMachinery && currentMachinery.id) ? false : true}
      popupIcon={<SearchIcon />}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        filtered.push({
          dbData: true,
        });

        if (params.inputValue !== "") {
          filtered.push({
            missing: true,
            givenName: params.inputValue,
          });
        }

        return filtered;
      }}
      value={currentMachinery}
      getOptionLabel={(option) => (option.givenName ? option.givenName : "")}
      renderOption={(option) => {
        return option ? (
          option.id ? (
            <div>{option.givenName}</div>
          ) : option.missing ? (
            <div style={{ display: "block" }}>
              <div style={{ color: "#f44336" }}>
                {t("operations.search-equipment.missing.1")}
              </div>
              {option.givenName}
            </div>
          ) : option.dbData ? (
            <div style={{ color: "blue" }} data-woi='search-machines-db'>
              {t("operations.search-equipment.open-database")}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        );
      }}
      options={equipments.length > 0 ? equipments : [{ ...currentMachinery }]}
      renderInput={(params) => (
        <Tooltip
          title={
            currentMachinery && currentMachinery.givenName
              ? currentMachinery.givenName
              : ""
          }
        >
          <TextField
            {...params}
            label={label}
            margin='normal'
            variant='outlined'
            error={field ? true : error}
            helperText={message ? message : noteMachinery ? noteMachinery : ""}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Tooltip>
      )}
      data-woi='select-machine'
    />
  );
};

SearchEquipment.propTypes = propsDefinition;

export default SearchEquipment;
