/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import FieldWithTextAdornment from "../../../../../FieldWithTextAdornment";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  errors: PropTypes.array.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  unit: PropTypes.string,
  setQuantity: PropTypes.func.isRequired,
};

const QuantityConfirmation = ({
  open,
  min,
  max,
  errors,
  quantity,
  onCancel,
  onConfirm,
  unit,
  setQuantity,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='confirmation-dialog-title'>
        {t("operations.operation-input.quantity-confirmation-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={6}>
            <Typography>
              {t("operations.operation-input.quantity-confirmation-modal.sub-title-left")}
            </Typography>
            <FieldWithTextAdornment
              style={{ marginTop: "10px" }}
              name='quantity'
              format={/\d{1,3}([.,]\d{1,3})?/}
              label={t("operations.operation-input.section.ha-quantity-label")}
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              errors={errors}
              adornment={unit}
              width='135px'
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {t(
                "operations.operation-input.quantity-confirmation-modal.sub-title-right"
              )}
            </Typography>
            <FieldWithTextAdornment
              style={{ marginTop: "8px" }}
              disabled
              name='min'
              format={/\d{1,3}([.,]\d{1,3})?/}
              label={t("operations.operation-input.section.min")}
              value={min}
              adornment={unit}
              width='135px'
            />
            <FieldWithTextAdornment
              style={{ marginTop: "8px" }}
              disabled
              name='max'
              format={/\d{1,3}([.,]\d{1,3})?/}
              label={t("operations.operation-input.section.max")}
              value={max}
              adornment={unit}
              width='135px'
            />
          </Grid>
        </Grid>
        <Typography style={{ marginTop: "16px" }}>
          {t("operations.operation-input.quantity-confirmation-modal.description")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant='text' style={{ color: "#f44336" }}>
          {t(
            "operations.operation-input.quantity-confirmation-modal.cancel-button-label"
          )}
        </Button>
        <Button onClick={handleConfirm} color='secondary'>
          {t(
            "operations.operation-input.quantity-confirmation-modal.confirm-button-label"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

QuantityConfirmation.propTypes = propsDefinition;

export default QuantityConfirmation;
