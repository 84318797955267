/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  operationId: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
};

const SearchMachineries = ({
  operationId,
  value,
  onChange,
  name,
  periodId,
  farmId,
  disabled,
  errors,
}) => {
  const { t } = useTranslation();

  const [machineries, setMachineries] = useState([
    { id: "loading", name: t("equipments.search-machinery.loading") },
  ]);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const getMachineries = useCallback(
    async (query = "") => {
      const { body, error } = await agent.Machineries.search(
        operationId,
        periodId,
        farmId,
        query
      );
      if (!error) {
        setMachineries(body.machineries);
      } else {
        setMachineries({ id: "error", name: "error" });
      }
    },
    [operationId, periodId, farmId]
  );

  useEffect(() => {
    if (open) {
      let timer;
      if (query !== "") {
        timer = setTimeout(() => {
          getMachineries(query);
        }, 300);

        return () => clearTimeout(timer);
      } else {
        timer = setTimeout(() => {
          getMachineries();
        }, 100);

        return () => clearTimeout(timer);
      }
    }
  }, [query, open, getMachineries]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      disabled={disabled}
      closeIcon={
        <HighlightOffIcon style={{ fontSize: "18px", marginTop: "1px", color: "red" }} />
      }
      fullWidth={true}
      value={value}
      onChange={onChange}
      onInputChange={(e) => setQuery(e && e.target ? e.target.value : "")}
      onOpen={onOpen}
      onClose={onClose}
      freeSolo
      size='small'
      name={name}
      getOptionSelected={(opt, value) => {
        return opt.id === value.id;
      }}
      options={machineries}
      getOptionDisabled={(option) => {
        return (
          (option.id === "loading" || option.id === "error" || option.usedByFarm === 1) &&
          value !== null &&
          option.id !== value.id
        );
      }}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          margin='normal'
          variant='outlined'
          error={errors.filter((item) => item.field === "machinery").length > 0}
          helperText={
            errors.filter((item) => item.field === "machinery").length > 0
              ? errors.filter((item) => item.field === "machinery")[0].message
              : ""
          }
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
  );
};

SearchMachineries.propTypes = propsDefinition;

export default SearchMachineries;
