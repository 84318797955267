/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  GLOBAL_COVERS_LOADED,
  GLOBAL_TECHS_LOADED,
  GLOBAL_DESTRUCTIONS_LOADED,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Custom Components
import LoadingSpinner from "../../LoadingSpinner";
import CropsTechsRow from "./CropsTechsRow";

//Theme
import customTheme from "../../../theme";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
  onTechsLoaded: PropTypes.func, // Provided by Redux
  onCoversLoaded: PropTypes.func, // Provided by Redux
  onDestructionsLoaded: PropTypes.func, // Provided by Redux
};

const CropsTechsSection = (props) => {
  const {
    farmId,
    selectedPeriod,
    selectedCrop,
    onTechsLoaded,
    onCoversLoaded,
    onDestructionsLoaded,
  } = props;

  const [cropsTechs, setCropsTechs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getTechsLoaded = async () => {
      const { body, error } = await agent.Techs.get();

      if (!error) {
        onTechsLoaded(body.techs);
      }
    };
    const getCoversLoaded = async () => {
      const { body, error } = await agent.Covers.get();

      if (!error) {
        onCoversLoaded(body.covers);
      }
    };
    const getDestructionsLoaded = async () => {
      const { body, error } = await agent.Destructions.get();

      if (!error) {
        onDestructionsLoaded(body.destructions);
      }
    };
    const getCropsTechs = async () => {
      const { body, error } = await agent.Farms.CropsTechs.get(
        farmId,
        selectedPeriod,
        selectedCrop
      );

      if (!error) {
        setCropsTechs(body.cropsTechs);
      }
      setLoading(false);
    };
    getTechsLoaded();
    getCoversLoaded();
    getDestructionsLoaded();
    getCropsTechs();
  }, [
    onTechsLoaded,
    onCoversLoaded,
    farmId,
    selectedPeriod,
    selectedCrop,
    onDestructionsLoaded,
  ]);

  const cropsTechsList = cropsTechs.map((item, index) => (
    <CropsTechsRow key={index} cropsTechs={item} farmId={farmId} />
  ));

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (cropsTechs.length > 0) {
    return <div>{cropsTechsList}</div>;
  }
  return null;
};

CropsTechsSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  ...state.crop,
  selectedPeriod: state.farm.selectedPeriod,
  farmId: state.farm.farm && state.farm.farm.id,
});

const mapDispatchToProps = (dispatch) => ({
  onTechsLoaded: (payload) => dispatch({ type: GLOBAL_TECHS_LOADED, payload }),
  onCoversLoaded: (payload) => dispatch({ type: GLOBAL_COVERS_LOADED, payload }),
  onDestructionsLoaded: (payload) =>
    dispatch({ type: GLOBAL_DESTRUCTIONS_LOADED, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CropsTechsSection);
