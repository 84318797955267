/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import {
  promiseMiddleware,
  localStorageMiddleware,
  languageMiddleware,
} from "./middleware";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

const initialState = JSON.parse(localStorage.getItem("reduxState"));

if (initialState) {
  initialState.crop.selectedOperation = null;
  initialState.crop.operations = [];
  initialState.crop.operationLoaded = false;
  initialState.common.currentUser = null;
  initialState.common.appLoaded = null;
  initialState.common.error = null;
  initialState.form.LoginForm = null;
  initialState.crop.selectedPaddock = null;
  initialState.global.customTypes = [];
  initialState.farm.loaded = false;
  initialState.farm.refreshFarm = false;
}

const persistedState = localStorage.getItem("reduxState") ? initialState : {};

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(
      routerMiddleware(history),
      promiseMiddleware,
      localStorageMiddleware,
      languageMiddleware
    );
  } else {
    return applyMiddleware(
      routerMiddleware(history),
      promiseMiddleware,
      localStorageMiddleware,
      languageMiddleware,
      createLogger()
    );
  }
};

function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(getMiddleware())
  );

  return store;
}

export const store = configureStore(persistedState);
