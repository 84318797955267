/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  GHG_PROGRAMS_LOADED,
  ADD_GHG_PROGRAM,
  UPDATE_GHG_PROGRAM,
  REMOVE_GHG_PROGRAM,
} from "../constants/actionTypes";

const GhgPrograms = (state = { loaded: false }, action) => {
  switch (action.type) {
    case GHG_PROGRAMS_LOADED:
      return {
        ...state,
        ghgPrograms: action.payload,
        loaded: true,
      };
    case ADD_GHG_PROGRAM:
      return {
        ...state,
        ghgPrograms: [...state.ghgPrograms, action.payload],
      };
    case UPDATE_GHG_PROGRAM:
      return {
        ...state,
        ghgPrograms: state.ghgPrograms.map((ghgProgram, index) => {
          if (index === action.index) {
            return action.payload;
          }
          return ghgProgram;
        }),
      };
    case REMOVE_GHG_PROGRAM:
      return {
        ...state,
        ghgPrograms: [...state.ghgPrograms.filter((i, index) => action.index !== index)],
      };
    default:
      return state;
  }
};

export default GhgPrograms;
