/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const style = (theme) => ({
  titleContainer: {
    marginBottom: "24px",
  },
});

const Header = (props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Grid className={classes.titleContainer} container>
      <Grid item container xs={6} alignItems='center'>
        <Typography variant='h4'>{t("land-use.header.title")}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Header);
