/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import FieldWithTextAdornment from "../../FieldWithTextAdornment";
import LoadingSpinner from "../../LoadingSpinner";

//Theme
import customTheme from "../../../theme";

//UI Components
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const style = (theme) => ({
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  breakline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by withStyles HOC
};

const CropsResiduesSection = ({ farmId, selectedPeriod, selectedCrop, classes }) => {
  const { t } = useTranslation();

  const [id, setId] = useState(null);
  const [status, setStatus] = useState(0);
  const [isSold, setIsSold] = useState(0);
  const [soldArea, setSoldArea] = useState(0);
  const [soldQuantity, setSoldQuantity] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [lock, setLock] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  const checkValidate = useCallback(() => {
    if (isSold) {
      if (!(soldQuantity > 0)) {
        setErrors((errors) => [
          ...errors,
          {
            field: "soldQuantity",
            message: t("operations.crops-residues.errors.required-quantity"),
          },
        ]);
      } else {
        setErrors((errors) => [
          ...errors.filter((item) => item.field !== "soldQuantity"),
        ]);
      }
      if (!(soldArea > 0)) {
        setErrors((errors) => [
          ...errors,
          {
            field: "soldArea",
            message: t("operations.crops-residues.errors.required-sold-area"),
          },
        ]);
      } else {
        setErrors((errors) => [...errors.filter((item) => item.field !== "soldArea")]);
      }
    } else {
      setErrors((errors) => [
        ...errors.filter(
          (item) => item.field !== "soldArea" && item.field !== "soldQuantity"
        ),
      ]);
    }
  }, [t, soldQuantity, soldArea, isSold]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed]);

  const handleUpdate = useCallback(() => {
    const validate = (soldQuantity, soldArea, isSold) => {
      let error = false;
      if (isSold === 1) {
        if (!(soldQuantity > 0)) {
          error = true;
        }
        if (!(soldArea > 0)) {
          error = true;
        }
      } else if (isSold === null) {
        error = true;
      }
      return error;
    };

    if (!validate(soldQuantity, soldArea, isSold)) {
      const reqBody = {
        cropsResidues: {
          status,
          isSold,
          sold: {
            area: soldArea,
            quantity: soldQuantity,
          },
        },
      };

      const updateCropResidue = agent.Farms.CropsResidues.update(farmId, id, reqBody)
        .then((res) => {
          const cropsResidues = res.body.cropsResidues;
          setId(cropsResidues.id);
          setIsSold(cropsResidues.isSold);
          setStatus(cropsResidues.status);
          setSoldArea(cropsResidues.sold.area);
          setSoldQuantity(cropsResidues.sold.quantity);
          setTotalArea(cropsResidues.totalArea);
          setChanged(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      toast.promise(updateCropResidue, {
        loading: t("global.loading"),
        success: t("operations.crops-residues.notifications.crops-residues-saved"),
        error: t("operations.crops-residues.notifications.error"),
      });
    }
  }, [t, farmId, id, soldQuantity, soldArea, status, isSold]);

  useEffect(() => {
    if (changed && id) {
      setLoading(true);
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed]);

  useEffect(() => {
    let mounted = true;
    const getCropsResidues = async () => {
      setPageLoading(true);
      const { body, error } = await agent.Farms.CropsResidues.get(
        farmId,
        selectedPeriod,
        selectedCrop
      );
      if (!error && mounted) {
        const cropsResidues = body.cropsResidues;
        setId(cropsResidues.id);
        setStatus(cropsResidues.status);
        setIsSold(cropsResidues.isSold);
        setSoldArea(cropsResidues.sold.area);
        setSoldQuantity(cropsResidues.sold.quantity);
        setTotalArea(cropsResidues.totalArea);
        setLock(cropsResidues.lock);
      }
      setPageLoading(false);
    };
    getCropsResidues();
    return () => (mounted = false);
  }, [selectedPeriod, farmId, selectedCrop]);

  if (pageLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (id) {
    return (
      <div>
        <Paper className={classes.outlinedContainer} variant='outlined'>
          <Grid container spacing={1}>
            <Grid container item justify='space-between'>
              <Grid container item alignItems='center' xs={6}>
                <Typography className={classes.typoMarginRight} variant='h4'>
                  {t("operations.crops-residues.title")}
                </Typography>
                {status === 3 ? (
                  <Tooltip title={t("operations.crops-residues.valid")}>
                    <VerifiedUserIcon className={classes.valid} />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("operations.crops-residues.invalid")}>
                    <ErrorIcon className={classes.invalid} />
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                {permissions.canEdit() && permissions.canView() && status !== 3 && (
                  <Button
                    disabled={
                      isSold === null ||
                      loading ||
                      (isSold === 0 ? false : !(soldArea > 0) || !(soldQuantity > 0))
                    }
                    style={{ height: "40px" }}
                    className={classes.validateButton}
                    onClick={() => {
                      setStatus(3);
                      setChanged(true);
                    }}
                    data-woi='validate-residue-button'
                  >
                    <VerifiedUserIcon style={{ marginRight: "5px" }} />
                    {t("operations.crops-residues.validate-button-label")}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container item alignItems='center'>
              <Typography variant='subtitle1'>
                {t("operations.crops-residues.subtitle")}
              </Typography>
              <Grid item xs={12}>
                <FormControl component='fieldset' disabled={!permissions.canEdit()}>
                  <RadioGroup
                    size='small'
                    row
                    name='position'
                    style={{ display: "flex", alignItems: "center" }}
                    onChange={(e) => {
                      setIsSold(parseInt(e.target.value));
                      setChanged(true);
                    }}
                    value={isSold !== null ? isSold.toString() : null}
                  >
                    <FormControlLabel
                      value='0'
                      style={
                        isSold === null ? { color: customTheme.palette.error.main } : null
                      }
                      control={
                        <Radio
                          style={
                            isSold === null
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                          size='small'
                          color='secondary'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t("operations.crops-residues.no")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value='1'
                      style={
                        isSold === null ? { color: customTheme.palette.error.main } : null
                      }
                      control={
                        <Radio
                          style={
                            isSold === null
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                          size='small'
                          color='secondary'
                          data-woi='residue-checked'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t("operations.crops-residues.yes")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  {isSold === null && (
                    <Typography
                      variant='caption'
                      style={{ color: customTheme.palette.error.main }}
                    >
                      {t("operations.crops-residues.errors.required-choice")}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {isSold === 1 && (
              <>
                <Grid className={classes.underline} item />
                <Grid container item alignItems='center'>
                  <Grid item xs={3}>
                    <Typography className={classes.typoMarginRight}>
                      {t("operations.crops-residues.total-surface-label")}
                    </Typography>
                  </Grid>
                  <FieldWithTextAdornment
                    disabled={true}
                    name='totalArea'
                    format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
                    value={totalArea || 0}
                    adornment={t("operations.crops-residues.total-surface-adornment")}
                    width='90px'
                  />
                </Grid>
                <Grid container item alignItems='center' data-woi='residue-area'>
                  <Grid item xs={3}>
                    <Typography className={classes.typoMarginRight}>
                      {t("operations.crops-residues.surface-label")}
                    </Typography>
                  </Grid>
                  <FieldWithTextAdornment
                    disabled={!permissions.canView() || !(isSold === 1)}
                    name='soldArea'
                    format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
                    value={soldArea}
                    onChange={(e) => {
                      setSoldArea(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t("operations.crops-residues.surface-adornment")}
                    width='130px'
                  />
                </Grid>
                <Grid container item alignItems='center' data-woi='residue-quantity'>
                  <Grid item xs={3}>
                    <Typography className={classes.typoMarginRight}>
                      {t("operations.crops-residues.quantity-label")}
                    </Typography>
                  </Grid>
                  <FieldWithTextAdornment
                    disabled={!permissions.canView() || !(isSold === 1)}
                    name='soldQuantity'
                    format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,1})?$/}
                    value={soldQuantity}
                    onChange={(e) => {
                      setSoldQuantity(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t("operations.crops-residues.quantity-adornment")}
                    width='130px'
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
  return null;
};

CropsResiduesSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  selectedCrop: state.crop.selectedCrop,
});

export default connect(mapStateToProps)(withStyles(style)(CropsResiduesSection));
