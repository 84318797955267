/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  errors: PropTypes.array.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  setPrice: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

function PriceConfirmation({
  open,
  min,
  max,
  errors,
  price,
  onCancel,
  onConfirm,
  unit,
  setPrice,
  currency,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='confirmation-dialog-title'>
        {t("purchases.price-confirmation-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={6}>
            <Typography>
              {t("purchases.price-confirmation-modal.sub-title-left")}
            </Typography>
            <FieldWithTextAdornment
              style={{ marginTop: "10px" }}
              name='price'
              format={/\d{1,4}([.,]\d{1,2})?/}
              label={t("purchases.section.price")}
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              errors={errors}
              adornment={`${currency}/${unit}`}
              width='130px'
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {t("purchases.price-confirmation-modal.sub-title-right")}
            </Typography>
            <FieldWithTextAdornment
              style={{ marginTop: "8px" }}
              disabled
              name='min'
              format={/\d{1,4}([.,]\d{1,2})?/}
              label={t("purchases.section.min")}
              value={min}
              onChange={() => {}}
              adornment={`€/${unit}`}
              width='130px'
            />
            <FieldWithTextAdornment
              style={{ marginTop: "8px" }}
              disabled
              name='max'
              format={/\d{1,4}([.,]\d{1,2})?/}
              label={t("purchases.section.max")}
              value={max}
              onChange={() => {}}
              adornment={`€/${unit}`}
              width='130px'
            />
          </Grid>
        </Grid>
        <Typography style={{ marginTop: "16px" }}>
          {t("purchases.price-confirmation-modal.description")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("purchases.price-confirmation-modal.cancel-button-label")}</Box>
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          {t("purchases.price-confirmation-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PriceConfirmation.propTypes = propsDefinition;

export default PriceConfirmation;
