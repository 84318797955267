/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { makeStyles } from "@material-ui/core/styles";

//Theme
import customTheme from "../../theme";

//UI Components
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
  index: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

function ListElement({ data, itemIndex, style, index, onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const variableListBackground = itemIndex % 2 ? classes.root : "";
  const newBadge =
    data.verified === 0 ? (
      <Chip
        size='small'
        label={t("home.list-element.new-farm-chip")}
        style={{
          backgroundColor: customTheme.palette.info.main,
          color: customTheme.palette.info.contrastText,
        }}
      />
    ) : (
      ""
    );

  return (
    <ListItem
      button
      className={variableListBackground}
      style={style}
      key={index}
      onClick={() => {
        onClick(data.id);
      }}
    >
      <Grid container alignItems='center'>
        <Grid className={classes.nameColumn} item xs={7}>
          <Typography variant='body1' noWrap={true}>
            {data.lastName} {data.firstName}
          </Typography>
          <Typography variant='body2'>
            {data.country} - {data.region}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {newBadge}
        </Grid>
        <Grid container item xs={3} alignItems='center' justify='flex-end'>
          <Grid item>
            <Typography variant='caption' color='secondary'>
              {t("home.list-element.dashboard-label")}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex'>
              <NavigateNextIcon fontSize='small' color='secondary' />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}

ListElement.propTypes = propsDefinition;

export default ListElement;
