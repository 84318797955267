/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntercom } from "react-use-intercom";

//Redux
import { connect } from "react-redux";
import { HOME_PAGE_LOADED, DASHBOARD_REDIRECT } from "../../constants/actionTypes";

//API
import agent from "../../agent";

//HOC
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import SearchBar from "./SearchBar";
import List from "./List";

//UI Components
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    width: "70%",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  search: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: { marginTop: theme.spacing(2) },
});

const propsDefinition = {
  //Provided props
  currentUser: PropTypes.string, // Provided by Redux
  onDashboardRedirect: PropTypes.func, // Provided by Redux
  farms: PropTypes.array, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
};

const Home = ({ onLoad, classes, currentUser, farms, onDashboardRedirect }) => {
  const { t } = useTranslation();
  const { boot: intercomBoot } = useIntercom();
  const [intercomInit, setIntercomInit] = useState();

  useEffect(() => {
    if (!intercomInit && currentUser) {
      intercomBoot({
        name: currentUser,
      });
      setIntercomInit(true);
    }
  }, [intercomInit, currentUser, intercomBoot]);

  useEffect(() => {
    onLoad(agent.Farms.get(200));
  }, [onLoad]);

  return (
    <Container className={classes.root}>
      <div className={classes.title}>
        <Typography variant='h4'>{t("home.welcome-title", { currentUser })}</Typography>
        <Typography variant='h6'>{t("home.search-question")}</Typography>
      </div>
      <SearchBar label={t("home.search-bar.label")} className={classes.search} />
      <List items={farms} onClick={onDashboardRedirect} />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          data-woi='btnNewFarm'
          onClick={() => onDashboardRedirect("new")}
        >
          {t("home.add-farm-button-label")}
        </Button>
      </div>
    </Container>
  );
};

Home.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farms: state.home.farms,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: HOME_PAGE_LOADED, payload: payload }),
  onDashboardRedirect: (farmId) =>
    dispatch({ type: DASHBOARD_REDIRECT, payload: { farmId } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
