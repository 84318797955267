/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_SERACH_RESULT = "HOME_SERACH_RESULT";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const DASHBOARD_REDIRECT = "DASHBOARD_REDIRECT";
export const SELECT_CROP = "SELECT_CROP";
export const CROP_REDIRECT = "CROP_REDIRECT";
export const SELECT_GLOBAL_PERIOD = "SELECT_GLOBAL_PERIOD";
export const SELECT_FARM_PERIOD = "SELECT_FARM_PERIOD";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";
export const DASHBOARD_UNLOADED = "DASHBOARD_UNLOADED";
export const PADDOCKS_LOADED = "PADDOCKS_LOADED";
export const PADDOCKS_UNLOADED = "PADDOCKS_UNLOADED";
export const SELECT_PADDOCK = "SELECT_PADDOCK";
export const PADDOCK_LOADED = "PADDOCK_LOADED";
export const PADDOCK_UNLOADED = "PADDOCK_UNLOADED";
export const PADDOCK_NAME = "PADDOCK_NAME";
export const PADDOCK_AREA = "PADDOCK_AREA";
export const PADDOCK_YIELD = "PADDOCK_YIELD";
export const PADDOCK_SELECT_OPERATION = "PADDOCK_SELECT_OPERATION";
export const EQUIPMENTS_REDIRECT = "EQUIPMENTS_REDIRECT";
export const PURCHASES_REDIRECT = "PURCHASES_REDIRECT";
export const LAND_REDIRECT = "LAND_REDIRECT";
export const UPDATE_FARM = "UPDATE_FARM";
export const OPERATIONS_LOADED = "OPERATIONS_LOADED";
export const OPERATION_TYPES_LOADED = "OPERATION_TYPES_LOADED";
export const SELECT_OPERATION = "SELECT_OPERATION";
export const PERIOD_LOADED = "SELECT_OPERATION";
export const GLOBAL_PERIOD_LOADED = "GLOBAL_PERIOD_LOADED";
export const FARM_MACHINERIES_LOADED = "FARM_MACHINERIES_LOADED";
export const UPDATE_FARM_MACHINERY = "UPDATE_FARM_MACHINERY";
export const ADD_FARM_MACHINERY = "ADD_FARM_MACHINERY";
export const REMOVE_FARM_MACHINERY = "REMOVE_FARM_MACHINERY";
export const ADD_OPERATION = "ADD_OPERATION";
export const UPDATE_OPERATION = "UPDATE_OPERATION";
export const DELETE_OPERATION = "DELETE_OPERATION";
export const FARM_INPUTS_PURCHASES_LOADED = "FARM_INPUTS_PURCHASES_LOADED";
export const SELECTED_INPUT_TYPE = "SELECTED_INPUT_TYPE";
export const UPDATE_FARM_INPUT_PURCHASE = "UPDATE_FARM_INPUT_PURCHASE";
export const ADD_FARM_INPUT_PURCHASE = "ADD_FARM_INPUT_PURCHASE";
export const REMOVE_FARM_INPUT_PURCHASE = "REMOVE_FARM_INPUT_PURCHASE";
export const LAND_LOADED = "LAND_LOADED";
export const SET_LAND_PAGE = "SET_LAND_PAGE";
export const SET_LAND_LOADING = "SET_LAND_LOADING";
export const CROPS_LOADED = "CROPS_LOADED";
export const PHS_LOADED = "PHS_LOADED";
export const DRAINAGES_LOADED = "DRAINAGES_LOADED";
export const MOISTURES_LOADED = "MOISTURES_LOADED";
export const FREQUENCIES_LOADED = "FREQUENCIES_LOADED";
export const SOIL_TYPES_LOADED = "SOIL_TYPES_LOADED";
export const UPDATE_SELECTED_PADDOCK = "UPDATE_SELECTED_PADDOCK";
export const ADD_BLOC = "ADD_BLOC";
export const UPDATE_BLOC = "UPDATE_BLOC";
export const DELETE_BLOC = "DELETE_BLOC";
export const ADD_BLOCPERI = "ADD_BLOCPERI";
export const UPDATE_BLOCPERI = "UPDATE_BLOCPERI";
export const DELETE_BLOCPERI = "DELETE_BLOCPERI";
export const ADD_PADDOCK = "ADD_PADDOCK";
export const UPDATE_PADDOCK = "UPDATE_PADDOCK";
export const DELETE_PADDOCK = "DELETE_PADDOCK";
export const UPDATE_FARM_INPUTS_PURCHASES = "UPDATE_FARM_INPUTS_PURCHASES";
export const UPDATE_OPERATIONS = "UPDATE_OPERATIONS";
export const UPDATE_SELECTED_PADDOCK_STATUS = "UPDATE_SELECTED_PADDOCK_STATUS";
export const CLEAR_FARM_INPUTS_PURCHASES = "CLEAR_FARM_INPUTS_PURCHASES";
export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const GLOBAL_LANGUAGE_LOADED = "GLOBAL_LANGUAGE_LOADED";
export const SELECT_BAR = "SELECT_BAR";
export const LAND_OCCUPATION_REDIRECT = "LAND_OCCUPATION_REDIRECT";
export const GHG_PROGRAM_REDIRECT = "GHG_PROGRAM_REDIRECT";
export const HEDGEROWS_REDIRECT = "HEDGEROWS_REDIRECT";
export const GLOBAL_TECHS_LOADED = "GLOBAL_TECHS_LOADED";
export const GLOBAL_COVERS_LOADED = "GLOBAL_COVERS_LOADED";
export const GLOBAL_PROGRAMS_LOADED = "GLOBAL_PROGRAMS_LOADED";
export const GLOBAL_SPECIES_LOADED = "GLOBAL_SPECIES_LOADED";
export const GLOBAL_OCCUPATIONS_LOADED = "GLOBAL_OCCUPATIONS_LOADED";
export const HEDGEROWS_LOADED = "HEDGEROWS_LOADED";
export const UPDATE_HEDGEROWS = "UPDATE_HEDGEROWS";
export const REMOVE_HEDGEROWS = "REMOVE_HEDGEROWS";
export const ADD_HEDGEROWS = "ADD_HEDGEROWS";
export const SET_OPERATIONS = "SET_OPERATIONS";
export const UPDATE_LOCK = "UPDATE_LOCK";
export const GHG_PROGRAMS_LOADED = "GHG_PROGRAMS_LOADED";
export const ADD_GHG_PROGRAM = "ADD_GHG_PROGRAM";
export const UPDATE_GHG_PROGRAM = "UPDATE_GHG_PROGRAM";
export const REMOVE_GHG_PROGRAM = "REMOVE_GHG_PROGRAM";
export const GLOBAL_DESTRUCTIONS_LOADED = "GLOBAL_DESTRUCTIONS_LOADED";
export const GLOBAL_IRRIGATIONS_LOADED = "GLOBAL_IRRIGATIONS_LOADED";
export const GLOBAL_SOURCES_LOADED = "GLOBAL_SOURCES_LOADED";
export const GLOBAL_POWERS_LOADED = "GLOBAL_POWERS_LOADED";
export const GLOBAL_CUSTOM_TYPES_LOADED = "GLOBAL_CUSTOM_TYPES_LOADED";
export const SET_DEFAULT_PERIOD = "SET_DEFAULT_PERIOD";
