/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const ConfirmValidatePaddock = ({ onCancel, onConfirm, open }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='md'
      aria-labelledby='validation-dialog-title'
      open={open}
    >
      <DialogTitle color='primary' id='validation-dialog-title'>
        {t("operations.section.menu.validate-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("operations.section.menu.validate-modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text' style={{ color: "#f44336" }}>
          {t("operations.section.menu.validate-modal.cancel-button-label")}
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          {t("operations.section.menu.validate-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmValidatePaddock.propTypes = propsDefinition;

export default ConfirmValidatePaddock;
