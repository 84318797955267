/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  OPERATIONS_LOADED,
  OPERATION_TYPES_LOADED,
  UPDATE_OPERATIONS,
  UPDATE_SELECTED_PADDOCK_STATUS,
} from "../../../../constants/actionTypes";

//API
import agent from "../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../../utils/Permissions";
import profiles from "../../../../constants/profiles";

//Custom Components
import OperationSectionMenu from "./OperationSectionMenu";
import ValidationErrorModal from "./ValidationErrorModal";
import LoadingSpinner from "../../../LoadingSpinner";
import ValidatePaddock from "./ValidatePaddock";
import OperationCard from "./OperationCard";

//Theme
import customTheme from "../../../../theme";

//UI Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Portal from "@material-ui/core/Portal";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "65vh",
    backgroundColor: theme.palette.grey[300],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  validatePaddock: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    marginBottom: theme.spacing(2),
  },
});

const propsDefinition = {
  //Provided props
  classes: PropTypes.object, // Provided by Matrial Ui Styles
  operations: PropTypes.array, // Provided by Redux
  selectedPaddock: PropTypes.object, // Provided by Redux
  farmId: PropTypes.string, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  setOperationTypes: PropTypes.func, // Provided by Redux
  updateOperations: PropTypes.func, // Provided by Redux
  updateSelectedPaddockStatus: PropTypes.func, // Provided by Redux
  operationLoaded: PropTypes.bool, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
};

const OperationSection = ({
  classes,
  operations,
  selectedPaddock,
  farmId,
  onLoad,
  setOperationTypes,
  selectedOperation,
  updateOperations,
  updateSelectedPaddockStatus,
  operationLoaded,
  lock,
}) => {
  const { t } = useTranslation();

  const [validationErrors, setValidationErrors] = useState([]);
  const [openErrors, setOpenErrors] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);

  const permissions = new Permissions(null, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  useEffect(() => {
    if (selectedOperation !== null) {
      window.onbeforeunload = function () {
        return t("operations.section.before-leave");
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [selectedOperation, t]);

  useEffect(() => {
    onLoad(
      agent.Farms.Paddocks.Operations.get(farmId, selectedPaddock.id),
      selectedPaddock.id
    );
  }, [selectedPaddock, farmId, onLoad]);

  useEffect(() => {
    const getOperationTypes = async () => {
      const { body, error } = await agent.OperationTypes.get();
      if (!error) {
        setOperationTypes(body.operationTypes);
      }
    };
    getOperationTypes();
  }, [setOperationTypes]);

  const handleValidatePaddock = () => {
    setValidationLoading(true);
    const validateOperations = agent.Farms.Paddocks.Operations.validate(
      farmId,
      selectedPaddock.id
    )
      .then((res) => {
        updateOperations(res.body.operations);
        updateSelectedPaddockStatus(3, selectedPaddock.id);
        setValidationLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setValidationErrors(err.response.body.errors);
          setOpenErrors(true);
        }
        setValidationLoading(false);
        throw err;
      });

    toast.promise(validateOperations, {
      loading: t("global.loading"),
      success: t("operations.section.notifications.paddock-validated"),
      error: t("operations.validation-modal.title"),
    });
  };

  if (operations && selectedPaddock && selectedPaddock.area === 0) {
    return (
      <Paper variant='outlined' className={classes.root}>
        <Typography variant='h5'>{t("operations.section.null-area-message")}</Typography>
      </Paper>
    );
  }

  if (!operationLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (operations && selectedPaddock && selectedPaddock.area > 0) {
    if (operations.length > 0) {
      const encoded = operations.filter((item) => item.status !== -1);
      if (encoded.length > 0) {
        let operItems = operations.map((item, index) => {
          return <OperationCard operation={item} key={index} index={index} />;
        });
        return (
          <React.Fragment>
            <Typography className={classes.description}>
              {t("operations.section.description")}
            </Typography>
            {operItems}
            {selectedPaddock.status !== 3 && permissions.canView() ? (
              <div className={classes.validatePaddock}>
                <ValidatePaddock
                  disabled={
                    operations.filter((item) => item.status < 2 && item.status > -1)
                      .length > 0 || selectedOperation
                      ? true
                      : false
                  }
                  loading={validationLoading}
                  label={
                    validationLoading
                      ? t("Validating...")
                      : t("operations.section.validate-button-label")
                  }
                  disabledMessage={
                    selectedOperation
                      ? t("operations.section.not-saved")
                      : t("operations.section.invalid")
                  }
                  handleValidatePaddock={handleValidatePaddock}
                />
              </div>
            ) : (
              ""
            )}
            <Portal>
              <ValidationErrorModal
                open={openErrors}
                errors={validationErrors}
                onClose={() => setOpenErrors(false)}
              />
            </Portal>
          </React.Fragment>
        );
      }
    }
    if (permissions.canEdit()) {
      return (
        <>
          <OperationSectionMenu
            operations={operations}
            validatePaddock={handleValidatePaddock}
          />
          <Portal>
            <ValidationErrorModal
              open={openErrors}
              errors={validationErrors}
              onClose={() => setOpenErrors(false)}
            />
          </Portal>
        </>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "20vh",
          }}
        >
          <Typography variant='body1' style={{ fontSize: "3rem" }}>
            {t("operations.section.empty")}
          </Typography>
        </div>
      );
    }
  }
  return null;
};

OperationSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  selectedPaddock: state.crop.selectedPaddock,
  selectedPeriod: state.farm.selectedPeriod,
  farmId: state.farm.farm.id,
  operations: state.crop.operations,
  selectedOperation: state.crop.selectedOperation,
  operationLoaded: state.crop.operationLoaded,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, paddockId) =>
    dispatch({ type: OPERATIONS_LOADED, payload, paddockId }),
  setOperationTypes: (payload) => dispatch({ type: OPERATION_TYPES_LOADED, payload }),
  updateOperations: (payload) => dispatch({ type: UPDATE_OPERATIONS, payload }),
  updateSelectedPaddockStatus: (status, id) =>
    dispatch({ type: UPDATE_SELECTED_PADDOCK_STATUS, status, id }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OperationSection));
