/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import EquipmentRow from "./EquipmentRow";

//UI Components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  farmMachineries: PropTypes.array.isRequired,
  operationName: PropTypes.string.isRequired,
  operationId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  lock: PropTypes.number.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
};

const EquipmentSection = ({
  farmMachineries,
  operationName,
  operationId,
  farmId,
  lock,
  selectedPeriod,
}) => {
  const { t } = useTranslation();
  const permissions = new Permissions(null, lock);
  if (farmMachineries) {
    return (
      <Paper style={{ padding: "16px", marginBottom: "8px" }} variant='outlined'>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='h5'>{operationName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body1'>
              {t("equipments.section.operation-types.given-name")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant='body1'>
              {t("equipments.section.operation-types.equipment-name")}
            </Typography>
          </Grid>
          {farmMachineries.map((item, index) => {
            return (
              <EquipmentRow
                key={index}
                farmMachinery={item}
                index={index}
                operationId={operationId}
                farmId={farmId}
                selectedPeriod={selectedPeriod}
              />
            );
          })}
          {permissions.canEdit() && (
            <EquipmentRow
              key={farmMachineries.length}
              operationId={operationId}
              farmId={farmId}
              selectedPeriod={selectedPeriod}
            />
          )}
        </Grid>
      </Paper>
    );
  }
  return null;
};

EquipmentSection.propTypes = propsDefinition;

export default EquipmentSection;
