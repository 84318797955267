/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onVisualize: PropTypes.func.isRequired,
};

const ConfirmPeriod = ({ open, onConfirm, onCancel, onVisualize }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='period-confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='period-confirmation-dialog-title'>
        {t("farm-period.period.modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("farm-period.period.modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("farm-period.period.modal.cancel-button-label")}</Box>
        </Button>
        <Button
          onClick={onVisualize}
          variant='text'
          color='secondary'
          style={{ color: "#527BC7" }}
        >
          {t("farm-period.period.modal.visualize-button-label")}
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          <Box>{t("farm-period.period.modal.confirm-button-label")}</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmPeriod.propTypes = propsDefinition;

export default ConfirmPeriod;
