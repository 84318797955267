/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { FARM_MACHINERIES_LOADED } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import EquipmentsSection from "./EquipmentsSection";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  farmMachineries: PropTypes.array, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
};

const EquipmentsContainer = ({
  farmMachineries,
  selectedPeriod,
  onLoad,
  farmId,
  lock,
}) => {
  const permissions = new Permissions(null, lock);

  useEffect(() => {
    onLoad(agent.Farms.FarmMachineries.getAll(farmId, selectedPeriod));
  }, [onLoad, farmId, selectedPeriod]);

  const containers =
    permissions.canEdit() && farmMachineries && farmMachineries.length > 0
      ? farmMachineries.map((item, index) => (
          <EquipmentsSection
            key={index}
            operationId={item.id}
            operationName={item.name}
            farmMachineries={item.farmMachineries}
            farmId={farmId}
            selectedPeriod={selectedPeriod}
            lock={lock}
          />
        ))
      : farmMachineries &&
        farmMachineries.length > 0 &&
        farmMachineries
          .filter((item) => item.farmMachineries.length > 0)
          .map((item, index) => (
            <EquipmentsSection
              key={index}
              operationId={item.id}
              operationName={item.name}
              farmMachineries={item.farmMachineries}
              farmId={farmId}
              selectedPeriod={selectedPeriod}
              lock={lock}
            />
          ));

  if (farmMachineries && farmMachineries.length > 0 && selectedPeriod) {
    return <React.Fragment>{containers}</React.Fragment>;
  }
  return null;
};

EquipmentsContainer.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmMachineries: state.farmMachineries.farmMachineries,
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FARM_MACHINERIES_LOADED, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsContainer);
