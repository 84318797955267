/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import Button from "@material-ui/core/Button";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingSpinner from "../../../LoadingSpinner";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  loading: {
    backgroundColor: theme.palette.info.dark,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
      cursor: "default",
    },
  },
  icon: {
    color: "#FFF",
    marginRight: "8px",
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  disabledMessage: PropTypes.string.isRequired,
  handleValidatePaddock: PropTypes.func.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const ValidatePaddock = ({
  handleValidatePaddock,
  classes,
  disabled,
  disabledMessage,
  label,
  loading,
}) => {
  return (
    <Tooltip title={disabled ? disabledMessage : ""}>
      <div>
        <Button
          disabled={disabled}
          className={loading ? classes.loading : classes.root}
          onClick={!loading ? handleValidatePaddock : () => {}}
          data-woi='validate-paddock-button'
        >
          {!loading ? (
            <VerifiedUserIcon className={classes.icon} />
          ) : (
            <LoadingSpinner className={classes.icon} color='#FFFFFF' size='18px' />
          )}
          {label}
        </Button>
      </div>
    </Tooltip>
  );
};

ValidatePaddock.propTypes = propsDefinition;

export default withStyles(styles)(ValidatePaddock);
