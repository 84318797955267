/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  FARM_INPUTS_PURCHASES_LOADED,
  UPDATE_FARM_INPUTS_PURCHASES,
  CLEAR_FARM_INPUTS_PURCHASES
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import LoadingSpinner from "../../LoadingSpinner";
import ConfirmInputs from "./ConfirmInputs";
import PurchaseRow from "./PurchaseRow";

//Theme
import customTheme from "../../../theme";

const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  rowContainer: {
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    paddingRight: "10px"
  },
  confirmInputs: {
    display: "flex",
    justifyContent: "center"
  }
});

const propsDefinition = {
  //Provided props
  inputEstimated: PropTypes.number,
  farmId: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  selectedPeriod: PropTypes.string, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  selectedInputType: PropTypes.string, // Provided by Redux
  farmInputsPurchases: PropTypes.array, // Provided by Redux
  updateFarmInputsPurchases: PropTypes.func, // Provided by Redux
  clearFarmInputsPurchases: PropTypes.func, // Provided by Redux
  loaded: PropTypes.bool, // Provided by Redux
  lock: PropTypes.number // Provided by Redux
};

const PurchasesSection = ({
  classes,
  inputEstimated,
  selectedPeriod,
  farmId,
  onLoad,
  selectedInputType,
  farmInputsPurchases,
  updateFarmInputsPurchases,
  clearFarmInputsPurchases,
  loaded,
  lock
}) => {
  const permissions = new Permissions(null, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR
  ]);

  const { t } = useTranslation();

  const purchasesTypes = [
    {
      type: "seed",
      name: t("purchases.header.types.seed")
    },
    {
      type: "fertiliser",
      name: t("purchases.header.types.fertiliser")
    },
    {
      type: "pesticide",
      name: t("purchases.header.types.pesticide")
    }
  ];

  const inputType =
    purchasesTypes &&
    purchasesTypes.filter(item => item.type === selectedInputType).length > 0
      ? purchasesTypes.filter(item => item.type === selectedInputType)[0].name
      : "";

  useEffect(() => {
    onLoad(
      agent.Farms.FarmInputsPurchases.get(
        farmId,
        selectedInputType,
        selectedPeriod
      )
    );
  }, [onLoad, farmId, selectedPeriod, selectedInputType]);

  const handleConfirmInputs = async () => {
    const validatePurchases = agent.Farms.FarmInputsPurchases.validate(
      farmId,
      selectedInputType,
      selectedPeriod
    ).then(res => {
      updateFarmInputsPurchases(res.body.farmInputsPurchases);
    });

    toast.promise(validatePurchases, {
      loading: t("global.loading"),
      success: t("purchases.section.notifications.inputs-validated"),
      error: t("purchases.section.notifications.error")
    });
  };

  useEffect(() => {
    if (selectedInputType !== "") {
      clearFarmInputsPurchases();
    }
  }, [selectedInputType, clearFarmInputsPurchases]);

  if (!loaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh"
        }}
      >
        <LoadingSpinner
          size="3rem"
          color={customTheme.palette.secondary.main}
        />
      </div>
    );
  }

  if (farmInputsPurchases && farmInputsPurchases.length > 0) {
    return (
      <div className={classes.root}>
        {farmInputsPurchases.map((item, index) => (
          <PurchaseRow
            key={index}
            data={item}
            index={index}
            inputEstimated={inputEstimated}
            selectedInputType={selectedInputType}
            farmId={farmId}
            selectedPeriod={selectedPeriod}
          />
        ))}
        {permissions.canEdit() && (
          <PurchaseRow
            inputEstimated={inputEstimated}
            selectedInputType={selectedInputType}
            farmId={farmId}
            selectedPeriod={selectedPeriod}
          />
        )}
        {permissions.canEdit() &&
        permissions.canView() &&
        farmInputsPurchases.length > 0 ? (
          <div className={classes.confirmInputs}>
            <ConfirmInputs
              disabled={
                farmInputsPurchases.filter(item => item.status < 2).length >
                  0 ||
                farmInputsPurchases.filter(item => item.status !== 3).length ===
                  0
              }
              label={t("purchases.container.validate-button-label", {
                inputType
              })}
              disabledMessage={
                farmInputsPurchases.filter(item => item.status < 2).length > 0
                  ? t("purchases.container.validation.invalid", { inputType })
                  : farmInputsPurchases.filter(item => item.status !== 3)
                      .length === 0
                  ? t("purchases.container.validation.valid")
                  : ""
              }
              handleConfirmInputs={handleConfirmInputs}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  return (
    <PurchaseRow
      inputEstimated={inputEstimated}
      selectedInputType={selectedInputType}
      farmId={farmId}
      selectedPeriod={selectedPeriod}
    />
  );
};

const mapStateToProps = state => ({
  farmId: state.farm.farm.id,
  farmInputsPurchases: state.farmInputs.farmInputsPurchases,
  selectedInputType: state.farmInputs.selectedInputType,
  loaded: state.farmInputs.loaded,
  lock: state.farm.lock.status,
  selectedPeriod: state.farm.selectedPeriod
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({
      type: FARM_INPUTS_PURCHASES_LOADED,
      payload
    }),
  updateFarmInputsPurchases: payload =>
    dispatch({
      type: UPDATE_FARM_INPUTS_PURCHASES,
      payload
    }),
  clearFarmInputsPurchases: () =>
    dispatch({
      type: CLEAR_FARM_INPUTS_PURCHASES
    })
});

PurchasesSection.propTypes = propsDefinition;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PurchasesSection));
