/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";

//Custom Components
import Header from "./Header";
import HedgerowsSection from "./HedgerowsSection";


class Hedgerows extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <HedgerowsSection />
      </div>
    );
  }
}

export default Hedgerows;
