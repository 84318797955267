/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.error.main,
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  children: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const CustomDialogTitle = ({ children, classes, onClose }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

CustomDialogTitle.propTypes = propsDefinition;

export default withStyles(styles)(CustomDialogTitle);
