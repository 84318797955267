/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  UPDATE_FARM_MACHINERY,
  ADD_FARM_MACHINERY,
  REMOVE_FARM_MACHINERY,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notification
import toast from "react-hot-toast";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import DeleteConfirmation from "../../DeleteConfirmation";
import SearchMachineries from "./SearchMachineries";

//UI Components
import Button from "@material-ui/core/Button";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Portal from "@material-ui/core/Portal";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  farmMachinery: PropTypes.object,
  operationId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  index: PropTypes.number,
  selectedPeriod: PropTypes.string.isRequired,

  //Provided props
  updateFarmMachinery: PropTypes.func, // Provided by Redux
  addFarmMachinery: PropTypes.func, // Provided by Redux
  removeFarmMachinery: PropTypes.func, // Provided by Redux
};

const EquipmentRow = ({
  farmMachinery = {},
  operationId,
  farmId,
  index,
  selectedPeriod,
  updateFarmMachinery,
  addFarmMachinery,
  removeFarmMachinery,
}) => {
  const [id, setId] = useState(null);
  const [givenName, setGivenName] = useState("");
  const [machinery, setMachinery] = useState({});
  const [thirdParty, setThirdParty] = useState(0);
  const [lock, setLock] = useState(0);
  const [body, setBody] = useState({});
  const [loading, setLoading] = useState(false);
  const [notDeletable, setNotDeletable] = useState(false);
  const [isUsed, setIsUsed] = useState(false);
  const [errorsG, setErrors] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { t } = useTranslation();

  const permissions = new Permissions(null, lock);

  useEffect(() => {
    if (farmMachinery.id) {
      setId(farmMachinery.id || null);
      setGivenName(farmMachinery.givenName || "");
      setMachinery(farmMachinery.machinery || {});
      setThirdParty(farmMachinery.thirdParty || 0);
      setNotDeletable(farmMachinery.flagDelete ? true : false);
      setIsUsed(farmMachinery.isUsed);
      setLock(farmMachinery.lock);
    }
  }, [farmMachinery]);

  const update = useCallback(
    (item) => {
      const updateMachinery = agent.Farms.FarmMachineries.update(farmId, id, item)
        .then((res) => {
          updateFarmMachinery(res.body.farmMachinery, operationId, index);
        })
        .catch(() => {
          setId(farmMachinery.id || null);
          setGivenName(farmMachinery.givenName || "");
          setMachinery(farmMachinery.machinery || {});
          setThirdParty(farmMachinery.thirdParty || 0);
        });

      toast.promise(updateMachinery, {
        loading: t("global.loading"),
        success: t("equipments.section.notifications.equipment-saved"),
        error: t("equipments.section.notifications.error"),
      });

      setBody({});
    },
    [farmId, id, farmMachinery, operationId, updateFarmMachinery, index, t]
  );

  useEffect(() => {
    if (body.farmMachinery) {
      const timer = setTimeout(() => update(body), 1000);
      return () => clearTimeout(timer);
    }
  }, [body, update]);

  const handleChange = (field, value) => {
    let item = {};
    let errors = [];
    switch (field) {
      case "givenName":
        setGivenName(value);
        if (value !== "") {
          item.givenName = value;
        } else {
          errors.push({
            field: field,
            message: t("equipments.section.errors.name-required"),
          });
        }
        break;
      case "machinery":
        setMachinery(value);
        if (value && value.id !== "") {
          item.machinery = value;
        } else {
          errors.push({
            field: field,
            message: t("equipments.section.errors.equipment-required"),
          });
        }
        break;
      case "thirdParty":
        setThirdParty(value);
        item.thirdParty = value;
        break;
      default:
        break;
    }
    if (
      errors.length === 0 &&
      id &&
      errorsG.filter((item) => item.field !== field).length === 0
    ) {
      setErrors([]);
      setBody({
        ...body,
        farmMachinery: {
          ...body.farmMachinery,
          ...item,
        },
      });
    } else {
      setErrors([...errorsG.filter((item) => item.field !== field), ...errors]);
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    const deleteMachinery = agent.Farms.FarmMachineries.delete(farmId, id)
      .then((res) => {
        removeFarmMachinery(res.body.farmMachinery.id, operationId);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    toast.promise(deleteMachinery, {
      loading: t("global.loading"),
      success: t("equipments.section.notifications.equipment-deleted"),
      error: t("equipments.section.notifications.error"),
    });
  };

  const handleCreate = async () => {
    let errors = [];

    if (givenName === "") {
      errors.push({
        field: "givenName",
        message: t("equipments.section.errors.name-required"),
      });
    }
    if (!machinery.id) {
      errors.push({
        field: "machinery",
        message: t("equipments.section.errors.equipment-required"),
      });
    }

    if (errors.length === 0) {
      setLoading(true);

      const createMachinery = agent.Farms.FarmMachineries.create(farmId, {
        farmMachinery: {
          givenName,
          machinery,
          thirdParty,
          period: { id: selectedPeriod },
        },
      })
        .then((res) => {
          setErrors([]);
          addFarmMachinery(res.body.farmMachinery, operationId);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      toast.promise(createMachinery, {
        loading: t("global.loading"),
        success: t("equipments.section.notifications.equipment-added"),
        error: t("equipments.section.notifications.error"),
      });
    } else {
      setErrors(errors);
    }
  };

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  if (farmMachinery) {
    return (
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={4}>
          <TextField
            disabled={!permissions.canEdit()}
            fullWidth={true}
            name='givenName'
            value={givenName}
            error={errorsG.filter((item) => item.field === "givenName").length > 0}
            helperText={
              errorsG.filter((item) => item.field === "givenName").length > 0
                ? errorsG.filter((item) => item.field === "givenName")[0].message
                : ""
            }
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            arrow
            title={isUsed ? t("equipments.section.validated-in-operation") : ""}
          >
            <div>
              <SearchMachineries
                disabled={!permissions.canEdit() || Boolean(isUsed)}
                operationId={operationId}
                farmId={farmId}
                periodId={selectedPeriod}
                fullWidth={true}
                name='machinery'
                errors={errorsG}
                onChange={(e, value) => handleChange("machinery", value)}
                value={machinery}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Paper variant='outlined'>
            <Checkbox
              disabled={!permissions.canEdit()}
              size='small'
              name='thirdParty'
              checked={Boolean(thirdParty)}
              onChange={(e, value) => handleChange(e.currentTarget.name, value ? 1 : 0)}
            />
            {t("equipments.section.third-party-label")}
          </Paper>
        </Grid>
        {!id && permissions.canEdit() ? (
          <Grid item xs={2}>
            <Button disabled={loading} fullWidth={true} onClick={handleCreate}>
              {t("equipments.section.add-button-label")}
            </Button>
          </Grid>
        ) : (
          permissions.canEdit() && (
            <Tooltip
              title={
                (notDeletable ? true : false) ? (
                  <Typography>{t("equipments.section.not-deletable-message")}</Typography>
                ) : (
                  ""
                )
              }
            >
              <div>
                <IconButton
                  disabled={notDeletable ? true : false}
                  onClick={handleDeleteConfirm}
                >
                  <DeleteForever
                    color={(notDeletable ? true : false) ? "disabled" : "error"}
                  />
                </IconButton>
              </div>
            </Tooltip>
          )
        )}
        <Portal>
          <DeleteConfirmation
            open={deleteOpen}
            onCancel={() => {
              setDeleteOpen(false);
            }}
            onConfirm={() => {
              setDeleteOpen(false);
              handleDelete();
            }}
          />
        </Portal>
      </Grid>
    );
  }
  return null;
};

EquipmentRow.propTypes = propsDefinition;

const mapDispatchToProps = (dispatch) => ({
  updateFarmMachinery: (payload, operationId, index) =>
    dispatch({
      type: UPDATE_FARM_MACHINERY,
      payload,
      operationId,
      index,
    }),
  addFarmMachinery: (payload, operationId) =>
    dispatch({ type: ADD_FARM_MACHINERY, payload, operationId }),
  removeFarmMachinery: (payload, operationId) =>
    dispatch({ type: REMOVE_FARM_MACHINERY, payload, operationId }),
});

export default connect(null, mapDispatchToProps)(EquipmentRow);
