/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  PHS_LOADED,
  MOISTURES_LOADED,
  DRAINAGES_LOADED,
  FREQUENCIES_LOADED,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import BlocperiRow from "./BlocperiRow";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
});

const propsDefinition = {
  //Provided props
  blocsperi: PropTypes.array, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  phsLoad: PropTypes.func, // Provided by Redux
  moisturesLoad: PropTypes.func, // Provided by Redux
  drainagesLoad: PropTypes.func, // Provided by Redux
  frequenciesLoad: PropTypes.func, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
};

const BlocsperiSection = ({
  blocsperi,
  classes,
  phsLoad,
  moisturesLoad,
  drainagesLoad,
  frequenciesLoad,
  lock,
}) => {
  const permissions = new Permissions(null, lock);

  useEffect(() => {
    const loadPhs = async () => {
      const { body, error } = await agent.Phs.get();
      if (!error) {
        phsLoad(body.ph);
      }
    };
    loadPhs();

    const loadMoistures = async () => {
      const { body, error } = await agent.Moistures.get();
      if (!error) {
        moisturesLoad(body.moistures);
      }
    };
    loadMoistures();

    const loadDrainages = async () => {
      const { body, error } = await agent.Drainages.get();
      if (!error) {
        drainagesLoad(body.drainages);
      }
    };
    loadDrainages();

    const loadFrequencies = async () => {
      const { body, error } = await agent.Frequencies.get();
      if (!error) {
        frequenciesLoad(body.frequencies);
      }
    };
    loadFrequencies();
  }, [phsLoad, moisturesLoad, drainagesLoad, frequenciesLoad]);

  const blocperiList =
    blocsperi.length > 0
      ? blocsperi.map((blocperi, index) => (
          <BlocperiRow key={index} index={index} blocperi={blocperi} />
        ))
      : "";

  return (
    <div className={classes.root}>
      <div>
        {blocperiList}
        {permissions.canEdit() && <BlocperiRow />}
      </div>
    </div>
  );
};

BlocsperiSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  blocsperi: state.land.blocsperi,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  phsLoad: (payload) => dispatch({ type: PHS_LOADED, payload }),
  moisturesLoad: (payload) => dispatch({ type: MOISTURES_LOADED, payload }),
  drainagesLoad: (payload) => dispatch({ type: DRAINAGES_LOADED, payload }),
  frequenciesLoad: (payload) => dispatch({ type: FREQUENCIES_LOADED, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlocsperiSection));
