/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { UPDATE_FARM, DASHBOARD_REDIRECT } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation, Translation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//Notification
import toast from "react-hot-toast";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import SearchCountry from "./SearchCountry";
import SearchRegion from "./SearchRegion";
import SearchAgronomist from "./SearchAgronomist";

//Theme
import customTheme from "../../../theme";

//UI Components
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const InitialFormState = {
  id: undefined,
  farmName: "",
  firstName: "",
  lastName: "",
  tel: "",
  mobile: "",
  email: "",
  address: "",
  postalCode: "",
  city: "",
  country: { id: "" },
  region: { id: "" },
  agronomist: { id: "" },
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "postalCode",
    "city",
    "country",
    "region",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = (
        <Translation>{(t) => t("dashboard.form.fields.errors.required")}</Translation>
      );
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = (
      <Translation>{(t) => t("dashboard.form.fields.errors.invalid-email")}</Translation>
    );
  }
  return errors;
};

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const renderSearchCountry = ({ input, label, meta: { touched, error }, ...custom }) => (
  <React.Fragment>
    <SearchCountry
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      onChange={(event, data) => input.onChange(data)}
      {...custom}
      label={label}
    />
    {renderFormHelper({ touched, error })}
  </React.Fragment>
);

const renderSearchRegion = ({ input, label, meta: { touched, error }, ...custom }) => (
  <React.Fragment>
    <SearchRegion
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      onChange={(event, data) => input.onChange(data)}
      {...custom}
      label={label}
    />
    {renderFormHelper({ touched, error })}
  </React.Fragment>
);

const renderSearchAgronomist = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <React.Fragment>
    <SearchAgronomist
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      onChange={(event, data) => input.onChange(data)}
      {...custom}
      label={label}
    />
    {renderFormHelper({ touched, error })}
  </React.Fragment>
);

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText error>{touched && error}</FormHelperText>;
  }
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "#faf5ea",
  },
  topBar: {
    height: "30px",
    marginBottom: theme.spacing(2),
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

const propsDefinition = {
  //Provided props
  farm: PropTypes.object, // Provided by Redux
  updateFarm: PropTypes.func, // Provided by Redux
  onDashboardRedirect: PropTypes.func, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  initialValues: PropTypes.object, // Provided by Redux-Form
  submitting: PropTypes.bool, // Provided by Redux-Form
  handleSubmit: PropTypes.func, // Provided by Redux-Form
  match: PropTypes.object, // Provided by Router
};

const ProfileForm = ({
  handleSubmit,
  submitting,
  classes,
  match,
  onDashboardRedirect,
  updateFarm,
  initialValues,
  farm,
}) => {
  const [edit, setEdit] = useState(false);
  const [verify, setVerify] = useState(false);
  const { t } = useTranslation();

  const permissions = new Permissions(initialValues.verified ? 3 : 0, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  useEffect(() => {
    if (match.params.farmId === "new") {
      setEdit(true);
    }
  }, [match, setEdit]);

  const handleForm = (values) => {
    if (match.params.farmId === "new") {
      const createFarm = agent.Farms.create({
        farm: { ...values },
      }).then((res) => {
        onDashboardRedirect(res.body.farm.id);
      });

      toast.promise(createFarm, {
        loading: t("global.loading"),
        success: t("dashboard.form.notifications.farm-added"),
        error: t("dashboard.form.notifications.error"),
      });
    } else {
      const difference = Object.keys(values).filter(
        (k) => !isEqual(values[k], initialValues[k])
      );
      const request = { farm: {} };
      difference.forEach((item) => {
        request.farm[item] = values[item];
      });
      if (verify) {
        request.farm.verified = 3;
      }
      if (difference.length > 0 || verify) {
        const modifyFarm = agent.Farms.update(initialValues.id, request).then((res) => {
          updateFarm(res.body);
        });

        toast.promise(modifyFarm, {
          loading: t("global.loading"),
          success: t("dashboard.form.notifications.farm-saved"),
          error: t("dashboard.form.notifications.error"),
        });
      }
      setEdit(false);
      setVerify(false);
    }
  };

  const normalizePhone = (value, prev) => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^0-9+]/g, "");

    return onlyNums;
  };

  return (
    <Paper variant='outlined' className={classes.root}>
      <form
        noValidate
        id='profileForm'
        autoComplete='chrome-off'
        onSubmit={handleSubmit(handleForm)}
      >
        {!edit && !verify && permissions.canView() && (
          <Grid item container justify='space-between' className={classes.topBar}>
            <Grid item>
              {initialValues.verified === 0 && (
                <Chip
                  size='small'
                  label={t("dashboard.form.new-farm-chip")}
                  style={{
                    backgroundColor: customTheme.palette.info.main,
                    color: customTheme.palette.info.contrastText,
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {permissions.canView() ? (
                <Button size='small' variant='text' onClick={() => setEdit(true)}>
                  {t("dashboard.form.modify-button-label")}
                </Button>
              ) : null}
              {permissions.canVerify() && (
                <Button size='small' variant='text' onClick={() => setVerify(true)}>
                  {t("dashboard.form.verify-button-label")}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Field
              disabled={!edit && !verify}
              name='lastName'
              data-woi='lastName'
              component={renderTextField}
              label={t("dashboard.form.fields.last-name")}
              fullWidth={true}
              normalize={(value) => value.toUpperCase()}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item xs={5}>
            <Field
              disabled={!edit && !verify}
              name='firstName'
              data-woi='firstName'
              component={renderTextField}
              label={t("dashboard.form.fields.first-name")}
              fullWidth={true}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item container xs={12} alignItems='center'>
            <EmailIcon fontSize='large' style={{ marginRight: "8px" }} />
            <Grid item xs={9}>
              <Field
                disabled={!edit && !verify}
                fullWidth={true}
                name='email'
                data-woi='email'
                component={renderTextField}
                label={t("dashboard.form.fields.email")}
                autoComplete='chrome-off'
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems='center' display={"flex"}>
            <SmartphoneIcon fontSize='large' style={{ marginRight: "8px" }} />
            <Grid item xs={9}>
              <Field
                disabled={!edit && !verify}
                fullWidth={true}
                name='mobile'
                component={renderTextField}
                label={t("dashboard.form.fields.mobile")}
                normalize={normalizePhone}
                style={{ width: "180px" }}
                autoComplete='chrome-off'
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems='center' display={"flex"}>
            <PhoneIcon fontSize='large' style={{ marginRight: "8px" }} />
            <Grid item xs={9}>
              <Field
                disabled={!edit && !verify}
                fullWidth={true}
                name='tel'
                component={renderTextField}
                normalize={normalizePhone}
                label={t("dashboard.form.fields.fix")}
                style={{ width: "180px" }}
                autoComplete='chrome-off'
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={!edit && !verify}
              fullWidth={true}
              name='farmName'
              component={renderTextField}
              label={t("dashboard.form.fields.farm-name")}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={!edit && !verify}
              fullWidth={true}
              name='address'
              component={renderTextField}
              label={t("dashboard.form.fields.address")}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              disabled={!edit && !verify}
              fullWidth={true}
              name='postalCode'
              data-woi='postalCode'
              component={renderTextField}
              label={t("dashboard.form.fields.postal-code")}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item xs={8}>
            <Field
              disabled={!edit && !verify}
              fullWidth={true}
              name='city'
              data-woi='city'
              component={renderTextField}
              label={t("dashboard.form.fields.city")}
              autoComplete='chrome-off'
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              disabled={(!edit && !verify) || Boolean(farm && farm.countryLocked)}
              locked={Boolean(farm && farm.countryLocked && (verify || edit))}
              name='country'
              label={t("dashboard.form.fields.country")}
              component={renderSearchCountry}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              disabled={(!edit && !verify) || Boolean(farm && farm.countryLocked)}
              locked={Boolean(farm && farm.countryLocked && (verify || edit))}
              name='region'
              data-woi='region'
              label={t("dashboard.form.fields.region")}
              component={renderSearchRegion}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={(!edit && !verify) || Boolean(farm && farm.agronomistLocked)}
              locked={Boolean(farm && farm.agronomistLocked && (verify || edit))}
              name='agronomist'
              label={t("dashboard.form.fields.agronomist")}
              component={renderSearchAgronomist}
              autoComplete='chrome-off'
            />
          </Grid>
          {edit && (
            <Grid item xs={12}>
              <Button disabled={submitting} fullWidth={true} type='submit'>
                {t("dashboard.form.confirm-button-label")}
              </Button>
            </Grid>
          )}
          {permissions.canView() && verify && (
            <Grid item xs={12}>
              <Button disabled={submitting} fullWidth={true} type='submit'>
                {t("dashboard.form.verify-button-label")}
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};

ProfileForm.propTypes = propsDefinition;

const mapStateToProps = (state) => {
  return {
    initialValues: state.farm.farm ? state.farm.farm : InitialFormState,
    farm: state.farm.farm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFarm: (payload) => dispatch({ type: UPDATE_FARM, payload }),
  onDashboardRedirect: (farmId) =>
    dispatch({ type: DASHBOARD_REDIRECT, payload: { farmId } }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "ProfileForm",
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
  })(withStyles(styles)(ProfileForm))
);
