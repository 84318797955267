/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const SearchGlobalInputsBar = ({ className, query, onChange, handleClear, label }) => {
  return (
    <TextField
      className={className}
      label={label}
      fullWidth={true}
      value={query}
      onChange={onChange}
      data-woi='search-input-bar'
      InputProps={{
        endAdornment:
          query !== "" ? (
            <InputAdornment position='end'>
              <IconButton
                style={{
                  marginRight: "-6px",
                  padding: "4px",
                }}
                className='clearButton'
                aria-label='clear date'
                onClick={handleClear}
                edge='end'
              >
                <HighlightOffIcon style={{ fontSize: "18px", color: "red" }} />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment>
              <SearchIcon />
            </InputAdornment>
          ),
      }}
    />
  );
};
SearchGlobalInputsBar.propTypes = propsDefinition;

export default SearchGlobalInputsBar;
