/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  loading: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AddFarmInputPurchase = ({ onCancel, onAdd, open, input, loading }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='md'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle color='primary' id='confirmation-dialog-title'>
        {t("operations.purchases-database-modal.add-modal.title")}
      </DialogTitle>
      <DialogContent
        style={{ display: "block", justifyContent: "center", width: "500px" }}
        dividers
      >
        <Typography style={{ marginTop: "8px", marginLeft: "8px" }}>
          {t("operations.purchases-database-modal.add-modal.description", { input })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onCancel}
          variant='text'
          style={{ color: "#f44336" }}
        >
          {t("operations.purchases-database-modal.add-modal.cancel-button-label")}
        </Button>
        <Button
          disabled={loading}
          onClick={onAdd}
          color='secondary'
          data-woi='add-input-purchase'
        >
          {t("operations.purchases-database-modal.add-modal.add-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddFarmInputPurchase.propTypes = propsDefinition;

export default AddFarmInputPurchase;
