import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { makeStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../../../utils/Permissions";
import profiles from "../../../../../constants/profiles";

//Custom Components
import SelectDate from "../../../../SelectDate";
import SelectDestruction from "./SelectDestruction";
import SelectCover from "./SelectCover";
import FieldWithTextAdornment from "../../../../FieldWithTextAdornment";

//Theme
import customTheme from "../../../../../theme";

//UI Components
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles((theme) => ({
  mainOutlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  blocTitle: {
    marginBottom: theme.spacing(1),
  },
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  exportedTitle: {
    marginRight: theme.spacing(2),
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  cropsTechsId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  farmId: PropTypes.string,
  lock: PropTypes.number,
};

function CoverCropsSection({ cropsTechsId, data, farmId, lock }) {
  const classes = useStyle();
  const { t } = useTranslation();

  const [status, setStatus] = useState(0);
  const [changedCovers, setChangedCovers] = useState(null);
  const [cover, setCover] = useState(null);
  const [passedYears, setPassedYears] = useState(0);
  const [amount, setAmount] = useState(100);
  const [maxYears, setMaxYears] = useState(0);
  const [biomass, setBiomass] = useState(0);
  const [biodiversity, setBiodiversity] = useState(0);
  const [leguminous, setLeguminous] = useState(0);
  const [sowingDate, setSowingDate] = useState(null);
  const [destructionDate, setDestructionDate] = useState(null);
  const [destruction, setDestruction] = useState(null);
  const [exported, setExported] = useState(null);

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const [sowingDateIsOpen, setSowingDateIsOpen] = useState(false);
  const [destructionDateIsOpen, setDestructionDateIsOpen] = useState(false);

  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
      setChangedCovers(data.changedCovers);
      setCover(data.cover);
      setPassedYears(data.passedYears);
      setAmount(data.amount);
      setMaxYears(data.maxYears);
      setBiomass(data.biomass);
      setBiodiversity(data.biodiversity);
      setLeguminous(data.leguminous);
      setSowingDate(data.sowingDate);
      setDestructionDate(data.destructionDate);
      setDestruction(data.destruction);
      setExported(data.residuesExported);
    }
  }, [data]);

  const checkValidateLandCover = useCallback(() => {
    if (!cover || (cover && !cover.id)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "cover",
          message: t("operations.crops-techs.land-cover.errors.required-cover"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "cover")]);
    }
    if (!(passedYears > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "passedYears",
          message: t("operations.crops-techs.land-cover.errors.required-years"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "passedYears")]);
    }
    if (!(amount > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "amount",
          message: t("operations.crops-techs.land-cover.errors.required-amount"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "amount")]);
    }
    if (cover && !cover.ended) {
      if (!(biomass > 0)) {
        setErrors((errors) => [
          ...errors,
          {
            field: "biomass",
            message: t("operations.crops-techs.land-cover.errors.required-biomass"),
          },
        ]);
      } else {
        setErrors((errors) => [...errors.filter((item) => item.field !== "biomass")]);
      }
      if (!(biodiversity > 0)) {
        setErrors((errors) => [
          ...errors,
          {
            field: "biodiversity",
            message: t("operations.crops-techs.land-cover.errors.required-biodiversity"),
          },
        ]);
      } else {
        setErrors((errors) => [
          ...errors.filter((item) => item.field !== "biodiversity"),
        ]);
      }
      if (sowingDate === null) {
        setErrors((errors) => [
          ...errors,
          {
            field: "sowingDate",
            message: t("operations.crops-techs.land-cover.errors.required-sowing-date"),
          },
        ]);
      } else {
        setErrors((errors) => [...errors.filter((item) => item.field !== "sowingDate")]);
      }
      if (exported === null) {
        setErrors((errors) => [
          ...errors,
          {
            field: "exported",
            message: t("operations.crops-techs.land-cover.errors.required-exported"),
          },
        ]);
      } else {
        setErrors((errors) => [...errors.filter((item) => item.field !== "exported")]);
      }
    } else {
      setErrors((errors) => [
        ...errors.filter(
          (item) =>
            item.field !== "exported" ||
            item.field !== "sowingDate" ||
            item.field !== "biodiversity" ||
            item.field !== "biomass"
        ),
      ]);
    }
    if (!(changedCovers === 1)) {
      setErrors([]);
    }
  }, [
    t,
    cover,
    passedYears,
    amount,
    changedCovers,
    biomass,
    biodiversity,
    sowingDate,
    exported,
  ]);

  useEffect(() => {
    if (changed) {
      checkValidateLandCover();
    }
  }, [checkValidateLandCover, changed]);

  const handleUpdateLandCover = useCallback(() => {
    const validate = (
      cover,
      passedYears,
      amount,
      changedCovers,
      biomass,
      biodiversity,
      sowingDate,
      exported
    ) => {
      let error = false;
      if (changedCovers === 1) {
        if (cover && !cover.id) {
          error = true;
        }
        if (!(passedYears > 0)) {
          error = true;
        }
        if (!(amount > 0)) {
          error = true;
        }
        if (cover && !cover.ended) {
          if (!(biomass > 0)) {
            error = true;
          }
          if (!(biodiversity > 0)) {
            error = true;
          }
          if (sowingDate === null) {
            error = true;
          }
          if (exported === null) {
            error = true;
          }
        }
      } else if (changedCovers === null) {
        error = true;
      }
      return error;
    };

    if (
      !validate(
        cover,
        passedYears,
        amount,
        changedCovers,
        biomass,
        biodiversity,
        sowingDate,
        exported
      )
    ) {
      const reqBody = {
        cropsTechs: {
          landCover: {
            status,
            changedCovers,
            cover,
            passedYears,
            amount,
            biomass,
            biodiversity,
            leguminous,
            sowingDate,
            destructionDate,
            destruction,
            residuesExported: exported,
          },
        },
      };

      const updateCropTechs = agent.Farms.CropsTechs.update(
        farmId,
        cropsTechsId,
        reqBody
      ).then((res) => {
        const landCover = res.body.cropsTechs.landCover;
        setStatus(landCover.status);
        setChangedCovers(landCover.changedCovers);
        setCover(landCover.cover);
        setPassedYears(landCover.passedYears);
        setAmount(landCover.amount);
        setMaxYears(landCover.maxYears);
        setBiomass(landCover.biomass);
        setBiodiversity(landCover.biodiversity);
        setLeguminous(landCover.leguminous);
        setSowingDate(landCover.sowingDate);
        setDestructionDate(landCover.destructionDate);
        setDestruction(landCover.destruction);
        setExported(landCover.residuesExported);
        setChanged(false);
      });

      toast.promise(updateCropTechs, {
        loading: t("global.loading"),
        success: t("operations.crops-techs.land-cover.notifications.land-cover-saved"),
        error: t("operations.crops-techs.land-cover.notifications.error"),
      });
    }
  }, [
    t,
    farmId,
    cropsTechsId,
    cover,
    passedYears,
    amount,
    changedCovers,
    status,
    biomass,
    biodiversity,
    leguminous,
    sowingDate,
    destructionDate,
    destruction,
    exported,
  ]);

  useEffect(() => {
    if (changed && cropsTechsId) {
      const timer = setTimeout(() => handleUpdateLandCover(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdateLandCover, cropsTechsId, changed]);

  return (
    <Paper className={classes.outlinedContainer} variant='outlined'>
      <Grid container spacing={2}>
        <Grid container item justify='space-between'>
          <Grid container item alignItems='center' xs={9}>
            <Typography className={classes.typoMarginRight} variant='h4'>
              {t("operations.crops-techs.land-cover.title")}
            </Typography>
            {status === 3 ? (
              <Tooltip title={t("operations.crops-techs.land-cover.valid")}>
                <VerifiedUserIcon className={classes.valid} />
              </Tooltip>
            ) : (
              permissions.canView() && (
                <Tooltip title={t("operations.crops-techs.land-cover.invalid")}>
                  <ErrorIcon className={classes.invalid} />
                </Tooltip>
              )
            )}
          </Grid>
          <Grid item>
            {permissions.canEdit() && permissions.canView() && status !== 3 && (
              <Button
                disabled={
                  changedCovers === 0
                    ? false
                    : changedCovers === null ||
                      (cover && !cover.id) ||
                      !(passedYears > 0) ||
                      !(amount > 0) ||
                      !(biomass > 0) ||
                      !(biodiversity > 0) ||
                      sowingDate === null ||
                      exported === null
                }
                style={{ height: "40px" }}
                className={classes.validateButton}
                onClick={() => {
                  setStatus(3);
                  setChanged(true);
                }}
                data-woi='validate-cover-crop-button'
              >
                <VerifiedUserIcon style={{ marginRight: "5px" }} />
                {t("operations.crops-techs.land-cover.validate-button-label")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Typography>
            {t("operations.crops-techs.land-cover.boolean-subtitle")}
          </Typography>
          <Grid item xs={12}>
            <FormControl component='fieldset' disabled={!permissions.canEdit()}>
              <RadioGroup
                size='small'
                row
                name='position'
                style={{ display: "flex", alignItems: "center" }}
                onChange={(e) => {
                  setChangedCovers(parseInt(e.target.value));
                  setChanged(true);
                }}
                value={changedCovers !== null ? changedCovers.toString() : null}
              >
                <FormControlLabel
                  value='0'
                  style={
                    changedCovers === null
                      ? { color: customTheme.palette.error.main }
                      : null
                  }
                  control={
                    <Radio
                      style={
                        changedCovers === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      size='small'
                      color='secondary'
                    />
                  }
                  label={
                    <Typography variant='subtitle2'>
                      {t("operations.crops-techs.land-cover.no")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value='1'
                  style={
                    changedCovers === null
                      ? { color: customTheme.palette.error.main }
                      : null
                  }
                  control={
                    <Radio
                      style={
                        changedCovers === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      size='small'
                      color='secondary'
                      data-woi='cover-crop-checked'
                    />
                  }
                  label={
                    <Typography variant='subtitle2'>
                      {t("operations.crops-techs.land-cover.yes")}
                    </Typography>
                  }
                />
              </RadioGroup>
              {changedCovers === null && (
                <Typography
                  variant='caption'
                  style={{ color: customTheme.palette.error.main }}
                >
                  {t("operations.crops-techs.land-cover.errors.required-choice")}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {changedCovers === 1 && (
          <>
            <div className={classes.underline} />
            <Grid item>
              <SelectCover
                disabled={!permissions.canEdit() || !(changedCovers === 1)}
                name='cover'
                onChange={(e) => {
                  const { ended } = e.currentTarget.dataset;
                  setCover({ id: e.target.value, ended: parseInt(ended) });
                  setChanged(true);
                }}
                errors={errors}
                value={cover && cover.id ? cover.id : ""}
                label={t("operations.crops-techs.land-cover.changed-select")}
                width='400px'
              />
            </Grid>
            <Grid container item alignItems='center' data-woi='cover-crop-years'>
              <Typography className={classes.typoMarginRight}>
                {t("operations.crops-techs.land-cover.over-time-label")}
              </Typography>
              <FieldWithTextAdornment
                disabled={!permissions.canEdit() || !(changedCovers === 1)}
                name='passedYears'
                format={/^[0-9][0-9]?[0-9]?$/}
                value={passedYears}
                onChange={(e) => {
                  setPassedYears(e.target.value <= maxYears ? e.target.value : maxYears);
                  setChanged(true);
                }}
                errors={errors}
                onFocus={(event) => event.target.select()}
                adornment={t("operations.crops-techs.land-cover.years")}
                width='130px'
              />
            </Grid>
            <Grid container item alignItems='center' data-woi='cover-crop-percent'>
              <Typography className={classes.typoMarginRight}>
                {t("operations.crops-techs.land-cover.before-%")}
              </Typography>
              <FieldWithTextAdornment
                disabled={!permissions.canEdit() || !(changedCovers === 1)}
                name='amount'
                format={/^[0-9][0-9]?$|^100$/}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setChanged(true);
                }}
                errors={errors}
                onFocus={(event) => event.target.select()}
                adornment={t("operations.crops-techs.land-cover.%-adornment")}
                width='100px'
              />
              <Typography className={classes.typoMarginLeft}>
                {t("operations.crops-techs.land-cover.%-area-label")}
              </Typography>
            </Grid>
            {cover && !cover.ended && (
              <>
                <Grid item data-woi='cover-crop-biomass'>
                  <FieldWithTextAdornment
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    name='biomass'
                    format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,1})?$/}
                    value={biomass}
                    onChange={(e) => {
                      setBiomass(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t("operations.crops-techs.land-cover.biomass-adornment")}
                    width='140px'
                    label={t("operations.crops-techs.land-cover.biomass")}
                  />
                </Grid>
                <Grid item data-woi='cover-crop-biodiversity'>
                  <FieldWithTextAdornment
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    name='biodiversity'
                    format={/^[0-9][0-9]?[0-9]?$/}
                    value={biodiversity}
                    onChange={(e) => {
                      setBiodiversity(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t(
                      "operations.crops-techs.land-cover.biodiversity-adornment"
                    )}
                    width='140px'
                    label={t("operations.crops-techs.land-cover.biodiversity")}
                  />
                </Grid>
                <Grid item data-woi='cover-crop-leguminous'>
                  <FieldWithTextAdornment
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    name='leguminous'
                    format={/^[0-9][0-9]?[0-9]?$/}
                    value={leguminous}
                    onChange={(e) => {
                      setLeguminous(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t(
                      "operations.crops-techs.land-cover.leguminous-adornment"
                    )}
                    width='140px'
                    label={t("operations.crops-techs.land-cover.leguminous")}
                  />
                </Grid>
                <Grid item data-woi='cover-crop-date-seed'>
                  <SelectDate
                    width='150px'
                    label={t("operations.crops-techs.land-cover.sowingDate")}
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    isOpen={sowingDateIsOpen}
                    setIsOpen={setSowingDateIsOpen}
                    value={sowingDate ? new Date(sowingDate) : null}
                    onChange={(date) => {
                      setSowingDate(date);
                      setChanged(true);
                    }}
                    maxDate={
                      destructionDate !== null
                        ? new Date(destructionDate)
                        : new Date("2100-01-01")
                    }
                    name='sowingDate'
                    errors={errors}
                  />
                </Grid>
                <Grid item data-woi='cover-crop-date-destruction'>
                  <SelectDate
                    width='150px'
                    label={t("operations.crops-techs.land-cover.destructionDate")}
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    isOpen={destructionDateIsOpen}
                    setIsOpen={setDestructionDateIsOpen}
                    value={destructionDate ? new Date(destructionDate) : null}
                    onChange={(date) => {
                      setDestructionDate(date);
                      setChanged(true);
                    }}
                    minDate={
                      sowingDate !== null ? new Date(sowingDate) : new Date("1900-01-01")
                    }
                    name='destructionDate'
                    errors={errors}
                  />
                </Grid>
                <Grid item>
                  <SelectDestruction
                    disabled={!permissions.canEdit() || !(changedCovers === 1)}
                    value={destruction && destruction.id ? destruction.id : ""}
                    name='destruction'
                    onChange={(e) => {
                      setDestruction({ id: e.target.value });
                      setChanged(true);
                    }}
                    errors={errors}
                    width='180px'
                    label={t("operations.crops-techs.land-cover.destruction")}
                  />
                </Grid>
                <Grid container item xs={12} alignItems='center'>
                  <Typography className={classes.exportedTitle}>
                    {t("operations.crops-techs.land-cover.exported-title")}
                  </Typography>
                  <FormControl component='fieldset' disabled={!permissions.canEdit()}>
                    <RadioGroup
                      size='small'
                      row
                      name='exported'
                      style={{ display: "flex", alignItems: "center" }}
                      onChange={(e) => {
                        setExported(parseInt(e.target.value));
                        setChanged(true);
                      }}
                      value={exported !== null ? exported.toString() : null}
                    >
                      <FormControlLabel
                        value='0'
                        style={
                          exported === null
                            ? { color: customTheme.palette.error.main }
                            : null
                        }
                        control={
                          <Radio
                            style={
                              exported === null
                                ? { color: customTheme.palette.error.main }
                                : null
                            }
                            size='small'
                            color='secondary'
                          />
                        }
                        label={
                          <Typography variant='subtitle2'>
                            {t("operations.crops-techs.land-cover.left-in-field")}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='1'
                        style={
                          exported === null
                            ? { color: customTheme.palette.error.main }
                            : null
                        }
                        control={
                          <Radio
                            style={
                              exported === null
                                ? { color: customTheme.palette.error.main }
                                : null
                            }
                            size='small'
                            color='secondary'
                          />
                        }
                        label={
                          <Typography variant='subtitle2'>
                            {t("operations.crops-techs.land-cover.exported")}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {changedCovers === null && (
                      <Typography
                        variant='caption'
                        style={{ color: customTheme.palette.error.main }}
                      >
                        {t("operations.crops-techs.land-cover.errors.required-choice")}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
}

CoverCropsSection.propTypes = propsDefinition;

export default CoverCropsSection;
