/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Custom Components
import Header from "./Header";
import PurchasesSection from "./PurchasesSection";

// API
import agent from "../../agent";

// Redux
import { connect } from "react-redux";

// TODO: Add UseEffect to get inputEstimated when components is loaded without dependencies

const propsDefinition = {
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string // Provided by Redux
};

const Purchases = ({ farmId, selectedPeriod }) => {
  const [inputEstimated, setInputEstimated] = useState(0);
  useEffect(() => {
    const getInputEstimated = async () => {
      const {
        body,
        errors
      } = await agent.Farms.FarmInputsPurchases.getInputEstimated(
        farmId,
        selectedPeriod
      );
      if (!errors) {
        setInputEstimated(parseInt(body.inputEstimation.inputEstimated));
      }
    };

    getInputEstimated();
  }, [farmId, selectedPeriod]);

  return (
    <React.Fragment>
      <Header
        inputEstimated={inputEstimated}
        setInputEstimated={setInputEstimated}
      />
      <PurchasesSection inputEstimated={inputEstimated} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod
});

// export default Purchases;

Purchases.propTypes = propsDefinition;

export default connect(mapStateToProps)(Purchases);
