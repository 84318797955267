/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { withStyles } from "@material-ui/core/styles";

//Theme
import customTheme from "../../theme";

//UI Components
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dataWoi: PropTypes.string.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const CardButton = ({ classes, title, desc, onClick, dataWoi }) => {
  return (
    <Card className={classes.root} variant='outlined'>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Grid
            className={classes.container}
            container
            spacing={1}
            justify='space-between'
            alignItems='center'
          >
            <Grid item data-woi={dataWoi}>
              <Typography variant='h5' color='secondary'>
                {title}
              </Typography>
              <Typography>{desc}</Typography>
            </Grid>
            <Grid item>
              <NavigateNextIcon
                style={{ fontSize: 50, color: customTheme.palette.secondary.main }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardButton.propTypes = propsDefinition;

export default withStyles(styles)(CardButton);
