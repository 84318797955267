/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
};

const SearchSpecies = ({ value, onChange, name, label, disabled, errors }) => {
  const { t } = useTranslation();
  const [species, setSpecies] = useState([
    { id: "loading", name: t("hedgerows.section.species-search") },
  ]);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const getSpecies = useCallback(async (query = "") => {
    const { body, error } = await agent.Species.search(query);
    if (!error) {
      setSpecies(body.trees);
    } else {
      setSpecies({ id: "error", name: "error" });
    }
  }, []);

  useEffect(() => {
    if (open) {
      let timer;
      if (query !== "") {
        timer = setTimeout(() => {
          getSpecies(query);
        }, 300);

        return () => clearTimeout(timer);
      } else {
        timer = setTimeout(() => {
          getSpecies();
        }, 100);

        return () => clearTimeout(timer);
      }
    }
  }, [query, open, getSpecies]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = (e, val) => {
    setOpen(false);
  };

  return (
    <Autocomplete
      style={{ width: "220px" }}
      closeIcon={
        <HighlightOffIcon style={{ fontSize: "18px", marginTop: "1px", color: "red" }} />
      }
      fullWidth={true}
      value={value}
      onChange={(e, data) => onChange(data)}
      onInputChange={(e) => setQuery(e && e.target ? e.target.value : "")}
      onOpen={onOpen}
      onClose={onClose}
      freeSolo
      size='small'
      name={name}
      options={species}
      disabled={disabled}
      getOptionDisabled={(option) => {
        return (
          (option.id === "loading" || option.id === "error" || option.usedByFarm === 1) &&
          (!value || option.id !== value.id)
        );
      }}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='normal'
          variant='outlined'
          error={errors.filter((item) => item.field === name).length > 0}
          helperText={
            errors.filter((item) => item.field === name).length > 0
              ? errors.filter((item) => item.field === name)[0].message
              : ""
          }
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
          value={value}
        />
      )}
    />
  );
};

SearchSpecies.propTypes = propsDefinition;

export default SearchSpecies;
