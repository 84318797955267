/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//Custom Components
import CropHeader from "./CropHeader";
import PaddockSection from "./PaddockSection";
import CropsResiduesSection from "./CropsResiduesSection";
import CropsTechsSection from "./CropsTechsSection";
import CropsIrrigationSection from "./CropsIrrigationSection";
import NavigationBar from "./NavigationBar";
import CropsOrganicsSection from "./CropsOrganicsSection";

const propsDefinition = {
  //Provided props
  match: PropTypes.object, // Provided by Router
  selectedBar: PropTypes.string, // Provided by Redux
};

const Crop = ({ match, selectedBar }) => {
  const content =
    selectedBar === "paddock" ? (
      <PaddockSection />
    ) : selectedBar === "cropsTechs" ? (
      <CropsTechsSection />
    ) : selectedBar === "cropsResidues" ? (
      <CropsResiduesSection />
    ) : selectedBar === "cropsIrrigation" ? (
      <CropsIrrigationSection />
    ) : selectedBar === "cropsOrganics" ? (
      <CropsOrganicsSection />
    ) : (
      ""
    );

  return (
    <React.Fragment>
      <CropHeader match={match} />
      <NavigationBar />
      {content}
    </React.Fragment>
  );
};

Crop.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  selectedBar: state.crop.selectedBar,
});

export default connect(mapStateToProps)(Crop);
