/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  ADD_BLOC,
  DELETE_BLOC,
  UPDATE_BLOC
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import DeleteConfirmation from "../../DeleteConfirmation";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//UI Components
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    width: "140px"
  }
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  bloc: PropTypes.object,
  index: PropTypes.number,

  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  addBloc: PropTypes.func, // Provided by Redux
  deleteBloc: PropTypes.func, // Provided by Redux
  updateBloc: PropTypes.func // Provided by Redux
};

const BlocRow = ({
  bloc = null,
  classes,
  addBloc,
  farmId,
  deleteBloc,
  index,
  updateBloc
}) => {
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [area, setArea] = useState(0);
  const [notDeletable, setNotDeletable] = useState(false);
  const [loading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [changed, setChanged] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const permissions = new Permissions(null, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
    profiles._ENC
  ]);

  let nameInput = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (bloc) {
      setId(bloc.id);
      setName(bloc.name);
      setArea(bloc.area);
      setNotDeletable(!bloc.flagDelete);
    }
  }, [bloc]);

  const checkValidate = useCallback(() => {
    if (name === "") {
      setErrors(errors => [
        ...errors,
        { field: "name", message: t("land.blocs.errors.name-required") }
      ]);
    } else {
      setErrors(errors => [...errors.filter(item => item.field !== "name")]);
    }
    if (!(area > 0)) {
      setErrors(errors => [
        ...errors,
        { field: "area", message: t("land.blocs.errors.area-required") }
      ]);
    } else {
      setErrors(errors => [...errors.filter(item => item.field !== "area")]);
    }
  }, [name, area, t]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed]);

  const handleCreate = () => {
    if (errors.length === 0) {
      const reqBody = {
        bloc: {
          name,
          area
        }
      };

      const createBloc = agent.Farms.Blocs.create(farmId, reqBody).then(res => {
        addBloc(res.body.bloc);
        setChanged(false);
        setId(null);
        setName("");
        setArea(0);
        nameInput.current.focus();
      });

      toast.promise(createBloc, {
        loading: t("global.loading"),
        success: t("land.blocs.notifications.bloc-added"),
        error: t("land.blocs.notifications.error")
      });
    }
  };

  const handleUpdate = useCallback(() => {
    const validate = (name, area) => {
      let error = false;
      if (name === "") {
        error = true;
      }
      if (!(area > 0)) {
        error = true;
      }
      return error;
    };

    if (!validate(name, area)) {
      const reqBody = {
        bloc: {
          name,
          area
        }
      };

      const changeBloc = agent.Farms.Blocs.update(farmId, id, reqBody).then(
        res => {
          updateBloc(res.body.bloc, index);
          setChanged(false);
        }
      );

      toast.promise(changeBloc, {
        loading: t("global.loading"),
        success: t("land.blocs.notifications.bloc-saved"),
        error: t("land.blocs.notifications.error")
      });
    }
  }, [name, area, t, farmId, id, index, updateBloc]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed, name, area]);

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  const handleDelete = () => {
    const removeBloc = agent.Farms.Blocs.delete(farmId, id).then(() => {
      deleteBloc(index);
    });

    toast.promise(removeBloc, {
      loading: t("global.loading"),
      success: t("land.blocs.notifications.bloc-deleted"),
      error: t("land.blocs.notifications.error")
    });
  };

  const actionType =
    permissions.canEdit() &&
    (!id ? (
      <Grid item>
        <Button
          disabled={loading}
          fullWidth={true}
          onClick={handleCreate}
          data-woi="add-bloc-button"
        >
          {t("land.blocs.add-button-label")}
        </Button>
      </Grid>
    ) : (
      <Tooltip
        title={
          (notDeletable ? (
            true
          ) : (
            false
          )) ? (
            <Typography>{t("land.blocs.not-deletable-message")}</Typography>
          ) : (
            ""
          )
        }
      >
        <div>
          <IconButton
            disabled={notDeletable ? true : false}
            onClick={handleDeleteConfirm}
            data-woi="delete-bloc-button"
          >
            <DeleteForever
              color={(notDeletable ? true : false) ? "disabled" : "error"}
            />
          </IconButton>
        </div>
      </Tooltip>
    ));

  return (
    <Grid container spacing={1} className={classes.root} data-woi="bloc-row">
      <Grid item style={{ width: "230px" }} data-woi="bloc-name">
        <TextField
          disabled={!permissions.canEdit()}
          inputRef={nameInput}
          fullWidth={true}
          label={t("land.blocs.bloc-name")}
          name="name"
          value={name}
          error={errors.filter(item => item.field === "name").length > 0}
          helperText={
            errors.filter(item => item.field === "name").length > 0
              ? errors.filter(item => item.field === "name")[0].message
              : ""
          }
          onChange={e => {
            setChanged(true);
            setName(e.target.value);
          }}
        />
      </Grid>
      <Grid item style={{ width: "180px" }} data-woi="bloc-area">
        <FieldWithTextAdornment
          format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,2})?$/}
          fullWidth={true}
          disabled={!permissions.canEdit()}
          color="secondary"
          label={t("land.blocs.area")}
          value={area}
          onChange={e => {
            setChanged(true);
            setArea(e.target.value);
          }}
          error={errors.filter(item => item.field === "area").length > 0}
          helperText={
            errors.filter(item => item.field === "area").length > 0
              ? errors.filter(item => item.field === "area")[0].message
              : ""
          }
          onFocus={event => event.target.select()}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">
                <Box color="secondary.main">ha</Box>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      {actionType}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
    </Grid>
  );
};

BlocRow.propTypes = propsDefinition;

const mapStateToProps = state => ({
  farmId: state.farm.farm.id
});

const mapDispachToProps = dispatch => ({
  addBloc: payload => dispatch({ type: ADD_BLOC, payload }),
  updateBloc: (payload, index) =>
    dispatch({ type: UPDATE_BLOC, payload, index }),
  deleteBloc: index => dispatch({ type: DELETE_BLOC, index })
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(withStyles(styles)(BlocRow));
