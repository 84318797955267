/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { ADD_GHG_PROGRAM, REMOVE_GHG_PROGRAM } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

//Notifications
import toast from "react-hot-toast";

//Custom
import profiles from "../../../constants/profiles";
import Permissions from "../../../utils/Permissions";
import DeleteConfirmation from "../../DeleteConfirmation";

//Custom Components
import SelectProgram from "./SelectProgram";
import SelectPeriod from "./SelectPeriod";

//UI Components
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Portal from "@material-ui/core/Portal";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  ghgProgram: PropTypes.object,
  index: PropTypes.number,
  farmId: PropTypes.string.isRequired,

  //Provided props
  onGhgProgramCreate: PropTypes.func, // Provided by Redux
  onGhgProgramDelete: PropTypes.func, // Provided by Redux
};

const GhgProgramRow = ({
  ghgProgram = {},
  onGhgProgramCreate,
  onGhgProgramDelete,
  index,
  farmId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [id, setId] = useState(null);
  const [program, setProgram] = useState({});
  const [baselinePeriod, setBaselinePeriod] = useState({});
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");

  const [deleteOpen, setDeleteOpen] = useState(false);

  const permissions = new Permissions(null, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
    profiles._ENC,
  ]);

  useEffect(() => {
    if (ghgProgram.id) {
      setId(ghgProgram.id);
      setProgram(ghgProgram.program);
      setStartPeriod(ghgProgram.program.start);
      setEndPeriod(ghgProgram.program.end);
      setBaselinePeriod(ghgProgram.period);
    }
  }, [ghgProgram]);

  const handleProgramSelection = (e) => {
    const { start, end } = e.currentTarget.dataset;
    setProgram({ id: e.target.value });
    setStartPeriod(start);
    setEndPeriod(end);
  };

  const handlePeriodSelection = (e) => {
    setBaselinePeriod({ id: e.target.value });
  };

  const handleCreate = () => {
    const reqBody = {
      ghgProgram: {
        program: {
          id: program.id,
        },
        period: {
          id: baselinePeriod.id,
        },
      },
    };

    const createGhgProgram = agent.Farms.GhgPrograms.create(farmId, reqBody).then(
      (res) => {
        onGhgProgramCreate(res.body.ghgProgram);
        setProgram(null);
        setBaselinePeriod(null);
        setStartPeriod("");
        setEndPeriod("");
      }
    );

    toast.promise(createGhgProgram, {
      loading: t("global.loading"),
      success: t("ghg-program.section.notifications.program-added"),
      error: t("ghg-program.section.notifications.error"),
    });
  };

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    const deleteGhgProgram = agent.Farms.GhgPrograms.delete(farmId, id).then((res) => {
      onGhgProgramDelete(index);
    });

    toast.promise(deleteGhgProgram, {
      loading: t("global.loading"),
      success: t("ghg-program.section.notifications.program-deleted"),
      error: t("ghg-program.section.notifications.error"),
    });
  };

  return (
    <Grid className={classes.rowContainer} container spacing={1}>
      <Grid item data-woi='select-program'>
        <SelectProgram
          disabled={id || !permissions.canEdit() ? true : false}
          value={(program && program.id) || ""}
          onChange={handleProgramSelection}
          label={t("ghg-program.section.program-select")}
          width='250px'
        />
      </Grid>
      <Grid item data-woi='select-period'>
        <SelectPeriod
          disabled={id || !permissions.canEdit() ? true : false}
          label={t("ghg-program.section.period-select")}
          baselinePeriod={baselinePeriod}
          onChange={handlePeriodSelection}
        />
      </Grid>
      <Grid item>
        <TextField disabled label='Start Period' value={startPeriod} />
      </Grid>
      <Grid item>
        <TextField disabled label='End Period' value={endPeriod} />
      </Grid>

      {permissions.canEdit() &&
        (id === null ? (
          <Grid item>
            <Button
              disabled={
                program && program.id && baselinePeriod && baselinePeriod.id
                  ? false
                  : true
              }
              style={{ minWidth: "120px" }}
              fullWidth={true}
              onClick={handleCreate}
              data-woi='add-program-button'
            >
              {t("ghg-program.section.add-button-label")}
            </Button>
          </Grid>
        ) : (
          <IconButton onClick={handleDeleteConfirm} data-woi='delete-program-button'>
            <DeleteForever color='error' />
          </IconButton>
        ))}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
    </Grid>
  );
};

GhgProgramRow.propTypes = propsDefinition;

const mapDispatchToProps = (dispatch) => ({
  onGhgProgramCreate: (payload) => dispatch({ type: ADD_GHG_PROGRAM, payload }),
  onGhgProgramDelete: (index) => dispatch({ type: REMOVE_GHG_PROGRAM, index }),
});

export default connect(null, mapDispatchToProps)(GhgProgramRow);
