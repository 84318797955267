/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import { FixedSizeList } from "react-window";
import PropTypes from "prop-types";

//API
import agent from "../../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//Custom Components
import PurchaseListElement from "./PurchaseListElement";
import AddFarmInputPurchase from "./AddFarmInputPurchase";
import ConfirmFarmInputPurchase from "./ConfirmFarmInputPurchase";

//UI Components
import Paper from "@material-ui/core/Paper";
import Portal from "@material-ui/core/Portal";

const ItemRenderer = ({ data, index, style }) => {
  // Access the items array using the "data" prop:
  const machinery = data.inputs[index];

  return (
    <PurchaseListElement
      onClick={data.onClick}
      style={style}
      data={machinery}
      itemIndex={index}
    />
  );
};

const propsDefinition = {
  //Required attributes to be defined in the implementation
  inputs: PropTypes.array.isRequired,
  periodId: PropTypes.string.isRequired,
  selectedInputsType: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setFarmInputPurchase: PropTypes.func.isRequired,
};

const PurchaseList = ({
  inputs,
  periodId,
  selectedInputsType,
  farmId,
  onClose,
  setFarmInputPurchase,
}) => {
  const { t } = useTranslation();

  const [selectedInput, setSelectedInput] = useState({});
  const [usedFarmInputPurchase, setUsedFarmInputPurchase] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectMachinery = async (input, farmInputPurchaseId = null) => {
    if (farmInputPurchaseId) {
      setLoading(true);
      const { body, error } = await agent.Farms.FarmInputsPurchases.getOne(
        farmId,
        selectedInputsType,
        farmInputPurchaseId
      );
      if (!error) {
        setUsedFarmInputPurchase(body.farmInputPurchase);
        setOpenConfirm(true);
      }
      setLoading(false);
    } else {
      setSelectedInput(input);
      setOpenAdd(true);
    }
  };

  const handleAddFarmInputPurchase = async () => {
    setLoading(true);
    const requestBody = {
      farmInputPurchase: {
        price: null,
        input: {
          ...selectedInput,
        },
        period: {
          id: periodId,
        },
      },
    };

    const createPurchase = agent.Farms.FarmInputsPurchases.create(
      farmId,
      selectedInputsType,
      requestBody
    )
      .then((res) => {
        setFarmInputPurchase(res.body.farmInputPurchase);
        setOpenConfirm(false);
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setLoading(false);
        onClose();
      });

    toast.promise(createPurchase, {
      loading: t("global.loading"),
      success: t("operations.equipment-database-modal.notifications.purchase-added"),
      error: t("operations.equipment-database-modal.notifications.purchase-error"),
    });
  };

  const handleSetFarmInputPurchase = () => {
    setFarmInputPurchase(usedFarmInputPurchase);
    setOpenConfirm(false);
    setLoading(false);
    onClose();
  };

  return (
    <Paper style={{ marginTop: "16px" }} variant='outlined'>
      {inputs ? (
        <FixedSizeList
          itemData={{ inputs: inputs, onClick: onSelectMachinery }}
          height={644}
          itemSize={50}
          itemCount={inputs.length}
        >
          {ItemRenderer}
        </FixedSizeList>
      ) : (
        ""
      )}
      <Portal>
        <AddFarmInputPurchase
          loading={loading}
          input={selectedInput}
          open={openAdd}
          onAdd={handleAddFarmInputPurchase}
          onCancel={() => setOpenAdd(false)}
        />
      </Portal>
      <Portal>
        <ConfirmFarmInputPurchase
          usedFarmInputPurchase={usedFarmInputPurchase}
          onConfirm={handleSetFarmInputPurchase}
          onCancel={() => setOpenConfirm(false)}
          loading={loading}
          open={openConfirm}
        />
      </Portal>
    </Paper>
  );
};

PurchaseList.propTypes = propsDefinition;

export default PurchaseList;
