/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";

//Custom Components
import LandOccupationSection from "./LandOccupationSection";
import Header from "./Header";

class LandOccupation extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <LandOccupationSection />
      </div>
    );
  }
}

export default LandOccupation;
