/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import SearchGlobalInputsBar from "./SearchGlobalInputsBar";
import PurchaseList from "./PurchaseList";
import CustomDialogTitle from "../../../../../CustomDialogTitle";

//UI Components
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  selectedInputsType: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  setFarmInputPurchase: PropTypes.func.isRequired,
};

const PurchaseListModal = ({
  open,
  onClose,
  farmId,
  selectedInputsType,
  periodId,
  note,
  setFarmInputPurchase,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [inputs, setInputs] = useState([]);
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      const searchInputs = async () => {
        const { body, error } = await agent.Inputs.search(
          selectedInputsType,
          periodId,
          farmId,
          searchQuery
        );

        if (!error) {
          setInputs(body.inputs);
        }
      };
      searchInputs();
    }
  }, [open, searchQuery, selectedInputsType, periodId, farmId]);

  useEffect(() => {
    if (open) {
      const getInputs = async () => {
        const { body, error } = await agent.Inputs.get(
          selectedInputsType,
          periodId,
          farmId
        );

        if (!error) {
          setInputs(body.inputs);
        }
      };
      getInputs();
    }
  }, [open, selectedInputsType, farmId, periodId]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth='sm'
      aria-labelledby='inputs-modal-dialog-title'
      open={open}
    >
      <CustomDialogTitle id='inputs-modal-dialog-title' onClose={handleClose}>
        {t("operations.purchases-database-modal.title")}
      </CustomDialogTitle>
      <DialogContent dividers>
        <SearchGlobalInputsBar
          query={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          label={t("operations.purchases-database-modal.search-bar-label")}
          handleClear={() => setSearchQuery("")}
        />
        {note !== "" ? (
          <div style={{ marginTop: "8px", marginLeft: "8px" }}>
            <span style={{ color: "#f44336" }}>
              {t("operations.purchases-database-modal.given-name")}
            </span>{" "}
            {note}
          </div>
        ) : null}
        <PurchaseList
          setFarmInputPurchase={setFarmInputPurchase}
          farmId={farmId}
          periodId={periodId}
          inputs={inputs}
          onClose={onClose}
          selectedInputsType={selectedInputsType}
        ></PurchaseList>
      </DialogContent>
    </Dialog>
  );
};

PurchaseListModal.propTypes = propsDefinition;

export default PurchaseListModal;
