/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { CROPS_LOADED } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import PaddockRow from "./PaddockRow";
import PaddockRowTotalArea from "./PaddockRowTotalArea";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  paddocks: PropTypes.array, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  cropLoad: PropTypes.func, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
};

const PaddocksSecion = ({ paddocks, classes, cropLoad, lock }) => {
  const permissions = new Permissions(null, lock);

  useEffect(() => {
    const getCrops = async () => {
      const { body, error } = await agent.Crops.get();
      if (!error) {
        cropLoad(body.crops);
      }
    };

    getCrops();
  }, [cropLoad]);

  const paddockList =
    paddocks && paddocks.length > 0
      ? paddocks.map((paddock, index) => (
          <PaddockRow key={index} index={index} paddock={paddock} />
        ))
      : "";

  return (
    <div className={classes.root}>
      <div>
        {paddockList}
        {permissions.canEdit() && <PaddockRow paddock={null} />}
        <PaddockRowTotalArea />
      </div>
    </div>
  );
};

PaddocksSecion.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  paddocks: state.land.paddocks,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  cropLoad: (payload) => dispatch({ type: CROPS_LOADED, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaddocksSecion));
