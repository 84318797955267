/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import TextField from "@material-ui/core/TextField";

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  loginError,
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && Boolean(loginError)}
    helperText={touched && loginError}
    inputProps={{
      autoCorrect: "off",
      spellCheck: "off",
    }}
    {...input}
    {...custom}
  />
);
