/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const EquipmentsHeader = () => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ marginBottom: "24px" }}>
      <Grid item container xs={6} alignItems='center'>
        <Typography variant='h4'>{t("equipments.header.title")}</Typography>
      </Grid>
    </Grid>
  );
};

export default EquipmentsHeader;
