/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  icon: {
    color: "#FFF",
    marginRight: "8px",
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  handleConfirmInputs: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledMessage: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const ConfirmInputs = ({
  handleConfirmInputs,
  classes,
  disabled,
  disabledMessage,
  label,
}) => {
  return (
    <Tooltip title={disabledMessage}>
      <div>
        <Button
          disabled={disabled}
          className={classes.root}
          onClick={handleConfirmInputs}
          data-woi='validate-purchases-button'
        >
          <VerifiedUserIcon className={classes.icon} />
          {label}
        </Button>
      </div>
    </Tooltip>
  );
};

ConfirmInputs.propTypes = propsDefinition;

export default withStyles(styles)(ConfirmInputs);
