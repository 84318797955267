/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
const statuses = {
  CANVAS: -1,
  INVALID: 0,
  UNEXPECTED: 1,
  AUTO_VALIDATED: 2,
  AGRO_VALIDATED: 3,
};

export default statuses;
