/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import useIsMountedRef from "../../../utils/isMountedRef";

//UI Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Tooltip from "@material-ui/core/Tooltip";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorText: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,
};

const SearchAgronomist = ({
  value,
  onChange,
  disabled,
  errorText,
  name,
  label,
  locked,
}) => {
  const { t } = useTranslation();
  const [agronomists, setAgronomists] = useState([
    { id: 0, name: t("dashboard.form.fields.agronomist-search") },
  ]);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const getAgronomists = async () => {
      const { body, error } = await agent.Users.Agronomists.get();
      if (isMountedRef.current && !error) {
        setAgronomists(body.agronomists);
        if (!(body.agronomists.length > 1)) {
          onChange(null, body.agronomists[0]);
        }
      }
    };
    getAgronomists();
  }, [isMountedRef, onChange]);

  if (agronomists) {
    return (
      <Tooltip
        arrow
        title={locked ? t("dashboard.form.fields.agronomist-search-locked") : ""}
      >
        <Autocomplete
          closeIcon={
            <HighlightOffIcon
              style={{ fontSize: "18px", marginTop: "1px", color: "red" }}
            />
          }
          fullWidth={true}
          value={value}
          onChange={onChange}
          size='small'
          freeSolo
          disabled={!(agronomists.length > 1) || disabled}
          name={name}
          options={agronomists || [""]}
          getOptionLabel={(option) =>
            option.name ? option.name : typeof option === "string" ? option : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              margin='normal'
              variant='outlined'
              error={Boolean(errorText)}
              InputProps={{
                ...params.InputProps,
                type: "search",
                autoComplete: "off",
                autoCorrect: "off",
                spellCheck: "off",
              }}
              value={value}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Tooltip>
    );
  }
  return null;
};

SearchAgronomist.propTypes = propsDefinition;

export default SearchAgronomist;
