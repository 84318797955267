/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import FieldWithTextAdornment from "../../FieldWithTextAdornment";
import SelectIrrigation from "./SelectIrrigation";
import SelectSource from "./SelectSource";
import SelectPower from "./SelectPower";

//Theme
import customTheme from "../../../theme";

//UI Components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const style = (theme) => ({
  mainOutlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  blocTitle: {
    marginBottom: theme.spacing(1),
  },
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  cropIrrigation: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by withStyles HOC
};

const CropsIrrigationRow = ({ farmId, cropIrrigation, classes }) => {
  const { t } = useTranslation();

  const [id, setId] = useState(null);
  const [bloc, setBloc] = useState(null);
  const [status, setStatus] = useState(0);
  const [changedIrrigation, setChangedIrrigation] = useState(null);
  const [irrigation, setIrrigation] = useState(null);
  const [source, setSource] = useState(null);
  const [depth, setDepth] = useState(0);
  const [horizontal, setHorizontal] = useState(0);
  const [power, setPower] = useState(null);
  const [total, setTotal] = useState(0);
  const [proportion, setProportion] = useState(0);
  const [lock, setLock] = useState(0);

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  const checkValidate = useCallback(() => {
    if ((irrigation && !irrigation.id) || irrigation === null) {
      setErrors((errors) => [
        ...errors,
        {
          field: "irrigation",
          message: t("operations.crops-irrigation.errors.required-irrigation"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "irrigation")]);
    }
    if ((source && !source.id) || source === null) {
      setErrors((errors) => [
        ...errors,
        {
          field: "source",
          message: t("operations.crops-irrigation.errors.required-source"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "source")]);
    }
    if ((power && !power.id) || power === null) {
      setErrors((errors) => [
        ...errors,
        {
          field: "power",
          message: t("operations.crops-irrigation.errors.required-power"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "power")]);
    }
    if (!(depth > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "depth",
          message: t("operations.crops-irrigation.errors.required-depth"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "depth")]);
    }
    if (!(horizontal > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "horizontal",
          message: t("operations.crops-irrigation.errors.required-horizontal"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "horizontal")]);
    }
    if (!(total > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "total",
          message: t("operations.crops-irrigation.errors.required-total"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "total")]);
    }
    if (!(proportion > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "proportion",
          message: t("operations.crops-irrigation.errors.required-proportion"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "proportion")]);
    }
    if (changedIrrigation !== 1) {
      setErrors([]);
    }
  }, [
    t,
    irrigation,
    source,
    power,
    depth,
    horizontal,
    total,
    proportion,
    changedIrrigation,
  ]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed]);

  const handleUpdate = useCallback(() => {
    const validate = (
      changedIrrigation,
      irrigation,
      source,
      power,
      depth,
      horizontal,
      total,
      proportion
    ) => {
      let error = false;
      if (changedIrrigation === 1) {
        if ((irrigation && !irrigation.id) || irrigation === null) {
          error = true;
        }
        if ((source && !source.id) || source === null) {
          error = true;
        }
        if ((power && !power.id) || power === null) {
          error = true;
        }
        if (!(depth > 0)) {
          error = true;
        }
        if (!(horizontal > 0)) {
          error = true;
        }
        if (!(total > 0)) {
          error = true;
        }
        if (!(proportion > 0)) {
          error = true;
        }
      } else if (changedIrrigation === null) {
        error = true;
      }
      return error;
    };

    if (
      !validate(
        changedIrrigation,
        irrigation,
        source,
        power,
        depth,
        horizontal,
        total,
        proportion
      )
    ) {
      const reqBody = {
        cropIrrigation: {
          changedIrrigation,
          status,
          irrigation,
          source,
          power,
          depth,
          horizontal,
          total,
          proportion,
        },
      };

      const updateIrrigation = agent.Farms.CropsIrrigations.update(
        farmId,
        id,
        reqBody
      ).then((res) => {
        const { cropIrrigation } = res.body;
        setId(cropIrrigation.id);
        setBloc(cropIrrigation.blocperi.bloc);
        setIrrigation(cropIrrigation.irrigation);
        setSource(cropIrrigation.source);
        setPower(cropIrrigation.power);
        setDepth(cropIrrigation.depth);
        setHorizontal(cropIrrigation.horizontal);
        setTotal(cropIrrigation.total);
        setStatus(cropIrrigation.status);
        setLock(cropIrrigation.lock);
        setChangedIrrigation(cropIrrigation.changedIrrigation);
        setProportion(cropIrrigation.proportion);
        setChanged(false);
      });

      toast.promise(updateIrrigation, {
        loading: t("global.loading"),
        success: t("operations.crops-irrigation.notifications.crops-irrigation-saved"),
        error: t("operations.crops-irrigation.notifications.error"),
      });
    }
  }, [
    t,
    farmId,
    id,
    status,
    changedIrrigation,
    irrigation,
    source,
    power,
    depth,
    horizontal,
    total,
    proportion,
  ]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed]);

  useEffect(() => {
    if (cropIrrigation.id) {
      setId(cropIrrigation.id);
      setBloc(cropIrrigation.blocperi.bloc);
      setIrrigation(cropIrrigation.irrigation);
      setSource(cropIrrigation.source);
      setPower(cropIrrigation.power);
      setDepth(cropIrrigation.depth);
      setHorizontal(cropIrrigation.horizontal);
      setTotal(cropIrrigation.total);
      setStatus(cropIrrigation.status);
      setChangedIrrigation(cropIrrigation.changedIrrigation);
      setProportion(cropIrrigation.proportion);
      setLock(cropIrrigation.lock);
    }
  }, [cropIrrigation]);

  if (id) {
    return (
      <Paper className={classes.mainOutlinedContainer} variant='outlined'>
        <Typography className={classes.blocTitle} variant='h3'>
          {bloc.name}
        </Typography>
        <Grid item>
          <Paper className={classes.outlinedContainer} variant='outlined'>
            <Grid container spacing={1}>
              <Grid container item justify='space-between'>
                <Grid item container alignItems='center' xs={9}>
                  <Typography className={classes.typoMarginRight} variant='h4'>
                    {t("operations.crops-irrigation.title")}
                  </Typography>
                  {status === 3 ? (
                    <Tooltip title={t("operations.crops-irrigation.valid")}>
                      <VerifiedUserIcon className={classes.valid} />
                    </Tooltip>
                  ) : (
                    permissions.canView() && (
                      <Tooltip title={t("operations.crops-irrigation.invalid")}>
                        <ErrorIcon className={classes.invalid} />
                      </Tooltip>
                    )
                  )}
                </Grid>
                {permissions.canEdit() && permissions.canView() && status !== 3 && (
                  <Grid container justify='flex-end' item xs={3}>
                    <Button
                      disabled={
                        changedIrrigation === null ||
                        (changedIrrigation === 0
                          ? false
                          : (irrigation && !irrigation.id) ||
                            (source && !source.id) ||
                            (power && !power.id) ||
                            !(depth > 0) ||
                            !(horizontal > 0) ||
                            !(total > 0))
                      }
                      style={{ height: "40px" }}
                      className={classes.validateButton}
                      onClick={() => {
                        setStatus(3);
                        setChanged(true);
                      }}
                      data-woi='validate-irrigation-button'
                    >
                      <VerifiedUserIcon style={{ marginRight: "5px" }} />
                      {t("operations.crops-irrigation.validate-button-label")}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item container alignItems='center'>
                <Typography>{t("operations.crops-irrigation.sub-ttile")}</Typography>
                <Grid item xs={12}>
                  <FormControl component='fieldset' disabled={!permissions.canEdit()}>
                    <RadioGroup
                      size='small'
                      row
                      name='position'
                      style={{ display: "flex", alignItems: "center" }}
                      onChange={(e) => {
                        setChangedIrrigation(parseInt(e.target.value));
                        setChanged(true);
                      }}
                      value={
                        changedIrrigation !== null ? changedIrrigation.toString() : null
                      }
                    >
                      <FormControlLabel
                        value='0'
                        style={
                          changedIrrigation === null
                            ? { color: customTheme.palette.error.main }
                            : null
                        }
                        control={
                          <Radio
                            style={
                              changedIrrigation === null
                                ? { color: customTheme.palette.error.main }
                                : null
                            }
                            size='small'
                            color='secondary'
                          />
                        }
                        label={
                          <Typography variant='subtitle2'>
                            {t("operations.crops-irrigation.no")}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='1'
                        style={
                          changedIrrigation === null
                            ? { color: customTheme.palette.error.main }
                            : null
                        }
                        control={
                          <Radio
                            style={
                              changedIrrigation === null
                                ? { color: customTheme.palette.error.main }
                                : null
                            }
                            size='small'
                            color='secondary'
                            data-woi='irrigation-checked'
                          />
                        }
                        label={
                          <Typography variant='subtitle2'>
                            {t("operations.crops-irrigation.yes")}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {changedIrrigation === null && (
                      <Typography
                        variant='caption'
                        style={{ color: customTheme.palette.error.main }}
                      >
                        {t("operations.crops-irrigation.errors.required-choice")}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {changedIrrigation === 1 && (
                <>
                  <Grid className={classes.underline} item />
                  <Grid item>
                    <SelectIrrigation
                      disabled={!permissions.canEdit() || !(changedIrrigation === 1)}
                      value={irrigation && irrigation.id ? irrigation.id : ""}
                      name='irrigation'
                      onChange={(e) => {
                        setIrrigation({ id: e.target.value });
                        setChanged(true);
                      }}
                      errors={errors}
                      label={t("operations.crops-irrigation.irrigation")}
                      width='200px'
                    />
                  </Grid>
                  <Grid item>
                    <SelectSource
                      disabled={!permissions.canEdit() || !(changedIrrigation === 1)}
                      value={source && source.id ? source.id : ""}
                      name='source'
                      onChange={(e) => {
                        setSource({ id: e.target.value });
                        setChanged(true);
                      }}
                      errors={errors}
                      label={t("operations.crops-irrigation.source")}
                      width='200px'
                    />
                  </Grid>
                  <Grid item>
                    <SelectPower
                      disabled={!permissions.canEdit() || !(changedIrrigation === 1)}
                      value={power && power.id ? power.id : ""}
                      name='power'
                      onChange={(e) => {
                        setPower({ id: e.target.value });
                        setChanged(true);
                      }}
                      errors={errors}
                      label={t("operations.crops-irrigation.power")}
                      width='200px'
                    />
                  </Grid>
                  <Grid container item alignItems='center' data-woi='irrigation-depth'>
                    <Grid item xs={3}>
                      <Typography className={classes.typoMarginRight}>
                        {t("operations.crops-irrigation.depth")}
                      </Typography>
                    </Grid>
                    <FieldWithTextAdornment
                      disabled={!permissions.canEdit() || !Boolean(changedIrrigation)}
                      name='depth'
                      format={/^[0-9][0-9]?[0-9]?$/}
                      value={depth}
                      onChange={(e) => {
                        setDepth(e.target.value);
                        setChanged(true);
                      }}
                      errors={errors}
                      onFocus={(event) => event.target.select()}
                      adornment={t("operations.crops-irrigation.depth-adornment")}
                      width='140px'
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems='center'
                    data-woi='irrigation-horizontal'
                  >
                    <Grid item xs={3}>
                      <Typography className={classes.typoMarginRight}>
                        {t("operations.crops-irrigation.horizontal")}
                      </Typography>
                    </Grid>
                    <FieldWithTextAdornment
                      disabled={!permissions.canEdit() || !Boolean(changedIrrigation)}
                      name='horizontal'
                      format={/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/}
                      value={horizontal}
                      onChange={(e) => {
                        setHorizontal(e.target.value);
                        setChanged(true);
                      }}
                      errors={errors}
                      onFocus={(event) => event.target.select()}
                      adornment={t("operations.crops-irrigation.horizontal-adornment")}
                      width='140px'
                    />
                  </Grid>
                  <Grid container item alignItems='center' data-woi='irrigation-quantity'>
                    <Grid item xs={3}>
                      <Typography className={classes.typoMarginRight}>
                        {t("operations.crops-irrigation.total")}
                      </Typography>
                    </Grid>
                    <FieldWithTextAdornment
                      disabled={!permissions.canEdit() || !Boolean(changedIrrigation)}
                      name='total'
                      format={/^[0-9][0-9]?[0-9]?$/}
                      value={total}
                      onChange={(e) => {
                        setTotal(e.target.value);
                        setChanged(true);
                      }}
                      errors={errors}
                      onFocus={(event) => event.target.select()}
                      adornment={t("operations.crops-irrigation.total-adornment")}
                      width='140px'
                    />
                  </Grid>
                  <Grid container item alignItems='center' data-woi='irrigation-percent'>
                    <Grid item xs={3}>
                      <Typography className={classes.typoMarginRight}>
                        {t("operations.crops-irrigation.proportion")}
                      </Typography>
                    </Grid>
                    <FieldWithTextAdornment
                      disabled={!permissions.canEdit() || !Boolean(changedIrrigation)}
                      name='proportion'
                      format={/^[0-9][0-9]?$|^100$/}
                      value={proportion}
                      onChange={(e) => {
                        setProportion(e.target.value);
                        setChanged(true);
                      }}
                      errors={errors}
                      onFocus={(event) => event.target.select()}
                      adornment={t("operations.crops-irrigation.proportion-adornment")}
                      width='140px'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Paper>
    );
  }
  return null;
};

CropsIrrigationRow.propTypes = propsDefinition;

export default withStyles(style)(CropsIrrigationRow);
