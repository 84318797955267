/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import FieldWithTextAdornment from "../../../FieldWithTextAdornment";
import SelectCustomType from "./SelectCustomType";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  breakline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(1),
  },
  rightMargin: {
    marginRight: "8px",
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
};

const CustomInput = ({ open, onCancel, onConfirm, farmId, periodId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [type, setType] = useState("");
  const [requiresNpk, setRequiresNpk] = useState(false);
  const [name, setName] = useState("");
  const [useDefaultName, setUseDefaultName] = useState(true);
  const [currency, setCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const [N, setN] = useState("");
  const [P, setP] = useState("");
  const [K, setK] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (requiresNpk && useDefaultName) {
      setName(`NPK ${N}-${P}-${K}*`);
    }
  }, [requiresNpk, name, useDefaultName, N, P, K]);

  useEffect(() => {
    if (!requiresNpk) {
      setName("");
    }
  }, [type, requiresNpk]);

  useEffect(() => {
    setErrors((errors) => errors.filter((error) => error.field !== "global"));
  }, [N, P, K]);

  useEffect(() => {
    setErrors([]);
  }, [type]);

  const handleCancel = () => {
    onCancel();
  };

  const getComposition = () => {
    return requiresNpk
      ? {
          N,
          P,
          K,
        }
      : {};
  };

  const handleConfirm = () => {
    if (requiresNpk && name !== "") {
      agent.Farms.FarmInputsPurchases.NPK.check(farmId, periodId, type, N, P, K).then(
        (res) => {
          if (res.body.input.exist) {
            setErrors((errors) => [
              ...errors,
              { field: "global", message: t("purchases.custom-input-modal.input-exist") },
            ]);
          } else {
            setName("");
            setCurrency("");
            setUnit("");
            setN("");
            setP("");
            setK("");
            setErrors([]);
            onConfirm(type, name, getComposition(), currency, unit);
          }
        }
      );
    } else if (name !== "") {
      setName("");
      setCurrency("");
      setUnit("");
      setN("");
      setP("");
      setK("");
      setErrors([]);
      onConfirm(type, name, getComposition(), currency, unit);
    } else {
      setErrors((errors) => [
        ...errors,
        {
          field: "name",
          message: t("purchases.custom-input-modal.name-required"),
        },
      ]);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='confirmation-dialog-title'>
        {t("purchases.custom-input-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item container spacing={1} alignItems='center' xs={12}>
            <Grid item>
              <Typography>{t("purchases.custom-input-modal.input-type")}</Typography>
            </Grid>
            <Grid item>
              <SelectCustomType
                selectedType={type}
                onChange={(e) => {
                  const { npk, currency, unit } = e.currentTarget.dataset;
                  setRequiresNpk(Boolean(parseInt(npk)));
                  setCurrency(currency);
                  setUnit(unit);
                  setType(e.target.value);
                }}
                farmId={farmId}
              />
            </Grid>
          </Grid>
          <Grid className={classes.breakline} item />
          <Grid item container spacing={1} alignItems='center' xs={12}>
            <Grid item>
              <Typography>{t("purchases.custom-input-modal.input-name")}</Typography>
            </Grid>
            <Grid item>
              <TextField
                value={name}
                disabled={useDefaultName && requiresNpk}
                onChange={(e) => setName(e.target.value)}
                error={errors.filter((error) => error.field === "name").length > 0}
                helperText={
                  errors.filter((error) => error.field === "name").length > 0
                    ? errors.filter((error) => error.field === "name")[0].message
                    : ""
                }
                InputProps={{
                  startAdornment: requiresNpk && (
                    <InputAdornment position='start'>
                      {t("purchases.custom-input-modal.default-letter")}
                      <Checkbox
                        checked={useDefaultName}
                        onChange={(e, value) => setUseDefaultName(value)}
                        size='small'
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems='center' spacing={1}>
            {requiresNpk && (
              <>
                <Grid item>
                  <Typography>{t("purchases.custom-input-modal.description")}</Typography>
                </Grid>
                <Grid item>
                  <FieldWithTextAdornment
                    name='N'
                    format={/^(?=.*[0-9])\d{0,2}(?:[,.]\d{0,1})?$/}
                    value={N}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value > 100) value = 100;
                      if (value < 0) value = 0;
                      setN(value);
                    }}
                    error={!(parseInt(N) >= 0)}
                    helperText={
                      !(parseInt(N) >= 0) &&
                      t("purchases.custom-input-modal.error.mandatory")
                    }
                    adornment={`% ${t("purchases.custom-input-modal.N")}`}
                    width='90px'
                  />
                </Grid>
                <Grid item>
                  <FieldWithTextAdornment
                    name='P'
                    format={/^(?=.*[0-9])\d{0,2}(?:[,.]\d{0,1})?$/}
                    value={P}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value > 100) value = 100;
                      if (value < 0) value = 0;
                      setP(value);
                    }}
                    error={!(parseInt(P) >= 0)}
                    helperText={
                      !(parseInt(P) >= 0) &&
                      t("purchases.custom-input-modal.error.mandatory")
                    }
                    adornment={`% ${t("purchases.custom-input-modal.P")}`}
                    width='90px'
                  />
                </Grid>
                <Grid item>
                  <FieldWithTextAdornment
                    name='K'
                    format={/^(?=.*[0-9])\d{0,2}(?:[,.]\d{0,1})?$/}
                    value={K}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value > 100) value = 100;
                      if (value < 0) value = 0;
                      setK(value);
                    }}
                    error={!(parseInt(K) >= 0)}
                    helperText={
                      !(parseInt(K) >= 0) &&
                      t("purchases.custom-input-modal.error.mandatory")
                    }
                    adornment={`% ${t("purchases.custom-input-modal.K")}`}
                    width='90px'
                  />
                </Grid>
                {errors.filter((item) => item.field === "global").length > 0 && (
                  <Grid item>
                    <Typography color='error'>
                      {errors.filter((item) => item.field === "global")[0].message}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("purchases.custom-input-modal.cancel-button-label")}</Box>
        </Button>
        <Button
          disabled={
            requiresNpk &&
            (!(parseInt(N) >= 0) || !(parseInt(P) >= 0) || !(parseInt(K) >= 0))
          }
          onClick={handleConfirm}
          color='secondary'
        >
          {t("purchases.custom-input-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomInput.propTypes = propsDefinition;

export default CustomInput;
