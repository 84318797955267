/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//i18n
import i18next from "i18next";

//utils
import getSeparator from "../utils/getSeparator";

//UI Components
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  tooltip: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  attribute: PropTypes.string,
  errors: PropTypes.array,
  name: PropTypes.string,
  format: PropTypes.object,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
  adornment: PropTypes.string,
};

const FieldWithTextAdornment = ({
  tooltip = "",
  error = false,
  helperText,
  width,
  style,
  label,
  value,
  disabled,
  attribute,
  errors: errList,
  name,
  format,
  InputProps,
  onChange,
  adornment,
}) => {
  const errors = errList && errList.filter((item) => item.field === name);
  const { field, message } = errors && errors.length > 0 ? errors[0] : {};

  return (
    <Tooltip title={tooltip !== "" ? <Typography>{tooltip}</Typography> : ""}>
      <TextField
        disabled={disabled || false}
        style={{ width: width || "100%", ...style }}
        color='secondary'
        label={label}
        value={value.toString().replace(".", getSeparator(i18next.language, "decimal"))}
        onChange={(e) => {
          if (
            e.target.value.match(format || /[0-9]+([.,][0-9]+)?/) ||
            e.target.value === ""
          ) {
            e.target.value = e.target.value.replace(",", ".");
            onChange(e);
          }
        }}
        onFocus={(event) => event.target.select()}
        error={field || error ? true : false}
        helperText={message ? message : helperText ? helperText : null}
        InputProps={{
          endAdornment:
            InputProps && InputProps.endAdornment ? (
              InputProps.endAdornment
            ) : (
              <InputAdornment position='end' styles={{ paddingLeft: "10px" }}>
                <Box fontSize='12px' color='secondary.main'>
                  {adornment}
                </Box>
              </InputAdornment>
            ),
          ...InputProps,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        data-woi={attribute}
      />
    </Tooltip>
  );
};

FieldWithTextAdornment.propTypes = propsDefinition;

export default FieldWithTextAdornment;
