/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SELECT_CROP } from "../../constants/actionTypes";

//UI Components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const propsDefinition = {
  //Provided props
  crops: PropTypes.array, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
  selectedOperation: PropTypes.string, // Provided by Redux
  setSelectedCrop: PropTypes.func, // Provided by Redux
};

const SelectCrop = ({ crops, selectedCrop, selectedOperation, setSelectedCrop }) => {
  useEffect(() => {
    if (crops && !crops.some((item) => item.id === selectedCrop)) {
      setSelectedCrop(crops[0].id);
    }
  }, [crops, setSelectedCrop, selectedCrop]);

  const onSelectChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  if (crops && crops.some((item) => item.id === selectedCrop)) {
    return (
      <FormControl variant='outlined' size='small' style={{ width: "240px" }}>
        <Select
          name='crop'
          disabled={Boolean(selectedOperation)}
          value={selectedCrop}
          onChange={onSelectChange}
        >
          {crops.map((item, i) => {
            return (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectCrop.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  crops: state.farm.crops,
  selectedCrop: state.crop.selectedCrop,
  selectedOperation: state.crop.selectedOperation,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCrop: (payload) => dispatch({ type: SELECT_CROP, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCrop);
