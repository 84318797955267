/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import { FixedSizeList } from "react-window";
import PropTypes from "prop-types";

//API
import agent from "../../../../../agent";

//Custom Components
import MachineryListElement from "./MachineryListElement";
import AddFarmMachinery from "./AddFarmMachinery";
import ConfirmFarmMachinery from "./ConfirmFarmMachinery";

//UI Components
import Paper from "@material-ui/core/Paper";
import Portal from "@material-ui/core/Portal";

const ItemRenderer = ({ data, index, style }) => {
  // Access the items array using the "data" prop:
  const machinery = data.machineries[index];

  return (
    <MachineryListElement
      onClick={data.onClick}
      style={style}
      data={machinery}
      itemIndex={index}
    />
  );
};

const propsDefinition = {
  //Required attributes to be defined in the implementation
  machineries: PropTypes.array.isRequired,
  periodId: PropTypes.string.isRequired,
  givenName: PropTypes.string,
  farmId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setFarmMachinery: PropTypes.func.isRequired,
};

const MachineryList = ({
  machineries,
  periodId,
  givenName: gName,
  farmId,
  onClose,
  setFarmMachinery,
}) => {
  const [selectedMachinery, setSelectedMachinery] = useState({});
  const [usedFarmMachinery, setUsedFarmMachinery] = useState({});
  const [givenName, setGivenName] = useState(gName ? gName : "");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectMachinery = async (machinery, farmMachineryId = null) => {
    if (farmMachineryId) {
      setLoading(true);
      const { body, error } = await agent.Farms.FarmMachineries.getOne(
        farmId,
        farmMachineryId
      );
      if (!error) {
        setUsedFarmMachinery(body.farmMachinery);
        setOpenConfirm(true);
      }
      setLoading(false);
    } else {
      setSelectedMachinery(machinery);
      if (givenName === "") {
        setGivenName(machinery.name);
      }
      setOpenAdd(true);
    }
  };

  const handleAddMachinery = async () => {
    if (givenName !== "") {
      setLoading(true);
      const requestBody = {
        farmMachinery: {
          givenName,
          period: {
            id: periodId,
          },
          machinery: selectedMachinery,
        },
      };
      const { body, error } = await agent.Farms.FarmMachineries.create(
        farmId,
        requestBody
      );
      if (!error) {
        setGivenName("");
        setFarmMachinery(null, body.farmMachinery);
        setOpenAdd(false);
        setLoading(false);
        onClose();
      }
    }
  };

  const handleSetMachinery = () => {
    setFarmMachinery(null, usedFarmMachinery);
    setOpenConfirm(false);
    setLoading(false);
    onClose();
  };

  return (
    <Paper style={{ marginTop: "16px" }} variant='outlined'>
      {machineries ? (
        <FixedSizeList
          itemData={{ machineries: machineries, onClick: onSelectMachinery }}
          height={644}
          itemSize={50}
          itemCount={machineries.length}
        >
          {ItemRenderer}
        </FixedSizeList>
      ) : (
        ""
      )}
      <Portal>
        <AddFarmMachinery
          givenName={
            givenName !== ""
              ? givenName
              : selectedMachinery && selectedMachinery.name
              ? selectedMachinery.name
              : ""
          }
          loading={loading}
          setGivenName={setGivenName}
          open={openAdd}
          onAdd={handleAddMachinery}
          onCancel={() => {
            setGivenName("");
            setOpenAdd(false);
          }}
        />
      </Portal>
      <Portal>
        <ConfirmFarmMachinery
          givenName={usedFarmMachinery.givenName}
          onConfirm={handleSetMachinery}
          onCancel={() => setOpenConfirm(false)}
          loading={loading}
          open={openConfirm}
        />
      </Portal>
    </Paper>
  );
};

MachineryList.propTypes = propsDefinition;

export default MachineryList;
