/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  LAND_LOADED,
  SET_LAND_PAGE,
  SET_LAND_LOADING,
  ADD_BLOC,
  UPDATE_BLOC,
  DELETE_BLOC,
  ADD_BLOCPERI,
  UPDATE_BLOCPERI,
  DELETE_BLOCPERI,
  ADD_PADDOCK,
  UPDATE_PADDOCK,
  DELETE_PADDOCK,
} from "../constants/actionTypes";

const Land = (state = { loaded: false }, action) => {
  switch (action.type) {
    case LAND_LOADED:
      return {
        ...state,
        loaded: true,
        paddocks:
          action.payload.length >= 2 && action.payload[2]
            ? action.payload[2].paddocks
            : [],
        blocsperi:
          action.payload.length >= 1 && action.payload[1]
            ? action.payload[1].blocsperi
            : [],
        blocs:
          action.payload.length >= 0 && action.payload[0] ? action.payload[0].blocs : [],
      };
    case SET_LAND_LOADING:
      return {
        ...state,
        loaded: action.payload,
      };
    case SET_LAND_PAGE:
      return {
        ...state,
        selectedLandPage: action.payload,
      };
    case UPDATE_BLOC:
      return {
        ...state,
        blocs: state.blocs.map((bloc, index) => {
          if (index === action.index) {
            return { ...bloc, ...action.payload };
          }
          return bloc;
        }),
      };
    case ADD_BLOC:
      return {
        ...state,
        blocs: [...state.blocs, action.payload],
      };
    case DELETE_BLOC:
      const deletedBloc = state.blocs.find((i, index) => action.index === index);
      return {
        ...state,
        blocs: [...state.blocs.filter((i, index) => action.index !== index)],
        blocsperi: [...state.blocsperi.filter((item) => item.bloc.id !== deletedBloc.id)],
      };
    case UPDATE_BLOCPERI:
      return {
        ...state,
        blocsperi: state.blocsperi.map((blocperi, index) => {
          if (index === action.index) {
            return action.payload;
          }
          return blocperi;
        }),
        paddocks: state.paddocks.map((paddock) => {
          if (paddock.blocperi.id === action.payload.id) {
            return { ...paddock, status: 0 };
          }
          return paddock;
        }),
      };
    case ADD_BLOCPERI:
      return {
        ...state,
        blocsperi: [...state.blocsperi, action.payload],
        blocs: state.blocs.map((bloc) => {
          if (bloc.id === action.payload.bloc.id) {
            bloc.blocperi = 1;
          }
          return bloc;
        }),
      };
    case DELETE_BLOCPERI:
      const blocId = state.blocsperi.filter((i, index) => action.index === index)[0].bloc
        .id;
      return {
        ...state,
        blocsperi: [...state.blocsperi.filter((i, index) => action.index !== index)],
        blocs: state.blocs.map((bloc) => {
          if (bloc.id === blocId) {
            bloc.blocperi = 0;
          }
          return bloc;
        }),
      };
    case UPDATE_PADDOCK:
      return {
        ...state,
        paddocks: state.paddocks.map((paddock, index) => {
          if (index === action.index) {
            return action.payload;
          }
          return paddock;
        }),
      };
    case ADD_PADDOCK:
      return {
        ...state,
        paddocks: [...state.paddocks, action.payload],
      };
    case DELETE_PADDOCK:
      return {
        ...state,
        paddocks: [...state.paddocks.filter((i, index) => action.index !== index)],
      };
    default:
      return state;
  }
};

export default Land;
