/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  LAND_LOADED,
  SET_LAND_LOADING,
  SET_LAND_PAGE,
} from "../../constants/actionTypes";

//API
import agent from "../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import Header from "./Header";
import BlocsSection from "./BlocsSection";
import BlocsperiSection from "./BlocsperiSection";
import PaddocksSection from "./PaddockSection";

const styles = (theme) => ({
  root: {
    "@global": {
      ".MuiInputBase-root.Mui-disabled": {
        color: theme.palette.text.primary,
      },
    },
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  selectedLandPage: PropTypes.string, // Provided by Redux
  setLoading: PropTypes.func, // Provided by Redux
  loaded: PropTypes.bool, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  setLandPage: PropTypes.func, // Provided by Redux
};

const Land = ({
  onLoad,
  selectedLandPage,
  setLoading,
  loaded,
  selectedPeriod,
  farmId,
  classes,
  setLandPage,
}) => {
  const loadLand = useCallback(
    (selectedPeriod) => {
      setLoading(false);
      const loadPaddocks = agent.Farms.Paddocks.get(farmId, selectedPeriod);
      const loadBlocsperi = agent.Farms.Blocsperi.get(farmId, selectedPeriod);
      const loadBlocs = agent.Farms.Blocs.get(farmId, selectedPeriod);
      onLoad(
        Promise.all([loadBlocs, loadBlocsperi, loadPaddocks]).then((values) => ({
          body: values.map((item) => (item ? item.body : null)),
        }))
      );
    },
    [setLoading, onLoad, farmId]
  );

  useEffect(() => {
    loadLand(selectedPeriod);
  }, [loadLand, selectedPeriod]);

  useEffect(() => {
    if (!selectedLandPage) {
      setLandPage("blocs");
    }
  }, [setLandPage, selectedLandPage]);

  const page = (selectedLandPage) => {
    if (loaded) {
      switch (selectedLandPage) {
        case "blocs":
          return <BlocsSection />;
        case "blocsperi":
          return <BlocsperiSection />;
        case "paddocks":
          return <PaddocksSection />;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Header />
      {page(selectedLandPage)}
    </div>
  );
};

Land.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  ...state.land,
  loaded: state.land.loaded,
  farmId: state.farm.farm ? state.farm.farm.id : null,
  selectedPeriod: state.farm.selectedPeriod,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: LAND_LOADED, payload }),
  setLoading: (payload) => dispatch({ type: SET_LAND_LOADING, payload }),
  setLandPage: (payload) => dispatch({ type: SET_LAND_PAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Land));
