/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import SearchPaddockBar from "./SearchPaddockBar";
import PaddockList from "./PaddockList";
import CustomDialogTitle from "../../../CustomDialogTitle";

//UI Components
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  selectedCrop: PropTypes.string.isRequired,
  selectedPaddockId: PropTypes.string.isRequired,
  setOperations: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
};

const PaddockListModal = ({
  open,
  onClose,
  farmId,
  selectedCrop,
  selectedPaddockId,
  setOperations,
  selectedPeriod,
}) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [paddocks, setPaddocks] = useState([]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      const getPaddocks = async () => {
        const { body, error } = await agent.Farms.Paddocks.search(
          farmId,
          selectedPeriod,
          selectedCrop
        );

        if (!error) {
          setPaddocks(body.paddocks);
        }
      };

      getPaddocks();
    }
  }, [farmId, selectedCrop, searchQuery, selectedPeriod, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth='sm'
      aria-labelledby='paddock-modal-dialog-title'
      open={open}
    >
      <CustomDialogTitle id='paddock-modal-dialog-title' onClose={handleClose}>
        {t("operations.paddock-modal.title")}
      </CustomDialogTitle>
      <DialogContent dividers>
        <SearchPaddockBar
          query={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          handleClear={() => setSearchQuery("")}
          label={t("operations.paddock-modal.search-bar-label")}
        />
        <PaddockList
          setOperations={setOperations}
          farmId={farmId}
          selectedPaddockId={selectedPaddockId}
          paddocks={paddocks}
          onClose={onClose}
        ></PaddockList>
      </DialogContent>
    </Dialog>
  );
};

PaddockListModal.propTypes = propsDefinition;

export default PaddockListModal;
