/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  SELECT_GLOBAL_PERIOD,
  OPERATION_TYPES_LOADED,
  GLOBAL_PERIOD_LOADED,
  SELECT_FARM_PERIOD,
  CROPS_LOADED,
  PHS_LOADED,
  MOISTURES_LOADED,
  DRAINAGES_LOADED,
  FREQUENCIES_LOADED,
  SOIL_TYPES_LOADED,
  GLOBAL_LANGUAGE_LOADED,
  GLOBAL_TECHS_LOADED,
  GLOBAL_COVERS_LOADED,
  GLOBAL_PROGRAMS_LOADED,
  GLOBAL_SPECIES_LOADED,
  GLOBAL_OCCUPATIONS_LOADED,
  GLOBAL_DESTRUCTIONS_LOADED,
  GLOBAL_IRRIGATIONS_LOADED,
  GLOBAL_SOURCES_LOADED,
  GLOBAL_POWERS_LOADED,
  GLOBAL_CUSTOM_TYPES_LOADED,
} from "../constants/actionTypes";

const Global = (state = {}, action) => {
  switch (action.type) {
    case GLOBAL_PERIOD_LOADED:
      const periods = action.payload.periods;
      return {
        ...state,
        periods,
        selectedGlobalPeriod: state.selectedGlobalPeriod
          ? state.selectedGlobalPeriod
          : periods[periods.length - 1].id,
        lock: state.selectedGlobalPeriod ? state.lock : periods[periods.length - 1].lock,
      };
    case SELECT_GLOBAL_PERIOD:
      return {
        ...state,
        selectedGlobalPeriod: action.payload,
        lock: action.lock,
      };
    case SELECT_FARM_PERIOD:
      return {
        ...state,
        selectedGlobalPeriod: action.payload,
        lock: action.lock,
      };
    case OPERATION_TYPES_LOADED:
      return {
        ...state,
        operationTypes: action.payload,
      };
    case CROPS_LOADED:
      return {
        ...state,
        crops: action.payload,
      };
    case PHS_LOADED:
      return {
        ...state,
        phs: action.payload,
      };
    case MOISTURES_LOADED:
      return {
        ...state,
        moistures: action.payload,
      };
    case DRAINAGES_LOADED:
      return {
        ...state,
        drainages: action.payload,
      };
    case FREQUENCIES_LOADED:
      return {
        ...state,
        frequencies: action.payload,
      };
    case SOIL_TYPES_LOADED:
      return {
        ...state,
        soilTypes: action.payload,
      };
    case GLOBAL_LANGUAGE_LOADED:
      return {
        ...state,
        languages: action.payload,
      };
    case GLOBAL_TECHS_LOADED:
      return {
        ...state,
        techs: action.payload,
      };
    case GLOBAL_COVERS_LOADED:
      return {
        ...state,
        covers: action.payload,
      };
    case GLOBAL_PROGRAMS_LOADED:
      return {
        ...state,
        programs: action.payload,
      };
    case GLOBAL_SPECIES_LOADED:
      return {
        ...state,
        species: action.payload,
      };
    case GLOBAL_OCCUPATIONS_LOADED:
      return {
        ...state,
        occupations: action.payload,
      };
    case GLOBAL_DESTRUCTIONS_LOADED:
      return {
        ...state,
        destructions: action.payload,
      };
    case GLOBAL_IRRIGATIONS_LOADED:
      return {
        ...state,
        irrigations: action.payload,
      };
    case GLOBAL_SOURCES_LOADED:
      return {
        ...state,
        sources: action.payload,
      };
    case GLOBAL_POWERS_LOADED:
      return {
        ...state,
        powers: action.payload,
      };
    case GLOBAL_CUSTOM_TYPES_LOADED:
      return {
        ...state,
        customTypes: action.payload,
      };
    default:
      return state;
  }
};

export default Global;
