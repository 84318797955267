/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  FARM_MACHINERIES_LOADED,
  UPDATE_FARM_MACHINERY,
  ADD_FARM_MACHINERY,
  REMOVE_FARM_MACHINERY,
  SELECT_GLOBAL_PERIOD,
} from "../constants/actionTypes";

const FarmMachineries = (state = {}, action) => {
  switch (action.type) {
    case FARM_MACHINERIES_LOADED:
      return {
        ...state,
        farmMachineries: action.payload.operationTypes,
      };
    case SELECT_GLOBAL_PERIOD:
      return {
        ...state,
        farmMachineries: [],
      };
    case UPDATE_FARM_MACHINERY:
      return {
        ...state,
        farmMachineries: state.farmMachineries.map((operationType) => {
          if (operationType.id === action.operationId) {
            return {
              ...operationType,
              farmMachineries: operationType.farmMachineries.map((farmMachinery, index) =>
                index === action.index ? { ...action.payload } : { ...farmMachinery }
              ),
            };
          }
          return operationType;
        }),
      };
    case ADD_FARM_MACHINERY:
      return {
        ...state,
        farmMachineries: state.farmMachineries.map((operationType) => {
          if (operationType.id === action.operationId) {
            return {
              ...operationType,
              farmMachineries: [...operationType.farmMachineries, action.payload],
            };
          }
          return operationType;
        }),
      };
    case REMOVE_FARM_MACHINERY:
      return {
        ...state,
        farmMachineries: state.farmMachineries.map((operationType) => {
          if (operationType.id === action.operationId) {
            return {
              ...operationType,
              farmMachineries: operationType.farmMachineries.filter(
                (item) => item.id !== action.payload
              ),
            };
          }
          return operationType;
        }),
      };
    default:
      return state;
  }
};

export default FarmMachineries;
