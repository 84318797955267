/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
const compareChrono = (a, b) => {
  let comparison = 0;
  if (a.status >= 0 && b.status < 0) {
    comparison = -1;
  } else if (a.status < 0 && b.status >= 0) {
    comparison = 1;
  } else if (a.status < 0 && b.status < 0) {
    comparison = 0;
  } else if (a.status >= 0 && b.status >= 0) {
    comparison = 0;
  }
  return comparison;
};

const compareAlpha = () => {
  return 0;
};

const sorter = (data, filter) => {
  let arr = [...data];
  switch (filter) {
    case "chrono":
      arr.sort(compareChrono);
      break;
    case "alpha":
      arr.sort(compareAlpha);
      break;
    default:
      break;
  }
  return arr;
};

export default sorter;
