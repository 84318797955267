/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  width: PropTypes.string,

  //Provided props
  occupations: PropTypes.array, // Provided by Redux
};

const SelectOccupation = ({ value, onChange, label, occupations, disabled, width }) => {
  if (occupations) {
    return (
      <FormControl
        disabled={disabled || false}
        variant='outlined'
        size='small'
        style={{ width: width || "100px" }}
        color='secondary'
        data-woi='select-land-occupation'
      >
        <InputLabel
          style={{
            backgroundColor: "#FFF",
            paddingRight: "4px",
            paddingLeft: "4px",
            marginLeft: "-4px",
          }}
          shrink
        >
          {label}
        </InputLabel>
        <Select
          name='occupation'
          displayEmpty={true}
          value={value || ""}
          onChange={onChange}
        >
          {occupations.map((item, index) => (
            <MenuItem
              key={index}
              data-to-confirm={item.toConfirm}
              value={item.id}
              data-woi='land-occupation-item'
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectOccupation.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  occupations: state.global.occupations,
});

export default connect(mapStateToProps)(SelectOccupation);
