/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SELECTED_INPUT_TYPE } from "../../constants/actionTypes";

//Hooks
import { useTranslation } from "react-i18next";

// API
import agent from "../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  root: {
    marginBottom: "8px"
  },
  titleContainer: {
    marginBottom: "24px"
  },
  checkboxes: {
    marginLeft: "30px",
    paddingRight: "10px"
  },
  tab: {
    padding: "8px 16px 6px 16px",
    borderRadius: "16px 16px 0px 0px",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
    minWidth: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[100]
    }
  },
  disabled: {
    backgroundColor: theme.palette.grey[500],
    "&:hover": {
      color: "inherit",
      backgroundColor: theme.palette.grey[500],
      cursor: "default"
    }
  },
  barStart: {
    width: theme.spacing(4),
    backgroundColor: "inherit"
  },
  barEnd: {
    width: "100%",
    backgroundColor: "inherit"
  },
  barStyle: {
    borderRadius: "0px",
    borderColor: theme.palette.grey[500],
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0
  },
  active: {
    borderBottom: 0,
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    cursor: "default",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent"
    }
  },
  add: {
    color: theme.palette.info.dark,
    backgroundColor: "transparent",
    "&:hover": {
      color: theme.palette.info.dark
    }
  },
  title: {
    display: "flex",
    justifyContent: "center"
  }
});

const propsDefinition = {
  inputEstimated: PropTypes.number, //Provided props
  setInputEstimated: PropTypes.func, //Provided props
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  selectedInputType: PropTypes.string, // Provided by Redux
  setInputType: PropTypes.func // Provided by Redux
};

const Header = ({
  classes,
  farmId,
  selectedPeriod,
  inputEstimated,
  setInputEstimated,
  selectedInputType,
  setInputType
}) => {
  const { t } = useTranslation();

  const purchasesTypes = [
    {
      type: "seed",
      name: t("purchases.header.types.seed")
    },
    {
      type: "fertiliser",
      name: t("purchases.header.types.fertiliser")
    },
    {
      type: "pesticide",
      name: t("purchases.header.types.pesticide")
    }
  ];

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.titleContainer} item container xs={12}>
        <Grid item container xs={6} alignItems="center">
          <Typography variant="h4">{t("purchases.header.title")}</Typography>
          <Paper variant="outlined" className={classes.checkboxes}>
            <Checkbox
              size="small"
              name="inputEstimated"
              checked={inputEstimated ? true : false}
              onChange={() => {
                if (inputEstimated === 1) {
                  setInputEstimated(0);
                  agent.Farms.FarmInputsPurchases.deleteInputEstimated(
                    farmId,
                    selectedPeriod
                  );
                } else {
                  setInputEstimated(1);
                  agent.Farms.FarmInputsPurchases.postInputEstimated(
                    farmId,
                    selectedPeriod
                  );
                }
              }}
            />
            {t("purchases.header.disable-prices")}
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.root} display="flex">
          <Paper
            variant="outlined"
            className={`${classes.barStyle} ${classes.barStart}`}
          />
          {purchasesTypes.map((item, index) => (
            <Paper
              key={index}
              variant="outlined"
              onClick={() => setInputType(item.type)}
              className={`${classes.tab} ${
                item.type === selectedInputType ? classes.active : ""
              }`}
              data-woi="purchase-section"
            >
              <Typography className={classes.title} variant="body2">
                {item.name}
              </Typography>
            </Paper>
          ))}
          <Paper
            variant="outlined"
            className={`${classes.barStyle} ${classes.barEnd}`}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

Header.propTypes = propsDefinition;

const mapStateToProps = state => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  selectedInputType: state.farmInputs.selectedInputType
});

const mapDispatchToProps = dispatch => ({
  setInputType: payload => dispatch({ type: SELECTED_INPUT_TYPE, payload })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
