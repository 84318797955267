/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import fr from "./locale/fr/fr.json";
import en from "./locale/en/en.json";
import es from "./locale/es/es.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["localStorage", "sessionStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
    },
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    load: "languageOnly",
    fallbackLng: "en",
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      es: { translation: es },
    },
  });
export default i18n;
