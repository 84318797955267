/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  ADD_BLOCPERI,
  DELETE_BLOCPERI,
  UPDATE_BLOCPERI,
  UPDATE_BLOC,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import SelectBloc from "./SelectBloc";
import SelectPh from "./SelectPh";
import SelectMoisture from "./SelectMoisture";
import SelectDrainage from "./SelectDrainage";
import DeleteConfirmation from "../../DeleteConfirmation";
import SelectSoilType from "./SelectSoilType";
import ConfirmSoilType from "./ConfirmSoilType";
import ConfirmDevalidation from "./ConfirmDevalidation";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//Theme
import customTheme from "../../../theme";

//UI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Portal from "@material-ui/core/Portal";
import DeleteForever from "@material-ui/icons/DeleteForever";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  checkboxes: {
    minWidth: "140px",
    paddingRight: "9px",
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  blocperi: PropTypes.object,
  classes: PropTypes.object,
  index: PropTypes.number,

  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  addBlocperi: PropTypes.func, // Provided by Redux
  deleteBlocperi: PropTypes.func, // Provided by Redux
  updateBlocperi: PropTypes.func, // Provided by Redux
  updateBloc: PropTypes.func, // Provided by Redux
  blocs: PropTypes.array, // Provided by Redux
  periodId: PropTypes.string, // Provided by Redux
};

const BlocperiRow = ({
  blocperi = null,
  classes,
  farmId,
  periodId,
  index,
  addBlocperi,
  deleteBlocperi,
  updateBlocperi,
  updateBloc,
  blocs,
}) => {
  const [id, setId] = useState(null);
  const [bloc, setBloc] = useState({});
  const [soilType, setSoilType] = useState({});
  const [orga, setOrga] = useState(0);
  const [ph, setPh] = useState({});
  const [drainage, setDrainage] = useState({});
  const [moisture, setMoisture] = useState({});
  const [status, setStatus] = useState(0);
  const [notDeletable, setNotDeletable] = useState(false);
  const [loading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [lock, setLock] = useState(0);
  const [confirmSoilTypeOpen, setConfirmSoilTypeOpen] = useState(false);
  const [confirmDevalidationOpen, setConfirmDevalidationOpen] = useState(false);
  const { t } = useTranslation();

  const permissions = new Permissions(null, lock);

  useEffect(() => {
    if (blocperi) {
      setId(blocperi.id);
      setBloc(blocperi.bloc);
      setSoilType(blocperi.bloc.soilType);
      setOrga(blocperi.orga);
      setPh(blocperi.ph);
      setDrainage(blocperi.drainage);
      setMoisture(blocperi.moisture);
      setStatus(blocperi.status);
      setNotDeletable(!blocperi.flagDelete);
      setLock(blocperi.lock);
    }
  }, [blocperi]);

  const checkValidate = useCallback(() => {
    if (!bloc || !bloc.id) {
      setErrors((errors) => [
        ...errors,
        { field: "bloc", message: t("land.blocsperi.errors.bloc-required") },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "bloc")]);
    }
  }, [bloc, t]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed, bloc]);

  const handleCreate = () => {
    if (errors.length === 0) {
      const reqBody = {
        blocperi: {
          bloc: {
            id: bloc.id,
            soilType,
          },
          period: {
            id: periodId,
          },
          orga,
          ph,
          drainage,
          moisture,
        },
      };

      const createBlocperi = agent.Farms.Blocsperi.create(farmId, reqBody).then((res) => {
        addBlocperi(res.body.blocperi);
        let blocIndex;
        blocs.forEach((bloc, index) => {
          if (bloc.id === res.body.blocperi.bloc.id) {
            blocIndex = index;
          }
        });
        updateBloc(res.body.blocperi.bloc, blocIndex);
        setChanged(false);
        setId(null);
        setBloc({});
        setOrga(0);
        setPh({});
        setDrainage({});
        setMoisture({});
        setSoilType({});
        setStatus(0);
      });

      toast.promise(createBlocperi, {
        loading: t("global.loading"),
        success: t("land.blocsperi.notifications.blocperi-added"),
        error: t("land.blocsperi.notifications.error"),
      });
    }
  };

  const handleUpdate = useCallback(() => {
    const validate = (bloc) => {
      let error = false;

      if (!bloc || !bloc.id) {
        error = true;
      }

      return error;
    };

    if (!validate(bloc)) {
      const reqBody = {
        blocperi: {
          bloc: {
            id: bloc.id,
            soilType,
          },
          orga,
          ph,
          drainage,
          moisture,
        },
      };

      const changeBlocperi = agent.Farms.Blocsperi.update(farmId, id, reqBody).then(
        (res) => {
          updateBlocperi(res.body.blocperi, index);
          setChanged(false);
        }
      );

      toast.promise(changeBlocperi, {
        loading: t("global.loading"),
        success: t("land.blocsperi.notifications.blocperi-saved"),
        error: t("land.blocsperi.notifications.error"),
      });
    }
  }, [
    farmId,
    id,
    index,
    updateBlocperi,
    orga,
    ph,
    drainage,
    moisture,
    soilType,
    t,
    bloc,
  ]);

  useEffect(() => {
    if (changed && id && status === 0) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    } else if (changed && id && status === 3) {
      const timer = setTimeout(() => setConfirmDevalidationOpen(true), 900);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, changed, id, orga, ph, drainage, moisture, status]);

  const handleDeleteConfirm = () => {
    setDeleteOpen(true);
  };

  const handleDelete = () => {
    const removeBlocperi = agent.Farms.Blocsperi.delete(farmId, id).then(() => {
      deleteBlocperi(index);
    });

    toast.promise(removeBlocperi, {
      loading: t("global.loading"),
      success: t("land.blocsperi.notifications.blocperi-deleted"),
      error: t("land.blocsperi.notifications.error"),
    });
  };

  const actionType =
    !id && permissions.canEdit() ? (
      <Grid item>
        <Button
          disabled={loading}
          fullWidth={true}
          onClick={handleCreate}
          data-woi='add-blocperi'
        >
          {t("land.blocsperi.add-button-label")}
        </Button>
      </Grid>
    ) : permissions.canEdit() ? (
      <Tooltip
        title={
          (notDeletable ? true : false) ? (
            <Typography>{t("land.blocsperi.not-deletable-message")}</Typography>
          ) : (
            ""
          )
        }
      >
        <div>
          <IconButton
            disabled={notDeletable ? true : false}
            onClick={handleDeleteConfirm}
            data-woi='delete-blocperi-button'
          >
            <DeleteForever color={(notDeletable ? true : false) ? "disabled" : "error"} />
          </IconButton>
        </div>
      </Tooltip>
    ) : null;

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item data-woi='select-bloc'>
        <SelectBloc
          name='bloc'
          disabled={id ? true : false}
          onChange={(e, bloc) => {
            if (bloc && bloc.id) {
              setBloc(bloc);
              if (bloc.soilType.id) {
                setSoilType(bloc.soilType);
              }
            } else {
              setBloc({});
            }
            setChanged(true);
          }}
          bloc={bloc}
          label={t("land.blocsperi.bloc")}
          errors={errors}
        />
      </Grid>
      <Grid item style={{ paddingTop: "9px" }}>
        {id && status === 0 ? (
          <Tooltip title={t("land.blocsperi.validation.confirm")} arrow>
            <div>
              <IconButton size='small' disabled>
                <ErrorIcon style={{ color: customTheme.palette.error.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : id && status === 3 ? (
          <Tooltip title={t("land.blocsperi.validation.confirmed")} arrow>
            <div>
              <IconButton disabled size='small'>
                <CheckCircleIcon style={{ color: customTheme.palette.success.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : !id && status === 0 ? (
          <Tooltip title={t("land.blocsperi.validation.to-add")} arrow>
            <div>
              <IconButton disabled size='small'>
                <AddCircleIcon style={{ color: customTheme.palette.success.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          ""
        )}
      </Grid>
      <Grid item data-woi='organic-matter'>
        <FieldWithTextAdornment
          format={/^(?=.*[0-9])\d{0,2}(?:[,.]\d{0,1})?$/}
          disabled={!permissions.canEdit()}
          style={{ width: "130px" }}
          color='secondary'
          label={t("land.blocsperi.organic-matter-rate")}
          value={orga}
          onChange={(e) => {
            setOrga(e.target.value);
            setChanged(true);
          }}
          error={errors.filter((item) => item.field === "orga").length > 0}
          helperText={
            errors.filter((item) => item.field === "orga").length > 0
              ? errors.filter((item) => item.field === "orga")[0].message
              : ""
          }
          onFocus={(event) => event.target.select()}
          InputProps={{
            inputProps: { min: 0, max: 100 },
            endAdornment: (
              <InputAdornment position='end'>
                <Box color='secondary.main'>%</Box>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item data-woi='select-ph'>
        <SelectPh
          disabled={!permissions.canEdit()}
          name='ph'
          onChange={(e, ph) => {
            setChanged(true);
            setPh(ph);
          }}
          ph={ph}
          label={t("land.blocsperi.ph")}
          errors={errors}
          width='150px'
        />
      </Grid>
      <Grid item data-woi='select-moisture'>
        <SelectMoisture
          disabled={!permissions.canEdit()}
          name='moisture'
          onChange={(e, moisture) => {
            setChanged(true);
            setMoisture(moisture);
          }}
          moisture={moisture}
          label={t("land.blocsperi.moisture")}
          errors={errors}
          width='125px'
        />
      </Grid>
      <Grid item data-woi='select-drainage'>
        <SelectDrainage
          disabled={!permissions.canEdit()}
          name='drainage'
          onChange={(e, drainage) => {
            setChanged(true);
            setDrainage(drainage);
          }}
          drainage={drainage}
          label={t("land.blocsperi.drainage")}
          errors={errors}
          width='125px'
        />
      </Grid>
      <Grid item data-woi='select-soil-type'>
        <Tooltip title={id !== null ? t("land.blocsperi.soil-type-cannot-change") : ""}>
          <div>
            <SelectSoilType
              disabled={Boolean(
                !permissions.canEdit() || (bloc && bloc.soilType && bloc.soilType.id)
              )}
              name='soilType'
              onChange={(e, soilType) => {
                setSoilType(soilType);
                setConfirmSoilTypeOpen(true);
              }}
              soilType={soilType}
              label={t("land.blocs.soil-type")}
              errors={errors}
              width='180px'
            />
          </div>
        </Tooltip>
      </Grid>
      {actionType}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
      <Portal>
        <ConfirmSoilType
          open={confirmSoilTypeOpen}
          onCancel={() => {
            setConfirmSoilTypeOpen(false);
            setSoilType({});
          }}
          onConfirm={() => {
            setConfirmSoilTypeOpen(false);
            setChanged(true);
          }}
        />
      </Portal>
      <Portal>
        <ConfirmDevalidation
          open={confirmDevalidationOpen}
          onCancel={() => {
            setConfirmDevalidationOpen(false);
            setSoilType(blocperi.bloc.soilType);
            setOrga(blocperi.orga);
            setPh(blocperi.ph);
            setDrainage(blocperi.drainage);
            setMoisture(blocperi.moisture);
            setChanged(false);
          }}
          onConfirm={() => {
            setConfirmDevalidationOpen(false);
            handleUpdate();
          }}
        />
      </Portal>
    </Grid>
  );
};

BlocperiRow.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  periodId: state.farm.selectedPeriod,
  blocs: state.land.blocs,
});

const mapDispatchToProps = (dispatch) => ({
  addBlocperi: (payload) => dispatch({ type: ADD_BLOCPERI, payload }),
  updateBlocperi: (payload, index) => dispatch({ type: UPDATE_BLOCPERI, payload, index }),
  deleteBlocperi: (index) => dispatch({ type: DELETE_BLOCPERI, index }),
  updateBloc: (payload, index) => dispatch({ type: UPDATE_BLOC, payload, index }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlocperiRow));
