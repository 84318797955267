/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import { store } from "../store";
import dataStatuses from "../constants/dataStatuses";
import profiles from "../constants/profiles";
import lockStatuses from "../constants/lockStatuses";

/*
 ** Permission Class for managing permission access in app.
 **
 ** Edit required params Premissions(dataStatus, lockStatus)
 ** Data statuses and lockStatuses are provided from backend API's.
 **
 ** View required params Permissions(null, null, requiredProfiles)
 ** Profile import contains the profiles enumeration. Import it in required components.
 */
class Permissions {
  constructor(dataStatus = null, lockStatus = null, requiredProfiles = null) {
    this.currentProfile = profiles[store.getState().common.access];

    this.dataStatus = dataStatus;
    this.lockStatus = lockStatus;
    this.requiredProfiles = requiredProfiles;
    this.dataStatuses = dataStatuses;
    this.profiles = profiles;
    this.lockStatuses = lockStatuses;
  }

  isAdmin = (currentProfile) => {
    if (this.currentProfile === profiles._ADM || currentProfile === profiles._ADM) {
      return true;
    }
    return false;
  };

  // private
  isEditProfile = (currentProfile) => {
    switch (currentProfile) {
      case profiles._ADM:
      case profiles._AGRS:
      case profiles._AGR:
        return true;
      case profiles._ENC:
      case profiles._AUD:
        return false;
      default:
        return false;
    }
  };

  // private
  isCurrentProfile = (requiredProfiles) => {
    return requiredProfiles.some((profile) => profile === this.currentProfile);
  };

  // private
  isViewProfile = (requiredProfiles) => {
    if (requiredProfiles !== null) {
      if (this.isCurrentProfile(requiredProfiles)) {
        return true;
      }

      return false;
    }

    return true;
  };

  // private
  isViewProfileForStatus = (dataStatus) => {
    switch (dataStatus) {
      case dataStatuses.AGRO_VALIDATED:
        return this.isViewProfile([profiles._ADM, profiles._AGRS, profiles._AGR]);
      default:
        return this.isViewProfile(null);
    }
  };

  // Returns edit access boolean
  canEdit = () => {
    if (this.lockStatus !== lockStatuses.LOCKED) {
      if (
        this.isCurrentProfile([
          profiles._ADM,
          profiles._AGRS,
          profiles._AGR,
          profiles._ENC,
        ]) &&
        (this.dataStatus === null || this.dataStatus !== dataStatuses.AGRO_VALIDATED)
      ) {
        return true;
      }
      return this.isEditProfile(this.currentProfile);
    }
    return false;
  };

  // Return verified access boolean
  canVerify = () => {
    if (
      this.dataStatus !== null &&
      this.dataStatus === dataStatuses.AGRO_VALIDATED
    ) {
      return false;
    }
    return true;
  };

  // Returns view access boolean
  canView = () => {
    if (this.dataStatus !== null && this.requiredProfiles === null) {
      return this.isViewProfileForStatus(this.dataStatus);
    }
    return this.isViewProfile(this.requiredProfiles);
  };
}

export default Permissions;
