/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SELECT_PADDOCK, SELECT_BAR, PADDOCKS_LOADED } from "../../constants/actionTypes";

//API
import agent from "../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import BioLogo from "../BioLogo";

//UI Components
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  tab: {
    padding: "8px 16px 6px 16px",
    borderRadius: "16px 16px 0px 0px",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
    textAlign: "center",
    maxWidth: "150px",
    maxHeight: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
  tabStatic: {
    padding: "8px 16px 6px 16px",
    borderRadius: "16px 16px 0px 0px",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
    minWidth: "110px",
    textAlign: "center",
    maxWidth: "150px",
    maxHeight: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[500],
    "&:hover": {
      color: "inherit",
      backgroundColor: theme.palette.grey[500],
      cursor: "default",
    },
  },
  barStart: {
    width: theme.spacing(4),
    backgroundColor: "inherit",
  },
  barEnd: {
    width: "100%",
    backgroundColor: "inherit",
  },
  barStyle: {
    borderRadius: "0px",
    borderColor: theme.palette.grey[500],
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  active: {
    borderBottom: 0,
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    cursor: "default",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent",
    },
  },
  add: {
    color: theme.palette.info.dark,
    backgroundColor: "transparent",
    "&:hover": {
      color: theme.palette.info.dark,
    },
  },
  title: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
});

const propsDefinition = {
  //Provided props
  classes: PropTypes.object, // Provided by Matrial Ui Styles
  paddocks: PropTypes.array, // Provided by Redux
  selectedPaddock: PropTypes.object, // Provided by Redux
  selectedOperation: PropTypes.string, // Provided by Redux
  selectedBar: PropTypes.string, // Provided by Redux
  setSelectedBar: PropTypes.func, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  setSelectedPaddock: PropTypes.func,
  farmId: PropTypes.string, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  selectedCrop: PropTypes.string, // Provided by Redux
};

const NavigationBar = ({
  classes,
  paddocks,
  selectedPaddock,
  selectedOperation,
  selectedBar,
  setSelectedBar,
  onLoad,
  setSelectedPaddock,
  farmId,
  selectedPeriod,
  selectedCrop,
}) => {
  const { t } = useTranslation();

  const getPaddock = useCallback(
    (selectedPeriod, selectedCrop) => {
      onLoad(agent.Farms.Paddocks.search(farmId, selectedPeriod, selectedCrop));
    },
    [onLoad, farmId]
  );

  useEffect(() => {
    getPaddock(selectedPeriod, selectedCrop);
  }, [getPaddock, selectedPeriod, selectedCrop]);

  const handleTabClick = (newValue) => {
    setSelectedBar("paddock");
    setSelectedPaddock(newValue);
  };

  if (paddocks) {
    return (
      <Box className={classes.root} display='flex' data-woi='crop-navigation-bar'>
        <Paper variant='outlined' className={`${classes.barStyle} ${classes.barStart}`} />
        <Paper
          id='cropsTechs'
          variant='outlined'
          onClick={() =>
            !Boolean(selectedOperation) ? setSelectedBar("cropsTechs") : null
          }
          className={`${classes.tabStatic} ${
            "cropsTechs" === selectedBar ? classes.active : ""
          } ${Boolean(selectedOperation) ? classes.disabled : ""}`}
        >
          {t("operations.paddock.paddock-crops-techs")}
        </Paper>
        <Paper
          id='cropsResidues'
          variant='outlined'
          onClick={() =>
            !Boolean(selectedOperation) ? setSelectedBar("cropsResidues") : null
          }
          className={`${classes.tabStatic} ${
            "cropsResidues" === selectedBar ? classes.active : ""
          } ${Boolean(selectedOperation) ? classes.disabled : ""}`}
        >
          {t("operations.paddock.paddock-crops-residues")}
        </Paper>
        <Paper
          id='cropsIrrigation'
          variant='outlined'
          onClick={() =>
            !Boolean(selectedOperation) ? setSelectedBar("cropsIrrigation") : null
          }
          className={`${classes.tabStatic} ${
            "cropsIrrigation" === selectedBar ? classes.active : ""
          } ${Boolean(selectedOperation) ? classes.disabled : ""}`}
        >
          {t("operations.paddock.paddock-crops-irrigation")}
        </Paper>
        <Paper
          id='cropsOrganics'
          variant='outlined'
          onClick={() =>
            !Boolean(selectedOperation) ? setSelectedBar("cropsOrganics") : null
          }
          className={`${classes.tabStatic} ${
            "cropsOrganics" === selectedBar ? classes.active : ""
          } ${Boolean(selectedOperation) ? classes.disabled : ""}`}
        >
          {t("operations.paddock.paddock-crops-organics")}
        </Paper>
        {paddocks.map((item, index) => (
          <Tooltip
            key={index}
            title={
              item.blocperi.bloc.name +
              (item.isOrganic ? ` ${t("operations.paddock.paddock-organic")}` : "")
            }
          >
            <Paper
              variant='outlined'
              onClick={() =>
                !Boolean(selectedOperation) &&
                (!selectedPaddock || item.id !== selectedPaddock.id)
                  ? handleTabClick(item.id)
                  : null
              }
              className={`${classes.tab} ${
                selectedPaddock &&
                item.id === selectedPaddock.id &&
                selectedBar === "paddock"
                  ? classes.active
                  : ""
              } ${Boolean(selectedOperation) ? classes.disabled : ""}`}
              data-woi='crop-operations'
            >
              {item.status === 3 ? (
                <Tooltip title={t("operations.paddock.paddock-valid")}>
                  <VerifiedUserIcon className={classes.valid} />
                </Tooltip>
              ) : (
                <Tooltip title={t("operations.paddock.paddock-not-valid")}>
                  <ErrorIcon className={classes.invalid} />
                </Tooltip>
              )}
              <Typography className={classes.title} variant='body2'>
                {item.blocperi.bloc.name}
              </Typography>
              {item.isOrganic ? (
                <BioLogo height='18px' style={{ marginLeft: "8px" }} />
              ) : (
                ""
              )}
            </Paper>
          </Tooltip>
        ))}
        <Paper variant='outlined' className={`${classes.barStyle} ${classes.barEnd}`} />
      </Box>
    );
  }
  return null;
};

NavigationBar.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  paddocks: state.crop.paddocks,
  selectedPaddock: state.crop.selectedPaddock,
  selectedCrop: state.crop.selectedCrop,
  selectedBar: state.crop.selectedBar,
  farmId: state.farm.farm && state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedPaddock: (payload) =>
    dispatch({
      type: SELECT_PADDOCK,
      payload,
    }),
  setSelectedBar: (payload) => {
    dispatch({ type: SELECT_BAR, payload });
  },
  onLoad: (payload) =>
    dispatch({
      type: PADDOCKS_LOADED,
      payload,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NavigationBar));
