/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { GLOBAL_OCCUPATIONS_LOADED } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import ConfirmOccupation from "./ConfirmOccupation";
import SelectOccupation from "./SelectOccupation";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//Theme
import customTheme from "../../../theme";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Portal from "@material-ui/core/Portal";

const style = (theme) => ({
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  onOccupationsLoaded: PropTypes.func, // Provided by Redux
};

const LandOccupationSection = ({
  classes,
  farmId,
  selectedPeriod,
  onOccupationsLoaded,
}) => {
  const { t } = useTranslation();

  const [id, setId] = useState(null);
  const [status, setStatus] = useState(0);
  const [changedOccupation, setChangedOccupation] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [passedYears, setPassedYears] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmValue, setConfirmValue] = useState(null);
  const [maxYears, setMaxYears] = useState(0);
  const [lock, setLock] = useState(0);

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  const checkValidate = useCallback(() => {
    if (occupation && !occupation.id) {
      setErrors((errors) => [
        ...errors,
        {
          field: "occupation",
          message: t("land-use.section.errors.required-occupation"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "occupation")]);
    }
    if (!(passedYears > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "passedYears",
          message: t("land-use.section.errors.required-years"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "passedYears")]);
    }
    if (!(quantity > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "quantity",
          message: t("land-use.section.errors.required-area"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "quantity")]);
    }
    if (!changedOccupation) {
      setErrors([]);
    }
  }, [t, occupation, passedYears, quantity, changedOccupation]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [checkValidate, changed]);

  const handleUpdate = useCallback(() => {
    const validate = (occupation, passedYears, quantity) => {
      let error = false;
      if (changedOccupation === 1) {
        if (occupation && !occupation.id) {
          error = true;
        }
        if (!(passedYears > 0)) {
          error = true;
        }
        if (!(quantity > 0)) {
          error = true;
        }
      } else if (changedOccupation === null) {
        error = true;
      }
      return error;
    };

    if (!validate(occupation, passedYears, quantity)) {
      const reqBody = {
        landOccupation: {
          status,
          changedOccupation,
          occupation,
          passedYears,
          quantity,
        },
      };

      const updateLandOccupation = agent.Farms.LandOccupation.update(
        farmId,
        id,
        reqBody
      ).then((res) => {
        const landOccupation = res.body.landOccupation;
        setId(landOccupation.id);
        setStatus(landOccupation.status);
        setChangedOccupation(landOccupation.changedOccupation);
        setOccupation(landOccupation.occupation);
        setPassedYears(landOccupation.passedYears);
        setMaxYears(landOccupation.maxYears);
        setQuantity(landOccupation.quantity);
        setChanged(false);
      });

      toast.promise(updateLandOccupation, {
        loading: t("global.loading"),
        success: t("land-use.section.notifications.occupation-saved"),
        error: t("land-use.section.notifications.error"),
      });
    }
  }, [t, farmId, id, occupation, passedYears, quantity, status, changedOccupation]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdate, id, changed]);

  useEffect(() => {
    let mounted = true;
    const getLandOccupation = async () => {
      const { body, error } = await agent.Farms.LandOccupation.get(
        farmId,
        selectedPeriod
      );

      if (!error && mounted) {
        const landOccupation = body.landOccupation;
        setId(landOccupation.id);
        setStatus(landOccupation.status);
        setChangedOccupation(landOccupation.changedOccupation);
        setOccupation(landOccupation.occupation);
        setPassedYears(landOccupation.passedYears);
        setMaxYears(landOccupation.maxYears);
        setQuantity(landOccupation.quantity);
        setLock(landOccupation.lock);
      }
    };
    if (selectedPeriod) {
      getLandOccupation();
    }
    return () => (mounted = false);
  }, [farmId, selectedPeriod]);

  useEffect(() => {
    const getOccupationsLoaded = async () => {
      const { body, error } = await agent.Occupations.get();

      if (!error) {
        onOccupationsLoaded(body.occupations);
      }
    };
    getOccupationsLoaded();
  }, [onOccupationsLoaded]);

  if (id) {
    return (
      <div>
        <Paper className={classes.outlinedContainer} variant='outlined'>
          <Grid container spacing={1}>
            <Grid item container justify='space-between'>
              <Grid container item alignItems='center' xs={6} data-woi='test-1'>
                <Typography className={classes.typoMarginRight} variant='h4'>
                  {t("land-use.section.title")}
                </Typography>
                {status === 3 ? (
                  <Tooltip title={t("land-use.section.valid")}>
                    <VerifiedUserIcon className={classes.valid} />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("land-use.section.invalid")}>
                    <ErrorIcon className={classes.invalid} />
                  </Tooltip>
                )}
              </Grid>
              <Grid item data-woi='test-2'>
                {permissions.canEdit() && permissions.canView() && status !== 3 && (
                  <Button
                    disabled={
                      changedOccupation === null ||
                      (changedOccupation === 0
                        ? false
                        : (occupation && !occupation.id) ||
                          !(passedYears > 0) ||
                          !(quantity > 0))
                    }
                    style={{ height: "40px" }}
                    className={classes.validateButton}
                    onClick={() => {
                      setStatus(3);
                      setChanged(true);
                    }}
                    data-woi='confirm-occupation-button'
                  >
                    <VerifiedUserIcon style={{ marginRight: "5px" }} />
                    {t("land-use.section.validate-button-label")}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container item alignItems='center'>
              <Typography>{t("land-use.section.boolean-subtitle")}</Typography>
              <Grid item xs={12} data-woi='test-3'>
                <FormControl component='fieldset' disabled={!permissions.canEdit()}>
                  <RadioGroup
                    size='small'
                    row
                    name='position'
                    style={{ display: "flex", alignItems: "center" }}
                    onChange={(e) => {
                      setChangedOccupation(parseInt(e.target.value));
                      setChanged(true);
                    }}
                    value={
                      changedOccupation !== null ? changedOccupation.toString() : null
                    }
                  >
                    <FormControlLabel
                      value='0'
                      style={
                        changedOccupation === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      control={
                        <Radio
                          style={
                            changedOccupation === null
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                          size='small'
                          color='secondary'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t("land-use.section.no")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value='1'
                      style={
                        changedOccupation === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      control={
                        <Radio
                          style={
                            changedOccupation === null
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                          size='small'
                          color='secondary'
                          data-woi='check-occupation-changed'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t("land-use.section.yes")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  {changedOccupation === null && (
                    <Typography
                      variant='caption'
                      style={{ color: customTheme.palette.error.main }}
                    >
                      {t("land-use.section.errors.required-choice")}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {changedOccupation === 1 && (
              <>
                <Grid className={classes.underline} item />
                <Grid item>
                  <SelectOccupation
                    disabled={!permissions.canEdit() || !Boolean(changedOccupation)}
                    value={occupation ? occupation.id : null}
                    onChange={(e) => {
                      const { toConfirm } = e.currentTarget.dataset;
                      if (parseInt(toConfirm)) {
                        setConfirmValue({ id: e.target.value });
                        setConfirmOpen(true);
                      } else {
                        setOccupation({ id: e.target.value });
                        setChanged(true);
                      }
                    }}
                    name='occupation'
                    errors={errors}
                    label={t("land-use.section.changed-select")}
                    width='400px'
                  />
                </Grid>
                <Grid container item alignItems='center' data-woi='land-occupation-years'>
                  <Typography className={classes.typoMarginRight}>
                    {t("land-use.section.over-time-label")}
                  </Typography>
                  <FieldWithTextAdornment
                    disabled={!permissions.canEdit() || !Boolean(changedOccupation)}
                    name='passedYears'
                    format={/^[0-9][0-9]?[0-9]?$/}
                    value={passedYears}
                    onChange={(e) => {
                      setPassedYears(
                        e.target.value <= maxYears ? e.target.value : maxYears
                      );
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t("land-use.section.years")}
                    width='130px'
                  />
                </Grid>
                <Grid
                  container
                  item
                  alignItems='center'
                  data-woi='land-occupation-percent'
                >
                  <Typography className={classes.typoMarginRight}>
                    {t("land-use.section.before-%")}
                  </Typography>
                  <FieldWithTextAdornment
                    disabled={!permissions.canEdit() || !Boolean(changedOccupation)}
                    name='quantity'
                    format={/^[0-9][0-9]?$|^100$/}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                      setChanged(true);
                    }}
                    errors={errors}
                    onFocus={(event) => event.target.select()}
                    adornment={t("land-use.section.area-adornment")}
                    width='100px'
                  />
                  <Typography className={classes.typoMarginLeft}>
                    {t("land-use.section.%-area-label")}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
        <Portal>
          <ConfirmOccupation
            onCancel={() => {
              setConfirmValue(null);
              setConfirmOpen(false);
            }}
            onConfirm={() => {
              setOccupation(confirmValue);
              setConfirmValue(null);
              setConfirmOpen(false);
              setChanged(true);
            }}
            open={confirmOpen}
            loading={false}
          />
        </Portal>
      </div>
    );
  }
  return null;
};

LandOccupationSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
});

const mapDispatchToProps = (dispatch) => ({
  onOccupationsLoaded: (payload) =>
    dispatch({ type: GLOBAL_OCCUPATIONS_LOADED, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(LandOccupationSection));
