/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { LOGOUT } from "../constants/actionTypes";

//API
import agent from "../agent";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import SelectLanguage from "./SelectLanguage";
import Logo from "./Logo";

//UI Components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    height: "45px",
  },
  logo: {
    height: "60px",
  },
  marginRight: {
    marginRight: "8px",
  },
  logout: {
    color: theme.palette.error.main,
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  logout: PropTypes.func.isRequired,

  //Provided props
  farm: PropTypes.object, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
};

const Header = ({ classes, farm, logout }) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]);
  const { t, i18n } = useTranslation();

  const generateCrumbs = useCallback(
    (routeArr, farm) => {
      if (farm) {
        let uri = "";
        return routeArr.map((item, index) => {
          let label = t("header.home");
          switch (index) {
            case 0:
              label = null;
              break;
            case 1:
              uri = "/";
              break;
            case 2:
              if (item === "new") {
                label = t("header.new-profile");
              } else if (item >= 0) {
                label = `${farm.lastName} ${farm.firstName}`;
              }
              uri = uri + "farm/" + item + "/dashboard";
              break;
            case 3:
              if (item === "crop") {
                label = t("header.crop");
              } else if (item === "equipments") {
                label = t("header.equipments");
              } else if (item === "purchases") {
                label = t("header.purchases");
              } else if (item === "land") {
                label = t("header.land");
              } else if (item === "program") {
                label = t("header.program");
              } else if (item === "hedgerows") {
                label = t("header.hedgerows");
              } else if (item === "occupation") {
                label = t("header.occupation");
              } else if (item === "dashboard") {
                label = null;
              }
              uri = uri + "/" + item;
              break;
            default:
              break;
          }
          if (
            index < routeArr.length - 1 &&
            index >= 1 &&
            label &&
            routeArr[index + 1] !== "dashboard"
          ) {
            return (
              <Button
                key={index}
                variant='text'
                color='inherit'
                component={RouterLink}
                to={uri}
              >
                {label}
              </Button>
            );
          } else if (label) {
            return (
              <Typography key={index} color='secondary'>
                {label}
              </Typography>
            );
          }
          return null;
        });
      }
    },
    [t]
  );

  useEffect(() => {
    const routeArr = location.pathname.split("/");
    const crumbs = generateCrumbs(routeArr, farm);
    setCrumbs(crumbs);
  }, [location, farm, generateCrumbs]);

  const handleLogout = async () => {
    const { error } = await agent.Auth.logout();

    if (!error) {
      logout();
      i18n.changeLanguage(navigator.language);
    }
  };

  if (
    location.pathname.split("/")[1] === "farm" &&
    location.pathname.split("/")[2] !== "new"
      ? farm && location.pathname.split("/")[2] === farm.id
      : true
  ) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Logo className={classes.logo} />
        </Grid>
        <Grid
          className={classes.root}
          container
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={9}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize='small' />}
              aria-label='breadcrumb'
            >
              {crumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item container alignItems='center' justify='flex-end' xs={3}>
            <SelectLanguage className={classes.marginRight} />
            <Button
              name='logout'
              variant='text'
              color='default'
              onClick={handleLogout}
              className={classes.logout}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
};

Header.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  farm: state.farm.farm,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
