/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { LOGIN } from "../../constants/actionTypes";

//API
import agent from "../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import Logo from "../Logo";
import LoginForm from "./LoginForm";

//UI Components
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  root: {
    width: 280,
    padding: theme.spacing(2),
  },
  logo: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "24px",
    display: "block",
  },
  form: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
});

const propsDefinition = {
  //Provided props
  currentUser: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  onSubmit: PropTypes.func, // Provided by Redux
};

const Login = ({ classes, currentUser, onSubmit }) => {
  const submitForm = ({ username, password }) => {
    onSubmit(username, password);
  };

  if (currentUser) {
    return <Redirect to='/' />;
  }
  return (
    <Box display='flex' height='100vh' justifyContent='center' alignItems='center'>
      <Paper data-test='loginContainer' className={classes.root} elevation={6}>
        <Logo data-test='logo' className={classes.logo} />
        <LoginForm data-test='loginForm' onSubmit={submitForm} />
      </Paper>
    </Box>
  );
};

Login.propTypes = propsDefinition;

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (username, password) =>
    dispatch({
      type: LOGIN,
      payload: agent.Auth.login(username, password),
    }),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.common.currentUser,
  };
};

export { Login, styles };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
