import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { makeStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../../../utils/Permissions";
import profiles from "../../../../../constants/profiles";

//Custom Components
import SelectTech from "./SelectTech";
import FieldWithTextAdornment from "../../../../FieldWithTextAdornment";

//Theme
import customTheme from "../../../../../theme";

//UI Components
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles((theme) => ({
  mainOutlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  blocTitle: {
    marginBottom: theme.spacing(1),
  },
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  cropsTechsId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  farmId: PropTypes.string,
  lock: PropTypes.number,
};

function TillageSection({ cropsTechsId, data, farmId, lock }) {
  const classes = useStyle();
  const { t } = useTranslation();

  const [status, setStatus] = useState(0);
  const [changedTechs, setChangedTechs] = useState(null);
  const [tech, setTech] = useState(null);
  const [passedYears, setPassedYears] = useState(0);
  const [amount, setAmount] = useState(0);
  const [maxYears, setMaxYears] = useState(0);

  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const permissions = new Permissions(status, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
      setChangedTechs(data.changedTechs);
      setTech(data.tech);
      setPassedYears(data.passedYears);
      setAmount(data.amount);
      setMaxYears(data.maxYears);
    }
  }, [data]);

  const checkValidateSoilWork = useCallback(() => {
    if (tech && !tech.id) {
      setErrors((errors) => [
        ...errors,
        {
          field: "tech",
          message: t("operations.crops-techs.soil-work.errors.required-tech"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "tech")]);
    }
    if (!(passedYears > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "passedYears",
          message: t("operations.crops-techs.soil-work.errors.required-years"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "passedYears")]);
    }
    if (!(amount > 0)) {
      setErrors((errors) => [
        ...errors,
        {
          field: "amount",
          message: t("operations.crops-techs.soil-work.errors.required-amount"),
        },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((item) => item.field !== "amount")]);
    }
    if (!(changedTechs === 1)) {
      setErrors([]);
    }
  }, [t, tech, passedYears, amount, changedTechs]);

  useEffect(() => {
    if (changed) {
      checkValidateSoilWork();
    }
  }, [checkValidateSoilWork, changed]);

  const handleUpdateSoilWork = useCallback(async () => {
    const validate = (tech, passedYears, amount, changedTechs) => {
      let error = false;
      if (changedTechs === 1) {
        if (tech && !tech.id) {
          error = true;
        }
        if (!(passedYears > 0)) {
          error = true;
        }
        if (!(amount > 0)) {
          error = true;
        }
      } else if (changedTechs === null) {
        error = true;
      }
      return error;
    };

    if (!validate(tech, passedYears, amount, changedTechs)) {
      const reqBody = {
        cropsTechs: {
          soilWork: {
            status,
            changedTechs,
            tech,
            passedYears,
            amount,
          },
        },
      };

      const updateCropTechs = agent.Farms.CropsTechs.update(
        farmId,
        cropsTechsId,
        reqBody
      ).then((res) => {
        const soilWork = res.body.cropsTechs.soilWork;
        setStatus(soilWork.status);
        setChangedTechs(soilWork.changedTechs);
        setTech(soilWork.tech);
        setPassedYears(soilWork.passedYears);
        setAmount(soilWork.amount);
        setMaxYears(soilWork.maxYears);
        setChanged(false);
      });

      toast.promise(updateCropTechs, {
        loading: t("global.loading"),
        success: t("operations.crops-techs.soil-work.notifications.soil-work-saved"),
        error: t("operations.crops-techs.soil-work.notifications.error"),
      });
    }
  }, [t, farmId, cropsTechsId, tech, passedYears, amount, changedTechs, status]);

  useEffect(() => {
    if (changed && cropsTechsId) {
      const timer = setTimeout(() => handleUpdateSoilWork(), 700);
      return () => clearTimeout(timer);
    }
  }, [handleUpdateSoilWork, cropsTechsId, changed]);

  return (
    <Paper className={classes.outlinedContainer} variant='outlined'>
      <Grid container spacing={1}>
        <Grid container item justify='space-between'>
          <Grid item container alignItems='center' xs={9}>
            <Typography className={classes.typoMarginRight} variant='h4'>
              {t("operations.crops-techs.soil-work.title")}
            </Typography>
            {status === 3 ? (
              <Tooltip title={t("operations.crops-techs.soil-work.valid")}>
                <VerifiedUserIcon className={classes.valid} />
              </Tooltip>
            ) : (
              permissions.canView() && (
                <Tooltip title={t("operations.crops-techs.soil-work.invalid")}>
                  <ErrorIcon className={classes.invalid} />
                </Tooltip>
              )
            )}
          </Grid>
          {permissions.canEdit() && permissions.canView() && status !== 3 && (
            <Grid item xs={3}>
              <Button
                disabled={
                  changedTechs <= 0
                    ? false
                    : changedTechs === null ||
                      (tech && !tech.id) ||
                      !(passedYears > 0) ||
                      !(amount > 0)
                }
                style={{ height: "40px" }}
                className={classes.validateButton}
                onClick={() => {
                  setStatus(3);
                  setChanged(true);
                }}
                data-woi='validate-tillage-button'
              >
                <VerifiedUserIcon style={{ marginRight: "5px" }} />
                {t("operations.crops-techs.soil-work.validate-button-label")}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item container alignItems='center'>
          <Typography>
            {t("operations.crops-techs.soil-work.boolean-subtitle")}
          </Typography>
          <Grid item xs={12}>
            <FormControl component='fieldset' disabled={!permissions.canEdit()}>
              <RadioGroup
                size='small'
                row
                name='position'
                onChange={(e) => {
                  setChangedTechs(parseInt(e.target.value));
                  setChanged(true);
                }}
                value={changedTechs !== null ? changedTechs.toString() : null}
              >
                <FormControlLabel
                  value='0'
                  style={
                    changedTechs === null
                      ? { color: customTheme.palette.error.main, width: "100%" }
                      : { width: "100%" }
                  }
                  control={
                    <Radio
                      style={
                        changedTechs === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      size='small'
                      color='secondary'
                      data-woi='crop-always-laboured'
                    />
                  }
                  label={
                    <Typography variant='subtitle2'>
                      {t("operations.crops-techs.soil-work.no")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value='-1'
                  style={
                    changedTechs === null
                      ? { color: customTheme.palette.error.main, width: "100%" }
                      : { width: "100%" }
                  }
                  control={
                    <Radio
                      style={
                        changedTechs === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      size='small'
                      color='secondary'
                      data-woi='crop-never-laboured'
                    />
                  }
                  label={
                    <Typography variant='subtitle2'>
                      {t("operations.crops-techs.soil-work.never")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value='1'
                  style={
                    changedTechs === null
                      ? { color: customTheme.palette.error.main, width: "100%" }
                      : { width: "100%" }
                  }
                  control={
                    <Radio
                      style={
                        changedTechs === null
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      size='small'
                      color='secondary'
                      data-woi='crop-tillage-changed'
                    />
                  }
                  label={
                    <Typography variant='subtitle2'>
                      {t("operations.crops-techs.soil-work.yes")}
                    </Typography>
                  }
                />
              </RadioGroup>
              {changedTechs === null && (
                <Typography
                  variant='caption'
                  style={{ color: customTheme.palette.error.main }}
                >
                  {t("operations.crops-techs.soil-work.errors.required-choice")}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {changedTechs === 1 && (
          <>
            <div className={classes.underline} />
            <Grid item>
              <SelectTech
                disabled={!permissions.canEdit() || !(changedTechs === 1)}
                value={tech && tech.id ? tech.id : ""}
                name='tech'
                onChange={(e) => {
                  setTech({ id: e.target.value });
                  setChanged(true);
                }}
                errors={errors}
                label={t("operations.crops-techs.soil-work.changed-select")}
                width='400px'
              />
            </Grid>
            <Grid container item alignItems='center' data-woi='tillage-years'>
              <Typography className={classes.typoMarginRight}>
                {t("operations.crops-techs.soil-work.over-time-label")}
              </Typography>
              <FieldWithTextAdornment
                disabled={!permissions.canEdit() || !(changedTechs === 1)}
                name='passedYears'
                format={/^[0-9][0-9]?[0-9]?$/}
                value={passedYears}
                onChange={(e) => {
                  setPassedYears(e.target.value <= maxYears ? e.target.value : maxYears);
                  setChanged(true);
                }}
                errors={errors}
                onFocus={(event) => event.target.select()}
                adornment={t("operations.crops-techs.soil-work.years")}
                width='130px'
              />
            </Grid>
            <Grid container item alignItems='center' data-woi='tillage-percent'>
              <Typography className={classes.typoMarginRight}>
                {t("operations.crops-techs.soil-work.before-%")}
              </Typography>
              <FieldWithTextAdornment
                disabled={!permissions.canEdit() || !(changedTechs === 1)}
                name='amount'
                format={/^[0-9][0-9]?$|^100$/}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setChanged(true);
                }}
                errors={errors}
                onFocus={(event) => event.target.select()}
                adornment={t("operations.crops-techs.soil-work.%-adornment")}
                width='100px'
              />
              <Typography className={classes.typoMarginLeft}>
                {t("operations.crops-techs.soil-work.%-area-label")}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

TillageSection.propTypes = propsDefinition;

export default TillageSection;
