/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

const ConfirmCrop = ({ onCancel, onConfirm, open, loading }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='md'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle color='primary' id='confirmation-dialog-title'>
        {t("land.paddocks.confirm-crop-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("land.paddocks.confirm-crop-modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCancel}
          variant='text'
          style={{ color: "#f44336" }}
        >
          {t("land.paddocks.confirm-crop-modal.cancel-button-label")}
        </Button>
        <Button disabled={loading} onClick={handleConfirm} color='secondary'>
          {t("land.paddocks.confirm-crop-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmCrop.propTypes = propsDefinition;

export default ConfirmCrop;
