/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import SelectCrop from "./SelectCrop";

//UI Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Provided props
  match: PropTypes.object, // Provided by Router
};

const CropsHeader = ({ match }) => {
  const { t } = useTranslation();

  return (
    <Grid style={{ marginBottom: "12px", marginLeft: "24px" }} container>
      <Grid item container alignItems='center'>
        <Typography style={{ marginRight: "8px" }} variant='h6'>
          {t("operations.crop-title")}
        </Typography>
        <SelectCrop cropId={match.params.cropId} />
      </Grid>
    </Grid>
  );
};

CropsHeader.propTypes = propsDefinition;

export default CropsHeader;
