/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { UPDATE_SELECTED_PADDOCK } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import OperationSection from "./OperationSection";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//UI Components
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(5)}px ${theme.spacing(2)}px`,
  },
  textField: {
    width: "160px",
  },
  operationContainer: {
    "@global": {
      ".MuiInputBase-root.Mui-disabled": {
        color: theme.palette.text.primary,
      },
    },
  },
});

const propsDefinition = {
  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
  selectedPaddock: PropTypes.object, // Provided by Material Ui Style
  farmId: PropTypes.string, // Provided by Material Ui Style
  selectedOperation: PropTypes.string, // Provided by Material Ui Style
  updatePaddock: PropTypes.func, // Provided by Material Ui Style
};

const PaddockSection = ({
  classes,
  selectedPaddock,
  farmId,
  selectedOperation,
  updatePaddock,
}) => {
  const { t } = useTranslation();

  const [paddockArea, setPaddockArea] = useState(0);
  const [paddockYield, setPaddockYield] = useState(0);
  const [isAccurateYield, setIsAccurateYield] = useState("");
  const [lock, setLock] = useState(0);
  const [changed, setChanged] = useState(false);

  const permissions = new Permissions(
    selectedPaddock ? selectedPaddock.status : null,
    lock
  );

  useEffect(() => {
    if (selectedPaddock) {
      setPaddockArea(selectedPaddock.area);
      setPaddockYield(selectedPaddock.yield);
      setIsAccurateYield(selectedPaddock.isAccurateYield);
      setLock(selectedPaddock.lock);
    }
  }, [selectedPaddock]);

  const update = useCallback(
    (newYield, newArea, isAccurateYield) => {
      const requestBody = {
        paddock: {
          yield: newYield,
          area: newArea,
          isAccurateYield,
        },
      };

      const changePaddock = agent.Farms.Paddocks.update(
        farmId,
        selectedPaddock.id,
        requestBody
      )
        .then((res) => {
          updatePaddock(res.body.paddock);
          setChanged(false);
        })
        .catch(() => {
          setChanged(false);
        });

      toast.promise(changePaddock, {
        loading: t("global.loading"),
        success: t("land.paddocks.notifications.paddock-saved"),
        error: t("land.paddocks.notifications.error"),
      });
    },
    [farmId, selectedPaddock, updatePaddock, t]
  );

  useEffect(() => {
    if (changed) {
      const timer = setTimeout(
        () => update(paddockYield, paddockArea, isAccurateYield),
        900
      );
      return () => clearTimeout(timer);
    }
  }, [paddockYield, paddockArea, changed, isAccurateYield, update]);

  if (selectedPaddock) {
    return (
      <React.Fragment>
        <Grid className={classes.root} container spacing={2}>
          <Grid item>
            <FieldWithTextAdornment
              format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
              style={{ width: "130px" }}
              color='secondary'
              label={t("operations.paddock.area")}
              disabled={!permissions.canEdit() || Boolean(selectedOperation)}
              value={paddockArea}
              onChange={(e) => {
                setPaddockArea(e.target.value);
                setChanged(true);
              }}
              onFocus={(event) => event.target.select()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Box color='secondary.main'>ha</Box>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <FieldWithTextAdornment
              format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,1})?$/}
              color='secondary'
              label={t("operations.paddock.yield")}
              disabled={!permissions.canEdit() || Boolean(selectedOperation)}
              value={paddockYield}
              onChange={(e) => {
                setPaddockYield(e.target.value);
                setChanged(true);
              }}
              onFocus={(event) => event.target.select()}
              InputProps={{
                inputProps: { style: { width: "60px" } },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Box color='secondary.main' style={{ marginRight: "8px" }}>
                      T/ha
                    </Box>
                    <FormControl
                      component='fieldset'
                      disabled={!permissions.canEdit() || Boolean(selectedOperation)}
                    >
                      <RadioGroup
                        size='small'
                        row
                        name='position'
                        style={{ display: "flex", alignItems: "center" }}
                        onChange={(e) => {
                          setIsAccurateYield(e.target.value);
                          setChanged(true);
                        }}
                        value={isAccurateYield.toString()}
                      >
                        <FormControlLabel
                          value='0'
                          control={<Radio size='small' color='secondary' />}
                          label={
                            <Typography variant='subtitle2'>
                              {t("land.paddocks.is-accurate-estimated")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value='1'
                          control={<Radio size='small' color='secondary' />}
                          style={{ marginRight: 0 }}
                          label={
                            <Typography variant='subtitle2'>
                              {t("land.paddocks.is-accurate-real")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.operationContainer}>
          <OperationSection />
        </div>
      </React.Fragment>
    );
  }
  return null;
};

PaddockSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  selectedPaddock: state.crop.selectedPaddock,
  selectedOperation: state.crop.selectedOperation,
  farmId: state.farm.farm && state.farm.farm.id,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaddock: (payload) => dispatch({ type: UPDATE_SELECTED_PADDOCK, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaddockSection));
