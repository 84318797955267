/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import BioLogo from "../../../BioLogo";

//UI Components
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    maxWidth: "70%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  data: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
  index: PropTypes.index, // Provided by React Window
};

const PaddockListElement = ({ classes, style, itemIndex, data, onClick, index }) => {
  const { t } = useTranslation();

  const variableListBackground = itemIndex % 2 ? classes.root : "";

  return (
    <ListItem
      button
      className={variableListBackground}
      style={style}
      key={index}
      onClick={() => {
        const paddockId = data ? data.id : null;
        onClick(paddockId);
      }}
    >
      <Grid container alignItems='center'>
        <Grid item xs={2}>
          <Typography variant='body1' noWrap={true}>
            {data.blocperi.period.name}
          </Typography>
        </Grid>
        <Grid item xs={6} container alignItems='center' style={{ paddingLeft: "16px" }}>
          {data.status === 3 ? (
            <Tooltip title={t("operations.paddock.paddock-valid")}>
              <VerifiedUserIcon className={classes.valid} />
            </Tooltip>
          ) : (
            <Tooltip title={t("operations.paddock.paddock-not-valid")}>
              <ErrorIcon className={classes.invalid} />
            </Tooltip>
          )}
          <Typography className={classes.title} variant='body2'>
            {data.blocperi.bloc.name}
          </Typography>
          {data.isOrganic ? <BioLogo height='18px' style={{ marginLeft: "8px" }} /> : ""}
        </Grid>
        <Grid container item xs={4} alignItems='center' justify='flex-end'>
          <Grid item>
            <Typography variant='caption' color='secondary'>
              {t("operations.paddock-modal.list-element.add-label")}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex'>
              <NavigateNextIcon fontSize='small' color='secondary' />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PaddockListElement.propTypes = propsDefinition;

export default withStyles(styles)(PaddockListElement);
