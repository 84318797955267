/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  SELECTED_INPUT_TYPE,
  FARM_INPUTS_PURCHASES_LOADED,
  UPDATE_FARM_INPUT_PURCHASE,
  ADD_FARM_INPUT_PURCHASE,
  REMOVE_FARM_INPUT_PURCHASE,
  UPDATE_FARM_INPUTS_PURCHASES,
  CLEAR_FARM_INPUTS_PURCHASES,
  SELECT_GLOBAL_PERIOD,
} from "../constants/actionTypes";

const FarmInputs = (state = { selectedInputType: "seed" }, action) => {
  switch (action.type) {
    case SELECTED_INPUT_TYPE:
      return {
        ...state,
        loaded: false,
        selectedInputType: action.payload,
      };
    case SELECT_GLOBAL_PERIOD:
      return {
        ...state,
        loaded: false,
      };
    case FARM_INPUTS_PURCHASES_LOADED:
      return {
        ...state,
        loaded: true,
        farmInputsPurchases: action.payload.farmInputsPurchases,
      };
    case UPDATE_FARM_INPUTS_PURCHASES:
      return {
        ...state,
        farmInputsPurchases: action.payload,
      };
    case UPDATE_FARM_INPUT_PURCHASE:
      return {
        ...state,
        farmInputsPurchases: state.farmInputsPurchases.map((farmInputPurchase, index) => {
          if (index === action.index) {
            return action.payload;
          }
          return farmInputPurchase;
        }),
      };
    case ADD_FARM_INPUT_PURCHASE:
      return {
        ...state,
        farmInputsPurchases: [...state.farmInputsPurchases, action.payload],
      };
    case REMOVE_FARM_INPUT_PURCHASE:
      return {
        ...state,
        farmInputsPurchases: [
          ...state.farmInputsPurchases.filter((i, index) => action.index !== index),
        ],
      };
    case CLEAR_FARM_INPUTS_PURCHASES:
      return {
        ...state,
        loaded: false,
        farmInputsPurchases: [],
      };
    default:
      return state;
  }
};

export default FarmInputs;
