/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import FieldWithTextAdornment from "../../../../../FieldWithTextAdornment";
import SearchFarmInputsPurchases from "./SearchFarmInputsPurchases";
import PurchaseListModal from "./PurchaseListModal";
import DeleteConfirmation from "../../../../../DeleteConfirmation";
import QuantityConfirmation from "./QuantityConfirmation";

//Custom Components
import OrganicModal from "./OrganicModal";

//Theme
import customTheme from "../../../../../../theme";

//UI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  selectedPaddock: PropTypes.object.isRequired,
  selectedInputsType: PropTypes.string.isRequired,
  purchase: PropTypes.object,
  farmId: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedOperation: PropTypes.string,
  index: PropTypes.number,
  handleInputErrors: PropTypes.func.isRequired,
  inputErrors: PropTypes.array.isRequired,
  onInputsChange: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  selectedCrop: PropTypes.string.isRequired,
  setCheckStatus: PropTypes.func.isRequired,
};

const OperationInputsRow = ({
  selectedPaddock,
  selectedInputsType,
  purchase = { id: "newLine" },
  farmId,
  selectedPeriod,
  selectedOperation,
  index,
  handleInputErrors,
  inputErrors,
  onInputsChange,
  setEditing,
  selectedCrop,
  setCheckStatus,
}) => {
  const { t } = useTranslation();

  const [id, setId] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [farmInputPurchase, setFarmInputPurchase] = useState({});
  const [operationPrice, setOperationPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantityMin, setQuantityMin] = useState(0);
  const [quantityMax, setQuantityMax] = useState(0);
  const [currency, setCurrency] = useState("");
  const [status, setStatus] = useState(0);
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState([]);
  const [changed, setChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [organicOpen, setOrganicOpen] = useState(false);

  useEffect(() => {
    if (purchase.id && purchase.id !== "newLine" && !id) {
      setId(purchase.id || null);
      setStatus(purchase.status || 0);
      setNote(purchase.note || "");
      setQuantity(String(purchase.quantity));
      setOperationPrice(
        purchase.farmInputPurchase ? purchase.farmInputPurchase.operationPrice : 0
      );
      setCurrency(purchase.farmInputPurchase ? purchase.farmInputPurchase.currency : "");
      setFarmInputPurchase(
        purchase.farmInputPurchase
          ? {
              id: purchase.farmInputPurchase.id,
              name: purchase.farmInputPurchase.name,
              operationUnit: purchase.farmInputPurchase.operationUnit,
            }
          : {}
      );
      if (purchase.farmInputPurchase && purchase.farmInputPurchase.quantities) {
        setQuantityMin(purchase.farmInputPurchase.quantities.min);
        setQuantityMax(purchase.farmInputPurchase.quantities.max);
      } else {
        setQuantityMin("");
        setQuantityMax("");
      }
    } else if (purchase.id === "newLine" && !id) {
      setId("newLine");
    }
  }, [purchase, selectedInputsType, id]);

  useEffect(() => {
    if (id === "newLine") {
      setQuantity(0);
      setFarmInputPurchase({});
      setOperationPrice(0);
      setTotalQuantity(0);
      setTotalPrice(0);
      setQuantityMin(0);
      setQuantityMax(0);
      setCurrency("");
      setStatus(0);
      setNote("");
      setErrors([]);
      setChanged(false);
      setConfirmOpen(false);
      setConfirmed(false);
      setOpenPurchaseModal(false);
      setOpen(false);
      setDeleteOpen(false);
    }
  }, [selectedInputsType, id]);

  useEffect(() => {
    if (!(quantity > 0) || !farmInputPurchase.id) {
      setStatus(0);
    } else if (quantity < quantityMin || quantity > quantityMax) {
      setStatus(1);
    } else if (quantity >= quantityMin && quantity <= quantityMax) {
      setStatus(2);
    }
  }, [quantity, quantityMin, quantityMax, farmInputPurchase]);

  const validateData = useCallback(() => {
    if (id && !(id === "duplicate") && !(id === "newLine")) {
      if (!farmInputPurchase.id && note === "") {
        if (!id) {
          setErrors((errors) => [
            ...errors.filter((item) => item.field !== "farmInputPurchase"),
          ]);
        } else {
          setErrors((errors) => [
            ...errors,
            {
              field: "farmInputPurchase",
              message: t("operations.operation-input.farm-input-purchase-required"),
            },
          ]);
        }
      } else {
        setErrors((errors) => [
          ...errors.filter((item) => item.field !== "farmInputPurchase"),
        ]);
      }
      if (!(quantity > 0)) {
        if (!id && !farmInputPurchase.id) {
          setErrors((errors) => [...errors.filter((item) => item.field !== "quantity")]);
        } else {
          setErrors((errors) => [
            ...errors,
            {
              field: "quantity",
              message: t("operations.operation-input.errors.null-quantity"),
            },
          ]);
        }
      } else {
        setErrors((errors) => [...errors.filter((item) => item.field !== "quantity")]);
      }
    }
  }, [farmInputPurchase, quantity, id, note, t]);

  const setOperationInputErrors = useCallback(
    (errors) => {
      if (inputErrors !== null) {
        if (!inputErrors.some((item) => item === index)) {
          if (errors.length > 0) {
            handleInputErrors([...inputErrors, index]);
          }
        } else {
          if (errors.length === 0) {
            handleInputErrors([...inputErrors.filter((item) => item !== index)]);
          }
        }
      }
    },
    [inputErrors, handleInputErrors, index]
  );

  useEffect(() => {
    let invalidErrors = errors.filter((item) => item.field !== "quantity");
    if (id === "newLine" && farmInputPurchase && farmInputPurchase.id) {
      setOperationInputErrors([{ field: "id" }, ...invalidErrors]);
    } else if (id === "newLine" && (!farmInputPurchase || !farmInputPurchase.id)) {
      setOperationInputErrors([]);
    } else {
      setOperationInputErrors(invalidErrors);
    }
  }, [errors, setOperationInputErrors, id, farmInputPurchase]);

  useEffect(() => {
    if (changed) {
      validateData();
      setCheckStatus(true);
    }
  }, [changed, validateData, setCheckStatus]);

  useEffect(() => {
    if (
      !confirmed &&
      !confirmOpen &&
      quantity > 0 &&
      quantity !== purchase.quantity &&
      changed &&
      (quantityMin > 0 || quantityMax > 0) &&
      (quantity < quantityMin || quantity > quantityMax || quantityMin === quantityMax)
    ) {
      setEditing(true);
      const timer = setTimeout(() => setConfirmOpen(true), 1000);
      return () => clearTimeout(timer);
    } else if (!confirmOpen) {
      setConfirmed(true);
      setEditing(false);
    }
  }, [
    quantity,
    purchase.quantity,
    quantityMin,
    quantityMax,
    changed,
    confirmOpen,
    confirmed,
    setEditing,
  ]);

  const update = useCallback(
    (operationInput) => {
      onInputsChange((inputs) =>
        inputs.map((input, i) => {
          if (index === i) {
            return operationInput;
          }
          return input;
        })
      );
      setChanged(false);
    },
    [onInputsChange, index]
  );

  useEffect(() => {
    let invalidErrors = errors.filter((item) => item.field !== "quantity");
    if (changed && invalidErrors.length === 0 && id && id !== "duplicate" && confirmed) {
      const operationInput = {
        id,
        status,
        quantity,
        note,
        farmInputPurchase: farmInputPurchase.id
          ? {
              id: farmInputPurchase.id,
              name: farmInputPurchase.name
                ? farmInputPurchase.name
                : farmInputPurchase.input.name,
              currency,
              operationUnit: farmInputPurchase.operationUnit
                ? farmInputPurchase.operationUnit
                : farmInputPurchase.input.operationUnit,
              operationPrice: farmInputPurchase.operationPrice,
              quantities: {
                min: quantityMin,
                max: quantityMax,
              },
            }
          : {},
      };
      const timer = setTimeout(() => update(operationInput), 700);
      return () => clearTimeout(timer);
    }
  }, [
    status,
    note,
    farmInputPurchase,
    update,
    changed,
    errors,
    id,
    confirmed,
    quantity,
    quantityMin,
    quantityMax,
    currency,
  ]);

  useEffect(() => {
    setTotalQuantity(
      parseFloat(
        parseFloat(quantity) > 0 ? parseFloat(quantity) * selectedPaddock.area : 0
      ).toFixed(2)
    );
    if (parseFloat(operationPrice) > 0 && parseFloat(quantity) > 0) {
      setTotalPrice(
        parseFloat(parseFloat(operationPrice) * parseFloat(quantity)).toFixed(2)
      );
    }
  }, [quantity, operationPrice, selectedPaddock]);

  const handleCreate = async () => {
    if (errors.length === 0 && (farmInputPurchase.id || note !== "")) {
      const operationInput = {
        id: "toBeAdded",
        status: status,
        quantity: quantity,
        note: note,
        farmInputPurchase: farmInputPurchase.id
          ? {
              id: farmInputPurchase.id,
              name: farmInputPurchase.name
                ? farmInputPurchase.name
                : farmInputPurchase.input.name,
              currency,
              operationUnit: farmInputPurchase.operationUnit
                ? farmInputPurchase.operationUnit
                : farmInputPurchase.input.operationUnit,
              operationPrice: farmInputPurchase.operationPrice,
              quantities: {
                min: quantityMin,
                max: quantityMax,
              },
            }
          : {},
      };

      onInputsChange((inputs) => [...inputs, operationInput]);

      setId(null);
      setQuantity(0);
      setFarmInputPurchase({});
      setOperationPrice(0);
      setTotalQuantity(0);
      setTotalPrice(0);
      setQuantityMin(0);
      setQuantityMax(0);
      setStatus(0);
      setNote("");
      setErrors([]);
      setChanged(false);
    }
  };

  const handleDelete = async () => {
    if (id && id !== "toBeAdded" && id !== "duplicate") {
      onInputsChange((inputs) =>
        inputs.map((input, i) => {
          if (index === i) {
            return { ...input, status: -1 };
          }
          return input;
        })
      );
    } else {
      onInputsChange((inputs) => inputs.filter((input, i) => index !== i));
    }
    setCheckStatus(true);
  };

  const handleFarmInputPurchaseChange = async (selectedFarmInputPurchase) => {
    if (selectedFarmInputPurchase && selectedFarmInputPurchase.id) {
      if (
        selectedFarmInputPurchase.input &&
        selectedFarmInputPurchase.input.isOrganic === 1 &&
        selectedFarmInputPurchase.input.hasOrganicData !== 1
      ) {
        setOrganicOpen(true);
      }
      const { body, error } = await agent.Inputs.Quantities.get(
        selectedInputsType,
        selectedFarmInputPurchase.input.id,
        selectedPeriod
      );
      if (!error) {
        setFarmInputPurchase({
          ...selectedFarmInputPurchase,
          operationUnit: selectedFarmInputPurchase.input.operationUnit,
        });
        setOperationPrice(selectedFarmInputPurchase.operationPrice);
        setCurrency(selectedFarmInputPurchase.currency);
        setQuantityMin(body.quantities.min);
        setQuantityMax(body.quantities.max);
      } else {
        setOperationPrice(0);
        setQuantityMin(0);
        setQuantityMax(0);
        setFarmInputPurchase({});
      }
    } else {
      if (selectedFarmInputPurchase && selectedFarmInputPurchase.dbData) {
        setOpenPurchaseModal(true);
      }
      if (selectedFarmInputPurchase && selectedFarmInputPurchase.missing) {
        setNote(selectedFarmInputPurchase.name);
      }
      setQuantity(0);
      setOperationPrice(0);
      setQuantityMin(0);
      setQuantityMax(0);
      setFarmInputPurchase({});
    }
    setChanged(true);
  };

  const handleAddDuplicate = async () => {
    if (errors.length === 0 && selectedOperation) {
      const operationInput = {
        id: "toBeAdded",
        status: status,
        quantity: quantity,
        note: note,
        farmInputPurchase: farmInputPurchase.id
          ? {
              id: farmInputPurchase.id,
              name: farmInputPurchase.name
                ? farmInputPurchase.name
                : farmInputPurchase.input.name,
              currency,
              operationUnit: farmInputPurchase.operationUnit
                ? farmInputPurchase.operationUnit
                : farmInputPurchase.input.operationUnit,
              quantities: {
                min: quantityMin,
                max: quantityMax,
              },
            }
          : {},
      };

      onInputsChange((inputs) => [
        ...inputs.map((input, i) => {
          if (i === index) {
            return operationInput;
          }
          return input;
        }),
      ]);
    }
  };

  if (status >= 0) {
    return (
      <Grid item container spacing={2}>
        <Grid item data-woi='select-input'>
          <SearchFarmInputsPurchases
            selectedInputsType={selectedInputsType}
            periodId={selectedPeriod}
            farmId={farmId}
            setOpen={setOpen}
            open={open}
            onChange={handleFarmInputPurchaseChange}
            errors={errors}
            selectedCrop={selectedCrop}
            value={farmInputPurchase}
            label={t("operations.operation-input.section.purchase")}
            name='farmInputPurchase'
            note={farmInputPurchase && farmInputPurchase.id ? null : note}
          />
        </Grid>
        <Grid item data-woi='input-quantity'>
          <FieldWithTextAdornment
            disabled={
              farmInputPurchase && (farmInputPurchase.id || note !== "") ? false : true
            }
            label={t("operations.operation-input.section.ha-quantity-label")}
            name='quantity'
            format={/^(?=.*[0-9])\d{0,6}(?:[,.]\d{0,3})?$/}
            errors={[
              ...errors,
              !(quantity > 0) && id && id !== "newLine"
                ? {
                    field: "quantity",
                    message: t("operations.operation-input.errors.null-quantity"),
                  }
                : {},
            ]}
            InputProps={{ inputProps: { min: 0 } }}
            value={quantity}
            onChange={(e) => {
              setConfirmed(false);
              setQuantity(e.target.value);
              setChanged(true);
            }}
            adornment={farmInputPurchase ? farmInputPurchase.operationUnit : ""}
            width='135px'
          />
        </Grid>
        <Grid item>
          <FieldWithTextAdornment
            disabled
            name='min'
            format={/^(?=.*[0-9])\d{0,6}(?:[,.]\d{0,3})?$/}
            label={t("operations.operation-input.section.min")}
            value={quantityMin > 0 || quantityMax > 0 ? quantityMin : ""}
            adornment={farmInputPurchase ? farmInputPurchase.operationUnit : ""}
            width='135px'
          />
        </Grid>
        <Grid item>
          <FieldWithTextAdornment
            disabled
            name='max'
            format={/^(?=.*[0-9])\d{0,6}(?:[,.]\d{0,3})?$/}
            label={t("operations.operation-input.section.max")}
            value={quantityMin > 0 || quantityMax > 0 ? quantityMax : ""}
            adornment={farmInputPurchase ? farmInputPurchase.operationUnit : ""}
            width='135px'
          />
        </Grid>
        <Grid item>
          <FieldWithTextAdornment
            disabled
            format={/^(?=.*[0-9])\d{0,6}(?:[,.]\d{0,3})?$/}
            label={t("operations.operation-input.section.quantity-total")}
            value={totalQuantity}
            adornment={farmInputPurchase ? farmInputPurchase.operationUnit : ""}
            width='150px'
          />
        </Grid>
        <Grid item>
          <FieldWithTextAdornment
            disabled
            format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,2})?$/}
            label={t("operations.operation-input.section.ha-price")}
            value={totalPrice}
            adornment={currency}
            width='135px'
          />
        </Grid>
        {id === "newLine" ? (
          <Grid item>
            <Button
              width='130px'
              disabled={farmInputPurchase && farmInputPurchase.id && !confirmed}
              style={
                farmInputPurchase.id
                  ? {
                      boxShadow: `0px 0px 0px 3px ${customTheme.palette.error.light}`,
                    }
                  : {}
              }
              onClick={handleCreate}
              data-woi='add-operation-input'
            >
              {t("operations.operation-input.section.add-button-label")}
            </Button>
            {farmInputPurchase.id ? (
              <Box style={{ padding: "4px" }} color='error.main'>
                {t("operations.operation-input.section.add-error-message")}
              </Box>
            ) : (
              ""
            )}
          </Grid>
        ) : id !== "duplicate" ? (
          <div>
            <IconButton
              style={{ marginTop: "4px" }}
              onClick={() => {
                setDeleteOpen(true);
              }}
            >
              <DeleteForever color='error' />
            </IconButton>
          </div>
        ) : (
          <Grid item xs={2}>
            <Button disabled={false} fullWidth={true} onClick={handleAddDuplicate}>
              {t("operations.operation-input.section.add-button-label")}
            </Button>
          </Grid>
        )}
        <Portal>
          <QuantityConfirmation
            open={confirmOpen}
            onCancel={() => {
              setEditing(false);
              setQuantity(purchase.quantity ? purchase.quantity : "0");
              setConfirmed(false);
              setConfirmOpen(false);
            }}
            onConfirm={() => {
              setEditing(false);
              setConfirmOpen(false);
              setConfirmed(true);
            }}
            errors={errors}
            unit={farmInputPurchase.operationUnit}
            quantity={quantity}
            setQuantity={setQuantity}
            min={quantityMin}
            max={quantityMax}
          />
        </Portal>
        <Portal>
          <PurchaseListModal
            open={openPurchaseModal}
            operationId={selectedOperation}
            farmId={farmId}
            note={note}
            selectedInputsType={selectedInputsType}
            periodId={selectedPeriod}
            onClose={() => setOpenPurchaseModal(false)}
            setFarmInputPurchase={handleFarmInputPurchaseChange}
          />
        </Portal>
        <Portal>
          <DeleteConfirmation
            open={deleteOpen}
            onCancel={() => {
              setDeleteOpen(false);
            }}
            onConfirm={() => {
              setDeleteOpen(false);
              handleDelete();
            }}
          />
        </Portal>
        <Portal>
          <OrganicModal
            farmInputPurchase={farmInputPurchase}
            open={organicOpen}
            onCancel={() => {
              setQuantity(0);
              setOperationPrice(0);
              setQuantityMin(0);
              setQuantityMax(0);
              setFarmInputPurchase({});
              setOrganicOpen(false);
            }}
            onConfirm={() => {
              setOrganicOpen(false);
            }}
            selectedPeriod={selectedPeriod}
            selectedCrop={selectedCrop}
            farmId={farmId}
          />
        </Portal>
      </Grid>
    );
  }
  return null;
};

OperationInputsRow.propTypes = propsDefinition;

export default OperationInputsRow;
