/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  APP_LOAD,
  REDIRECT,
  LOGIN,
  LOGOUT,
  LOGIN_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  DASHBOARD_REDIRECT,
  EQUIPMENTS_REDIRECT,
  PURCHASES_REDIRECT,
  LAND_REDIRECT,
  CROP_REDIRECT,
  SELECT_LANGUAGE,
  GHG_PROGRAM_REDIRECT,
  HEDGEROWS_REDIRECT,
  LAND_OCCUPATION_REDIRECT,
} from "../constants/actionTypes";
import jwt_decode from "jwt-decode";

const defaultState = {
  token: null,
  viewChangeCounter: 0,
  language: null,
  error: false,
  selectedFarm: null,
};

const Common = (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.username : null,
        language: action.payload ? action.payload.language : null,
        access: action.payload ? action.payload.profile : null,
      };
    case CROP_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/crop`,
      };
    case DASHBOARD_REDIRECT:
      return {
        ...state,
        selectedFarm: action.payload.farmId,
        redirectTo: `/farm/${action.payload.farmId}/dashboard`,
      };
    case EQUIPMENTS_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/equipments`,
      };
    case PURCHASES_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/purchases`,
      };
    case LAND_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/land`,
      };
    case GHG_PROGRAM_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/program`,
      };
    case HEDGEROWS_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/hedgerows`,
      };
    case LAND_OCCUPATION_REDIRECT:
      return {
        ...state,
        redirectTo: `/farm/${action.payload.farmId}/occupation`,
      };
    case REDIRECT:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        redirectTo: null,
      };
    case LOGOUT:
      return { ...state, redirectTo: "/", token: null, currentUser: null };
    case LOGIN:
      const tokenContent = action.payload.sessionToken
        ? jwt_decode(action.payload.sessionToken)
        : null;
      return {
        ...state,
        redirectTo: action.error ? null : "/",
        token: action.error ? null : action.payload.sessionToken,
        currentUser: !action.error && tokenContent ? tokenContent.username : null,
        language: tokenContent ? tokenContent.language : null,
        access: tokenContent ? tokenContent.profile : null,
        error: !tokenContent,
      };
    case SELECT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case HOME_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};

export default Common;
