/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Custom Components
import OperationInputsRow from "./OperationInputsRow";

//UI Components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  purchases: PropTypes.array.isRequired,
  onInputsChange: PropTypes.func.isRequired,
  handleInputErrors: PropTypes.func.isRequired,
  inputErrors: PropTypes.array.isRequired,
  setEditing: PropTypes.func.isRequired,
  selectedInputsType: PropTypes.string.isRequired,
  setCheckStatus: PropTypes.func.isRequired,
  selectedPaddock: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedOperation: PropTypes.string,
  selectedCrop: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
};

const OperationInputsSection = ({
  purchases,
  onInputsChange,
  handleInputErrors,
  inputErrors,
  setEditing,
  selectedInputsType,
  selectedPaddock,
  setCheckStatus,
  selectedPeriod,
  selectedOperation,
  selectedCrop,
  farmId,
}) => {
  if (purchases && selectedInputsType) {
    return (
      <Paper
        style={{
          marginTop: "16px",
          marginBottom: "8px",
          padding: "12px",
          width: "100%",
        }}
        variant='outlined'
      >
        <Grid container spacing={2}>
          {purchases.map((item, index) => {
            let input = { ...item };
            if (!item.id) {
              input.id = "duplicate";
            }
            if (input.status >= 0) {
              return (
                <OperationInputsRow
                  key={index}
                  purchase={input}
                  index={index}
                  handleInputErrors={handleInputErrors}
                  inputErrors={inputErrors}
                  onInputsChange={onInputsChange}
                  setEditing={setEditing}
                  selectedInputsType={selectedInputsType}
                  setCheckStatus={setCheckStatus}
                  selectedPaddock={selectedPaddock}
                  farmId={farmId}
                  selectedPeriod={selectedPeriod}
                  selectedOperation={selectedOperation}
                  selectedCrop={selectedCrop}
                />
              );
            }
            return null;
          })}
          <OperationInputsRow
            handleInputErrors={handleInputErrors}
            inputErrors={inputErrors}
            onInputsChange={onInputsChange}
            setEditing={setEditing}
            selectedInputsType={selectedInputsType}
            setCheckStatus={setCheckStatus}
            selectedPaddock={selectedPaddock}
            farmId={farmId}
            selectedPeriod={selectedPeriod}
            selectedOperation={selectedOperation}
            selectedCrop={selectedCrop}
          />
        </Grid>
      </Paper>
    );
  }
  return null;
};

OperationInputsSection.propTypes = propsDefinition;

export default OperationInputsSection;
