/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SOIL_TYPES_LOADED } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import BlocRow from "./BlocRow";

//UI Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
});

const propsDefinition = {
  //Provided props
  blocs: PropTypes.array, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  soilTypesLoad: PropTypes.func, // Provided by Redux
};

const BlocSection = ({ blocs, classes, soilTypesLoad }) => {
  const { t } = useTranslation();

  const permissions = new Permissions(null, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
    profiles._ENC,
  ]);

  useEffect(() => {
    const loadSoilTypes = async () => {
      const { body, error } = await agent.SoilTypes.get();

      if (!error) {
        soilTypesLoad(body.soilTypes);
      }
    };
    loadSoilTypes();
  }, [soilTypesLoad]);

  const blocList =
    blocs.length > 0
      ? blocs.map((bloc, index) => <BlocRow key={index} index={index} bloc={bloc} />)
      : "";

  return (
    <Grid container className={classes.root} spacing={2} justify='center'>
      <Grid item xs={12}>
        <Typography>{t("land.blocs.description-1")}</Typography>
        <Typography>{t("land.blocs.description-2")}</Typography>
        <Typography>{t("land.blocs.description-3")}</Typography>
      </Grid>
      <Grid item>
        {blocList}
        {permissions.canEdit() && <BlocRow bloc={null} />}
      </Grid>
    </Grid>
  );
};

BlocSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  blocs: state.land.blocs,
});

const mapDispatchToProps = (dispatch) => ({
  soilTypesLoad: (payload) => dispatch({ type: SOIL_TYPES_LOADED, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlocSection));
