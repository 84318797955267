/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const filter = createFilterOptions();

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectedInputsType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  note: PropTypes.string,
  selectedCrop: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
};

const SearchFarmInputsPurchases = ({
  value,
  onChange,
  name,
  selectedInputsType,
  label,
  periodId,
  farmId,
  setOpen,
  open,
  note,
  selectedCrop,
  errors,
}) => {
  const { t } = useTranslation();

  const [farmInputsPurchases, setFarmInputsPurchases] = useState([]);

  useEffect(() => {
    setFarmInputsPurchases([
      {
        id: "chargement",
        input: { name: t("operations.operation-card.farm-input-search.loading") },
      },
    ]);
  }, [selectedInputsType, t]);

  const getFarmInputsPurchases = async () => {
    const { body, error } = await agent.Farms.FarmInputsPurchases.getWithCrop(
      farmId,
      selectedInputsType,
      periodId,
      selectedCrop
    );
    if (!error) {
      setFarmInputsPurchases(body.farmInputsPurchases);
    } else {
      setFarmInputsPurchases({ id: "error", name: "error" });
    }
  };

  const onOpen = async () => {
    setOpen(true);
    await getFarmInputsPurchases();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      style={{ width: "150px" }}
      closeIcon={
        value.id ? <HighlightOffIcon style={{ fontSize: "20px", color: "red" }} /> : ""
      }
      props={{ disableClearable: true }}
      fullWidth={true}
      value={value}
      onClose={onClose}
      onChange={(e, data) => onChange(data)}
      onOpen={onOpen}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        filtered.push({
          dbData: true,
        });

        if (params.inputValue !== "") {
          filtered.push({
            missing: true,
            name: params.inputValue,
          });
        }

        return filtered;
      }}
      freeSolo
      size='small'
      name={name}
      forcePopupIcon={open || value.id ? false : true}
      popupIcon={<SearchIcon />}
      options={farmInputsPurchases}
      getOptionDisabled={(option) => option.id === ("chargement" || "error")}
      getOptionLabel={(option) =>
        option.input && option.input.name
          ? option.input.name
          : option.name
          ? option.name
          : ""
      }
      renderOption={(option) => {
        return option ? (
          option.id ? (
            <div>
              {option.input && option.input.name
                ? option.input.name
                : option.name
                ? option.name
                : ""}
            </div>
          ) : option.missing ? (
            <div style={{ display: "block" }}>
              <div style={{ color: "#f44336" }}>
                {t("operations.search-purchases.missing.1")}
              </div>
              {option.name}
            </div>
          ) : option.dbData ? (
            <div style={{ color: "blue" }} data-woi='search-inputs-db'>
              {t("operations.search-purchases.open-database")}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin='normal'
          variant='outlined'
          label={label}
          error={errors.filter((item) => item.field === name).length > 0}
          helperText={
            errors.filter((item) => item.field === name).length > 0
              ? errors.filter((item) => item.field === name)[0].message
              : note
              ? note
              : ""
          }
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
          value={value}
        />
      )}
    />
  );
};

SearchFarmInputsPurchases.propTypes = propsDefinition;

export default SearchFarmInputsPurchases;
