/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../agent";

//Redux
import { connect } from "react-redux";

//Hooks
import { useTranslation } from "react-i18next";
import { change } from "redux-form";

//Custom
import useIsMountedRef from "../../../utils/isMountedRef";

//Custom theme
import customTheme from "../../../theme";

//UI Components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorText: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,

  //Provided props
  changeRegion: PropTypes.func, // Provided by Redux
};

const SearchCountry = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([
    { id: 0, name: t("dashboard.form.fields.country-search") },
  ]);
  const [loaded, setLooaded] = useState(false);
  const isMountedRef = useIsMountedRef();
  const {
    value,
    onChange,
    disabled,
    errorText,
    name,
    label,
    changeRegion,
    locked,
  } = props;

  useEffect(() => {
    const getCountries = async () => {
      const { body } = await agent.Countries.get();
      if (isMountedRef.current) {
        setCountries(body.countries);
        setLooaded(true);
      }
    };
    getCountries();
  }, [isMountedRef]);

  const onChangeCustom = (event, data) => {
    changeRegion();
    onChange(event, { id: event.target.value });
  };

  if (countries) {
    return (
      <Tooltip
        arrow
        title={locked ? t("dashboard.form.fields.country-search-locked") : ""}
      >
        <FormControl
          variant='outlined'
          color='secondary'
          size='small'
          style={{ width: "100%" }}
        >
          <InputLabel
            style={{
              backgroundColor: "#faf5ea",
              paddingRight: "6px",
              paddingLeft: "6px",
              marginLeft: "-4px",
              color: Boolean(errorText)
                ? customTheme.palette.error.main
                : disabled
                ? customTheme.palette.text.disabled
                : customTheme.palette.text.primary,
            }}
            shrink
          >
            {label}
          </InputLabel>
          <Select
            disabled={!(countries.length > 1) || disabled}
            name={name}
            value={loaded && value.id ? value.id || "" : ""}
            onChange={onChangeCustom}
            data-woi='country'
          >
            {countries.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id} data-woi='country-item'>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
  return null;
};

SearchCountry.propTypes = propsDefinition;

export default connect(null, {
  changeRegion: () => change("ProfileForm", "region", null),
})(SearchCountry);
