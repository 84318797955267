/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { SELECT_FARM_PERIOD, SET_DEFAULT_PERIOD } from "../../constants/actionTypes";

//Notification
import toast from "react-hot-toast";

//Hooks
import { useTranslation } from "react-i18next";

//API
import agent from "../../agent";

//Custom Components
import ConfirmPeriod from "./ConfirmPeriod";

//UI Components
import LockIcon from "@material-ui/icons/Lock";
import HomeIcon from "@material-ui/icons/Home";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Portal from "@material-ui/core/Portal";

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  periods: PropTypes.array, // Provided by Redux
  selectedPeriod: PropTypes.string, // Provided by Redux
  defaultPeriod: PropTypes.string, // Provided by Redux
  setSelectedPeriod: PropTypes.func, // Provided by Redux
  setDefaultPeriod: PropTypes.func, // Provided by Redux
};

const SelectFarmPeriod = ({
  periods,
  selectedPeriod,
  defaultPeriod,
  setSelectedPeriod,
  farmId,
  setDefaultPeriod,
}) => {
  const [confirmPeriodOpen, setConfirmPeriodOpen] = useState(false);
  const [period, setPeriod] = useState(false);

  const { t } = useTranslation();

  const onSelectChange = (e) => {
    const { lock } = e.currentTarget.dataset;
    if (e.target.value !== defaultPeriod) {
      setPeriod({ id: e.target.value, lock: parseInt(lock) });
      setConfirmPeriodOpen(true);
    } else {
      setSelectedPeriod(e.target.value, parseInt(lock));
    }
  };

  if (periods && selectedPeriod) {
    return (
      <>
        <FormControl variant='outlined' size='small' style={{ width: "180px" }}>
          <Select
            name='timePeriod'
            value={selectedPeriod ? selectedPeriod : ""}
            onChange={onSelectChange}
          >
            {periods.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id} data-lock={item.lock.status}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.id === defaultPeriod && (
                      <HomeIcon fontSize='small' style={{ marginRight: "8px" }} />
                    )}
                    {item.name}
                    {item.lock && item.lock.status === 2 && (
                      <LockIcon fontSize='small' style={{ marginLeft: "8px" }} />
                    )}
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Portal>
          <ConfirmPeriod
            open={confirmPeriodOpen}
            onCancel={() => {
              setConfirmPeriodOpen(false);
            }}
            onConfirm={() => {
              const updateDefaultPeriod = agent.Farms.update(farmId, {
                farm: { defaultPeriod: { id: period.id } },
              }).then((res) => {
                setDefaultPeriod(res.body.farm.defaultPeriod.id);
                setSelectedPeriod(period.id, period.lock);
                setConfirmPeriodOpen(false);
              });

              toast.promise(updateDefaultPeriod, {
                loading: t("global.loading"),
                success: t("login.notifications.success"),
                error: t("login.notifications.error"),
              });
            }}
            onVisualize={() => {
              setSelectedPeriod(period.id, period.lock);
              setConfirmPeriodOpen(false);
            }}
          />
        </Portal>
      </>
    );
  }
  return null;
};

SelectFarmPeriod.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  selectedPeriod: state.farm.selectedPeriod,
  farmId: state.farm.farm.id,
  periods: state.farm.periods,
  defaultPeriod: state.farm.defaultPeriod,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedPeriod: (payload, lock) =>
    dispatch({
      type: SELECT_FARM_PERIOD,
      payload,
      lock,
    }),
  setDefaultPeriod: (payload) =>
    dispatch({
      type: SET_DEFAULT_PERIOD,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFarmPeriod);
