import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom Components
import TillageSection from "./TillageSection";
import CoverCropsSection from "./CoverCropsSection";

//UI Components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const style = (theme) => ({
  mainOutlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  blocTitle: {
    marginBottom: theme.spacing(1),
  },
  outlinedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
  typoMarginRight: {
    marginRight: theme.spacing(1),
  },
  typoMarginLeft: {
    marginLeft: theme.spacing(1),
  },
  valid: {
    color: theme.palette.success.main,
    marginRight: "8px",
  },
  invalid: {
    color: theme.palette.error.main,
    marginRight: "8px",
  },
  validateButton: {
    backgroundColor: theme.palette.success.dark,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  cropsTechs: PropTypes.object.isRequired,
  farmId: PropTypes.string,

  //Provided props
  classes: PropTypes.object, // Provided by Redux
};

const CropsTechsRow = (props) => {
  const { cropsTechs, farmId, classes } = props;

  const [id, setId] = useState(null);
  const [bloc, setBloc] = useState(null);
  const [soilWork, setSoilWork] = useState(null);
  const [coverCrops, setCoverCrops] = useState(null);
  const [lock, setLock] = useState(0);

  useEffect(() => {
    if (cropsTechs.id) {
      setId(cropsTechs.id);
      setBloc(cropsTechs.blocperi.bloc);
      setSoilWork(cropsTechs.soilWork);
      setCoverCrops(cropsTechs.landCover);
      setLock(cropsTechs.lock);
    }
  }, [cropsTechs]);

  if (id) {
    return (
      <Paper className={classes.mainOutlinedContainer} variant='outlined'>
        <Typography className={classes.blocTitle} variant='h3'>
          {bloc.name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TillageSection
              lock={lock}
              cropsTechsId={id}
              data={soilWork}
              farmId={farmId}
            />
          </Grid>
          <Grid item xs={6}>
            <CoverCropsSection
              lock={lock}
              cropsTechsId={id}
              data={coverCrops}
              farmId={farmId}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return null;
};

CropsTechsRow.propTypes = propsDefinition;

export default withStyles(style)(CropsTechsRow);
