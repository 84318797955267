/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//HOC
import { withStyles } from "@material-ui/core/styles";

//UI Components
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  itemIndex: PropTypes.number.isRequired,
  style: PropTypes.object,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,

  //Provided props
  classes: PropTypes.object, // Provided by Material Ui Style
};

const PurchaseListElement = ({ classes, itemIndex, style, data, onClick }) => {
  const { t } = useTranslation();

  const variableListBackground = itemIndex % 2 ? classes.root : "";

  return (
    <ListItem
      button
      className={variableListBackground}
      style={style}
      key={itemIndex}
      onClick={() => {
        const farmInputPurchaseId =
          data && data.farmInputPurchase && data.farmInputPurchase.id
            ? data.farmInputPurchase.id
            : null;
        onClick(data, farmInputPurchaseId);
      }}
    >
      <Grid container alignItems='center'>
        <Grid className={classes.nameColumn} item xs={8} data-woi='input-item'>
          <Typography variant='body1' noWrap={true}>
            {data.name}
          </Typography>
        </Grid>
        <Grid container item xs={4} alignItems='center' justify='flex-end'>
          <Grid item>
            <Typography variant='caption' color='secondary'>
              {t("operations.purchases-database-modal.list-element.add-label")}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex'>
              <NavigateNextIcon fontSize='small' color='secondary' />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PurchaseListElement.propTypes = propsDefinition;

export default withStyles(styles)(PurchaseListElement);
