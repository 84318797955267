/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import CustomDialogTitle from "../../../CustomDialogTitle";

//Theme
import customTheme from "../../../../theme";

//UI Components
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ErrorIcon from "@material-ui/icons/Error";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ValidationErrorModal = ({ open, onClose, errors }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const errorList = errors.map((item, index) => (
    <ListItem key={index}>
      <ListItemIcon>
        <ErrorIcon
          style={{
            color: customTheme.palette.error.main,
            fontSize: 28,
          }}
        />
      </ListItemIcon>
      <ListItemText primary={item} />
    </ListItem>
  ));

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth='xs'
      aria-labelledby='validation-error-modal-dialog-title'
      open={open}
    >
      <CustomDialogTitle id='validation-error-modal-dialog-title' onClose={handleClose}>
        {t("operations.validation-modal.title")}
      </CustomDialogTitle>
      <DialogContent dividers>
        <List>{errorList}</List>
      </DialogContent>
    </Dialog>
  );
};

ValidationErrorModal.propTypes = propsDefinition;

export default ValidationErrorModal;
