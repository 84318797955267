/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import common from "./common";
import home from "./home";
import farm from "./farm";
import crop from "./crop";
import global from "./global";
import farmMachineries from "./farmMachineries";
import farmInputs from "./farmInputs";
import land from "./land";
import hedgerows from "./hedgerows";
import ghgPrograms from "./ghgPrograms";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth,
    common,
    home,
    farm,
    crop,
    global,
    farmMachineries,
    farmInputs,
    land,
    hedgerows,
    ghgPrograms,
  });
export default createRootReducer;
