/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  CROP_REDIRECT,
  SELECT_FARM_PERIOD,
  UPDATE_LOCK,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//HOC
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";
import SelectFarmPeriod from "../../SelectFarmPeriod";
import CropCard from "./CropCard";

//Custom Components
import LockButton from "./LockButton";
import LockingConfirmation from "./LockingConfirmation";
import UnlockingConfirmation from "./UnlockingConfirmation";

//Theme
import { grey } from "@material-ui/core/colors";

//UI Components
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Portal from "@material-ui/core/Portal";

const styles = (theme) => ({
  period: {
    fontSize: 20,
  },
  culturesList: {
    marginBottom: theme.spacing(1),
  },
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: grey[300],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  paper: {
    backgroundColor: "inherit",
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  periodId: PropTypes.string, // Provided by Redux
  crops: PropTypes.array, // Provided by Redux
  lock: PropTypes.object, // Provided by Redux
  classes: PropTypes.object, // provided by Material UI Style
  match: PropTypes.object, // provided by the router
  updateLock: PropTypes.func, // Provided by Redux
};

const CropsSection = ({
  classes,
  crops,
  onCropCardClick,
  lock,
  farmId,
  periodId,
  updateLock,
  match,
}) => {
  const { t } = useTranslation();
  const [lockingOpen, setLockingOpen] = useState(false);
  const [unlockingOpen, setUnlockingOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const permissions = new Permissions(null, null, [profiles._ADM]);

  const handleLockingConfirm = () => {
    setLoading(true);
    if (lock.status === 0) {
      const createLock = agent.Farms.Lock.create(farmId, periodId)
        .then((res) => {
          setLockingOpen(false);
          updateLock(res.body.lock, periodId);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });

      toast.promise(createLock, {
        loading: t("global.loading"),
        success: t("lock.locking-modal.notification.success"),
        error: t("lock.locking-modal.notification.error"),
      });
    } else {
      const lockingLock = agent.Farms.Lock.lock(farmId, lock.id)
        .then((res) => {
          setLockingOpen(false);
          updateLock(res.body.lock, periodId);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });

      toast.promise(lockingLock, {
        loading: t("global.loading"),
        success: t("lock.locking-modal.notification.success"),
        error: t("lock.locking-modal.notification.error"),
      });
    }
  };

  const handleLockingCancel = () => {
    setLockingOpen(false);
  };

  const handleUnlockingConfirm = (password) => {
    setLoading(true);
    const unlockLock = agent.Farms.Lock.unlock(farmId, lock.id, password)
      .then((res) => {
        updateLock(res.body.lock, periodId);
        setUnlockingOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.body.error);
        setLoading(false);
      });

    toast.promise(unlockLock, {
      loading: t("global.loading"),
      success: t("lock.unlock-modal.notification.success"),
      error: t("lock.unlock-modal.notification.error"),
    });
  };

  const handleUnlockingCancel = () => {
    setUnlockingOpen(false);
  };

  let cropsList = [];
  if (crops) {
    cropsList = crops.map((item, index) => {
      return (
        <CropCard
          key={index}
          uuid={item.id}
          verified={item.verified}
          title={item.name}
          progress={item.progress}
          onClick={() => onCropCardClick(farmId, item.id)}
        />
      );
    });
  }

  if (match.params.farmId === "new") {
    return (
      <Paper variant='outlined' data-woi='newFarmFeedback' className={classes.root}>
        <Typography align='center' variant='h5'>
          {t("dashboard.crops.new-farm-message")}
        </Typography>
      </Paper>
    );
  }

  if (crops) {
    return (
      <Paper elevation={0} className={classes.paper}>
        <Typography variant='h4'>{t("dashboard.crops.title")}</Typography>
        <Grid className={classes.culturesList} container spacing={3} alignItems='center'>
          <Grid item>
            <Typography className={classes.period}>
              {t("dashboard.crops.period-title")}
            </Typography>
          </Grid>
          <Grid item>
            <SelectFarmPeriod />
          </Grid>
          <Grid item>
            {permissions.canView() && (
              <LockButton
                lock={lock}
                handleUnlocking={() => setUnlockingOpen(true)}
                handleLocking={() => setLockingOpen(true)}
                handleCreateLock={() => setLockingOpen(true)}
              />
            )}
          </Grid>
        </Grid>
        <div>{cropsList}</div>
        <Portal>
          <LockingConfirmation
            disabled={loading}
            open={lockingOpen}
            onConfirm={handleLockingConfirm}
            onCancel={handleLockingCancel}
          />
        </Portal>
        <Portal>
          <UnlockingConfirmation
            error={error}
            disabled={loading}
            open={unlockingOpen}
            onConfirm={(password) => handleUnlockingConfirm(password)}
            onCancel={handleUnlockingCancel}
          />
        </Portal>
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' className={classes.root}>
      <Typography align='center' variant='h5'>
        {t("dashboard.crops.empty-crops-message")}
      </Typography>
    </Paper>
  );
};

CropsSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  crops: state.farm.crops,
  lock: state.farm.lock,
  periodId: state.farm.selectedPeriod,
  farmId: state.farm.farm ? state.farm.farm.id : null,
});

const mapDispatchToProps = (dispatch) => ({
  onCropCardClick: (farmId, cropId, selectedPeriod) => {
    dispatch({
      type: CROP_REDIRECT,
      payload: { farmId, cropId, selectedPeriod },
    });
  },
  setSelectedPeriod: (payload) =>
    dispatch({
      type: SELECT_FARM_PERIOD,
      payload,
    }),
  updateLock: (payload, periodId) => dispatch({ type: UPDATE_LOCK, payload, periodId }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CropsSection));
