/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import logo from "../assets/logo/italicmySoilCapital.png";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  className: PropTypes.string.isRequired,
};

function Logo({ className }) {
  return (
    <img style={{ paddingTop: "8px" }} className={className} alt='logo' src={logo} />
  );
}

Logo.propTypes = propsDefinition;

export default Logo;
