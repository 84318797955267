/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  HOME_SERACH_RESULT,
} from "../constants/actionTypes";

const Home = (state = { farms: [] }, action) => {
  switch (action.type) {
    case HOME_PAGE_LOADED:
    case HOME_SERACH_RESULT:
      return {
        ...state,
        farms: action.payload.farms,
      };
    case HOME_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};

export default Home;
