/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

//UI Components
import Button from "@material-ui/core/Button";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: "8px",
  },
  lock: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  unlock: {
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  lock: PropTypes.object.isRequired,
  handleCreateLock: PropTypes.func.isRequired,
  handleLocking: PropTypes.func.isRequired,
  handleUnlocking: PropTypes.func.isRequired,
};

const LockButton = ({ lock, handleCreateLock, handleLocking, handleUnlocking }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (lock.status) {
    case 0:
      return (
        <Button className={classes.lock} onClick={handleCreateLock}>
          <LockOutlinedIcon className={classes.icon} />
          {t("dashboard.lock.create")}
        </Button>
      );
    case 1:
      return (
        <Button className={classes.lock} onClick={handleLocking}>
          <LockOutlinedIcon className={classes.icon} />
          {t("dashboard.lock.lock")}
        </Button>
      );
    case 2:
      return (
        <Button className={classes.unlock} onClick={handleUnlocking}>
          <LockOpenOutlinedIcon className={classes.icon} />
          {t("dashboard.lock.unlock")}
        </Button>
      );
    default:
      return null;
  }
};

LockButton.propTypes = propsDefinition;

export default LockButton;
