/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const LockingConfirmation = ({ open, onConfirm, onCancel, disabled }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='locking-confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='locking-confirmation-dialog-title'>
        {t("lock.locking-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("lock.locking-modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={handleCancel}
          variant='text'
          style={{ color: "#f44336" }}
        >
          <Box>{t("lock.locking-modal.cancel-button-label")}</Box>
        </Button>
        <Button disabled={disabled} onClick={handleConfirm} color='secondary'>
          {t("lock.locking-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LockingConfirmation.propTypes = propsDefinition;

export default LockingConfirmation;
