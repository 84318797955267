/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import CircularProgress from "@material-ui/core/CircularProgress";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,

  //Provided props
  farmId: PropTypes.string, // Provided by Redux
};

const LoadingSpinner = ({ size, color, className }) => {
  return <CircularProgress className={className} size={size} style={{ color: color }} />;
};

LoadingSpinner.propTypes = propsDefinition;

export default LoadingSpinner;
