/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//Custom
import getErrors from "../../../utils/getErrors";

//Theme
import customTheme from "../../../theme";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired, // Example [{field: name, message: "Invalid value"}]

  //Provided props
  powers: PropTypes.array,
};

const SelectPower = ({
  value,
  onChange,
  label,
  disabled,
  name,
  width,
  errors,
  powers,
}) => {
  const helperError = getErrors(errors, name);

  if (powers) {
    return (
      <FormControl
        disabled={disabled || false}
        variant='outlined'
        size='small'
        style={{ width }}
        color='secondary'
        error={helperError !== ""}
        data-woi='select-irrigation-power'
      >
        <InputLabel
          style={{
            backgroundColor: "#FFF",
            paddingRight: "4px",
            paddingLeft: "4px",
            marginLeft: "-4px",
          }}
          shrink
        >
          {label}
        </InputLabel>
        <Select name='source' displayEmpty={true} value={value || ""} onChange={onChange}>
          {powers.map((item, index) => (
            <MenuItem key={index} value={item.id} data-woi='irrigation-power-item'>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {helperError !== "" && (
          <Typography
            variant='caption'
            style={{
              color: customTheme.palette.error.main,
              marginTop: "4px",
              marginLeft: "12px",
            }}
          >
            {helperError}
          </Typography>
        )}
      </FormControl>
    );
  }
  return null;
};

SelectPower.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  powers: state.global.powers,
});

export default connect(mapStateToProps)(SelectPower);
