/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { HOME_SERACH_RESULT } from "../../constants/actionTypes";

//API
import agent from "../../agent";

//UI Components
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,

  //Provided props
  setSearchResult: PropTypes.func, // Provided by Redux
};

function SearchBar({ label, className, setSearchResult }) {
  const [query, setQuery] = useState("");

  useEffect(() => {
    let timer;
    if (query !== "") {
      timer = setTimeout(() => {
        setSearchResult(agent.Farms.search(query));
      }, 100);

      return () => clearTimeout(timer);
    } else {
      timer = setTimeout(() => {
        setSearchResult(agent.Farms.get(200));
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [query, setSearchResult]);

  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  const handleClear = () => {
    setQuery("");
  };

  return (
    <TextField
      className={className}
      label={label}
      fullWidth={true}
      value={query}
      onChange={handleSearch}
      InputProps={{
        endAdornment:
          query !== "" ? (
            <InputAdornment position='end'>
              <IconButton
                style={{
                  marginRight: "-6px",
                  padding: "4px",
                }}
                className='clearButton'
                aria-label='clear date'
                onClick={handleClear}
                edge='end'
              >
                <HighlightOffIcon style={{ fontSize: "18px", color: "red" }} />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment>
              <SearchIcon />
            </InputAdornment>
          ),
      }}
    />
  );
}

SearchBar.propTypes = propsDefinition;

const mapDispatchToProps = (dispatch) => ({
  setSearchResult: (payload) => dispatch({ type: HOME_SERACH_RESULT, payload }),
});

export default connect(null, mapDispatchToProps)(SearchBar);
