/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import { useEffect, useRef } from "react";

function IsMountedRef() {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  });
  return ref;
}

export default IsMountedRef;
