/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";

//Redux
import { connect } from "react-redux";
import {
  GHG_PROGRAMS_LOADED,
  GLOBAL_PROGRAMS_LOADED,
  GLOBAL_PERIOD_LOADED,
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import LoadingSpinner from "../../LoadingSpinner";
import GhgProgramRow from "./GhgProgramRow";

//Theme
import customTheme from "../../../theme";

const style = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

const GhgProgramSection = (props) => {
  const {
    onGhgProgramsLoaded,
    farmId,
    onProgramsLoaded,
    ghgPrograms,
    loaded,
    classes,
    onPeriodLoaded,
  } = props;

  const permissions = new Permissions(null, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
    profiles._ENC,
  ]);

  useEffect(() => {
    let mounted = true;
    const getGhgProgram = async () => {
      const { body } = await agent.Farms.GhgPrograms.get(farmId);
      if (mounted) {
        onGhgProgramsLoaded(body.ghgPrograms);
      }
    };
    getGhgProgram();
    return () => (mounted = false);
  }, [farmId, onGhgProgramsLoaded]);

  useEffect(() => {
    let mounted = true;
    const getProgramsLoaded = async () => {
      const { body } = await agent.Programs.get();
      if (mounted) {
        onProgramsLoaded(body.programs);
      }
    };
    getProgramsLoaded();
    return () => (mounted = false);
  }, [onProgramsLoaded]);

  useEffect(() => {
    onPeriodLoaded(agent.Periods.get());
  }, [onPeriodLoaded]);

  if (!loaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <LoadingSpinner size='3rem' color={customTheme.palette.secondary.main} />
      </div>
    );
  }

  if (loaded) {
    return (
      <div className={classes.root}>
        {ghgPrograms.map((ghgProgram, index) => (
          <GhgProgramRow
            key={index}
            index={index}
            ghgProgram={ghgProgram}
            farmId={farmId}
          />
        ))}
        {permissions.canEdit() && <GhgProgramRow farmId={farmId} />}
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  ghgPrograms: state.ghgPrograms.ghgPrograms,
  loaded: state.ghgPrograms.loaded,
});

const mapDispatchToProps = (dispatch) => ({
  onGhgProgramsLoaded: (payload) => dispatch({ type: GHG_PROGRAMS_LOADED, payload }),
  onProgramsLoaded: (payload) => dispatch({ type: GLOBAL_PROGRAMS_LOADED, payload }),
  onPeriodLoaded: (payload) =>
    dispatch({
      type: GLOBAL_PERIOD_LOADED,
      payload,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(GhgProgramSection));
