/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//UI Components
import { KeyboardDatePicker } from "@material-ui/pickers";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  initDate: PropTypes.string,
  name: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  focused: PropTypes.bool,
  value: PropTypes.object,
  label: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  errors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SelectDate = ({
  initDate,
  name,
  isOpen,
  focused = true,
  value,
  label,
  setIsOpen,
  disabled = false,
  width,
  disableFuture,
  maxDate,
  minDate,
  errors: errList,
  onChange: handleChange,
}) => {
  const errors = errList ? errList.filter((item) => item.field === name) : [];
  const { field, message } = errors.length > 0 ? errors[0] : {};

  const onChange = (date) => {
    date ? handleChange(date.format("yyyy-MM-DD")) : handleChange(null);
  };

  return (
    <KeyboardDatePicker
      disabled={disabled || !focused}
      style={{
        width: width || "140px",
        "&:hover $clearButton": {
          visibilty: "visible",
        },
        "& $clearButton": {
          visibility: "hidden",
        },
      }}
      initialFocusedDate={initDate ? initDate : null}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      error={field ? true : false}
      helperText={message ? message : null}
      autoOk
      maxDate={maxDate}
      minDate={minDate}
      disableFuture={disableFuture}
      variant='inline'
      inputVariant='outlined'
      format='DD-MM-yyyy'
      onChange={onChange}
      label={label}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      KeyboardButtonProps={{
        style: { padding: 0 },
      }}
    />
  );
};

SelectDate.propTypes = propsDefinition;

export default SelectDate;
