/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import {
  DASHBOARD_LOADED,
  SELECT_FARM_PERIOD,
  SELECT_GLOBAL_PERIOD,
  UPDATE_FARM,
  UPDATE_LOCK,
  SET_DEFAULT_PERIOD,
  UPDATE_PADDOCK,
  ADD_PADDOCK,
  DELETE_PADDOCK,
  DELETE_BLOCPERI,
  DELETE_BLOC,
  DASHBOARD_REDIRECT,
  UPDATE_BLOCPERI,
  UPDATE_SELECTED_PADDOCK_STATUS,
  SELECT_LANGUAGE,
} from "../constants/actionTypes";

const Farm = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_LOADED:
      const periods =
        action.payload && action.payload.farm && action.payload.farm.periods
          ? action.payload.farm.periods
          : [];
      const defaultPeriod =
        action.payload.farm &&
        action.payload.farm.defaultPeriod &&
        Object.keys(action.payload.farm.defaultPeriod).length > 0
          ? action.payload.farm.defaultPeriod.id
          : null;
      const selectedPeriod =
        state.selectedPeriod && state.farm.id === action.payload.farm.id
          ? state.selectedPeriod
          : defaultPeriod && defaultPeriod !== ""
          ? defaultPeriod
          : periods.length > 0
          ? periods[periods.length - 1].id
          : null;
      let loadedCrops = [];
      let lock = {};
      periods.forEach((item) => {
        if (item.id === selectedPeriod) {
          loadedCrops = item.crops;
          lock = item.lock && item.lock.id ? item.lock : { status: 0 };
        }
      });
      return {
        ...state,
        farm: action.payload.farm ? action.payload.farm : null,
        periods,
        selectedPeriod: selectedPeriod,
        defaultPeriod: defaultPeriod,
        crops: loadedCrops,
        lock: lock,
        loaded: true,
        refreshFarm: false,
      };
    case SELECT_GLOBAL_PERIOD:
      return {
        ...state,
        selectedPeriod: action.payload,
      };
    case SELECT_FARM_PERIOD:
      let selectedCrops = [];
      let selectedLock = {};
      state.periods.forEach((item) => {
        if (item.id === action.payload) {
          selectedCrops = item.crops;
          selectedLock = item.lock && item.lock.id ? item.lock : { status: 0 };
        }
      });
      return {
        ...state,
        selectedPeriod: action.payload,
        crops: selectedCrops,
        lock: selectedLock,
      };
    case UPDATE_FARM:
      return {
        ...state,
        farm: action.payload.farm,
      };
    case UPDATE_LOCK:
      const newPeriods = state.periods.map((item) => {
        let period = { ...item };
        if (item.id === action.periodId) {
          period.lock = action.payload;
          return period;
        }
        return item;
      });
      return {
        ...state,
        periods: newPeriods,
        lock: action.payload,
      };
    case SET_DEFAULT_PERIOD:
      return {
        ...state,
        defaultPeriod: action.payload,
      };
    case UPDATE_PADDOCK:
    case ADD_PADDOCK:
    case DELETE_PADDOCK:
    case DELETE_BLOCPERI:
    case UPDATE_BLOCPERI:
    case SELECT_LANGUAGE:
    case DELETE_BLOC:
    case DASHBOARD_REDIRECT:
    case UPDATE_SELECTED_PADDOCK_STATUS:
      return {
        ...state,
        refreshFarm: true,
      };
    case "@@router/LOCATION_CHANGE":
      if (action.payload.location.pathname === "/") {
        return {
          ...state,
          farm: { id: null },
        };
      }
      return state;
    default:
      return state;
  }
};

export default Farm;
