/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  DASHBOARD_LOADED,
  EQUIPMENTS_REDIRECT,
  PURCHASES_REDIRECT,
  LAND_REDIRECT,
  GHG_PROGRAM_REDIRECT,
  HEDGEROWS_REDIRECT,
  LAND_OCCUPATION_REDIRECT,
} from "../../constants/actionTypes";

//API
import agent from "../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom Components
import CropSection from "./CropsSection";
import ProfileForm from "./ProfileForm";
import CardButton from "./CardButton";

//UI Components
import Grid from "@material-ui/core/Grid";

const propsDefinition = {
  //Provided props
  match: PropTypes.object, // Provided by Router
  farm: PropTypes.object, // Provided by Redux
  redirectEquipments: PropTypes.func, // Provided by Redux
  redirectPurchases: PropTypes.func, // Provided by Redux
  redirectLand: PropTypes.func, // Provided by Redux
  redirectHedgerows: PropTypes.func, // Provided by Redux
  redirectOccupation: PropTypes.func, // Provided by Redux
  redirectProgram: PropTypes.func, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  selectedFarm: PropTypes.string, // Provided by Redux
  loaded: PropTypes.bool, // Provided by Redux
  refreshFarm: PropTypes.bool, // Provided by Redux
};

const Dashboard = ({
  match,
  farm,
  redirectEquipments,
  redirectPurchases,
  redirectLand,
  redirectHedgerows,
  redirectOccupation,
  redirectProgram,
  onLoad,
  selectedFarm,
  loaded,
  refreshFarm,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!loaded || refreshFarm) {
      if (selectedFarm !== "new") {
        onLoad(agent.Farms.getOne(selectedFarm));
      } else {
        onLoad({ farm: {} });
      }
    }
  }, [selectedFarm, onLoad, loaded, refreshFarm]);

  if (selectedFarm === "new") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ProfileForm match={match} />
        </Grid>
        <Grid item xs={6}>
          <CropSection match={match} />
        </Grid>
      </Grid>
    );
  }
  if (farm && selectedFarm === farm.id) {
    return (
      <Grid id='dashboard' container spacing={2}>
        <Grid item xs={6}>
          <ProfileForm match={match} />
        </Grid>
        <Grid item xs={6}>
          <CropSection match={match} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='equipmentCard'
            title={t("dashboard.equipments.title")}
            desc={t("dashboard.equipments.sub-title")}
            onClick={() => redirectEquipments(match.params)}
          />
        </Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='purchaseCard'
            title={t("dashboard.purchases.title")}
            desc={t("dashboard.purchases.sub-title")}
            onClick={() => redirectPurchases(match.params)}
          />
        </Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='rotationCard'
            title={t("dashboard.land.title")}
            desc={t("dashboard.land.sub-title")}
            onClick={() => redirectLand(match.params)}
          />
        </Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='programCard'
            title={t("dashboard.ghg-program.title")}
            desc={t("dashboard.ghg-program.sub-title")}
            onClick={() => redirectProgram(match.params)}
          />
        </Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='hedgerowCard'
            title={t("dashboard.hedgerows.title")}
            desc={t("dashboard.hedgerows.sub-title")}
            onClick={() => redirectHedgerows(match.params)}
          />
        </Grid>
        <Grid item xs={6}>
          <CardButton
            dataWoi='landOccupationCard'
            title={t("dashboard.land-occupation.title")}
            desc={t("dashboard.land-occupation.sub-title")}
            onClick={() => redirectOccupation(match.params)}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

Dashboard.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  ...state.farm,
  selectedFarm: state.common.selectedFarm,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: DASHBOARD_LOADED, payload }),
  redirectEquipments: (payload) => dispatch({ type: EQUIPMENTS_REDIRECT, payload }),
  redirectPurchases: (payload) => dispatch({ type: PURCHASES_REDIRECT, payload }),
  redirectLand: (payload) => dispatch({ type: LAND_REDIRECT, payload }),
  redirectProgram: (payload) => dispatch({ type: GHG_PROGRAM_REDIRECT, payload }),
  redirectHedgerows: (payload) => dispatch({ type: HEDGEROWS_REDIRECT, payload }),
  redirectOccupation: (payload) => dispatch({ type: LAND_OCCUPATION_REDIRECT, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
