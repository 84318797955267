/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useTranslation } from "react-i18next";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const CancelConfirmation = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='cancel-confirmation-dialog-title'
      open={open}
    >
      <DialogTitle id='cancel-confirmation-dialog-title'>
        {t("global.cancel-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{t("global.cancel-modal.description")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text' style={{ color: "#f44336" }}>
          <Box>{t("global.cancel-modal.cancel-button-label")}</Box>
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          {t("global.cancel-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelConfirmation.propTypes = propsDefinition;

export default CancelConfirmation;
