/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import {
  ADD_PADDOCK,
  UPDATE_PADDOCK,
  DELETE_PADDOCK
} from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Notifications
import toast from "react-hot-toast";

//Custom
import Permissions from "../../../utils/Permissions";
import profiles from "../../../constants/profiles";

//Custom Components
import SelectCrop from "./SelectCrop";
import SelectBlocperi from "./SelectBlocperi";
import AdminDeleteConfirmation from "../../AdminDeleteConfirmation";
import BlankIcon from "../../BlankIcon";
import DeleteConfirmation from "../../DeleteConfirmation";
import ConfirmBlocperi from "./ConfirmBlocperi";
import ConfirmCrop from "./ConfirmCrop";
import FieldWithTextAdornment from "../../FieldWithTextAdornment";

//Theme
import customTheme from "../../../theme";

//UI Components
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    paddingRight: "16px",
    backgroundColor: "inherit"
  },
  radioGroup: {
    backgroundColor: "inherit"
  }
});

const propsDefinition = {
  //Required attributes to be defined in the implementation
  paddock: PropTypes.object,
  index: PropTypes.number,

  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  addPaddock: PropTypes.func, // Provided by Redux
  updatePaddock: PropTypes.func, // Provided by Redux
  deletePaddock: PropTypes.func, // Provided by Redux
  classes: PropTypes.object // Provided by Material Ui Style
};

const PaddockRow = ({
  paddock = null,
  index,
  farmId,
  addPaddock,
  updatePaddock,
  deletePaddock,
  classes
}) => {
  const [id, setId] = useState(null);
  const [paddockArea, setPaddockArea] = useState(0);
  const [paddockYield, setPaddockYield] = useState("");
  const [crop, setCrop] = useState({});
  const [blocperi, setBlocperi] = useState(null);
  const [notDeletable, setNotDeletable] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [adminDeleteOpen, setAdminDeleteOpen] = useState(false);
  const [isOrganic, setIsOrganic] = useState(false);
  const [isSecondCrop, setIsSecondCrop] = useState(false);
  const [isAccurateYield, setIsAccurateYield] = useState("");
  const [lock, setLock] = useState(0);
  const [blocperiConfirmOpen, setBlocperiConfirmOpen] = useState(false);
  const [cropConfirmOpen, setCropConfirmOpen] = useState(false);

  const { t } = useTranslation();

  const permissions = new Permissions(status ? 3 : 0, lock, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR
  ]);

  useEffect(() => {
    if (paddock) {
      setId(paddock.id);
      setPaddockArea(paddock.area);
      setPaddockYield(paddock.yield);
      setCrop(paddock.crop);
      setBlocperi(paddock.blocperi);
      setIsOrganic(paddock.isOrganic);
      setStatus(paddock.status);
      setNotDeletable(!paddock.flagDelete);
      setIsAccurateYield(paddock.isAccurateYield);
      setLock(paddock.lock);
      setIsSecondCrop(paddock.isSecondCrop);
    }
  }, [paddock]);

  const checkValidate = useCallback(() => {
    let valid = true;
    if (blocperi === null || (blocperi && !blocperi.id)) {
      setErrors(errors => [
        ...errors,
        { field: "blocperi", message: t("land.paddocks.errors.bloc-required") }
      ]);
      valid = false;
    } else {
      setErrors(errors => [
        ...errors.filter(item => item.field !== "blocperi")
      ]);
    }
    if (!crop || !crop.id) {
      setErrors(errors => [
        ...errors,
        { field: "crop", message: t("land.paddocks.errors.crop-required") }
      ]);
      valid = false;
    } else {
      setErrors(errors => [...errors.filter(item => item.field !== "crop")]);
    }
    if (!(paddockArea > 0)) {
      setErrors(errors => [
        ...errors,
        {
          field: "paddockArea",
          message: t("land.paddocks.errors.area-required")
        }
      ]);
      valid = false;
    } else {
      setErrors(errors => [
        ...errors.filter(item => item.field !== "paddockArea")
      ]);
    }
    if (!(paddockYield >= 0)) {
      setErrors(errors => [
        ...errors,
        {
          field: "paddockYield",
          message: t("land.paddocks.errors.yield-required")
        }
      ]);
      valid = false;
    } else {
      setErrors(errors => [
        ...errors.filter(
          item => item.message !== t("land.paddocks.errors.yield-required")
        )
      ]);
    }
    if (isAccurateYield === "") {
      setErrors(errors => [
        ...errors,
        {
          field: "paddockYield",
          message: t("land.paddocks.errors.yield-accuracy")
        }
      ]);
      valid = false;
    } else {
      setErrors(errors => [
        ...errors.filter(
          item => item.message !== t("land.paddocks.errors.yield-accuracy")
        )
      ]);
    }
    return valid;
  }, [blocperi, crop, paddockArea, paddockYield, isAccurateYield, t]);

  useEffect(() => {
    if (changed) {
      checkValidate();
    }
  }, [
    checkValidate,
    changed,
    blocperi,
    crop,
    paddockArea,
    paddockYield,
    isAccurateYield
  ]);

  const handleCreate = () => {
    if (checkValidate()) {
      const reqBody = {
        paddock: {
          blocperi,
          crop,
          area: paddockArea,
          yield: paddockYield,
          isOrganic,
          isAccurateYield,
          isSecondCrop
        }
      };

      const createPaddock = agent.Farms.Paddocks.create(farmId, reqBody).then(
        res => {
          setChanged(false);
          setId(null);
          setPaddockArea(0);
          setPaddockYield("");
          setCrop({});
          setBlocperi(null);
          setNotDeletable(0);
          setIsAccurateYield("");
          setIsSecondCrop(false);
          addPaddock(res.body.paddock);
        }
      );

      toast.promise(createPaddock, {
        loading: t("global.loading"),
        success: t("land.paddocks.notifications.paddock-added"),
        error: t("land.paddocks.notifications.error")
      });
    }
  };

  const handleUpdate = useCallback(() => {
    const validate = (
      blocperi,
      crop,
      paddockArea,
      paddockYield,
      isAccurateYield
    ) => {
      let error = false;
      if (!blocperi || !blocperi.id) {
        error = true;
      }
      if (!crop || !crop.id) {
        error = true;
      }
      if (!(paddockArea > 0)) {
        error = true;
      }
      if (!(paddockYield >= 0)) {
        error = true;
      }
      if (isAccurateYield === "") {
        error = true;
      }

      return error;
    };

    if (!validate(blocperi, crop, paddockArea, paddockYield)) {
      const reqBody = {
        paddock: {
          blocperi,
          crop,
          area: paddockArea,
          yield: paddockYield,
          isOrganic,
          isAccurateYield,
          isSecondCrop
        }
      };

      const changePaddock = agent.Farms.Paddocks.update(
        farmId,
        id,
        reqBody
      ).then(res => {
        updatePaddock(res.body.paddock, index);
        setChanged(false);
      });

      toast.promise(changePaddock, {
        loading: t("global.loading"),
        success: t("land.paddocks.notifications.paddock-saved"),
        error: t("land.paddocks.notifications.error")
      });
    }
  }, [
    crop,
    paddockArea,
    paddockYield,
    farmId,
    id,
    index,
    updatePaddock,
    isOrganic,
    isAccurateYield,
    isSecondCrop,
    t,
    blocperi
  ]);

  useEffect(() => {
    if (changed && id) {
      const timer = setTimeout(() => handleUpdate(), 700);
      return () => clearTimeout(timer);
    }
  }, [
    handleUpdate,
    changed,
    id,
    crop,
    paddockArea,
    paddockYield,
    isOrganic,
    isAccurateYield,
    isSecondCrop
  ]);

  const handleDeleteConfirm = () => {
    if (permissions.isAdmin() && notDeletable) {
      setAdminDeleteOpen(true);
    } else {
      setDeleteOpen(true);
    }
  };

  const handleDelete = () => {
    if (permissions.isAdmin() && notDeletable) {
      const adminDelete = agent.Farms.Paddocks.adminDelete(farmId, id).then(
        () => {
          deletePaddock(index);
        }
      );

      toast.promise(adminDelete, {
        loading: t("global.loading"),
        success: t("land.paddocks.notifications.paddock-deleted"),
        error: t("land.paddocks.notifications.error")
      });
    } else {
      const removePaddock = agent.Farms.Paddocks.delete(farmId, id).then(() => {
        deletePaddock(index);
      });

      toast.promise(removePaddock, {
        loading: t("global.loading"),
        success: t("land.paddocks.notifications.paddock-deleted"),
        error: t("land.paddocks.notifications.error")
      });
    }
  };

  const actionType =
    !id && permissions.canEdit() ? (
      <Grid item>
        <Button
          disabled={loading}
          fullWidth={true}
          onClick={handleCreate}
          data-woi="add-paddock-button"
        >
          {t("land.paddocks.add-button-label")}
        </Button>
      </Grid>
    ) : (permissions.canView() || status !== 3) && permissions.canEdit() ? (
      <Tooltip
        title={
          (notDeletable && !permissions.isAdmin() ? (
            true
          ) : (
            false
          )) ? (
            <Typography>{t("land.paddocks.not-deletable-message")}</Typography>
          ) : (
            ""
          )
        }
      >
        <div>
          <IconButton
            disabled={notDeletable && !permissions.isAdmin() ? true : false}
            onClick={handleDeleteConfirm}
            data-woi="delete-paddock-button"
          >
            <DeleteForever
              color={
                (notDeletable && !permissions.isAdmin()
                ? true
                : false)
                  ? "disabled"
                  : "error"
              }
            />
          </IconButton>
        </div>
      </Tooltip>
    ) : null;

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item data-woi="select-bloc">
        <SelectBlocperi
          name="blocperi"
          disabled={!permissions.canEdit()}
          onChange={(e, blocperi) => {
            if (!id) {
              setBlocperi(blocperi);
              setChanged(true);
            } else {
              setBlocperi(blocperi);
              setBlocperiConfirmOpen(true);
            }
          }}
          blocperi={blocperi}
          width="140px"
          label={t("land.paddocks.bloc")}
          errors={errors}
        />
      </Grid>
      <Grid item data-woi="select-crop">
        <SelectCrop
          name="crop"
          disabled={!permissions.canEdit()}
          onChange={(e, crop) => {
            if (!id) {
              setCrop(crop);
              setChanged(true);
            } else {
              setCrop(crop);
              setCropConfirmOpen(true);
            }
          }}
          crop={crop}
          width="180px"
          label={t("land.paddocks.crop")}
          errors={errors}
        />
      </Grid>
      <Grid item data-woi="second-crop-check">
        <Paper variant="outlined" className={classes.checkboxes}>
          <Checkbox
            disabled={!permissions.canEdit()}
            size="small"
            name="grouped"
            checked={isSecondCrop ? true : false}
            onChange={(e, data) => {
              setIsSecondCrop(data);
              setChanged(true);
            }}
          />
          {t("land.paddocks.crop-second")}
        </Paper>
      </Grid>
      <Grid item style={{ paddingTop: "9px" /* Status height adjustement */ }}>
        {permissions.canView() && status === 0 ? (
          <Tooltip title={t("land.paddocks.paddock-not-valid")} arrow>
            <div>
              <IconButton disabled size="small">
                <ErrorIcon style={{ color: customTheme.palette.error.main }} />
              </IconButton>
            </div>
          </Tooltip>
        ) : status === 3 ? (
          <Tooltip title={t("land.paddocks.paddock-valid")} arrow>
            <div>
              <IconButton disabled size="small">
                <VerifiedUserIcon
                  style={{ color: customTheme.palette.success.dark }}
                />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div>
            <IconButton disabled size="small">
              <BlankIcon />
            </IconButton>
          </div>
        )}
      </Grid>
      <Grid item data-woi="organic-check">
        <Paper variant="outlined" className={classes.checkboxes}>
          <Checkbox
            disabled={!permissions.canEdit()}
            size="small"
            name="grouped"
            checked={isOrganic ? true : false}
            onChange={(e, data) => {
              setIsOrganic(data);
              setChanged(true);
            }}
          />
          {t("land.paddocks.organic")}
        </Paper>
      </Grid>
      <Grid item data-woi="paddock-area">
        <FieldWithTextAdornment
          format={/^(?=.*[0-9])\d{0,4}(?:[,.]\d{0,2})?$/}
          disabled={!permissions.canEdit()}
          style={{ width: "95px" }}
          color="secondary"
          label={t("land.paddocks.area")}
          value={paddockArea}
          onChange={e => {
            setPaddockArea(e.target.value);
            setChanged(true);
          }}
          error={errors.filter(item => item.field === "paddockArea").length > 0}
          helperText={
            errors.filter(item => item.field === "paddockArea").length > 0
              ? errors.filter(item => item.field === "paddockArea")[0].message
              : ""
          }
          onFocus={event => event.target.select()}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">
                <Box color="secondary.main">ha</Box>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item data-woi="crop-yield">
        <FieldWithTextAdornment
          format={/^(?=.*[0-9])\d{0,3}(?:[,.]\d{0,1})?$/}
          disabled={!permissions.canEdit()}
          color="secondary"
          label={t("land.paddocks.yield")}
          value={paddockYield}
          onChange={e => {
            setPaddockYield(e.target.value);
            setChanged(true);
          }}
          error={
            errors.filter(item => item.field === "paddockYield").length > 0
          }
          helperText={
            errors.filter(item => item.field === "paddockYield").length > 0
              ? errors.filter(item => item.field === "paddockYield")[0].message
              : ""
          }
          onFocus={event => event.target.select()}
          InputProps={{
            inputProps: { min: 0, style: { width: "40px" } },
            endAdornment: (
              <InputAdornment position="end">
                <Box color="secondary.main" style={{ marginRight: "8px" }}>
                  T/ha
                </Box>
                <FormControl
                  component="fieldset"
                  error={
                    errors.filter(item => item.field === "paddockYield")
                      .length > 0
                  }
                  disabled={!permissions.canEdit()}
                >
                  <RadioGroup
                    size="small"
                    row
                    name="position"
                    style={{ display: "flex", alignItems: "center" }}
                    onChange={e => {
                      setIsAccurateYield(e.target.value);
                      setChanged(true);
                    }}
                    value={isAccurateYield.toString()}
                  >
                    <FormControlLabel
                      value="0"
                      style={
                        isAccurateYield === "" && errors.length > 0
                          ? { color: customTheme.palette.error.main }
                          : null
                      }
                      control={
                        <Radio
                          data-woi="estimated-yield-check"
                          size="small"
                          color="secondary"
                          style={
                            isAccurateYield === "" && errors.length > 0
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                        />
                      }
                      label={
                        <Typography variant="subtitle2">
                          {t("land.paddocks.is-accurate-estimated")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="1"
                      style={
                        isAccurateYield === "" && errors.length > 0
                          ? {
                              color: customTheme.palette.error.main,
                              marginRight: 0
                            }
                          : { marginRight: 0 }
                      }
                      control={
                        <Radio
                          data-woi="real-yield-check"
                          size="small"
                          color="secondary"
                          style={
                            isAccurateYield === "" && errors.length > 0
                              ? { color: customTheme.palette.error.main }
                              : null
                          }
                        />
                      }
                      label={
                        <Typography variant="subtitle2">
                          {t("land.paddocks.is-accurate-real")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      {actionType}
      <Portal>
        <DeleteConfirmation
          open={deleteOpen}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          onConfirm={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
      <Portal>
        <AdminDeleteConfirmation
          open={adminDeleteOpen}
          onCancel={() => {
            setAdminDeleteOpen(false);
          }}
          onConfirm={() => {
            setAdminDeleteOpen(false);
            handleDelete();
          }}
        />
      </Portal>
      <Portal>
        <ConfirmBlocperi
          open={blocperiConfirmOpen}
          onCancel={() => {
            setBlocperiConfirmOpen(false);
            setBlocperi(paddock.blocperi);
          }}
          onConfirm={() => {
            setBlocperiConfirmOpen(false);
            setChanged(true);
          }}
        />
      </Portal>
      <Portal>
        <ConfirmCrop
          open={cropConfirmOpen}
          onCancel={() => {
            setCropConfirmOpen(false);
            setCrop(paddock.crop);
          }}
          onConfirm={() => {
            setCropConfirmOpen(false);
            setChanged(true);
          }}
        />
      </Portal>
    </Grid>
  );
};

PaddockRow.propTypes = propsDefinition;

const mapStateToProps = state => ({
  farmId: state.farm.farm.id,
  access: state.common.access
});

const mapDispachToProps = dispatch => ({
  addPaddock: payload => dispatch({ type: ADD_PADDOCK, payload }),
  updatePaddock: (payload, index) =>
    dispatch({ type: UPDATE_PADDOCK, payload, index }),
  deletePaddock: index => dispatch({ type: DELETE_PADDOCK, index })
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(withStyles(styles)(PaddockRow));
