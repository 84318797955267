/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//UI Components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onChange: PropTypes.func.isRequired,
  baselinePeriod: PropTypes.object,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,

  //Provided props
  periods: PropTypes.array, // Provided by Redux
};

const SelectPeriod = ({ periods = [], onChange, baselinePeriod, label, disabled }) => {
  if (baselinePeriod !== undefined && periods.length > 0) {
    return (
      <FormControl
        disabled={disabled}
        variant='outlined'
        size='small'
        style={{ width: "180px" }}
      >
        <InputLabel
          style={{
            backgroundColor: "#FFF",
            paddingRight: "4px",
            paddingLeft: "4px",
            marginLeft: "-4px",
          }}
          shrink
        >
          {label}
        </InputLabel>
        <Select
          name='timePeriod'
          value={(baselinePeriod && baselinePeriod.id) || ""}
          onChange={onChange}
        >
          {periods.map((item, i) => {
            return (
              <MenuItem key={i} value={item.id} data-woi='period-item'>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
  return null;
};

SelectPeriod.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  periods: state.global.periods,
});

export default connect(mapStateToProps)(SelectPeriod);
