/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import Header from "./Header";
import Container from "./Container";

class Equipments extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Container />
      </React.Fragment>
    );
  }
}

export default Equipments;
