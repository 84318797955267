/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

//Custom
import Permissions from "../../../../../utils/Permissions";
import profiles from "../../../../../constants/profiles";

//Custom Components
import BlankIcon from "../../../../BlankIcon";

//Theme
import customTheme from "../../../../../theme";

//UI Components
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    marginTop: "4px",
  },
}));

const propsDefinition = {
  //Required attributes to be defined in the implementation
  status: PropTypes.number,
};

const OperationStatusIcon = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const permissions = new Permissions(null, null, [
    profiles._ADM,
    profiles._AGRS,
    profiles._AGR,
  ]);

  let statusIcon = <BlankIcon />;

  switch (status) {
    case 0:
      if (permissions.canView())
        statusIcon = (
          <Tooltip
            className={classes.statusIcon}
            title={t("operations.operation-card.validation.status-0")}
            arrow
          >
            <ErrorIcon
              style={{
                color: customTheme.palette.error.main,
                fontSize: 28,
              }}
            />
          </Tooltip>
        );
      break;
    case 1:
      if (permissions.canView())
        statusIcon = (
          <Tooltip
            className={classes.statusIcon}
            title={t("operations.operation-card.validation.status-1")}
            arrow
          >
            <WarningIcon style={{ color: customTheme.palette.warning.main }} />
          </Tooltip>
        );
      break;
    case 2:
      if (permissions.canView())
        statusIcon = (
          <Tooltip
            className={classes.statusIcon}
            title={t("operations.operation-card.validation.status-2")}
            arrow
          >
            <CheckCircleIcon
              style={{
                color: customTheme.palette.success.main,
                fontSize: 28,
              }}
            />
          </Tooltip>
        );
      break;
    case 3:
      statusIcon = (
        <Tooltip
          className={classes.statusIcon}
          title={t("operations.operation-card.validation.status-3")}
          arrow
        >
          <VerifiedUserIcon
            style={{
              color: customTheme.palette.success.dark,
              fontSize: 28,
            }}
          />
        </Tooltip>
      );
      break;
    default:
      statusIcon = <BlankIcon />;
      break;
  }

  return <>{statusIcon}</>;
};

OperationStatusIcon.propTypes = propsDefinition;

export default OperationStatusIcon;
