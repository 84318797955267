/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";

//Custom Components
import Header from "./Header";
import GhgProgramSection from "./GhgProgramSection";


class GhgProgram extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <GhgProgramSection />
      </div>
    );
  }
}

export default GhgProgram;
