/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

//API
import agent from "../../../../../../agent";

//Hooks
import { useTranslation } from "react-i18next";

//Custom
import FieldWithTextAdornment from "../../../../../FieldWithTextAdornment";

//UI Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  farmInputPurchase: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedCrop: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
};

const OrganicModal = ({
  onCancel,
  onConfirm,
  open,
  loading,
  farmInputPurchase,
  selectedPeriod,
  selectedCrop,
  farmId,
}) => {
  const [passedYears, setPassedYears] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState([]);

  const { t } = useTranslation();

  const validateData = useCallback(() => {
    if (!(passedYears > 0)) {
      setErrors((errors) => [
        ...errors,
        { field: "passedYears", message: t("Mandatory") },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "passedYears")]);
    }
    if (!(percentage > 0)) {
      setErrors((errors) => [
        ...errors,
        { field: "percentage", message: t("Mandatory") },
      ]);
    } else {
      setErrors((errors) => [...errors.filter((error) => error.field !== "percentage")]);
    }
  }, [passedYears, percentage, t]);

  useEffect(() => {
    if (changed) {
      validateData();
    }
  }, [changed, validateData]);

  const handleCreate = async () => {
    const reqBody = {
      cropsOrganics: {
        fertiliser: {
          id: farmInputPurchase.input.id,
        },
        passedYears,
        percentage,
      },
    };
    const { error } = await agent.Farms.CropsOrganic.create(
      farmId,
      selectedPeriod,
      selectedCrop,
      reqBody
    );

    setPassedYears(0);
    setPercentage(0);
    setChanged(false);
    setErrors([]);

    if (!error) {
      onConfirm();
    } else {
      onCancel();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='confirmation-dialog-title'
      open={open}
    >
      <DialogTitle color='primary' id='confirmation-dialog-title'>
        {t("operations.operation-input-organmic-modal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item container>
            <Typography>
              {t("operations.operation-input-organmic-modal.subtitle")}
            </Typography>
          </Grid>
          <Grid container item alignItems='center' data-woi='organic-fertiliser-years'>
            <Grid item>
              <Typography style={{ marginRight: "8px" }}>
                {t("operations.operation-input-organmic-modal.passedYears")}
              </Typography>
            </Grid>
            <FieldWithTextAdornment
              name='passedYears'
              format={/^[0-9][0-9]?[0-9]?$/}
              value={passedYears}
              onChange={(e) => {
                setPassedYears(e.target.value);
                setChanged(true);
              }}
              errors={errors}
              onFocus={(event) => event.target.select()}
              adornment={t("operations.operation-input-organmic-modal.yearsAgo")}
              width='140px'
            />
          </Grid>
          <Grid container item alignItems='center' data-woi='organic-fertiliser-percent'>
            <Grid item>
              <Typography style={{ marginRight: "8px" }}>
                {t("operations.operation-input-organmic-modal.on")}
              </Typography>
            </Grid>
            <FieldWithTextAdornment
              name='percentage'
              format={/^[0-9][0-9]?$|^100$/}
              value={percentage}
              onChange={(e) => {
                setPercentage(e.target.value);
                setChanged(true);
              }}
              errors={errors}
              onFocus={(event) => event.target.select()}
              adornment={"%"}
              width='140px'
            />
            <Grid item>
              <Typography style={{ marginLeft: "8px" }}>
                {t("operations.operation-input-organmic-modal.percentage")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCancel}
          variant='text'
          style={{ color: "#f44336" }}
        >
          {t("operations.paddock-modal.confirm-modal.cancel-button-label")}
        </Button>
        <Button
          disabled={loading || !(passedYears > 0) || !(percentage > 0)}
          onClick={handleCreate}
          color='secondary'
          data-woi='add-organic-fertiliser'
        >
          {t("operations.paddock-modal.confirm-modal.confirm-button-label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrganicModal.propTypes = propsDefinition;

export default OrganicModal;
