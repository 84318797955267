/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";

//Custom
import getErrors from "../../../../../utils/getErrors";

//Theme
import customTheme from "../../../../../theme";

//UI Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

const propsDefinition = {
  //Required attributes to be defined in the implementation
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired, // Example [{field: name, message: "Invalid value"}]

  //Provided props
  techs: PropTypes.array, // Provided by Redux
};

const SelectTech = (props) => {
  const { value, onChange, label, disabled, name, width, errors, techs } = props;

  const helperError = getErrors(errors, name);

  if (techs) {
    return (
      <FormControl
        disabled={disabled || false}
        variant='outlined'
        size='small'
        style={{ width }}
        color='secondary'
        error={helperError !== ""}
        data-woi='select-tillage-change'
      >
        <InputLabel
          style={{
            backgroundColor: "#FFF",
            paddingRight: "4px",
            paddingLeft: "4px",
            marginLeft: "-4px",
          }}
          shrink
        >
          {label}
        </InputLabel>
        <Select
          name='tech'
          displayEmpty={true}
          value={value || ""}
          onChange={onChange}
          onClick={(e) => {
            if (props.focused) {
              e.stopPropagation();
            }
          }}
        >
          {techs.map((item, index) => (
            <MenuItem key={index} value={item.id} data-woi='tillage-change-item'>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {helperError !== "" && (
          <Typography
            variant='caption'
            style={{
              color: customTheme.palette.error.main,
              marginTop: "4px",
              marginLeft: "12px",
            }}
          >
            {helperError}
          </Typography>
        )}
      </FormControl>
    );
  }
  return null;
};

SelectTech.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  techs: state.global.techs,
});

export default connect(mapStateToProps)(SelectTech);
