/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React from "react";
import { FixedSizeList } from "react-window";
import PropTypes from "prop-types";

//Custom Components
import ListElement from "./ListElement";

//UI Components
import Paper from "@material-ui/core/Paper";

const ItemRenderer = ({ data, index, style }) => {
  // Access the items array using the "data" prop:
  const item = data.items[index];

  return (
    <ListElement onClick={data.onClick} style={style} data={item} itemIndex={index} />
  );
};

const propsDefinition = {
  //Required attributes to be defined in the implementation
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

const List = ({ onClick, items }) => {
  return (
    <Paper variant='outlined'>
      {items ? (
        <FixedSizeList
          itemData={{ items: items, onClick: onClick }}
          height={400}
          itemSize={50}
          itemCount={items.length}
        >
          {ItemRenderer}
        </FixedSizeList>
      ) : (
        ""
      )}
    </Paper>
  );
};

List.propTypes = propsDefinition;

export default List;
