/* Copyright (C) 2020 Soil Capital Belgium SPRL - All Rights Reserved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { HEDGEROWS_LOADED } from "../../../constants/actionTypes";

//API
import agent from "../../../agent";

//HOC
import { withStyles } from "@material-ui/core/styles";

//Custom
import Permissions from "../../../utils/Permissions";

//Custom Components
import HedgerowsRow from "./HedgerowRow";

const style = (theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
});

const propsDefinition = {
  //Provided props
  farmId: PropTypes.string, // Provided by Redux
  classes: PropTypes.object, // Provided by Material Ui Style
  selectedPeriod: PropTypes.string, // Provided by Redux
  onLoad: PropTypes.func, // Provided by Redux
  hedgerows: PropTypes.array, // Provided by Redux
  lock: PropTypes.number, // Provided by Redux
};

const HedgerowsSection = ({
  classes,
  farmId,
  selectedPeriod,
  onLoad,
  hedgerows,
  lock,
}) => {
  const permissions = new Permissions(null, lock);

  useEffect(() => {
    const getHedgerowsLoaded = async () => {
      const { body, error } = await agent.Farms.Hedgerows.get(farmId, selectedPeriod);

      if (!error) {
        onLoad(body.hedgerows);
      }
    };
    getHedgerowsLoaded();
  }, [farmId, selectedPeriod, onLoad]);

  const hedgerowsList =
    hedgerows && hedgerows.length > 0
      ? hedgerows.map((item, index) => (
          <HedgerowsRow
            key={index}
            hedgerow={item}
            index={index}
            farmId={farmId}
            selectedPeriod={selectedPeriod}
          />
        ))
      : [];

  return (
    <div className={classes.root}>
      <div>
        {hedgerowsList}
        {permissions.canEdit() && (
          <HedgerowsRow farmId={farmId} selectedPeriod={selectedPeriod} />
        )}
      </div>
    </div>
  );
};

HedgerowsSection.propTypes = propsDefinition;

const mapStateToProps = (state) => ({
  farmId: state.farm.farm.id,
  selectedPeriod: state.farm.selectedPeriod,
  hedgerows: state.hedgerows.hedgerows,
  lock: state.farm.lock.status,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: HEDGEROWS_LOADED, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(HedgerowsSection));
